import { Button, Form, Input, Select } from "antd"
import Module from '../style/notPunishDecision.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { MinusOutlined } from "@ant-design/icons";


export default function NotPutOnRecordDecision(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>不予行政处罚决定书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[1, "fieldValue"]}
                >
                  <Input
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人" />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[6, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="违反行为及法律"
                  />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[7, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="处罚法律内容"
                  />
                </Form.Item>
                <p>你（单位）如不服本决定，可以自收到本决定书之日起60日内申请行政复议</p>
                <div className={Module.time}>
                  <p className={Module.a1}>申请行政复议单位名称：</p>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="申请行政复议单位名称                         "
                    />
                  </Form.Item>
                </div>
                <p>可以自收到本决定书之日起6个月内依法向人民法院提起行政诉讼。</p>
                <div className={Module.time}>
                  <p className={Module.a1}>人民法院名称：</p>
                  <Form.Item
                    className={Module.item}
                    name={[3, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="人民法院名称                         "
                    />
                  </Form.Item>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>行政执法主体名称：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[8, "fieldValue"]}>
                      <Select placeholder="行政执法主体名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[4, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[5, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

