import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/blacklistNotice.module.scss'
export default function BlacklistNotice(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>纳入联合惩戒对象管理告知书</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row3}>
                    <div className={Module.a1} >
                      <p>被告知对象</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[0, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="被告知对象" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row1}>
                    <div className={Module.d1}>
                      <p>信息来源</p>
                    </div>
                    <div className={Module.d2}>
                      <p className={Module.h2}>执法检查</p>
                      <Form.Item
                        name={[1, "fieldValue"]}
                        className={Module.item}
                        initialValue="⬜"
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 1, "fieldValue"])}
                        />
                      </Form.Item>
                      <p className={Module.h2}>事故调查</p>
                      <Form.Item
                        name={[2, "fieldValue"]}
                        className={Module.item}
                        initialValue="⬜"
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 2, "fieldValue"])}
                        />
                      </Form.Item>
                      <p className={Module.h2}>举报核查</p>
                      <Form.Item
                        name={[3, "fieldValue"]}
                        className={Module.item}
                        initialValue="⬜"
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 3, "fieldValue"])}
                        />
                      </Form.Item>
                      <p className={Module.h2}>其他</p>
                      <Form.Item
                        name={[4, "fieldValue"]}
                        className={Module.item}
                        initialValue="⬜"
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 4, "fieldValue"])}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1} >
                      <p>纳入理由/失信情形</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[5, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="纳入理由/失信情形" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1} >
                      <p>拟纳入对象（单位或个人）</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[6, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="拟纳入对象（单位或个人）" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>认定部门提交意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <p>按照国家相关规定，拟将以上对象纳入联合惩戒对象黑名单管理</p>
                        <div className={Module.input1}>
                          <p>期限：</p>
                          <Form.Item
                            className={Module.item}
                            name={[7, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="期限" />
                          </Form.Item>
                        </div>
                        <div className={Module.input2}>
                          <Form.Item
                            className={Module.item}
                            name={[8, "fieldValue"]}
                          >
                            <StringDatePicker
                              format="YYYY年MM月DD日"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="开始时间" />
                          </Form.Item>
                          <Form.Item
                            className={Module.item}
                            name={[9, "fieldValue"]}
                          >
                            <StringDatePicker
                              format="YYYY年MM月DD日"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="截止时间" />
                          </Form.Item>
                        </div>
                        <p>有陈述和申辩的权利，请于接到此告知书之日起，7日内书面提交陈述和申辩意见，逾期视为放弃上述权利。</p>
                        <Form.Item
                          className={Module.select1}
                          name={[10, "fieldValue"]}
                        >
                          <StringDatePicker
                            placeholder="日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                        <Form.Item
                          className={Module.select1}
                          name={[12, "fieldValue"]} >
                          <Select placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>送达人（行政执法人员）</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        name={[13, "fieldValue"]} className={Module.select1}>
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="主办签名" >
                          {(props.users ?? []).map(user => <Select.Option
                            key={user.id.toString()}>{user.realName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[14, "fieldValue"]} className={Module.select1}>
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="协办签名" >
                          {(props.users ?? []).map(user => <Select.Option
                            key={user.id.toString()}>{user.realName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[11, "fieldValue"]}
                      >
                        <StringDatePicker
                          placeholder="签名日期"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          className={Module.time1} format="YYYY年MM月DD日" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}