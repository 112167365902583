import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Module from '../style/login.module.scss'
import { useLocation, useNavigate } from "react-router-dom";
import ApiOpen from "../internet/ApiOpen";
import { ApiUser } from "../internet/ApiUser";
import UserEntity from "../entity/UserEntity";
import { User } from "../entity/User";
import { ProjectInfoEntity } from "../entity/ProjectInfo";
import { ApiProjectInfo } from "../internet/ApiProjectInfo";


const Login: React.FC<{ setUser: (users: UserEntity) => void }> = (props) => {
  const [password, setPassword] = useState<string>("")
  const [account, setAccount] = useState<string>("")
  const state = useLocation().state as { from: Location | null }
  const passReg = new RegExp(/^(\w){6,20}$/)
  const navigate = useNavigate()
  const apiOpen = new ApiOpen(message, navigate)
  const apiUser = new ApiUser(message, navigate)
  const [projectInfo, setProjectInfo] = useState<ProjectInfoEntity>()
  const apiprojectinfo = new ApiOpen(message, navigate)
  const passwordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.target.value
    setPassword(value)
  }

  const accountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.target.value
    setAccount(value)
  }

  const longinMethod = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    apiOpen.login(account, password, {
      exit: 0,
      successHandler: user => {
        apiUser.getUser({
          successHandler: (user => {
            if (user) {
              props.setUser(user)
              let path = state?.from?.pathname ?? "/"
              if (path === "/logout") {
                path = "/"
              }
              navigate(path, { replace: true })
            } else {
              message.error("账号或密码错误")
            }
          }
          ),otherHandler(code, msg) {
            return false
          },
        })
      }, otherHandler: (code) => {
        if (code === "not_login") {
          return true
        } else {
          return false
        }

      }

    })
  }
  function getProjectInfo() {
    apiprojectinfo.get({
      successHandler: infos => {
        if (infos) {
          setProjectInfo(infos)
        }
      }
    })
  }
  useEffect(() => {
    getProjectInfo()
  }, [])
  return (
    <div className={Module.home}>
      <Form
        name="normal_login"
        className={Module.login_form}
        initialValues={{ remember: true }}
      >
        <div className={Module.image}></div>
        <div className={Module.text} >
          <p style={{ marginLeft: "20%" }}>林业行政案件管理系统</p>
        </div>

        <Form.Item
          name="username"
          rules={[{ required: true, message: '请输入账号!' },
          {
            message: "账号格式错误"
          }]}
          initialValue={account}
        >
          <Input
            value={account}
            onChange={accountChange}
            className={Module.login_input}
            prefix={<UserOutlined />} placeholder="用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: '请输入密码!' },
          {
            pattern: passReg,
            message: "密码为6-20个字母、数字、下划线"
          }]}
          initialValue={password}
        >
          <Input.Password
            value={password}
            onChange={passwordChange}
            className={Module.login_input1}
            prefix={<LockOutlined />}
            type="password"
            placeholder="密码"
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={longinMethod} type="primary"
            className={Module.btn}>登录</Button>
        </Form.Item>
      </Form>
    </div>


  )
}
export default Login
