import Module from '../style/singanar.module.scss'
import { Button, message, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ApiAdminSignature from "../internet/ApiAdminSignature";
import ApiAdminUser from '../internet/ApiAdminUser';

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('你需要传入PNG格式图片');
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('文件格式必须小于2m!');
  }
  return isJpgOrPng && isLt2M;
};


const Singanar: React.FC = () => {
  const location = useLocation()
  const uid = (location.state as { uid: number }).uid
  const [imageUrl, setImageUrl] = useState<string>(require("../image/file_not_exist.png"))
  const navigate = useNavigate()
  const [file, setFile] = useState<File | Blob | null>(null)
  const apiAdminSignature = new ApiAdminSignature(message, navigate)
  const apiAdminUser = new ApiAdminUser(message, navigate)

  const updateImage = () => {
    if (file) {
      apiAdminSignature.updateSignature(uid, file)
    } else {
      message.error("清先选择文件")
    }
  }

  const onUpload = (file: string | RcFile | Blob) => {
    if (!(typeof file === "string")) {
      const imageUrl = URL.createObjectURL(file)
      setImageUrl(imageUrl)
      setFile(file)
    }
  };
  const getSignature = () => {
    apiAdminUser.getSignature(uid, {
      exit: 0,
      successHandler: byteArray => {
        if (byteArray) {
          const url = "data:image/png;base64," + byteArray.toString()
          setImageUrl(url)
        }
      }
    })
  }
  useEffect(getSignature, [location])
  return (
    <div className={Module.all}>
      <div className={Module.img}>
        <Button type="link">点击下方图片修改</Button>
        <Upload
          accept={"image/png"}
          name="avatar"
          listType="picture-card"
          maxCount={1}
          showUploadList={false}
          customRequest={option => {
            onUpload(option.file)
          }}
          beforeUpload={beforeUpload}
        >
          <img src={imageUrl} alt="" style={{ width: '100%' }} />
        </Upload>
        <Button type="primary" onClick={updateImage}>点击上传</Button>
      </div>

    </div>
  );
};

export default Singanar;