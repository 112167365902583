import BaseType from "../entity/BaseType"
import InternetTool from "./Internetool"
import MenuData from "../entity/MenuData"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
class ApiGlobal {
    readonly internetTool: InternetTool
    constructor(message: MessageApi, navigate: NavigateFunction) {
        this.internetTool = new InternetTool("global", message, navigate)
    }
    getMenu(resultParam?: ResultParam<Array<MenuData>>) {
        this.internetTool.fe
            (
                "/menus",
                resultParam
            )
    }
    getBaseType(resultParam?: ResultParam<Array<BaseType>>) {
        this.internetTool.fe
            (
                "/base_type",
                resultParam
            )
    }
    logoutSuccess(resultParam?: ResultParam<null>) {
        this.internetTool.ft(
            "/login_success",
            {
                method: "GET"
            },
            resultParam
        )
    }
}
export default ApiGlobal