import InternetTool from "./Internetool";
import Seal from "../entity/Seal";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiSeal {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("seal", message, navigate)
  }
  get(resultParam?: ResultParam<Array<Seal>>) {
    this.internetTool.fe(
      "",
      resultParam
    )
  }
  getByUnit(id: number, resultParam?: ResultParam<Array<Seal>>) {
    this.internetTool.fe
      (
        `/unit/${id}`,
        resultParam
      )
  }
  getByUnitAndRoles(id: number, resultParam?: ResultParam<Array<Seal>>) {
    this.internetTool.fe
      (
        `/unit/${id}/roles`,
        resultParam
      )
  }
  getById(id: number, resultParam?: ResultParam<Seal>) {
    this.internetTool.fe
      (
        `/${id}`,
        resultParam
      )
  }
  getImg(id: number, resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.fe
      (
        `/${id}/img`,
        resultParam
      )

  }
}