import { AlignLeftOutlined, ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import { AutoComplete, Badge, Button, Cascader, DatePicker, Drawer, Form, Input, message, Popconfirm, Select, Spin, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCase } from '../internet/ApiCase';
import CaseEntity from '../entity/CaseEntity';
import AllLaw from '../lawlist/alllaw';
import Lawf from '../lawlist/lawf';
import Lawfwork from '../lawlist/lawwork';
import Lawfzj from '../lawlist/lawzj';
import Module from '../style/law.module.scss'
import { useSessionStorageState } from "ahooks";
import UnitEntity from '../entity/UnitEntity';
import Region from '../entity/Region';
import moment from "moment";
import CasePunish from '../lawlist/casepunish';
import { ApiLaw } from '../internet/ApiLaw';
import ApiAdminUnit from '../internet/ApiAdminUnit';
import ApiAdminRegion from '../internet/ApiAdminRegion';
import { LawPunishType } from '../entity/LawPunishType';
import { LawConversion } from '../tool/lawConversion';
import Law from '../entity/Law';
import ApiDocument from '../internet/ApiDocument';

interface Type {
  documentId?: number;
  caseId?: number;
  masterId?: number;
  status?: string;
  documentName?: string;
}
const Law1: React.FC<{ setTypes: (types: Type[]) => void, types: Type[] }> = (prpos) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const navigate = useNavigate()


  const crimnalChange = () => {
    navigate("/criminal", { state: { caseId: caseId } })
  }
  const workChange = () => {
    navigate("/work", { state: { caseId: caseId } })
  }

  const zjlistChange = () => {
    navigate("/zjlist", { state: { caseId: caseId } })
  }
  const punishChange = () => {
    navigate("/punishcontext", { state: { caseId: caseId } })
  }

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const [form] = Form.useForm<CaseEntity>()
  const [data, setData] = useSessionStorageState<CaseEntity | undefined>("caseData")
  const [regions, setAdress] = useState<Region[]>([])
  const [units, setUnits] = useState<UnitEntity[]>([])
  const [lawPunishTypes, setLawPunishTypes] = useState<LawPunishType[]>([])
  const [laws, setLaws] = useState<Law[]>([])
  const [value1, setValue1] = useState<string>("")
  const [lawId, setLawId] = useState<number[]>([])
  const [selectedRegions, setSelectedRegions] = useState<{
    city?: Region, county?: Region, village?: Region, street?: Region
  }>({})
  const location = useLocation()
  const caseId = (location.state as { caseId?: number })?.caseId
  const number = (location.state as { number?: boolean })?.number
  const value = (location.state as { value?: number })?.value
  const [infoslenth, setInfoslenth] = useState<number>()
  const apiCase = new ApiCase(message, navigate)
  const apiLaw = new ApiLaw(message, navigate)
  const apiAdminUnit = new ApiAdminUnit(message, navigate)
  const apiAdminRegion = new ApiAdminRegion(message, navigate)
  const laws1 = LawConversion.law11(laws)
  const [loading, setLoading] = useState(true);
  const [caseTypeId, setCaseTypeId] = useState<number>();
  const apiDocument = new ApiDocument(message, navigate)
  const distributionPeople = () => {
    if (data) {
      navigate("/distributionpeople", {
        state: {
          caseId: caseId,
          handleUid: data.handleUid,
          subHandleUid: data.handleSubUid
        }
      })
    }
  };
  function getInfos() {
    apiDocument.getInfos(caseId, {
      successHandler: infos => {
        if (infos) {
          setInfoslenth(infos.length)
        }
      }
    })
  }
  function getLaws() {
    apiLaw.get({
      successHandler: laws => {
        if (laws) {
          setLaws(laws)
          setLoading(false);
        }
      }
    })
  }
  function getLawPunishes() {
    apiLaw.getPunishTypes({
      successHandler: lawPunishTypes => {
        if (lawPunishTypes) {
          setLawPunishTypes(lawPunishTypes)
        }
      }
    })
  }

  function setCity(value: string) {
    const select = regions.find(region => region.regionName === value)
    form.setFieldsValue({
      caseDistrict: undefined,
      caseTownVillage: undefined,
      caseStreetVillage: undefined,
      caseCommunityGroup: undefined
    })
    setSelectedRegions({ city: select })
  }

  function setCounty(value: string) {
    const select = selectedRegions.city?.children?.find(region => region.regionName === value)
    form.setFieldsValue({
      caseTownVillage: undefined,
      caseStreetVillage: undefined,
      caseCommunityGroup: undefined
    })
    setSelectedRegions({ city: selectedRegions.city, county: select })
  }

  function setVillage(value: string) {
    form.setFieldsValue({ caseStreetVillage: undefined, caseCommunityGroup: undefined })
    const select = selectedRegions.county?.children?.find(region => region.regionName === value)
    setSelectedRegions({
      city: selectedRegions.city,
      county: selectedRegions.county,
      village: select
    })
  }

  function setStreet(value: string) {
    form.setFieldsValue({ caseCommunityGroup: undefined })
    const select = selectedRegions.village?.children?.find(region => region.regionName === value)
    setSelectedRegions({
      city: selectedRegions.city,
      county: selectedRegions.county,
      village: selectedRegions.village,
      street: select
    })
  }

  function saveCase() {
    form.validateFields()
      .then(caseData => {
        if (caseData.factLawId) {
          const chapterId = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).find(c => c.id === (caseData.factLawId ?? [])[1])?.chapterId ?? 0
          caseData.factLawId.splice(1, 0, chapterId);
        }
        const data1 = new CaseEntity(caseId ?? caseData?.id ?? 0, caseData?.caseCode,
          caseData?.caseName, caseData?.caseCause, caseData?.caseType, caseData?.caseTime, caseData?.caseRegisterTimer,
          caseData?.handleUid ?? data?.handleUid, caseData?.handleSubUid ?? data?.handleSubUid, caseData?.suspectLawId, caseData?.factLawId,
          caseData?.caseFact, caseData?.mainCase, caseData?.hostUnitId,
          caseData?.acceptingUnitId, caseData?.caseSource, caseData?.caseCity, caseData?.caseDistrict,
          caseData?.caseTownVillage, caseData?.caseStreetVillage, caseData?.caseCommunityGroup,
          caseData?.casePlace)
        if (caseId) {
          apiCase.updateCase(data1, { exit: 0 })
        } else {
          apiCase.addCase(caseData)
        }
      }
      )

  }

  function delectCase() {
    if (caseId) {
      apiCase.deleteCase(caseId, {
        otherHandler: (code) => {
          if (code === "case_error") {
            message.warn("你的案件已生成文书无法删除，如需删除，请先清空文书列表的文书")
            return true
          } else {
            return false
          }
        }
      })
    }
  }

  const getUnit = () => {
    apiAdminUnit.getUnits({
      successHandler: unitData => {
        if (unitData) {
          if (unitData.length)
            form.setFieldsValue({ hostUnitId: unitData[0].id })
          setUnits(unitData)
        }
      }
    })
  }
  const getRegion = () => {
    apiAdminRegion.getRegions({
      successHandler: regionsData => {
        if (regionsData) {
          setAdress(regionsData)
        }
      }
    })
  }
  const lastCase = () => [
    navigate("/jzong", { state: { caseId: data?.id } })
  ]
  function lawChage(value: (number | string)[]) {
    if (value) {
      const value1 = laws.find(a => a.id === value[0])?.lawValue ?? ''
      const value2 = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).find(c => c.id === value[1])?.articleIndex ?? ''
      const value3 = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).flatMap(c => c.paragraphs ?? []).find(d => d.id === value[2])?.paragraphValue ?? ''
      const value4 = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).flatMap(c => c.paragraphs ?? []).flatMap(d => d.subparagraphs ?? []).find(e => e.id === value[3])?.subparagraphValue ?? ""
      const value5 = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).flatMap(c => c.paragraphs ?? []).flatMap(d => d.subparagraphs ?? []).flatMap(e => e.items ?? []).find(f => f.id === value[4])?.itemValue ?? ""
      const values = [value1, `第${value2}条`, value3, value4, value5].filter(Boolean);
      const result = values.join('/');
      setValue1(result)
    } else {
      setValue1("")
    }

  }
  useEffect(() => {
    if (laws.length) {
      lawChage(lawId)
    }
  }, [laws])
  useEffect(() => {
    getInfos()
    getLaws()
    getLawPunishes()
    getUnit()
    getRegion()
    if (caseId) {
      apiCase.getCase(caseId, {
        successHandler: data => {
          if (data) {
            const caseTime = moment(data?.caseTime ?? "")
            const caseRegisterTimer = moment(data?.caseRegisterTimer ?? "")
            data.caseTime = caseTime
            data.caseRegisterTimer = caseRegisterTimer
            setData(data)
            setCaseTypeId(data.caseType)
            if (data.factLawId) {
              data.factLawId.splice(1, 1);
              setLawId(data.factLawId)
            }
            form.setFieldsValue(data)
          }
        }
      })
    }
  }, [location])

  const unitOption = units.map(item => {
    return <Option key={item.id} value={item.id}>{item.unitName}</Option>
  })

  function convertRegions(regions?: Region[]): string[] {
    if (regions) {
      return regions.map(region => region.regionName).filter(name => name).map(name => (name as string))
    } else
      return []
  }

  function writList() {
    navigate("/writlist", { state: { caseId: caseId } })
  }

  let element: JSX.Element
  if (value) {
    element = <></>
  } else {
    if (caseId) {
      element =
        (<div className={Module.btnt}>
          <Badge size="small" count={infoslenth} offset={[-12, 5]}> <Button className={Module.hbtn} onClick={showDrawer}><AlignLeftOutlined />文书列表</Button></Badge>
          <Button className={Module.hbtn}
            onClick={distributionPeople}><UserOutlined />分配人员</Button>
          <Button className={Module.hbtn1} type="primary" onClick={writList}>
            添加文书
          </Button>
          <Button className={Module.hbtn1} type="primary" onClick={lastCase}>结案</Button>
          <Button className={Module.hbtn1} type="primary" onClick={saveCase}>保存</Button>
          <Popconfirm
            title="是否删除案件？"
            onConfirm={delectCase}
            okText="确认删除"
            cancelText="取消"
          >
            <Button className={Module.hbtn1} type="primary" >删除</Button>
          </Popconfirm>
          <Drawer title="文书列表" placement="right" onClose={onClose} visible={visible}
            size="large">
            <AllLaw setTypes={prpos.setTypes} types={prpos.types} caseId={caseId} />
          </Drawer>
        </div>)

    } else {
      element =
        (<div className={Module.btnt}>
          <span
            className={Module.span}>请先添加案件，添加后才能添加嫌疑人信息，嫌疑人单位，证据清单<ArrowRightOutlined /></span>
          <Button className={Module.hbtn1} type="primary" onClick={saveCase}>添加</Button>
        </div>)
    }
  }

  let element2: JSX.Element
  if (value) {
    element2 = <></>
  } else {
    if (caseId) {
      element2 =
        (<div className={Module.list}>
          <div className={Module.listtable}>
            <Tabs className={Module.TabPane} type="card">
              <TabPane tab="违法嫌疑人员" key="1">
                <Button onClick={crimnalChange}>添加</Button>
                <Lawf caseId={caseId}
                />
              </TabPane>
              <TabPane tab="违法嫌疑单位" key="2">
                <Button onClick={workChange}>添加</Button>
                <Lawfwork caseId={caseId} />
              </TabPane>
              <TabPane tab="证据清单" key="4">
                <Button onClick={zjlistChange}>添加</Button>
                <Lawfzj caseId={caseId} />
              </TabPane>
              <TabPane tab="处罚" key="5">
                <Button onClick={punishChange}>添加</Button>
                <CasePunish caseId={caseId} caseTypeId={caseTypeId ?? 1} />
              </TabPane>
            </Tabs>
          </div>
        </div>)

    } else {
      element2 =
        (<></>)
    }
  }

  const options = ['投诉,举报', '上级机关,领导交办', '其他机关移送', '检查,巡查发现', '双随机', "其他"]
  let style1: string = "";
  if (caseId) {
    style1 = Module.form
  } else {
    style1 = Module.form1
  }
  if (value) {
    style1 = Module.form1
  }
  return (
    <div className={Module.all}>
      {element}
      <div className={Module.cen}>
        <Form
          name='表单'
          form={form}
          className={style1}
        >
          <div className={Module.hea}><span>案件信息</span></div>
          <Form.Item
            key={0}
            name="caseCode"
            label="案件编号："
            className={Module.item1}
          >
            <Input readOnly={!number} type={"number"} className={Module.input} />
          </Form.Item>
          <Form.Item
            key={1}
            name="caseName"
            label="案件名称："
            className={Module.item1}

          >
            <Input className={Module.input} />
          </Form.Item>

          <Form.Item
            key={2}
            name="hostUnitId"
            label="主办单位："
            className={Module.item1}
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Select className={Module.select}>
              {unitOption}
            </Select>
          </Form.Item>
          <Form.Item
            key={4}
            label="受案日期："
            name="caseTime"
            className={Module.item1}
          >
            <DatePicker className={Module.input} />
          </Form.Item>
          <Form.Item
            key={99}
            label="立案日期："
            name="caseRegisterTimer"
            className={Module.item1}
          >
            <DatePicker className={Module.input} />
          </Form.Item>
          <Form.Item
            key={5}
            name="caseSource"
            label="案件来源"
            className={Module.item1}
          >
            <AutoComplete dataSource={options} children={<Input />} />
          </Form.Item>
          <Form.Item
            key={6}
            name="caseCause"
            label="案由"
            className={Module.item1}

          >
            <Input className={Module.input}
            />
          </Form.Item>
          <Form.Item
            key={19}
            name="caseType"
            label="案件类型"
            className={Module.item1}>
            <Select
              options={
                lawPunishTypes.map(type => ({
                  value: type.id,
                  label: type.typeName
                }))
              }
            />
          </Form.Item>
          <Form.Item
            name="factLawId"
            label="违反法律"
            className={Module.item4}>
            {loading ? (
              <Spin >
                <Input style={{ borderColor: "#aeaeff" }} />
              </Spin>
            ) : (
              <Cascader
                className={Module.select}
                placement={"bottomLeft"}
                changeOnSelect
                onChange={(value: (number | string)[]) => (lawChage(value))}
                options={laws1}
              />
            )}

          </Form.Item>
          <Form.Item
            label="法律内容"
            className={Module.item4}
          >
            {loading ? (
              <Spin >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 30 }} style={{ borderColor: "#aeaeff" }} />
              </Spin>
            ) : (
              <Input.TextArea style={{ borderColor: "#aeaeff" }} autoSize={{ minRows: 2, maxRows: 30 }} value={value1} />
            )}
          </Form.Item>
          <div className={Module.hea}><span>案件地点</span></div>
          <Form.Item
            name="caseCity"
            label="&emsp;&emsp;&emsp;市："
            className={Module.item2}
          >
            <AutoComplete
              onChange={e => {
                const value = e.toString()
                setCity(value)
              }}
              dataSource={convertRegions(regions)}
            >
              <Input />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            name="caseDistrict"
            label="&emsp; &nbsp;区/县: "
            className={Module.item2}

          >
            <AutoComplete
              onChange={e => {
                const value = e.toString()
                setCounty(value)
              }}
              dataSource={convertRegions(selectedRegions.city?.children)}
            >
              <Input />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            name="caseTownVillage"
            label="&emsp; &nbsp;乡/镇："
            className={Module.item2}
          >
            <AutoComplete
              onChange={e => {
                const value = e.toString()
                setVillage(value)
              }}
              dataSource={convertRegions(selectedRegions.county?.children)}
              children={<Input />}
            />
          </Form.Item>
          <Form.Item
            name="caseStreetVillage"
            label="&nbsp; 街道/村："
            className={Module.item2}
          >
            <AutoComplete
              onChange={e => {
                const value = e.toString()
                setStreet(value)
              }}
              dataSource={convertRegions(selectedRegions.village?.children)}
              children={<Input />}
            />
          </Form.Item>
          <Form.Item
            name="caseCommunityGroup"
            label="&nbsp; 社区/组："
            className={Module.item2}
          >
            <AutoComplete
              dataSource={convertRegions(selectedRegions.street?.children)}
              children={<Input />}
            />
          </Form.Item>
          <Form.Item
            name="casePlace"
            label="案发地点： "
            className={Module.item2}
          >
            <Input className={Module.input1} />
          </Form.Item>
          <div className={Module.hea}><span>主要案情</span></div>
          <Form.Item
            name="mainCase"
            className={Module.item3}
          >
            <TextArea
              rows={4}
            />
          </Form.Item>
        </Form>

        {element2}
      </div>

    </div>
  );
}


export default Law1