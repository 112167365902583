import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import Module from '../style/userlist.module.scss'
import UserEntity from "../entity/UserEntity";
import { useLocation, useNavigate } from "react-router-dom";
import UnitEntity from "../entity/UnitEntity";
import ApiAdminUnit from "../internet/ApiAdminUnit";
import ApiAdminUser from "../internet/ApiAdminUser";
import IdCardTool from "../tool/IdCardTool";
const { Option } = Select;
const UserList: React.FC = () => {
  const [form] = Form.useForm<{
    password: string,
    id: number;
    realName: string;
    username: string;
    mobilePhone: string;
    identificationCard: string;
    lawEnforcementCard: string
    signature: string | undefined,
    unitId: number | undefined
  }>()
  const navigate = useNavigate()
  const location = useLocation()
  const unitId = (location.state as { unitId?: number })?.unitId
  const [units, setUnits] = useState<UnitEntity[]>([])
  const apiAdminUnit = new ApiAdminUnit(message, navigate)
  const apiAdminUser = new ApiAdminUser(message, navigate)
  const flapUnits = (units: UnitEntity[]): UnitEntity[] => {
    return units.flatMap(units => {
      const newUnits: UnitEntity[] = [units]
      const children = flapUnits(units.children ?? [])
      newUnits.push(...children)
      return newUnits
    })
  }
  const getUnits = () => {
    apiAdminUnit.getUnits({
      successHandler: units => {
        if (units) {
          setUnits(flapUnits(units))
        }
      }
    })
  }
  function addUser() {
    form.validateFields()
      .then(entity => {
        entity.id = 1
        const user = UserEntity.create(entity)
        const password = entity.password
        if (user && password) {
          apiAdminUser.addUserSimple(user, password)
        }
      })
  }
  useEffect(() => {
    getUnits()
    if (unitId) {
      form.setFieldsValue({ unitId: unitId })
    }
  }, [location])
  const idCard = new IdCardTool()
  const unitOption = units.map(unitName => (<Option key={unitName.id} value={unitName.id}>{unitName.unitName}</Option>))
  return (
    <div className={Module.all}>
      <Form
        name='添加嫌疑人员'
        className={Module.form}
        form={form}
      >
        <h1>用户信息</h1>
        <Form.Item
          name="realName"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="&emsp;&emsp;姓名">
          <Input />
        </Form.Item>
        <Form.Item
          name="username"
          className={Module.item}
          label="&emsp;用户名"
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="identificationCard"
          className={Module.item}
          label="身份证号"
          rules={[
            {
              required: true,
              message: "身份证号码格式错误",
              validator: (rule, value) => {
                if (idCard.validIdCard(value)) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('身份证号码格式错误!'));
              }
            },

          ]}

        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mobilePhone"
          className={Module.item}
          label="&emsp;手机号"
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="lawEnforcementCard"
          className={Module.item}
          label="执法证号"
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          className={Module.item}
          name="password"
          label="&emsp;&emsp;密码"
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="unitId"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="&emsp;&emsp;单位">
          <Select disabled={unitId !== undefined}>
            {unitOption}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button className={Module.btn} type="primary" onClick={addUser}>确认添加</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default UserList