import React, { useEffect, useState } from "react";
import StatisticsCaseEntity from "../entity/StatisticsCase";
import Table, { ColumnsType } from "antd/lib/table";
import { Button, Checkbox, Input, Tag, message, } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ApiCase } from "../internet/ApiCase";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const { Search } = Input;
const plainOptions = ['已完成', '未完成'];
const CheckboxGroup = Checkbox.Group;
const CaseTable: React.FC = () => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(plainOptions);
  const [indeterminate, setIndeterminate] = useState(true);
  const [caseData, setCaseData] = useState<StatisticsCaseEntity[]>([]);
  const [originalCaseList, setOriginalCaseList] = useState<StatisticsCaseEntity[]>([]);
  const [checkAll, setCheckAll] = useState(true);
  const navigete = useNavigate()
  const [searchText, setSearchText] = useState<string>("")
  const columns: ColumnsType<StatisticsCaseEntity> = [
    {
      title: '案件名称',
      dataIndex: 'caseName',
      key: 'caseName',
      fixed: 'left',
      width: 240,
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <Tag color="red">
            <span>{value}</span>
          </Tag>
        )
      }
    },
    {
      title: '立案编号',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      width: 100,
      ellipsis: true
    },
    {
      title: '立案时间',
      dataIndex: 'caseTime',
      key: 'caseTime',
      width: 150,
      ellipsis: true,
      render: (value, record, index) => {
        if (record.caseTime) {
          const d = record.caseTime;
          const text = moment(d).format('YYYY-MM-DD')
          if (searchText) {
            const isMatch = text.match(new RegExp(searchText, 'i'));
            return isMatch ? <span style={{ backgroundColor: "yellow" }}>{text}</span> : text;
          } else {
            return text
          }
        }
        return <></>;
      }
    },
    {
      title: '所属乡镇',
      dataIndex: 'affiliation',
      key: 'affiliation',
      width: 200,
      ellipsis: true,
      render: (value, record, index) => {
        if (searchText) {
          const isMatch = value?.match(new RegExp(searchText, 'i'));
          return isMatch ? <span style={{ backgroundColor: "yellow" }}>{value}</span> : value;
        } else {
          return value
        }
      },

    },
    {
      title: '是否涉及图斑',
      dataIndex: 'patternSpot',
      key: 'patternSpot',
      width: 120,
      ellipsis: true
    },
    {
      title: '批次',
      dataIndex: 'batch',
      key: 'batch',
      width: 100,
      ellipsis: true
    },
    {
      title: '图斑年度',
      dataIndex: 'patternSpotYear',
      key: 'patternSpotYear',
      width: 100,
      ellipsis: true
    },
    {
      title: '图斑号',
      dataIndex: 'patternSpotNumber',
      key: 'patternSpotNumber',
      width: 100,
      ellipsis: true
    },
    {
      title: '基本案情',
      dataIndex: 'basicCase',
      key: 'basicCase',
      width: 300,
      ellipsis: true
    },
    {
      title: '当事人基本信息',
      dataIndex: 'identityInformation',
      key: 'identityInformation',
      width: 150,
      ellipsis: true,
      render: (value, record, index) => {
        if (searchText) {
          const isMatch = value?.match(new RegExp(searchText, 'i'));
          return isMatch ? <span style={{ backgroundColor: "yellow" }}>{value}</span> : value;
        } else {
          return value
        }
      },
    },
    {
      title: '案件类型',
      dataIndex: 'caseType',
      key: 'caseType',
      width: 150,
      ellipsis: true
    },
    {
      title: '违法行为',
      dataIndex: 'unlawfulAct',
      key: 'unlawfulAct',
      width: 100,
      ellipsis: true,
      render: (value, record, index) => {
        if (searchText) {
          const isMatch = value?.match(new RegExp(searchText, 'i'));
          return isMatch ? <span style={{ backgroundColor: "yellow" }}>{value}</span> : value;
        } else {
          return value
        }
      },
    },
    {
      title: '违法面积',
      dataIndex: 'illegalArea',
      key: 'illegalArea',
      width: 100,
      ellipsis: true
    },
    {
      title: '罚款金额',
      dataIndex: 'forfeit',
      key: 'forfeit',
      width: 100,
      ellipsis: true
    },
    {
      title: '处罚决定',
      dataIndex: 'penaltyDecision',
      key: 'penaltyDecision',
      width: 100,
      ellipsis: true
    },
    {
      title: '处罚决定日期',
      dataIndex: 'penaltyDecisionDate',
      key: 'penaltyDecisionDate',
      width: 120,
      ellipsis: true,
      render: (value, record, index) => {
        if (record.penaltyDecisionDate) {
          const d = record.penaltyDecisionDate;
          const text = moment(d).format('YYYY-MM-DD')
          if (searchText) {
            const isMatch = text.match(new RegExp(searchText, 'i'));
            return isMatch ? <span style={{ backgroundColor: "yellow" }}>{text}</span> : text;
          } else {
            return text
          }
        }
        return <></>;
      }

    },
    {
      title: '办理进度',
      dataIndex: 'handlingProgress',
      key: 'handlingProgress',
      width: 100,
      ellipsis: true
    },
    {
      title: '罚款缴纳情况',
      dataIndex: 'finePaymentStatus',
      key: 'finePaymentStatus',
      width: 120,
      ellipsis: true
    },
    {
      title: '集体讨论时间',
      dataIndex: 'groupDiscussionTime',
      key: 'groupDiscussionTime',
      width: 120,
      ellipsis: true,
      render: (value, record, index) => {
        if (record.groupDiscussionTime) {
          const d = record.groupDiscussionTime;
          const text = moment(d).format('YYYY-MM-DD')
          if (searchText) {
            const isMatch = text.match(new RegExp(searchText, 'i'));
            return isMatch ? <span style={{ backgroundColor: "yellow" }}>{text}</span> : text;
          } else {
            return text
          }
        }
        return <></>;

      }
    },
    {
      title: '法制审核时间',
      dataIndex: 'legalReviewTime',
      key: 'legalReviewTime',
      width: 120,
      ellipsis: true,
      render: (value, record, index) => {
        if (record.legalReviewTime) {
          const d = record.legalReviewTime;
          const text = moment(d).format('YYYY-MM-DD')
          if (searchText) {
            const isMatch = text.match(new RegExp(searchText, 'i'));
            return isMatch ? <span style={{ backgroundColor: "yellow" }}>{text}</span> : text;
          } else {
            return text
          }
        }
        return <></>;
      }
    },
    {
      title: '结案时间',
      dataIndex: 'closingTime',
      key: 'closingTime',
      width: 120,
      ellipsis: true,
      render: (value, record, index) => {
        if (record.closingTime) {
          const d = record.closingTime;
          const text = moment(d).format('YYYY-MM-DD')
          if (searchText) {
            const isMatch = text.match(new RegExp(searchText, 'i'));
            return isMatch ? <span style={{ backgroundColor: "yellow" }}>{text}</span> : text;
          } else {
            return text
          }
        }
        return <></>;
      }
    },
    {
      title: '林地回收情况',
      dataIndex: 'recoveryOfForestLand',
      key: 'recoveryOfForestLand',
      width: 120,
      ellipsis: true
    },
    {
      title: '办案人员',
      dataIndex: 'handleName',
      key: 'handleName',
      width: 200,
      ellipsis: true,
      render: (value, record, index) => {
        if (searchText) {
          const isMatch = value?.match(new RegExp(searchText, 'i'));
          return isMatch ? <span style={{ backgroundColor: "yellow" }}>{value}</span> : value;
        } else {
          return value
        }
      },
    },
    {
      title: '归档人员',
      dataIndex: 'filingStaff',
      key: 'filingStaff',
      width: 100,
      ellipsis: true,
      fixed: "right",
    },
  ];
  const apiCase = new ApiCase(message, navigete)
  const onChange = (list: CheckboxValueType[]) => {
    if (list.length !== plainOptions.length) {
      if (list[0] === "已完成") {
        getCaseTable(1)
      } else if (list[0] === "未完成") {
        getCaseTable(0)
      } else {
        getCaseTable(2)
      }
    } else {
      getCaseTable(2)
    }
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const getCaseTable = (type: number) => {
    apiCase.getStatisticsCase(type, {
      exit: 0, successHandler: data => {
        if (data) {
          setCaseData(data)
          setOriginalCaseList(data)
        }
      }
    })
  }
  const exportExcel = () => {
    apiCase.statisticsCase({
      exit: 0, successHandler: excelData => {
        const url = "data:zip;base64," + excelData?.toString()
        const link = document.createElement('a')
        link.href = url
        link.download = "案件统计表.xls"
        link.click()
        message.success("导出成功")
      }
    })
  }
  const onSearch = (e: any) => {
    setSearchText(e)
    const regex = new RegExp(e, 'i'); // 'i' 表示不区分大小写
    if (e !== "") {
      const filteredProjects = originalCaseList.filter((caseData) => {
        return (
          (caseData.caseName?.match(regex) || []).join("").trim() !== "" ||
          (caseData.caseTime?.toString().match(regex) || []).join("").trim() !== "" ||
          (caseData.groupDiscussionTime?.toString().match(regex) || []).join("").trim() !== "" ||
          (caseData.closingTime?.toString().match(regex) || []).join("").trim() !== "" ||
          (caseData.affiliation?.match(regex) || []).join("").trim() !== "" ||
          (caseData.identityInformation?.match(regex) || []).join("").trim() !== "" ||
          (caseData.unlawfulAct?.match(regex) || []).join("").trim() !== "" ||
          (caseData.handleName?.match(regex) || []).join("").trim() !== ""
        )
      });
      setCaseData(filteredProjects);
    } else {
      setCaseData(originalCaseList)
    }
  };
  useEffect(() => {
    getCaseTable(2)
  }, [])
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "95%", height: "100%", margin: "auto" }}>
        <>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            选择全部
          </Checkbox>
          <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
        </>
        <Button onClick={exportExcel} style={{ margin: "1%", float: "right" }} type="primary">导出为EXCEL</Button>
        <Search style={{ width: "40%", float: "right", margin: "1%" }} placeholder="输入查询内容" onSearch={onSearch} enterButton />
        <Table
          scroll={{ x: 1500 }}
          columns={columns}
          dataSource={caseData}
          pagination={{ pageSize: 8 }}
          rowKey={"id"}
        />
      </div>
    </div>
  )
}
export default CaseTable