import {Button, Card, Form, Input, message, Select} from 'antd';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ApiCase} from '../internet/ApiCase';
import Evidence from '../entity/Evidence';
import Module from '../style/zjlist.module.scss'

const layout = {
  labelCol: {span: 5},
  wrapperCol: {span: 20},
};
const Zjlist: React.FC = () => {
  const [form] = Form.useForm<Evidence>();
  const location = useLocation()
  const state = (location.state as { evidence?: Evidence, caseId?: number })
  const caseId = state?.caseId
  const evidence = state?.evidence
  const navigate = useNavigate()
  const apiCase = new ApiCase(message, navigate)
  const setForm = () => {
    if (evidence) {
      form.setFieldsValue(evidence)
    }
  }

  useEffect(() => {
    setForm()
  }, [location])

  function addCriminalEvidence() {
    form.validateFields()
    .then(evidence => {
          if (caseId) {
            evidence.caseId = caseId
            apiCase.addEvidence(evidence)
          }

        }
    )

  }

  function deleteCriminalEvidence() {
    if (evidence?.id) {
      apiCase.deleteEvidence(evidence?.id)
    }

  }

  const updateCriminalEvidence = (evidence: Evidence) => {
    form.validateFields()
    .then(criminalEvidence => {
      if (caseId) {
        criminalEvidence.id = evidence.id
        criminalEvidence.caseId = evidence.caseId
        criminalEvidence.pageSize = evidence.pageSize
        criminalEvidence.pageStartNumber = evidence.pageStartNumber
        criminalEvidence.filePath = evidence.filePath
        apiCase.updateEvidence(criminalEvidence)
      }

    })
  }
  let button: JSX.Element
  const evidence1 = state.evidence;
  if (evidence1) {
    button = (
        <Form.Item>
          <Button type="primary" onClick={deleteCriminalEvidence}>删除</Button>
          <Button type="primary" onClick={() => updateCriminalEvidence(evidence1)}>确认修改</Button>
        </Form.Item>
    )
  } else {
    button = (
        <Form.Item>
          <Button type="primary" onClick={addCriminalEvidence}>添加</Button>
        </Form.Item>
    )
  }


  return (
      <div className={Module.all}>
        <Card className={Module.card}>
          <Form
              {...layout}
              name='添加嫌疑物品'
              form={form}
              className={Module.form}
          >
            <h1 style={{fontSize: 20}}>证据清单</h1>
            <Form.Item
                name="evidenceName"
                className={Module.item}
                label="证据名称"
                rules={[
                  {
                    required: true,
                    message: '该字段不能为空',
                  }
                ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="evidenceType"
                className={Module.item}
                label="证据类型"
            >
              <Select className={Module.select} options={[
                {value: "涉嫌违法当事人主体", label: "涉嫌违法当事人主体"},
                {value: "实施行为的事实，过程，结果", label: "实施行为的事实，过程，结果"}
              ]}/>
            </Form.Item>
            <Form.Item
                name="evidenceDescription"
                className={Module.item}
                label="证据描述"

            >
              <Input/>
            </Form.Item>
            {button}
          </Form>
        </Card>
      </div>
  );
};

export default Zjlist;