import { Button, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Wenshu from "./wenshu";
import Module from '../style/tabwrit.module.scss'

interface Type {
  documentId?: number;
  caseId?: number;
  masterId?: number;
  status?: string;
  documentName?: string;
}

interface Props {
  setTypes: (types: Type[]) => void;
  types: Type[];
}

export function TabWrit(props: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const type = (location.state as { type?: Type[] })?.type;
  const [type1, setType1] = useState<Type[]>([]);

  function handleTabEdit(targetKey: string | React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<Element>, action: "add" | "remove") {
    if (action === "remove" && typeof targetKey === "string") {
      const targetIndex = type1.findIndex((item) => item.documentId === Number(targetKey));
      const newTypes = [...type1.slice(0, targetIndex), ...type1.slice(targetIndex + 1)];
      setType1(newTypes);
      props.types.splice(targetIndex, 1);
      if (!newTypes.length) {
        navigate("/law", { state: { caseId: type1[0].caseId } })
      }

    }
  }

  useEffect(() => {
    if (type) {
      setType1(type)
    }
  }, [location])


  return (
    <div className={Module.center}>
      <Tabs
        tabPosition="left"
        className={Module.tabs}
        hideAdd
        size="small"
        type="editable-card"
        onEdit={(targetKey, action) => handleTabEdit(targetKey as string, action)}
      >
        {type1?.map((type) => {
          return (
            <Tabs.TabPane
              tab={type.documentName}
              key={type.documentId?.toString()}
            >
              <Wenshu type={type} setTypes={props.setTypes} types={props.types} />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  )
}
