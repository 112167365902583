import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Space } from 'antd';
import Module from '../style/live.module.scss'

const Live: React.FC = () => {
    const { TextArea } = Input;
    const { Option } = Select;
    const [form] = Form.useForm();
    const btnChange = () => {
    }
    return (
        <div className={Module.all}>
            <Card className={Module.card}>
                <Form
                    form={form}
                    name='添加笔录'
                    className={Module.form}
                >
                    <h1>添加询问笔录</h1>
                    <Form.Item
                        name={"询问时间"}
                        className={Module.item1}
                        label="&emsp;询问时间">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"询问地点"}
                        className={Module.item}
                        label="&emsp;询问地点">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"询问人一"}
                        className={Module.itemn}
                        label="&emsp;&emsp;询问人">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"执法证号"}
                        className={Module.itemz}
                        label="&emsp;执法证号">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"询问人二"}
                        className={Module.itemn}
                        label="&emsp;&emsp;询问人">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"执法证号"}
                        className={Module.itemz}
                        label="&emsp;执法证号">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"记录人"}
                        className={Module.item}
                        label="&emsp;&emsp;记录人">
                        <Input />
                    </Form.Item>
                    <h1>被询问人基本情况</h1>
                    <Form.Item
                        name={"姓名"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;姓名">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"性别"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;性别">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"身份证号码"}
                        className={Module.item}
                        label="身份证号码">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"工作单位"}
                        className={Module.item}
                        label="&emsp;联系方式">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"职业"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;职业">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"联系电话"}
                        className={Module.item}
                        label="&emsp;联系电话">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={"住址"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;住址">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"邮编"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;邮编">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={" 所属职务"}
                        className={Module.item}
                        label="&emsp;所属职务">
                        <Select className={Module.select} >
                            <Option value="中共党员">中共党员</Option>
                            <Option value="人大代表">人大代表</Option>
                            <Option value="政协委员">政协委员</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={"与当事人关系"}
                        className={Module.items}
                        label="与当事人关系">
                        <Select className={Module.select}>
                            <Option value="当事人">当事人</Option>
                            <Option value="法人代表">法人代表</Option>
                            <Option value="现场负责人">现场负责人</Option>
                            <Option value="其他">其他</Option>
                        </Select>
                    </Form.Item>
                    <h1>告知事项</h1>
                    <Form.Item
                        name={"问1"}
                        className={Module.itemq}
                        label="&emsp;&emsp;&emsp;问"
                        initialValue={'你好！我们是XXX 的执法人员XXX、 XXX ，执法证号分别是XXX 、XXX，这是我们的执法证件（出示执法证件），你是否看清楚？'}
                    >
                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} className={Module.que} />
                    </Form.Item>
                    <Form.Item
                        name={"答1"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;答">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"问2"}
                        className={Module.itemq}
                        label="&emsp;&emsp;&emsp;问"
                        initialValue={'我们依法就               有关问题进行调查，请予配合，如实回答有关问题。如果你认为我们与本案有利害关系从而影响到本案的公正办理，可以申请我们回避，你是否申请回避？'}
                    >
                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} className={Module.que} />
                    </Form.Item>
                    <Form.Item
                        name={"答2"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;答">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"问3"}
                        className={Module.itemq}
                        label="&emsp;&emsp;&emsp;问"
                        initialValue={'你应当如实回答询问，并协助调查，不得阻挠。你是否明白？'}
                    >
                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} className={Module.que} />
                    </Form.Item>
                    <Form.Item
                        name={"答3"}
                        className={Module.item}
                        label="&emsp;&emsp;&emsp;答">
                        <Input />
                    </Form.Item>
                    <h3>询问内容</h3>
                    <Form.List name="询问内容">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} className={Module.space} >
                                        <Form.Item
                                            className={Module.it}
                                            {...restField}
                                            label="问"
                                            name={[name, '问']}
                                        >
                                            <TextArea autoSize={{ minRows: 1, maxRows: 6 }} className={Module.nr} />
                                        </Form.Item>
                                        <Form.Item
                                            className={Module.it}
                                            label="答"
                                            {...restField}
                                            name={[name, '答']}
                                        >
                                            <TextArea autoSize={{ minRows: 1, maxRows: 6 }} className={Module.nr} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button className={Module.add} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        添加询问内容
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item  >
                        <Button type="primary" onClick={btnChange}>确认添加</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default Live;