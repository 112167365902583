import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, message, Table } from 'antd';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import Module from '../style/nowrit.module.scss'
import { RangeValue } from 'rc-picker/lib/interface'
import DocumentInfo from '../entity/documentinfo';
import { ColumnsType } from 'antd/lib/table';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiDocument from '../internet/ApiDocument';


const columns: ColumnsType<DocumentInfo> = [
  {
    title: "文档名称",
    dataIndex: "documentName"
  },
  {
    title: "案件编号",
    dataIndex: "caseCode"
  },
  {
    title: "案件名称",
    dataIndex: "caseName"
  },
  {
    title: "案件状态",
    dataIndex: "status",
    width: 100,
    render: (value: any, record: DocumentInfo, index: number) => {
      return (<Button type="link">{record.status}</Button>)
    }
  },

];
const { RangePicker } = DatePicker
const Nowrit: React.FC<{ setCaseId: (caseId: number | undefined) => void, setBadge: (caseId: number | undefined) => void, }> = (props) => {
  const [dateVaule, setDatevalue] = useState<RangeValue<Moment>>(null)
  const [associatedvalue, setAssociatedValue] = useState<string>("")
  const [data, setData] = useState<DocumentInfo[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const apiAdminDocumentType = new ApiDocument(message, navigate)
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const associatedvalue = e.target.value?.trim()
    setAssociatedValue(associatedvalue)
  }
  const change = (dates: any) => {
    setDatevalue(dates)
  }
  useEffect(() => {
    apiAdminDocumentType.getInfos(undefined, {
      successHandler: data => {
        props.setBadge((data ?? []).length)
        if (data) {
          setData(data)
        }
      }
    })
  }, [location])
  const filtrate = () => {
    let list = data
    if (associatedvalue !== "") {
      list = data.filter(item => {
        if (
          item?.caseName?.indexOf(associatedvalue) !== -1 ||
          item?.documentName?.indexOf(associatedvalue) !== -1
        ) {
          return true
        }
        return false
      })
    }
    setAssociatedValue(associatedvalue)
    setData(list)
  }

  const rowClick = (datas: DocumentInfo) => {
    return {
      onClick: () => {
        navigate("/wenshu", { state: { documentId: datas.id, status: datas.status, approval: true, caseId: datas.caseId } })
      }
    }
  }
  return (
    <div className={Module.center}>
      <div className={Module.rest}>
        <Form
          className={Module.form}
        >
          <Form.Item
            label="综合搜索"
            className={Module.item}
          >
            <Input
              className={Module.inp}
              value={associatedvalue}
              onChange={inputChange}
              placeholder="案件编号、主办人员、案件来由"
              allowClear
              prefix={<SearchOutlined style={{ color: '#DEE0E8' }} />} />
            <Button type='primary' className={Module.btn}
              onClick={filtrate}
            >筛选</Button>
          </Form.Item>

          <Form.Item
            className={Module.item}
            label="日期搜索"
          >
            <RangePicker
              className={Module.RangePicker}
              onChange={change} />
            <Button type='primary' className={Module.Button}
            // onClick={timeChange}
            >筛选</Button>
          </Form.Item>
        </Form>

      </div>
      <Table
        onRow={rowClick}
        className={Module.table}
        columns={columns}
        rowKey="id"
        dataSource={data}
        bordered
      />
    </div>
  )
};

export default Nowrit;








