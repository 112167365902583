import React from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
declare const DataPickerPlacements: ["topLeft", "bottomLeft", "bottomRight", "topRight"];
declare type DataPickerPlacement = typeof DataPickerPlacements[number];
interface StringDatePickerProps {
  value?: string | null
  onChange?: (dateString: string | null) => void
  format: string
  disabled?: boolean
  showTime?: boolean
  className?: string
  picker?: string
  placeholder?: string
  bordered?: boolean
  popupStyle?: object
  placement?: DataPickerPlacement;
}
export class StringDatePicker extends React.Component<StringDatePickerProps, {}> {
  constructor(props: StringDatePickerProps) {
    super(props);
    this.onChange = this.onChange.bind(this)
  }
  onChange(_: Moment | null, dateString: string) {
    const { onChange } = this.props
    if (!onChange) {
      return
    }
    let stringValue: string | null
    if (dateString) {
      stringValue = dateString
    } else {
      stringValue = null
    }
    onChange(stringValue)
  }
  render() {
    const { value, format, disabled, className, picker } = this.props
    let dateValue: Moment | null
    if (value) {
      dateValue = moment(value, format)
    } else {
      dateValue = null
    }
    let props: any = {}
    if (picker) {
      props = { picker: picker }
    }
    return (
      <DatePicker
        {...props}
        value={dateValue as moment.Moment | undefined}
        onChange={this.onChange}
        disabled={disabled}
        format={format}
        bordered={this.props.bordered}
        showTime={this.props.showTime}
        className={className}
        placement={this.props.placement}
        popupStyle={this.props.popupStyle}
        placeholder={this.props.placeholder}
      />
    )
  }
}
