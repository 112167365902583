import { MinusOutlined } from "@ant-design/icons"
import { AutoComplete, Button, Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/proofOfService.module.scss'
const ProofOfService: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>送达回证</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.a1}><p>送达文书</p></div>
                    <div className={Module.a2}>
                      <div className={Module.c1}>
                        <Form.List name={[0, "children"]}>
                          {(fields, { add, remove }) => {
                            const fieldEntity = props.form.getFieldValue(["fields", 0])
                            return (<div className={Module.list}>
                              <>
                                {fields.map((field) => (
                                  <Form.List name={[field.name]}>
                                    {fields => (
                                      <div className={Module.list1}>
                                        <div className={Module.context}>
                                          <Form.Item
                                            label="送达文书名称"
                                            name={[0, "fieldValue"]}
                                            className={Module.item}
                                          >
                                             <AutoComplete
                                           dataSource={["行政处罚决定书","盐边林罚决字〔2024〕52号","行政处罚告知书","盐边林罚告字〔2024〕52号)","责令改正违法行为通知书","盐边林责停通字〔2024〕52号)"]}
                                          children={<Input placeholder="送达文书名称"/>}
                                         disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        />
                                          </Form.Item>
                                          <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                            style={{ fontSize: 20 }} /></Button>
                                        </div>
                                      </div>
                                    )}
                                  </Form.List>
                                ))}
                                {props.genAddButton(fieldEntity, "添加文书", add)}
                              </>
                            </div>)
                          }}
                        </Form.List>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.a1}><p>送达人</p></div>
                    <div className={Module.a2}>
                      <Form.Item name={[4, "fieldValue"]} className={Module.item2}>
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="主办人员签名">
                          {(props.users ?? []).map(user => <Select.Option
                            key={user.id.toString()}>{user.realName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                      、
                      <Form.Item name={[5, "fieldValue"]} className={Module.item2}>
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="协办人员签名">
                          {(props.users ?? []).map(user => <Select.Option
                            key={user.id.toString()}>{user.realName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}>
                      <p>送达时间</p>
                    </div>
                    <div className={Module.c2}>
                      <Form.Item name={[1, "fieldValue"]} className={Module.itemtime}>
                        <StringDatePicker
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          format={"YYYY年MM月DD日"} className={Module.time} placeholder="送达时间" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}><p>受送达人</p></div>
                    <div className={Module.d2}>
                      <Form.Item name={[2, "fieldValue"]}>
                        <Input placeholder="受送达人姓名"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}><p>备注</p></div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.text}
                        name={[3, "fieldValue"]}
                      >
                        <Input.TextArea
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          autoSize={{ minRows: 2, maxRows: 5 }} placeholder="备注"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginLeft: "2%" }}
                        className={Module.select}
                        name={[6, "fieldValue"]} >
                        <Select placeholder="印章"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        >
                          {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default ProofOfService