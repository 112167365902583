import { CloseOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/saveEvidenceapprval.module.scss'
export default function SaveEvidenceapprval(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            const fieldEntity = props.form.getFieldValue(["fields", 15])
            return <div className={Module.margin}>
              <h1 className={Module.h1}>先行登记保存证据审批表</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row0}>
                    <div className={Module.a1}>
                      <p>案由</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[0, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="案由"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row1}>
                    <div className={Module.clumn1}>
                      <p>当事人基本情况</p>
                    </div>
                    <div className={Module.clumn2}>
                      <div className={Module.a1}>
                        <div className={Module.b1}>
                          <Form.Item
                            name={[1, "fieldValue"]}
                            className={Module.item}
                            initialValue="⬜"
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 1, "fieldValue"])}
                            />
                          </Form.Item>
                          <p className={Module.h2}>公民</p>
                        </div>
                        <div className={Module.b2}>
                          <div className={Module.e1}>
                            <div className={Module.f1}>
                              <p>姓名</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[2, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="姓名"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>性别</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[3, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="性别"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f5}>
                              <p>年龄</p>
                            </div>
                            <div className={Module.f6}>
                              <Form.Item
                                name={[4, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="年龄"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={Module.e2}>
                            <div className={Module.f1}>
                              <p>住址</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[5, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  style={{ fontSize: 10 }}
                                  autoSize={{ minRows: 2, maxRows: 2.6 }}
                                  placeholder="住址"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>身份证号码</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[6, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 3.5 }}
                                  placeholder="身份证号码"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f5}>
                              <p>联系电话</p>
                            </div>
                            <div className={Module.f6}>
                              <Form.Item
                                name={[7, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  placeholder="联系电话"
                                  autoSize={{ minRows: 2, maxRows: 3 }}
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={Module.a2}>
                        <div className={Module.b1}>
                          <Form.Item
                            name={[8, "fieldValue"]}
                            className={Module.item}
                            initialValue="⬜"
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 8, "fieldValue"])}
                            />
                          </Form.Item>
                          <p className={Module.h2}>法人或其他组织</p>
                        </div>
                        <div className={Module.b2}>
                          <div className={Module.e1}>
                            <div className={Module.f1}>
                              <p>名称</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[9, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="名称"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>法定代表人</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[10, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="法定代表人"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={Module.e2}>
                            <div className={Module.f1}>
                              <p>住址</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[11, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  style={{ fontSize: 10 }}
                                  autoSize={{ minRows: 2, maxRows: 2.5 }}
                                  placeholder="住址"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>联系电话</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[12, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 2.5 }}
                                  placeholder="联系电话"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row9}>
                    <div className={Module.d1}>
                      <p>承办人意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <div className={Module.f1}>
                          <div className={Module.g1}>
                            <p>先行登记保存证据的理由</p>
                          </div>
                          <div className={Module.g2} >
                            <Form.Item
                              name={[13, "fieldValue"]}
                              className={Module.item}
                            >
                              <Input
                                placeholder="先行登记保存证据的理由"
                                disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className={Module.f1}>
                          <div className={Module.g1}>
                            <p>法律依据</p>
                          </div>
                          <div className={Module.g2} >
                            <Form.Item
                              name={[14, "fieldValue"]}
                              className={Module.item}
                            >
                              <Input.TextArea
                                placeholder="法律依据"
                                autoSize={{ minRows: 2, maxRows: 5 }}
                                disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className={Module.f3}>
                          <div className={Module.g1}>
                            <p>内容</p>
                          </div>
                          <div className={Module.g2} >
                            <div className={Module.top}>
                              <div className={Module.top1}>
                                <span>名称</span>
                              </div>
                              <div className={Module.top2}>
                                <span>数量</span>
                              </div>
                              <div className={Module.top3}>
                                <span>方式</span>
                              </div>
                              <div className={Module.top4}>
                                <span>地点</span>
                              </div>
                              <div className={Module.top5}>
                                <span>期限</span>
                              </div>
                            </div>
                            <Form.List name={[15, "children"]}>
                              {(fields, { add, remove }) => {
                                return <div className={Module.list}>
                                  {fields.map(field => {
                                    return <Form.List name={field.name}>
                                      {fields => {
                                        return <div className={Module.buttom}>
                                          <div className={Module.buttom1}>
                                            <Form.Item
                                              name={[0, "fieldValue"]}
                                            >
                                              <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                                            </Form.Item>
                                          </div>
                                          <div className={Module.buttom2}>
                                            <Form.Item
                                              name={[1, "fieldValue"]}
                                            >
                                              <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                                            </Form.Item>
                                          </div>
                                          <div className={Module.buttom3}>
                                            <Form.Item
                                              name={[2, "fieldValue"]}
                                            >
                                              <Input
                                                disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className={Module.buttom4}>
                                            <Form.Item
                                              name={[3, "fieldValue"]}
                                            >
                                              <Input
                                                disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                                            </Form.Item>
                                          </div>
                                          <div className={Module.buttom5}>
                                            <Form.Item

                                              name={[4, "fieldValue"]}
                                            >
                                              <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                                            </Form.Item>
                                          </div>
                                          <div className={Module.buttom7}>
                                            <Button type="text" onClick={() => remove(field.name)}><CloseOutlined
                                              style={{ fontSize: 20 }} /></Button>
                                          </div>
                                        </div>
                                      }}
                                    </Form.List>
                                  })}

                                  {props.genAddButton(fieldEntity, "添加目录", add)}
                                </div>
                              }}
                            </Form.List>
                          </div>
                        </div>
                      </div>
                      <div className={Module.e2}>
                        <div className={Module.input1}>
                          <Form.Item
                            name={[16, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 2, maxRows: 5 }}
                              placeholder="承办人意见"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.input2}>
                          <Form.Item
                            name={[23, "fieldValue"]} className={Module.sig} >
                            <Select
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="主办人员签名"
                            >
                              {(props.users ?? []).map(user => <Select.Option
                                key={user.id.toString()}>{user.realName}</Select.Option>)}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name={[22, "fieldValue"]} className={Module.sig} >
                            <Select
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="协办人员签名"
                            >
                              {(props.users ?? []).map(user => <Select.Option
                                key={user.id.toString()}>{user.realName}</Select.Option>)}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{ marginLeft: "2%" }}
                            name={[17, "fieldValue"]} className={Module.time}>
                            <StringDatePicker
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              format={"YYYY年MM月DD日"} className={Module.time} placeholder="签名日期" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>承办机构审核意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[18, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="承办机构审核意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[24, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          name={[19, "fieldValue"]} className={Module.time}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.time} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>单位负责人审批意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[20, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="单位负责人审批意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[26, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          name={[21, "fieldValue"]} className={Module.time}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.time} placeholder="签名日期" />
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          className={Module.select}
                          name={[25, "fieldValue"]} >
                          <Select placeholder="印章"
                            placement="topLeft"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}