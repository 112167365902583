import Law from "../entity/Law";
import {LawItem} from "../entity/LawItem";
import {LawSubparagraph} from "../entity/LawSubparagraph";
import {LawParagraph} from "../entity/LawParagraph";
import LawArticle from "../entity/LawArticle";
import LawPunishArticleLaw from "../entity/LawPunishArticleLaw";
import LawChapter from "../entity/LawChapter";
import {LawPunishArticleLawEntity} from "../entity/LawPunishArticleLawEntity";

export const LawLevels = {
  Law: 0,
  LawChapter: 1,
  LawArticle: 2,
  LawParagraph: 3,
  LawSubparagraph: 4,
  LawItem: 5,
  getStr: (level: number): string | undefined => {
    switch (level) {
      case 0:
        return "Law"
      case 1:
        return "LawChapter"
      case 2:
        return "LawArticle"
      case 3:
        return "LawParagraph"
      case 4:
        return "LawSubparagraph"
      case 5:
        return "LawItem"
      default:
        return undefined
    }
  },
  getNumber: (level: string): number | undefined => {
    switch (level) {
      case "Law":
        return 0
      case "LawChapter":
        return 1
      case "LawArticle":
        return 2
      case "LawParagraph":
        return 3
      case "LawSubparagraph":
        return 4
      case "LawItem":
        return 5
      default:
        return undefined
    }
  },
  getLawString: (lawLevel: number, lawId: number, laws: Law[], prefix: boolean = true): string | undefined => {
    for (let law of laws) {
      if (lawLevel === 0) {
        if (law.id === lawId) {
          return law.lawValue
        }
      } else {
        for (const chapter of law.chapters ?? []) {
          if (lawLevel === 1) {
            if (chapter.id === lawId) {
              return law.lawValue + "第" + toChinese(chapter.chapterIndex) + "章"
            }
          } else {
            for (const article of chapter.articles ?? []) {
              if (lawLevel === 2) {
                if (article.id === lawId) {
                  if (prefix) {
                    return law.lawValue + getLawArticleString(article)
                  } else {
                    return getLawArticleString(article, false)
                  }
                }
              } else {
                for (const paragraph of article.paragraphs ?? []) {
                  if (lawLevel === 3) {
                    if (paragraph.id === lawId) {
                      if (prefix) {
                        return law.lawValue
                            + "第" + toChinese(article.articleIndex) + "条"
                            + "第" + toChinese(paragraph.paragraphIndex) + "款"
                            + getLawParagraphString(paragraph)
                      } else {
                        return getLawParagraphString(paragraph)
                      }
                    }
                  } else {
                    for (const subparagraph of paragraph.subparagraphs ?? []) {
                      if (lawLevel === 4) {
                        if (subparagraph.id === lawId) {
                          if (prefix) {
                            return law.lawValue
                                + "第" + toChinese(article.articleIndex) + "条"
                                + "第" + toChinese(paragraph.paragraphIndex) + "款"
                                + "第" + toChinese(subparagraph.subparagraphIndex) + "项"
                                + getLawSubparagraphString(subparagraph, false)
                          } else {
                            return getLawSubparagraphString(subparagraph, false)
                          }
                        }
                      } else {
                        for (const item of subparagraph.items ?? []) {
                          if (lawLevel === 5) {
                            if (item.id === lawId) {
                              if (prefix) {
                                return law.lawValue
                                    + "第" + toChinese(article.articleIndex) + "条"
                                    + "第" + toChinese(paragraph.paragraphIndex) + "款"
                                    + "第" + toChinese(subparagraph.subparagraphIndex) + "项"
                                    + "第" + item.itemIndex + "目"
                                    + getLawItemString(item, false)
                              } else {
                                return getLawItemString(item, false)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return undefined
  },
  getLawStringByArticleLaw(articleLaw: { entity?: LawPunishArticleLawEntity }, prefix: boolean = true): string | undefined {
    const entity = articleLaw.entity
    if (!entity) {
      return undefined
    }
    if (!entity.law) {
      return undefined
    }
    if (!entity.lawChapter) {
      return entity.law.lawValue
    }
    if (!entity.lawArticle) {
      return entity.law.lawValue + "第" + toChinese(entity.lawChapter.chapterIndex) + "章"
    }
    if (!entity.lawParagraph) {
      if (prefix) {
        return entity.law.lawValue + "\"" + getLawArticleString(entity.lawArticle) + "\""
      } else {
        return getLawArticleString(entity.lawArticle, false)
      }
    }
    if (!entity.lawSubparagraph) {
      if (prefix) {
        return entity.law.lawValue
            + "第" + toChinese(entity.lawArticle.articleIndex) + "条"
            + "第" + toChinese(entity.lawParagraph.paragraphIndex) + "款" + "\""
            + getLawParagraphString(entity.lawParagraph) + "\""
      } else {
        return getLawParagraphString(entity.lawParagraph)
      }
    }
    if (!entity.lawItem) {
      if (prefix) {
        return entity.law.lawValue
            + "第" + toChinese(entity.lawArticle.articleIndex) + "条"
            + "第" + toChinese(entity.lawParagraph.paragraphIndex) + "款"
            + "第" + toChinese(entity.lawSubparagraph.subparagraphIndex) + "项" + "\""
            + getLawSubparagraphString(entity.lawSubparagraph, false) + "\""
      } else {
        return getLawSubparagraphString(entity.lawSubparagraph, false)
      }
    }
    if (prefix) {
      return entity.law.lawValue
          + "第" + toChinese(entity.lawArticle.articleIndex) + "条"
          + "第" + toChinese(entity.lawParagraph.paragraphIndex) + "款"
          + "第" + toChinese(entity.lawSubparagraph.subparagraphIndex) + "项"
          + "第" + entity.lawItem.itemIndex + "目" + "\""
          + getLawItemString(entity.lawItem, false) + "\""
    } else {
      return getLawItemString(entity.lawItem, false)
    }
  },
  getLawPrefixByArticleLaw(articleLaw: { entity?: LawPunishArticleLawEntity }) {
    const entity = articleLaw.entity
    return this.getLawPrefix(
        entity?.law,
        entity?.lawChapter,
        entity?.lawArticle,
        entity?.lawParagraph,
        entity?.lawSubparagraph,
        entity?.lawItem
    )
  },
  getLawPrefix(
      law?: Law,
      chapter?: LawChapter,
      article?: LawArticle,
      paragraph?: LawParagraph,
      subparagraph?: LawSubparagraph,
      item?: LawItem
  ): string | undefined {
    let str: string | undefined = undefined
    if (!law) {
      return str
    }
    str = law.lawValue
    if (!chapter) {
      return str
    }
    if (!article) {
      return str + "第" + toChinese(chapter.chapterIndex) + "章"
    }
    str = str + "第" + toChinese(article.articleIndex) + "条"
    if (!paragraph) {
      return str
    }
    str = str + toChinese(paragraph.paragraphIndex) + "款"
    if (!subparagraph) {
      return str
    }
    str = str + "第" + toChinese(subparagraph.subparagraphIndex) + "项"
    if (!item) {
      return str
    }
    str = str + "第" + item.itemIndex + "目"
    return str
  },
  toChinese(num: number): string {
    let words: string[] = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
    let adds = ["", '十', '百', '千', '万', '亿', '十', '百', '千'];
    if (words[num]) {
      return words[num];
    } else if (num > 10 && num < 20) {
      let numStr = num.toString();
      let n = parseInt(numStr.substring(1, 2));
      return adds[1] + words[n];
    } else if (num > 10) {
      let result = "";
      let numStr = num.toString();
      for (let i = 0; i < numStr.length; ++i) {
        let n = parseInt(numStr.substring(i, i + 1));
        let m = numStr.length - i - 1;
        result += words[n] + adds[m];
      }
      return result;
    } else return "零";
  }
}

function toChinese(num: number): string {
  let words: string[] = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
  let adds = ["", '十', '百', '千', '万', '亿', '十', '百', '千'];
  if (words[num]) {
    return words[num];
  } else if (num > 10 && num < 20) {
    let numStr = num.toString();
    let n = parseInt(numStr.substring(1, 2));
    return adds[1] + words[n];
  } else if (num > 10) {
    let result = "";
    let numStr = num.toString();
    for (let i = 0; i < numStr.length; ++i) {
      let n = parseInt(numStr.substring(i, i + 1));
      let m = numStr.length - i - 1;
      result += words[n] + adds[m];
    }
    return result;
  } else return "零";
}

function getLawItemString(item: LawItem, prefix: boolean = true): string {
  let str = ""
  if (prefix) {
    str = str + item.itemIndex + "."
  }
  return str + item.itemValue
}

function getLawSubparagraphString(subparagraph: LawSubparagraph, prefix: boolean = true): string {
  let str = ""
  if (prefix) {
    str = str + "（" + toChinese(subparagraph.subparagraphIndex) + "）"
  }
  str = str + subparagraph.subparagraphValue
  subparagraph.items?.forEach(item => {
    str = str + getLawItemString(item)
  })
  return str
}

function getLawParagraphString(paragraph: LawParagraph): string {
  let str = paragraph.paragraphValue
  paragraph.subparagraphs?.forEach(subparagraph => {
    str = str + getLawSubparagraphString(subparagraph)
  })
  return str
}

function getLawArticleString(article: LawArticle, prefix: boolean = true): string {
  let str = ""
  if (prefix) {
    str = str + "第" + toChinese(article.articleIndex) + "条"
  }
  article.paragraphs?.forEach(paragraph => {
    str = str + getLawParagraphString(paragraph)
  })
  return str
}
