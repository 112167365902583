import { Form, Input, Select } from "antd"
import Module from '../style/statementOfDefence.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function StatementOfDefence(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>陈述申辩笔录</h1>
              <div className={Module.center}>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                    label="当事人"
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="当事人"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                    label="陈述人"
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="陈述人"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                    label="时间"
                  >
                    <StringDatePicker
                     showTime
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日hh时mm分"} placeholder="时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item2}
                    name={[3, "fieldValue"]}
                    label="地点"
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="地点"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item2}
                    name={[4, "fieldValue"]}
                    label="陈述、申辩内容"
                  >
                    <Input.TextArea
                    autoSize={{minRows:3,maxRows:15}}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="陈述、申辩内容"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item4}
                    name={[6, "fieldValue"]}
                    label="陈述人签名"
                  >
                    <Select
                    allowClear
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="陈述人签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={Module.item}
                    name={[5, "fieldValue"]}
                    label="签名时间"
                    style={{ marginLeft: "2%" }}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="签名时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item4}
                    name={[7, "fieldValue"]}
                    label="记录人签名"
                  >
                    <Select
                     allowClear
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="记录人签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={Module.item}
                    name={[8, "fieldValue"]}
                    label="签名时间"
                    style={{ marginLeft: "2%" }}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="签名时间"
                    />
                  </Form.Item>
                </div>
                {/* <Form.Item
                  className={Module.select1}
                  name={[6, "fieldValue"]}
                  label="印章管理"
                  style={{ width: "36%" }}
                >
                  <Select placeholder="印章"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  >
                    {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                  </Select>
                </Form.Item> */}
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

