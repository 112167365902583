import { Options } from "../home/user"

class Role {
  id: number
  roleName: string
  roleDescription: string
  parents?: number[]
  children?: number[]

  constructor(id: number, roleName: string, roleDescription: string,
    parents?: number[], children?: number[]) {
    this.id = id
    this.roleName = roleName
    this.roleDescription = roleDescription
    this.parents = parents
    this.children = children
  }

  static create(role: Role): Role | null {
    const id = role.id
    const roleName = role.roleName
    const roleDescription = role.roleDescription
    const parents = role.parents ?? []
    const children = role.children ?? []
    if (id && roleName && roleDescription && Array.isArray(parents) && Array.isArray(children)) {
      return new Role(id, roleName, roleDescription, parents, children)
    } else {
      return null
    }
  }

  static createList(roles: Role[]): Role[] {
    const newRoles: Role[] = []
    roles.forEach(role => {
      const newRole = Role.create(role)
      if (newRole) {
        newRoles.push(newRole)
      }
    })
    return newRoles
  }

  toOption(): Options {
    return {
      label: this.roleDescription,
      value: this.id
    }

  }


}

export default Role