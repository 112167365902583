import { message } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiCase } from '../internet/ApiCase'
import SuspectUnit from '../entity/SuspectUnit'


const columns: ColumnsType<SuspectUnit> = [
    {
        title: '单位编码',
        dataIndex: 'unitCode',
        width: 100,
    },
    {
        title: '单位名称',
        dataIndex: 'unitName',
        width: 100,
        ellipsis: true
    },
    {
        title: '单位类型',
        dataIndex: 'unitType',
        width: 100,
        ellipsis: true
    },
    {
        title: '法定代表人姓名',
        dataIndex: 'representativeName',
        width: 150,
    },
    {
        title: '身份证号码',
        dataIndex: 'representativeIdentificationCard',
        width: 150,
        ellipsis: true
    },
    {
        title: '单位地址',
        dataIndex: 'unitAddress',
        width: 200,
        ellipsis: true
    },
    {
        title: '负责人姓名',
        dataIndex: 'principalName',
        width: 100,
    },

]

const Lawfwork = (props: { caseId?: number }) => {
    const [data, setData] = useState<SuspectUnit[]>([])
    const location = useLocation()
    const navigate = useNavigate()
    const caseId = props.caseId as number
    const apiCase = new ApiCase(message, navigate)
    useEffect(() => {
        if (caseId) {
            apiCase.getSuspectUnit(caseId, {
                successHandler: data => {
                    if (data) {
                        setData(data)
                    } else {
                        setData([])
                    }
                }
            })

        }
    }, [location, props.caseId])
    const criminalUnitChange = (criminalUnitData: SuspectUnit) => {
        return {
            onClick: () => {
                navigate("/work", { state: { criminalUnitData: criminalUnitData, caseId: caseId, } })
            }
        }
    }
    return (
        <div>
            <Table
                onRow={criminalUnitChange}
                rowKey="id"
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </div>

    )
}
export default Lawfwork