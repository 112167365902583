import {
  DownOutlined,
  EditOutlined,
  FolderAddOutlined,
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Popconfirm, Tree } from 'antd';
import React, { Key, useEffect, useState } from 'react';
import Region from '../entity/Region';
import Module from '../style/address.module.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { ModalControl } from "./modalControl";
import { DataNode } from "./dataNode";
import ApiAdminRegion from '../internet/ApiAdminRegion';


const Address: React.FC = () => {
  const location = useLocation()
  const [nodes, setNodes] = useState<DataNode<Region>[]>([])
  const [addModalControl, setAddModalControl] = useState<ModalControl<Region>>(ModalControl.hide())
  const [editModalControl, setEditModalControl] = useState<ModalControl<Region>>(ModalControl.hide())
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [addForm] = Form.useForm<{ value: string }>();
  const [editForm] = Form.useForm<{ value: string }>();
  const navigate = useNavigate()
  const apiAdminRegion = new ApiAdminRegion(message, navigate)
  function showAddModal(entity: Region | null = null) {
    hideEditModal()
    setAddModalControl(ModalControl.show(entity))
  }

  function hideAddModal() {
    addForm.resetFields()
    setAddModalControl(ModalControl.hide())
  }

  function showEditModal(entity: Region | null = null) {
    hideAddModal()
    setEditModalControl(ModalControl.show(entity))
    if (entity) {
      editForm.setFieldsValue({ value: entity.regionName ?? "" })
    }
  }

  function hideEditModal() {
    editForm.resetFields()
    setEditModalControl(ModalControl.hide())
  }

  function addRegion(parentId: number | undefined | null) {
    addForm.validateFields()
      .then(item => {
        apiAdminRegion.addRegion(new Region(null, parentId ?? null, item.value, []), {
          exit: 0, successHandler: (res => {
            getRegions()
            setAddModalControl(ModalControl.hide())
          })
        })
      })
  }

  function editRegion(entity: Region | null) {
    if (entity) {
      editForm.validateFields()
        .then(item => {
          apiAdminRegion.updateRegion(new Region(entity.id, entity.parentRegionId, item.value, entity.children), {
            exit: 0, successHandler: (res => {
              getRegions()
              setEditModalControl(ModalControl.hide())
            })
          }
          )
        })
    }
  }

  function deleteRegion(id: number | null) {
    if (id) {
      apiAdminRegion.deleteRegion(id
        , {
          exit: 0, successHandler: (res => {
            getRegions()
          })
        })
    }
  }

  function getRegions() {
    apiAdminRegion.getRegions({
      successHandler: regions => {
        if (regions) {
          const nodes = regions.map(region => {
            return Region.create(region).toNode()
          })
          setNodes(nodes)
          setExpandedKeys(expandAll(nodes))
        }
      }
    })
  }

  useEffect(getRegions, [location])


  function expandAll(data: DataNode<any>[]): string[] {
    return data.flatMap(node => {
      let childKeys: string[] = [];
      if (node.children) {
        childKeys = expandAll(node.children);
      }
      childKeys.push(node.key)
      return childKeys;
    })
  }

  function onExpand(expandedKeys: Key[]) {
    setExpandedKeys(expandedKeys.map(key => {
      return key.toString()
    }))
  }

  const nodeReader = (node: DataNode<Region>) => {
    let nodeArr = node.title
    nodeArr =
      <div>
        <span>{nodeArr}</span>
        <span>
          <EditOutlined style={{ marginLeft: 10 }}
            onClick={() => showEditModal(node.entity)} />

          <PlusOutlined style={{ marginLeft: 10 }}
            onClick={() => showAddModal(node.entity)} />
          <Popconfirm
            title={<span>删除{node.title}?</span>}
            onConfirm={() => deleteRegion(node.entity.id)}
            onCancel={() => { }}
            okText="删除"
            cancelText="取消"
          >
            <MinusOutlined style={{ marginLeft: 10 }}
            />
          </Popconfirm>
        </span>

      </div>

    return nodeArr
  }

  const deleteNode = (key: string | null, data: Region[]) =>
    data.forEach((item, index) => {
      if (item.id?.toString() === key) {
        data.splice(index, 1);
        return;
      } else {
        if (item.children) {
          deleteNode(key, item.children);
        }
      }
    });

  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <h1>地理信息</h1>
        <Form className={Module.form}>
          <Button className={Module.colour} onClick={() => showAddModal()}
            type="text"><FolderAddOutlined
              className={Module.icon} />增加</Button>
        </Form>
        <Tree
          className={Module.tree}
          showLine
          titleRender={nodeReader}
          switcherIcon={<DownOutlined />}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          treeData={nodes}
        />
        <Modal title="编辑地理名称" visible={editModalControl.visible} onOk={() => {
          editRegion(editModalControl.entity)
        }} onCancel={hideEditModal}
          width={300} mask={false}>
          <Form form={editForm}>
            <Form.Item
              label="名称"
              name={"value"}
              rules={[{
                required: true,
                message: "清输入地区名称"
              }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <Modal title="添加地理名称" visible={addModalControl.visible} onOk={() => {
          addRegion(addModalControl.entity?.id)
        }} onCancel={hideAddModal}
          width={300} mask={false}>
          <Form form={addForm}>
            <Form.Item
              label="名称"
              name={"value"}
              rules={[{
                required: true,
                message: "清输入地区名称"
              }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default Address