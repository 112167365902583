import { Button, Form, Input, message, Popconfirm, Spin, } from 'antd';
import Module from '../style/wenshu.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import DocumentTypeEntity from "../entity/DocumentTypeEntity";
import FieldTypeEntity from "../entity/FieldTypeEntity";
import { ApiDocumentType } from "../internet/ApiDocumentType";
import DocumentEntity from "../entity/DocumentEntity";
import ApiDocument from "../internet/ApiDocument";
import FieldEntity from "../entity/FieldEntity";
import DocumentInfo from "../entity/documentinfo";
import { ApiCase } from "../internet/ApiCase";
import CaseEntity from "../entity/CaseEntity";
import { RoleMultistage } from "../entity/RoleMultistage";
import ApiRoles from "../internet/ApiRoles";
import UserEntity from "../entity/UserEntity";
import { ApiDepartment } from "../internet/ApiDepartment";
import UnitEntity from "../entity/UnitEntity";
import { ApiSeal } from '../internet/ApiSeal';
import Seal from '../entity/Seal';
import ApiAdminUnit from '../internet/ApiAdminUnit';
import { ApiUser } from '../internet/ApiUser';
import EvidenceList from '../allTemplate/evidencetemplate';
import { LawPunishType } from "../entity/LawPunishType";
import { ApiLaw } from "../internet/ApiLaw";
import FormElement from './formelment';
import { Template } from "../entity/Template";
import { ApiTemplate } from "../internet/ApiTemplate";
import { StrTemplate } from "../tool/StrTemplate";
import { Roles } from "../tool/Roles";
import Evidence from "../entity/Evidence";
import { Punish } from "../entity/Punish";
import { PunishItem } from "../entity/PunishItem";
import CaseReport from '../allTemplate/caseClosureReport';
import CaseSourceRegister from '../allTemplate/caseSourceRegister';
import PutRecordApproval from '../allTemplate/putRecordApproval';
import PutRecordDecisoin from '../allTemplate/putReacordDecison';
import PutReacordRegist from '../allTemplate/putReacordRegist';
import RightDutyTalk from '../allTemplate/rightDutyTalk';
import LiveReconnaissanceRecord from '../allTemplate/liveReconnaissanceRecord';
import FileCover from '../allTemplate/fileCover';
import FileCatalogue from '../allTemplate/fileCatalogue';
import AskRecord from '../allTemplate/askRecord';
import AdministrativeAsk from '../allTemplate/administrativeAsk';
import AddressForService from '../allTemplate/addressForService';
import ProofOfService from '../allTemplate/proofOfService';
import CheckOpinion from '../allTemplate/checkOpinion';
import DecisionApproval from '../allTemplate/decisionApproval';
import Role from "../entity/Role";
import InstructToCorrectNotation from '../allTemplate/instructToCorrectNotation';
import AdministrativePenaltyDecision from '../allTemplate/administrativePenaltyDecision';
import CloseApprovaltable from '../allTemplate/closeApprovaltable';
import BaseTypes from "../entity/BaseTypes";
import { FieldData } from "rc-field-form/lib/interface";
import { NamePath } from 'antd/lib/form/interface';
import { isArray } from "lodash";
import CaseTransferTable from '../allTemplate/caseTransferTable';
import CaseTransferBook from '../allTemplate/caseTransferbook';
import RejectionEvasionDesion from '../allTemplate/rejectionEvasiondesion';
import CaseTransferBookProof from '../allTemplate/caseTransferbookProof';
import NotPutOnRecordDecision from '../allTemplate/notPutOnRecordDecision';
import NotPutRecordApproval from '../allTemplate/notPutRecordApproval';
import NotPunishApproval from '../allTemplate/notPunishApproval';
import NotPunishDecision from '../allTemplate/notPunishDecision';
import SampleRecord from '../allTemplate/sampleRecord';
import InstallmentRepaymentApply from '../allTemplate/installmentRepaymentApply';
import InstallmentRepaymentApproval from '../allTemplate/installmentRepaymentApproval';
import ExpertiseReport from '../allTemplate/expertiseReport';
import ReportCompaint from '../allTemplate/reportCompaint';
import BlacklistManagementConfirmation from '../allTemplate/blacklistManagementConfirmation';
import BlacklistNotice from '../allTemplate/blacklistNotice';
import ApproveTheStagingDesion from '../allTemplate/approveTheStagingDesion';
import ApplicationForEnforcement from '../allTemplate/applicationForEnforcement';
import CrimalCaseTransferbook from '../allTemplate/crimalCaseTransferbook';
import CrimalCaseTransferbookProof from '../allTemplate/crimalCaseTransferbookProof';
import ConsentToRecusal from '../allTemplate/consentToRecusal';
import SaveEvidenceapprval from '../allTemplate/saveEvidenceapprval';
import SaveEvidenceNotice from '../allTemplate/saveEvidenceNotice';
import { SaveEvidenceManifest } from '../allTemplate/saveEvidenceManifest';
import SaveEvidenceBookNotice from '../allTemplate/saveEvidenceBookNotice';
import AssistWithInvestigationsNotice from '../allTemplate/assistWithInvestigationsNotice';
import { BrainstormingRecord } from '../allTemplate/brainstormingRecord';
import SamplingNotice from '../allTemplate/samplingNotice';
import FulfillmentOfReminders from '../allTemplate/fulfillmentOfReminders';
import { PunishHearingReport } from '../allTemplate/punishHearingReport';
import { PunishRecord } from '../allTemplate/punishRecord';
import SignificantPenaltiesReport from '../allTemplate/significantPenaltiesReport';
import SignificantPenaltiesTable from '../allTemplate/significantPenaltiesTable';
import PunishHearingNotice from '../allTemplate/punishHearingNotice';
import MandatoryApprovalTable from '../allTemplate/mandatoryApprovalTable';
import StatementOfDefence from '../allTemplate/statementOfDefence';
import { SaveEvidenceBook } from '../allTemplate/saveEvidenceBook';
import { SamplingForensicsBook } from '../allTemplate/samplingForensicsBook';
import Law from '../entity/Law';
import AskNotice from '../allTemplate/askNotice';
interface Type {
  documentId?: number
  caseId?: number
  masterId?: number
  status?: string
  documentName?: string
}
const Wenshu: React.FC<{ type?: Type, setTypes: (types: Type[]) => void, types: Type[] }> = (prop1) => {
  const location = useLocation()
  const navigate = useNavigate()
  const documentTypeId = (location.state as { documentTypeId?: number })?.documentTypeId
  let documentId: number | undefined
  let masterId: number | undefined
  let caseId: number | undefined
  let status: string | undefined
  if (prop1.type) {
    documentId = prop1.type.documentId
    status = prop1.type.status
    masterId = prop1.type.masterId
    caseId = prop1.type.caseId
  } else {
    documentId = (location.state as { documentId?: number })?.documentId
    status = (location.state as { status?: string })?.status
    masterId = (location.state as { masterId?: number })?.masterId
    caseId = (location.state as { caseId?: number })?.caseId
  }
  const approval = (location.state as { approval?: boolean })?.approval ?? false
  const dis = (!approval) && (status === "已完成" || status === "审批中" || status === "已审批")
  const infos = (location.state as { infos?: DocumentInfo[] })?.infos
  const [documentType, setDocumentType] = useState<DocumentTypeEntity | undefined>()
  const [document, setDocument] = useState<DocumentEntity | undefined>()
  const [form] = Form.useForm<DocumentEntity>()
  const [formArchive] = Form.useForm<DocumentEntity>()
  const menu = (location.state as { menu?: DocumentEntity })?.menu
  const [menuGen, setMenuGen] = useState(false)
  const [coverGen, setCoverGen] = useState(false)
  const [complete, setComplete] = useState(false)
  const [department, setDepartment] = useState<UnitEntity>()
  const [templateRoles, setTemplateRoles] = useState<Role[]>([])
  const accessorieComplate = (location.state as { accessorieComplate?: boolean })?.accessorieComplate
  const [caseEntity, setCase] = useState<CaseEntity | undefined>()
  const [fillRoles, setFillRoles] = useState<RoleMultistage[]>([])
  const [users, setUsers] = useState<UserEntity[]>([])
  const [user, setUser] = useState<UserEntity>()
  const [units, setUnits] = useState<UnitEntity[]>([])
  const [roles, setRoles] = useState<number[]>([])
  const [seals, setSeals] = useState<Seal[]>([])
  const [laws, setLaws] = useState<Law[]>([])
  const [lawPunishTypes, setLawPunishTypes] = useState<LawPunishType[]>([])
  const [templates, setTemplates] = useState<Template[]>([])
  const apiDocument = new ApiDocument(message, navigate)
  const apiDocumentType = new ApiDocumentType(message, navigate)
  const apiCase = new ApiCase(message, navigate)
  const apiRoles = new ApiRoles(message, navigate)
  const apiDepartment = new ApiDepartment(message, navigate)
  const apiSeal = new ApiSeal(message, navigate)
  const apiAdminUnit = new ApiAdminUnit(message, navigate)
  const apiUser = new ApiUser(message, navigate)
  const apiLaw = new ApiLaw(message, navigate)
  const apiTemplate = new ApiTemplate(message, navigate)
  const strTemplate = new StrTemplate(document?.documentCode ?? documentType?.typeDescription ?? "", units, users, lawPunishTypes, templates, fillRoles, templateRoles, laws, department)
  const baseTypes = new BaseTypes()
  const [font, setFont] = useState<number>(0)
  useEffect(() => {
    if (menuGen && coverGen && complete) {
      navigate(-5)
    }
  }, [menuGen, coverGen, complete])
  useEffect(() => {
    if (
      (!document)
      && caseEntity
      && documentType
      && fillRoles.length
      && users.length
      && units.length
      && templates.length
      && lawPunishTypes.length
      && templateRoles.length
      && laws.length
      && department
    ) {
      initDocument(documentType, caseEntity)
    }
  }, [caseEntity, document, documentType, fillRoles, users, units, templates, lawPunishTypes, department, laws, templateRoles])
  function changeNumber(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setFont(parseInt(value))
  }
  function getLaws() {
    apiLaw.get({
      successHandler: laws => {
        if (laws) {
          setLaws(laws)
        }
      }
    })
  }
  function saveDocument() {
    if (document) {
      const document1 = formatDocument(form.getFieldsValue(), document)
      if (!documentId) {
        if (infos) {
          navigate("/writ", { state: { limit: "封面", caseId: caseId, menu: document1 } })
        } else if (menu && caseId) {
          apiCase.completeCase(caseId, menu, document1, { exit: 5 })
        } else {
          apiDocument.add(document1, { exit: 2, }, documentId,)
        }
      } else {
        apiDocument.update(document1, {
          exit: 0, successHandler: () => {
            message.success("修改成功")
          }
        }, documentId)

      }
    }
  }


  function addAccessorie() {
    navigate("/accessrieslist", { state: { documentId: documentId, masterId: masterId, caseId: caseId, status: status } })
  }

  function upload() {
    navigate("/uploaddocument", {
      state: {
        documentId: document?.id,
        typeId: document?.typeId,
        masterId: masterId,
        caseId: caseId,
        complate: true
      }
    })
  }

  function reuplode() {
    navigate("/uploaddocument", {
      state: {
        documentId: document?.id,
        typeId: document?.typeId,
        caseId: caseId,
        masterId: masterId,
        recomplate: true
      }
    })
  }

  function onFieldsChange(changedFields: FieldData[], _: FieldData[]) {
  }

  function generateDocument(documentId: number) {
    return () => {
      message.warn("如修改文书，请先点击保存才能生成新的文书PDF")
      message.loading("正在生成文书", 1.5)
      apiDocument.generate(documentId, font, {
        exit: 0, successHandler: () => {
          message.success("生成文书成功")
          getDocument(documentId)
        }
      })
    }
  }

  function getTemplateRoles() {
    apiRoles.getTemplate({
      successHandler: roles => {
        setTemplateRoles(roles ?? [])
      }
    })
  }

  function deleteDocument(documentId: number) {
    return () => {
      apiDocument.delete(documentId, {
        successHandler: () => {
          if (prop1.type) {
            const targetIndex = [prop1.type].findIndex((item) => item.documentId === documentId);
            prop1.types.splice(targetIndex, 1);
          }
        }
      });
    }
  }


  const navigateToPdf = (documentId: number, replace: boolean = false, approval: boolean = false, see?: boolean) => {
    return () => {
      navigate("/pdf", {
        state: {
          accessorieComplate: accessorieComplate,
          documentTypeId: documentTypeId,
          see: see,
          caseId: caseId,
          masterId: masterId,
          documentId: documentId,
          status: status,
          approval: approval
        }, replace: replace
      })
    }
  }

  function formatDocument(documentForm: DocumentEntity, document: DocumentEntity): DocumentEntity {
    return new DocumentEntity(document.id, document.caseId, document.typeId, document.documentName, document.documentCode, document.docxPath, document.pdfPath, document.templatePath, document.pageNumber, document.startPageNumber, document.fix, document.complete,
      document.upload, document.masterId,
      documentForm.fields
    )
  }

  function createDocument(documentType: DocumentTypeEntity, caseEntity: CaseEntity): DocumentEntity {

    return new DocumentEntity(
      0, caseEntity.id, documentType.id, documentType.typeName, documentType.typeDescription,
      undefined, undefined, documentType.filePath, undefined, undefined,
      false, false, false, undefined,
      createFields(documentType.fieldTypes ?? [], caseEntity)
    )
  }

  function createFields(types: FieldTypeEntity[], caseEntity: CaseEntity): FieldEntity[] {
    return types.map(type => {
      let children: FieldEntity[][] = []
      if (type.fieldBaseTypeId === baseTypes.list) {
        if (infos === undefined || infos.length === 0) {
          if (type.fillRoleId === Roles.bind.fieldType.evidence.evidenceName.id && caseEntity.evidenceList?.length) {
            children = caseEntity.evidenceList.map((evidence, i) => createList(type.children ?? [], 0, { evidence: evidence }, undefined, i))
          } else if (type.fillRoleId === Roles.bind.fieldType.evidence.evidenceCategory.id && caseEntity.evidenceList?.length) {
            const evidences = caseEntity.evidenceList
            const map = new Map<string, Evidence[]>()
            evidences.forEach(evidence => {
              const type = evidence.evidenceType
              if (type) {
                const arr = map.get(type) ?? []
                arr.push(evidence)
                map.set(type, arr)
              }
            })
            const children1: FieldEntity[][] = []
            map.forEach((value) => {
              children1.push(createList(type.children ?? [], 0, { evidences: value }, undefined, undefined))
            })
            children = children1
          } else if (type.fillRoleId === Roles.bind.fieldType.punish.caseType.id && caseEntity.punishes?.length) {
            children = caseEntity.punishes.map((punish, i) => createList(type.children ?? [], 0, { punish: punish }, undefined, i))
          } else if (type.fillRoleId === Roles.bind.fieldType.punish.punishContent.id
            && caseEntity.punishes?.flatMap(it => it.items ?? [])?.length) {
            children = caseEntity.punishes.flatMap(it => it.items ?? []).map((item, i) => createList(type.children ?? [], 0, { punishItem: item }, undefined, i))
          } else {
            children = [createList(type.children ?? [], 0, { caseEntity: caseEntity })]
          }
        } else {
          children = infos.map((info, i) => createList(type.children ?? [], 0, undefined, info, i))
        }
      }
      let fieldValue = undefined
      if (type.fillRoleId) {
        fieldValue = strTemplate.getStr(type.fillRoleId, caseEntity)[0]
      }
      return new FieldEntity(0, 0, type.id,
        undefined, undefined, type.fieldBaseTypeId,
        type.fieldName, type.fieldDescription ?? "",
        fieldValue ?? type.defaultStr, type.defaultStr ?? "", type.permissionRoleId, type.fillRoleId, children, type
      )
    })
  }

  function getDocumentType(documentTypeId: number) {
    apiDocumentType.getDocumentType(documentTypeId, {
      successHandler: documentType => {
        if (documentType) {
          const type = DocumentTypeEntity.create(documentType)
          setDocumentType(type)
        }
      }
    })
  }

  function initDocument(documentType: DocumentTypeEntity, caseEntity: CaseEntity) {
    const document = createDocument(documentType, caseEntity)
    form.setFieldsValue(document)
    formArchive.setFieldsValue(document)
    document.masterId = masterId
    setDocument(document)
  }


  function getDocument(documentId: number) {
    apiDocument.selectById(documentId, {
      successHandler: document => {
        if (document) {
          if (document.upload && document.complete) {
            navigateToPdf(documentId, true)()
          } else {
            form.setFieldsValue(document)
            formArchive.setFieldsValue(document)
            setDocument(document)
            setDocumentType(document.documentTypeEntity)
          }
        }
      }
    })
  }

  function getCase(caseId: number) {
    apiCase.getCase(caseId, {
      successHandler: result => {
        if (result) {
          if (result.hostUnitId) {
            getSeals(result.hostUnitId)
            getUnit(result.hostUnitId)
          }
          setCase(result)
        }
      }
    })
  }

  function getFillRoles() {
    apiRoles.getFillRole({
      successHandler: roles => {
        if (roles) {
          setFillRoles(roles)
        }
      }
    })
  }

  function getRoles() {
    apiRoles.getRoles({
      successHandler: roles => {
        if (roles) {
          setRoles(roles)
        }
      }
    })

  }

  function getSeals(unitId: number) {
    apiSeal.getByUnit(unitId, {
      successHandler: seals => {
        if (seals) {
          setSeals(seals)
        }
      }
    })
  }

  function getTemplates() {
    apiTemplate.getAll({
      successHandler: templates => {
        setTemplates(templates ?? [])
      }
    })
  }

  function getLawPunishTypes() {
    apiLaw.getPunishTypes({
      successHandler: lawPunishTypes => {
        if (lawPunishTypes) {
          setLawPunishTypes(lawPunishTypes)
        }
      }
    })
  }

  function getUsers() {
    apiDepartment.getUsers({
      successHandler: users => {
        if (users) {
          setUsers(users)
        }
      }
    })
  }

  function getUser() {
    apiUser.getUser({
      successHandler: user => {
        if (user) {
          setUser(user)
        }
      }
    })
  }

  function getUnit(unitId: number) {
    apiAdminUnit.getUnit(unitId, {
      successHandler: unitData => {
        if (unitData) {
          setDepartment(unitData)
        }
      }
    })
  }

  function getUnits() {
    apiAdminUnit.getUnits({
      successHandler: unitData => {
        if (unitData) {
          setUnits(unitData)
        }
      }
    })
  }

  useEffect(() => {
    if (!caseId) {
      navigate(-1)
      return
    }
    if (documentId) {
      getDocument(documentId)
    } else if (documentTypeId) {
      getDocumentType(documentTypeId)
    } else {
      navigate(-1)
      return
    }
    getLaws()
    getCase(caseId)
    getFillRoles()
    getRoles()
    getUsers()
    getUser()
    getUnits()
    getLawPunishTypes()
    getTemplates()
    getTemplateRoles()
  }, [location])

  function createList(
    types: FieldTypeEntity[],
    documentId: number,
    entity?: { evidence?: Evidence, punish?: Punish, caseEntity?: CaseEntity, punishItem?: PunishItem, evidences?: Evidence[] },
    info?: DocumentInfo,
    i?: number
  ): FieldEntity[] {
    return types.map((type, index) => {
      let value: string | undefined = undefined
      if (info) {
        switch (index) {
          case 0:
            value = ((i ?? 0) + 1).toString()
            break
          case 1:
            value = info.documentName
            break
          case 2:
            value = info.documentCode
            break
          case 3:
            value = info.documentDate
            break
          case 4:
            if ((info.pageNumber ?? 0) > 1) {
              value = `${info.startPageNumber ?? 0}-${(info.startPageNumber ?? 0) + (info.pageNumber ?? 0) - 1}`
            } else {
              value = `${info.startPageNumber ?? 0}`
            }
            break
        }
      } else if (type.fillRoleId) {
        if (entity?.evidence) {
          value = strTemplate.getStrByEvidence(type.fillRoleId, entity.evidence, i)[0]
        } else if (entity?.evidences) {
          value = strTemplate.getStrByEvidences(type.fillRoleId, entity.evidences, i)[0]
        } else if (entity?.punish) {
          value = strTemplate.getStrByPunish(type.fillRoleId, entity.punish, i)[0]
        } else if (entity?.caseEntity) {
          value = strTemplate.getStr(type.fillRoleId, entity.caseEntity, i)[0]
        } else if (entity?.punishItem) {
          value = strTemplate.getStrByPunishItem(type.fillRoleId, entity.punishItem, i)[0]
        }
      }

      return new FieldEntity(0, 0, type.id,
        undefined, undefined, type.fieldBaseTypeId,
        type.fieldName, type.fieldDescription ?? "",
        value ?? type.defaultStr, type.defaultStr ?? "", type.permissionRoleId, type.fillRoleId, undefined, type
      )
    })
  }

  const buttons: JSX.Element[] = []
  const buttons1: JSX.Element[] = []
  if (documentId) {
    buttons.push(<Button name={"upload"} type="primary" className={Module.btn1}
      onClick={reuplode}>上传pdf</Button>)
    if (status || status === "未完成" || status === "待审批") {
      if (!accessorieComplate) {
        buttons.push(<Button type="primary" style={{ float: "right" }}
          onClick={addAccessorie}>附件管理</Button>)
      }
    }
    if (status !== "已完成") {
      buttons.push(<Button type="primary" className={Module.btn1}
        onClick={generateDocument(documentId)}>生成文书</Button>)
      buttons1.push(<Input prefix="文书字号：" type={"number"} defaultValue={font}
        onChange={changeNumber} style={{ width: 200, borderColor: "#1890ff" }} />
      )
    }
    if (document?.pdfPath) {
      if (approval) {
        buttons.push(<Button type="primary" className={Module.btn1}
          onClick={navigateToPdf(documentId, false, approval)}>查看PDF</Button>)
      } else {
        buttons.push(<Button type="primary" className={Module.btn1}
          onClick={navigateToPdf(documentId)}>查看PDF</Button>)
      }
    }
    if (status === "未完成" || status === "待审批") {
      buttons.push(<Button type="primary" className={Module.btn1}
        onClick={saveDocument}>保存</Button>)
      buttons.push(
        <Popconfirm
          title="是否删除文书"
          onConfirm={deleteDocument(documentId)}
          okText="确认删除"
          cancelText="取消"
        >
          <Button type="primary" className={Module.btn1}
          >删除</Button>
        </Popconfirm>

      )
    }
  } else {
    buttons.push(<Button name={"save"} type="primary" className={Module.btn1}
      onClick={saveDocument}>保存</Button>)
    buttons.push(<Button name={"upload"} type="primary" className={Module.btn1}
      onClick={upload}>上传pdf</Button>)
  }

  function getDeleteButton(remove?: ((index: (number | number[])) => void) | undefined, index?: number): JSX.Element {
    if (remove !== undefined && index !== undefined) {
      return <Button className={Module.button} onClick={() => remove(index)}>×</Button>
    } else {
      return <></>
    }
  }

  function getPermission(permissionRoleId: number | undefined): boolean {
    if (!permissionRoleId) {
      return false
    }
    return !(roles ?? []).includes(permissionRoleId)
  }

  function genAddButton(fieldEntity: FieldEntity, name: string, add: (defaultValue?: FieldEntity[], insertIndex?: number) => void): JSX.Element {
    const children = fieldEntity.fieldType?.children;
    if (children?.length) {
      return <Button type="primary" className={Module.btn} onClick={_ => {
        const fieldEntities = createList(children, document?.id ?? 0, { caseEntity: caseEntity });
        add(fieldEntities)
      }}>{name}</Button>
    } else {
      return <></>
    }
  }

  const radioRegex = /(radio\d+)\.(\w+)/

  function changeCheckbox(name: NamePath) {
    const value = form.getFieldValue(name)
    if (value === "⬜") {
      const names = radioUpdateField(name)
      form.setFields([{ name: name, value: "☑" }, ...names.map(it => ({ name: it, value: value }))])
    } else {
      form.setFields([{ name: name, value: "⬜" }])
    }
  }

  function radioUpdateField(name: NamePath): NamePath[] {
    if (!(isArray(name) && name.length)) {
      return [];
    }
    const fieldValue = form.getFieldValue(name.slice(0, name.length - 1)) as FieldEntity
    const baseTypeId = fieldValue?.baseTypeId
    if (baseTypeId !== baseTypes.radio) {
      return [];
    }
    const fieldCode = fieldValue?.fieldCode
    const result = fieldCode ? radioRegex.exec(fieldCode) : undefined
    if (!result) {
      return []
    }
    const head = result[1]
    const item = result[2]
    const regex = new RegExp(head + "\\.\\w+")
    const parentName = name.slice(0, name.length - 2);
    const values = form.getFieldValue(parentName)
    if (!(isArray(values) && values.length)) {
      return []
    }
    const fieldValues = values as FieldEntity[]
    return fieldValues.map((it, i) => ({ name: [...parentName, i, "fieldValue"], value: it }))
      .filter(it => regex.test(it.value.fieldCode) && it.value.fieldCode !== fieldCode)
      .map(it => it.name)
  }

  let element: JSX.Element
  let formElement: JSX.Element
  if (document) {
    const props = {
      form: form,
      formArchive: formArchive,
      status: status,
      approval: approval,
      document: document,
      caseEntity: caseEntity,
      seals: seals,
      roles: roles,
      units: units,
      user: user,
      users: users,
      fillRoles: fillRoles,
      lawPunishTypes: lawPunishTypes,
      getDeleteButton: getDeleteButton,
      createList: createList,
      strTemplate: strTemplate,
      getPermission: getPermission,
      dis: dis,
      genAddButton: genAddButton,
      onFieldsChange: onFieldsChange,
      changeCheckbox: changeCheckbox
    }
    switch (document.documentName) {
      case "证据清单":
        formElement = <EvidenceList
          {...props}
        />
        break;
      case "证据清单111":
        formElement = <EvidenceList
          {...props}
        />
        break;
      case "案件调查终结报告":
        formElement = <CaseReport
          {...props}
        />
        break;
      case "案件来源登记表":
        formElement = <CaseSourceRegister
          {...props}
        />
        break;
      case "立案审批表":
        formElement = <PutRecordApproval
          {...props}
        />
        break;
      case "立案决定书":
        formElement = <PutRecordDecisoin
          {...props}
        />
        break;
      case "立案登记表":
        formElement = <PutReacordRegist
          {...props}
        />
        break;
      case "权利义务告知书":
        formElement = <RightDutyTalk
          {...props}
        />
        break;
      case "现场检查（勘验）笔录":
        formElement = <LiveReconnaissanceRecord
          {...props}
        />
        break;

      case "行政处罚案卷目录":
        formElement = <FileCatalogue
          {...props}
        />
        break;
      case "行政处罚案卷目录1":
        formElement = <FileCatalogue
          {...props}
        />
        break;
      case "行政处罚卷宗封面":
        formElement = <FileCover
          {...props}
        />
        break;
      case "询问笔录":
        formElement = <AskRecord
          {...props}
        />
        break;
      case "行政处罚告知书":
        formElement = <AdministrativeAsk
          {...props}
        />
        break;
      case "送达地址确认书":
        formElement = <AddressForService
          {...props}
        />
        break;
      case "送达回证":
        formElement = <ProofOfService
          {...props}
        />
        break;
      case "行政处罚决定法制审核意见书":
        formElement = <CheckOpinion
          {...props}
        />
        break;
      case "行政处罚决定审批表":
        formElement = <DecisionApproval
          {...props}
        />
        break;
      case "责令改正违法行为通知书":
        formElement = <InstructToCorrectNotation
          {...props}
        />
        break;
      case "行政处罚决定书":
        formElement = <AdministrativePenaltyDecision
          {...props}
        />
        break;
      case "行政处罚案件结案审批表":
        formElement = <CloseApprovaltable
          {...props}
        />
        break;
      case "案件移送审批表":
        formElement = <CaseTransferTable
          {...props}
        />
        break;
      case "案件移送书":
        formElement = <CaseTransferBook
          {...props}
        />
        break;
      case "驳回回避申请决定书":
        formElement = <RejectionEvasionDesion
          {...props}
        />
        break;
      case "案件移送书送达回证":
        formElement = <CaseTransferBookProof
          {...props}
        />
        break;
      case "不予立案决定书":
        formElement = <NotPutOnRecordDecision
          {...props}
        />
        break;
      case "不予立案审批表":
        formElement = <NotPutRecordApproval
          {...props}
        />
        break;
      case "不予行政处罚决定审批表":
        formElement = <NotPunishApproval
          {...props}
        />
        break;
      case "不予行政处罚决定书":
        formElement = <NotPunishDecision
          {...props}
        />
        break;
      case "抽样取证记录":
        formElement = <SampleRecord
          {...props}
        />
        break;
      case "分期缴纳罚款申请":
        formElement = <InstallmentRepaymentApply
          {...props}
        />
        break;
      case "分期缴纳罚款审批表":
        formElement = <InstallmentRepaymentApproval
          {...props}
        />
        break;
      case "鉴定检测评估聘请委托书":
        formElement = <ExpertiseReport
          {...props}
        />
        break;
      case "举报投诉处理单":
        formElement = <ReportCompaint
          {...props}
        />
        break;
      case "纳入联合惩戒对象“黑名单”管理确认书":
        formElement = <BlacklistManagementConfirmation
          {...props}
        />
        break;
      case "纳入联合惩戒对象管理告知书":
        formElement = <BlacklistNotice
          {...props}
        />
        break;
      case "批准分期缴纳罚款申请决定书":
        formElement = <ApproveTheStagingDesion
          {...props}
        />
        break;
      case "强制执行申请书":
        formElement = <ApplicationForEnforcement
          {...props}
        />
        break;
      case "涉嫌犯罪案件移送书":
        formElement = <CrimalCaseTransferbook
          {...props}
        />
        break;
      case "涉嫌犯罪案件移送书送达回证":
        formElement = <CrimalCaseTransferbookProof
          {...props}
        />
        break;
      case "同意回避申请决定书":
        formElement = <ConsentToRecusal
          {...props}
        />
        break;
      case "先行登记保存证据审批表":
        formElement = <SaveEvidenceapprval
          {...props}
        />
        break;
      case "先行登记保存证据通知书":
        formElement = <SaveEvidenceNotice
          {...props}
        />
        break;
      case "先行登记保存证据物品处理清单":
        formElement = <SaveEvidenceManifest
          {...props}
        />
        break;
      case "先行登记保存证据物品处理通知书":
        formElement = <SaveEvidenceBookNotice
          {...props}
        />
        break;
      case "协助调查通知书":
        formElement = <AssistWithInvestigationsNotice
          {...props}
        />
        break;
      case "行政处罚案件单位负责人集体讨论笔录":
        formElement = <BrainstormingRecord
          {...props}
        />
        break;
      case "行政处罚抽样取证通知书":
        formElement = <SamplingNotice
          {...props}
        />
        break;
      case "行政处罚履行催告书":
        formElement = <FulfillmentOfReminders
          {...props}
        />
        break;
      case "行政处罚听证报告":
        formElement = <PunishHearingReport
          {...props}
        />
        break;
      case "行政处罚听证笔录":
        formElement = <PunishRecord
          {...props}
        />
        break;
      case "行政处罚听证通知书":
        formElement = <PunishHearingNotice
          {...props}
        />
        break;
      case "询问通知书":
        formElement = <AskNotice
          {...props}
        />
        break;
      case "重大行政处罚备案审批表":
        formElement = <SignificantPenaltiesTable
          {...props}
        />
        break;
      case "重大行政处罚决定备案报告":
        formElement = <SignificantPenaltiesReport
          {...props}
        />
        break;
      case "呈请审批表":
        formElement = <MandatoryApprovalTable
          {...props}
        />
        break;
      case "陈述申辩笔录":
        formElement = <StatementOfDefence
          {...props}
        />
        break;
      case "先行登记保存证据物品清单":
        formElement = <SaveEvidenceBook
          {...props}
        />
        break;
      case "抽样取证物品清单":
        formElement = <SamplingForensicsBook
          {...props}
        />
        break;
      default:
        formElement = <FormElement
          {...props}

        />
    }
    element =
      <div className={Module.all}>
        <div className={Module.cen}>
          {buttons}
          {buttons1}
          {formElement}
        </div>
      </div>
  } else {
    element = <div className={Module.loading}><Spin tip="数据加载中..." size="large" className={Module.spin} /></div>
  }
  return element
};

export default Wenshu;
