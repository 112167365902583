import { Form, Input, Select } from "antd"
import Module from '../style/rightdutytalk.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";

const RightDutyTalk: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>权利义务告知书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[8, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[0, "fieldValue"]}
                >
                  <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="当事人" />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[9, "fieldValue"]}
                >
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 20 }} disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)} className={Module.textarea} rows={3}
                    placeholder="违法行为及违反法律" />
                </Form.Item>
                <p style={{ textIndent: 30 }}>
                  依据《中华人民共和国行政处罚法》第四十四条规定，你享有陈述和申辩的权利，请在收到本告知书之日起3日内，
                  携带本人身份证明文件到本机关接受调查。逾期未按要求提供相关材料进行陈述、申辩的，视为你放弃上述权利。
                </p>
                <p style={{ textIndent: 30 }}>
                  本机关确定本案执法人员为
                  <Form.Item
                    className={Module.ite}
                    name={[1, "fieldValue"]}
                  >
                    <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="主办人员" />
                  </Form.Item>
                  、
                  <Form.Item
                    className={Module.ite}
                    name={[2, "fieldValue"]}
                  >
                    <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="协办人员" />
                  </Form.Item>
                  如你认为上述执法人员与本案有直接利害关系，
                  你可以在收到本告知书之日起3日内，携带本人身份证明文件、
                  执法人员存在直接利害关系证明材料向本机关提出回避申请。
                  逾期未按要求提供相关材料申请回避的，视为你对本案执法人员无异议。
                </p>
                <div className={Module.people1}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>联系人：</p>
                    <Form.Item
                      style={{ width: "35%", marginLeft: 0 }}
                      name={[1, "fieldValue"]}>
                      <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="主办人员" />
                    </Form.Item>
                    、
                    <Form.Item
                      style={{ width: "35%", }}
                      name={[2, "fieldValue"]}>
                      <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="协办人员" />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>联系电话：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[3, "fieldValue"]}>
                      <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="联系电话" />
                    </Form.Item>
                  </div>

                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>执法主体地址：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[4, "fieldValue"]}>
                      <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="执法主体地址" />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位名称</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[5, "fieldValue"]}>
                      <Select placeholder="单位名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.id.toString()}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[6, "fieldValue"]}>
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)} format="YYYY年MM月DD日" placeholder="时间" />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[7, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}


export default RightDutyTalk