import { Button, Form, Input, message } from "antd"
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Role from "../entity/Role";
import Module from '../style/wordrolesname.module.scss'
import { ApiAdminDocumentType } from "../internet/ApiAdminDocumentType";


const WordRolesName: React.FC = () => {
  const [form] = Form.useForm<Role>();
  const location = useLocation()
  const state = (location.state as { role?: Role, documentTypeId?: number })
  const role = state?.role
  const navigate = useNavigate()
  const documentTypeId = state?.documentTypeId
  const apiAdminDocumentType = new ApiAdminDocumentType(message, navigate)
  let button: JSX.Element = <></>
  if (documentTypeId) {
    if (role) {
      button = (
        <Form.Item className={Module.item}>
          <Button type="primary" onClick={() => updateDocumentTypeRole(documentTypeId, role.id)}>修改</Button>
          <Button type="primary" onClick={() => deleteDocumentTypeRole(documentTypeId, role.id)}>删除</Button>
        </Form.Item>
      )
    } else {
      button = (
        <Form.Item className={Module.item}>
          <Button type="primary" onClick={() => addDocumentTypeRole(documentTypeId)}>添加</Button>
        </Form.Item>
      )
    }
  }

  function addDocumentTypeRole(documentTypeId: number) {
    form.validateFields()
      .then(values => {
        apiAdminDocumentType.addDocumentTypeRole(values.roleDescription, documentTypeId)
      })
  }

  const deleteDocumentTypeRole = (documentTypeId: number, roleId: number) => {
    apiAdminDocumentType.deleteDocumentTypeRole(roleId, documentTypeId)
  }

  function updateDocumentTypeRole(documentTypeId: number, roleId: number) {
    form.validateFields()
      .then(values => {
        apiAdminDocumentType.updateDocumentTypeRole(roleId, values.roleDescription, documentTypeId, {
          otherHandler: code => {
            if (code === "sql_exist") {
              message.error("权限名称已经存在")
              return true
            } else {
              return false
            }
          }
        })

      })
  }

  useEffect(() => {
    if (!documentTypeId) {
      navigate(-1)
      return
    }
    if (role) {
      form.setFieldsValue(role)
    }
  }, [location])
  return (
    <div className={Module.all}>
      <Form
        form={form}
        className={Module.form}
      >
        <Form.Item
          className={Module.item}
          label="权限名称"
          name="roleDescription"
          rules={[{
            required: true
          }]}
        >
          <Input />
        </Form.Item>
        {button}
      </Form>
    </div>
  )
}
export default WordRolesName