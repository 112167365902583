import { Form, Input, Card, Button, message } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { ApiAdminDictionary } from "../internet/ApiAdminDictionary"
import Dictionary from "../entity/Dictionary"
import Module from '../style/adddir.module.scss'
class DictionaryForm {
    dictionaryValue: string = ""
}
const AddDir: React.FC = () => {
    const [form] = Form.useForm<DictionaryForm>()
    const typeId = (useLocation().state as { typeId: number }).typeId
    const navigate = useNavigate()
    const apiAdminDictionary = new ApiAdminDictionary(message, navigate)
    const btnClick = () => {
        form.validateFields()
            .then(dictionaryForm => {
                let dictionary = new Dictionary(null, typeId, dictionaryForm.dictionaryValue)
                apiAdminDictionary.addDictionary(dictionary)
            })
        let dictionaryForm = form.getFieldsValue()
    }

    return (
        <div className={Module.all}>
            <Card className={Module.card}>
                <Form
                    name='addDictionary'
                    form={form}
                    initialValues={new DictionaryForm()}
                    className={Module.form}
                >
                    <h1>添加字典</h1>
                    <Form.Item
                        name="dictionaryValue"
                        className={Module.item}
                        label="字典名称"
                        rules={[{
                            required: true,
                            message: "请输入名称"
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item  >
                        <Button type="primary" className={Module.btn} onClick={btnClick}>确认添加</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
export default AddDir