import { MinusOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/installmentRepaymentApproval.module.scss'
export default function InstallmentRepaymentApproval(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>分期缴纳罚款申请表</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.table}>
                  <div className={Module.row3}>
                    <div className={Module.a1} >
                      <p>案件名称</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[1, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="案件名称" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>申请事项及理由</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.List name={[2, "children"]}>
                        {(fields, { add, remove }) => {
                          const fieldEntity = props.form.getFieldValue(["fields", 2])
                          return (<div className={Module.list}>
                            <>
                              {fields.map((field) => (
                                <Form.List name={[field.name]}>
                                  {fields => (
                                    <div className={Module.list1}>
                                      <div className={Module.context1}>
                                        <Form.Item
                                          name={[0, "fieldValue"]}
                                          className={Module.page}
                                        >
                                          <Input
                                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                            placeholder="序号" />
                                        </Form.Item>
                                        <Form.Item
                                          label="："
                                          className={Module.item}
                                          name={[1, "fieldValue"]}
                                        >
                                          <Input.TextArea
                                            autoSize={{ minRows: 1, maxRows: 20 }}
                                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                            placeholder="内容" />
                                        </Form.Item>
                                        <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                          style={{ fontSize: 20 }} /></Button>
                                      </div>
                                    </div>
                                  )}
                                </Form.List>
                              ))}
                              {props.genAddButton(fieldEntity, "添加内容", add)}
                              妥否，请指示。
                            </>
                          </div>)
                        }}
                      </Form.List>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>承办人</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.time}>
                        <Form.Item
                          name={[7, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="主办人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[8, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="协办人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[3, "fieldValue"]}
                          className={Module.select1}
                        >
                          <StringDatePicker
                            placeholder="签名日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.select1} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>承办机构意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[4, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="承办机构意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[9, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="承承办机构签名">
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[3, "fieldValue"]}
                          className={Module.select1}
                        >
                          <StringDatePicker
                            placeholder="签名日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>行政机关负责人意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[5, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="行政机关负责人意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[11, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="行政机关负责人签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className={Module.select1}
                          name={[3, "fieldValue"]}
                        >
                          <StringDatePicker
                            placeholder="签名日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                        <Form.Item
                          className={Module.select1}
                          name={[10, "fieldValue"]} >
                          <Select placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>备注</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[6, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="备注" className={Module.text} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}