


const PathTitleMap: Record<string, string> = {
    "/": "主页",
    "/case": "案件办理",
    "/writ": "生成文书",
    "/wenshu": "生成文书",
    "/nowrit": "待审批文书",
    "/criminal": "嫌疑人信息",
    "/work": "嫌疑单位信息",
    "/books": "嫌疑人物品",
    "/zjlist": "证据清单",
    "/punish": "处罚意见",
    "/wordlist": "文档格式",
    "/Users": "用户设置",
    "/pdf": "PDF打印",
    "/dsp": "待审批案件",
    "/dws": "待审批文书详情",
    "/wslist": "文书列表",
    "/wsall": "文书详情",
    "/wsxg": "文书修改",

    "/dossier": "卷宗管理",
    "/jzlist": "卷宗列表",
    "/jzmain": "卷宗详细信息",
    "/cover": "卷宗封面",
    "/jzong": "卷宗目录",

    "/reason": "申请修改",
    "/info": "公共信息",
    "/unit": "单位信息",
    "/unitlist": "单位信息详情",
    "/lawitem": "法律条文",
    "/direact": "字典管理",
    "/address": "地理信息",

    "/chomod": "修改权限",
    "/users": "用户设置"

};





export default PathTitleMap