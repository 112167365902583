import { message } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCase } from '../internet/ApiCase';
import SuspectPerson from '../entity/SuspectPerson';


const columns: ColumnsType<SuspectPerson> = [
  {
    title: '嫌疑人姓名',
    dataIndex: 'suspectName',
    width: 100,
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 100,
    render(value, record) {
      if (record.gender !== null) {
        if (record.gender === true) {
          return "男"
        }
        return "女"
      }

    },
  },
  {
    title: '年龄',
    dataIndex: 'age',
    width: 100,
  },
  {
    title: '身份证号码',
    dataIndex: 'identificationCard',
    width: 250,
  },
  {
    title: '户籍地址',
    dataIndex: 'address',
    width: 250,
  },
  {
    title: '单位名称',
    dataIndex: 'unitName',
    width: 150,
  },
  {
    title: '职位',
    dataIndex: 'position',
    width: 150,

  },
];

const Lawf = (props: { caseId: number }) => {
  const [data, setData] = useState<SuspectPerson[]>([])
  const location = useLocation()
  const caseId = props.caseId
  const navigate = useNavigate()
  const apiCase = new ApiCase(message, navigate)
  useEffect(() => {
    if (caseId) {
      apiCase.getSuspectPerson(caseId, {
        successHandler: result => {
          if (result) {
            setData(result)
          } else {
            setData([])
          }
        }
      })
    }
  }, [location])
  const crimalChange = (criminalData: SuspectPerson) => {
    return {
      onClick: () => {
        navigate("/criminal", { state: { criminal: criminalData, caseId: caseId, } })
      }
    }

  }
  return (
    <div>
      <Table
        onRow={crimalChange}
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={false}
      />

    </div>
  )
}
export default Lawf