import { MinusOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/closeApprovaltable.module.scss'
export default function CloseApprovaltable(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>行政处罚案件结案审批表</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.clumn1}>
                      <p>当事人基本情况</p>
                    </div>
                    <div className={Module.clumn2}>
                      <div className={Module.a1}>
                        <div className={Module.b1}>
                          <Form.Item
                            name={[0, "fieldValue"]}
                            className={Module.item}
                            initialValue="⬜"
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 0, "fieldValue"])}
                            />
                          </Form.Item>
                          <p className={Module.h2}>公民</p>
                        </div>
                        <div className={Module.b2}>
                          <div className={Module.e1}>
                            <div className={Module.f1}>
                              <p>姓名</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[1, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="姓名"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>性别</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[2, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="性别"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f5}>
                              <p>年龄</p>
                            </div>
                            <div className={Module.f6}>
                              <Form.Item
                                name={[3, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="年龄"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={Module.e2}>
                            <div className={Module.f1}>
                              <p>住址</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[4, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 3 }}
                                  placeholder="住址"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>身份证号码</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[5, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 3 }}
                                  placeholder="身份证号码"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f5}>
                              <p>联系电话</p>
                            </div>
                            <div className={Module.f6}>
                              <Form.Item
                                name={[6, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  placeholder="联系电话"
                                  autoSize={{ minRows: 2, maxRows: 3 }}
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={Module.a2}>
                        <div className={Module.b1}>
                          <Form.Item
                            name={[7, "fieldValue"]}
                            className={Module.item}
                            initialValue="⬜"
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])}
                            />
                          </Form.Item>
                          <p className={Module.h2}>法人或其他组织</p>
                        </div>
                        <div className={Module.b2}>
                          <div className={Module.e1}>
                            <div className={Module.f1}>
                              <p>名称</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[8, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="名称"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>法定代表人</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[9, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="法定代表人"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={Module.e2}>
                            <div className={Module.f1}>
                              <p>住址</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[10, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 2.5 }}
                                  placeholder="住址"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>联系电话</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[11, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 2.5 }}
                                  placeholder="联系电话"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>案件名称</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[12, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="案件名称"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>承办人</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[13, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="主办人员"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[14, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="协办人员"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}><p>案由</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[15, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="案由"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>立案日期</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[16, "fieldValue"]}
                        className={Module.item}
                      >
                        <StringDatePicker
                          format="YYYY年MM月DD日"
                          placeholder="立案日期"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.c1}><p>行政处罚决定书案号</p>
                    </div>
                    <div className={Module.c2}>
                      <div className={Module.casenumber}>
                        <Form.Item
                          className={Module.item1}
                          name={[46, "fieldValue"]}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 46, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="案号" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.c3}><p>处罚日期</p>
                    </div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[17, "fieldValue"]}
                        className={Module.item}
                      >
                        <StringDatePicker
                          placeholder="处罚日期"
                          format="YYYY年MM月DD日"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>案件简要情况</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[18, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 20 }}
                            placeholder="案件简要情况"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>行政处罚内容</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.List
                        name={[19, "children"]}>
                        {(fields, { add, remove }) => {
                          const fieldEntity = props.form.getFieldValue(["fields", 19])
                          return <div className={Module.list}>
                            {fields.map((field, index) => {
                              return <div className={Module.list1}>
                                <Form.List name={field.name}>
                                  {fields => (
                                    < div className={Module.context1}>
                                      <Form.Item
                                        name={[0, "fieldValue"]}
                                        className={Module.page}
                                        initialValue={index + 1}
                                      >
                                        <Input
                                          disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                          placeholder="序号" />
                                      </Form.Item>
                                      <Form.Item
                                        label="："
                                        className={Module.item}
                                        name={[1, "fieldValue"]}
                                      >
                                        <Input
                                          disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                          placeholder="处罚内容" />
                                      </Form.Item>
                                      <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                        style={{ fontSize: 20 }} /></Button>
                                    </div>
                                  )}
                                </Form.List>
                              </div>
                            })}
                            {props.genAddButton(fieldEntity, "添加处罚", add)}
                          </div>
                        }}
                      </Form.List>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.d1}>
                      <p>行政处罚执行情况</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[20, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 20, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>责令改正违法行为的执行情况：</p>
                        <Form.Item
                          name={[21, "fieldValue"]}
                          className={Module.item1}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[22, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 22, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>罚款缴纳情况、违法所得、非法财物的处理情况：</p>
                        <Form.Item
                          name={[23, "fieldValue"]}
                          className={Module.item2}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[24, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 24, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>行政执法主体强制执行或者申请人民法院强制执行的情况：</p>
                        <Form.Item
                          name={[25, "fieldValue"]}
                          className={Module.item2}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[26, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 26, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>其他：</p>
                        <Form.Item
                          name={[27, "fieldValue"]}
                          className={Module.item2}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 27, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row8}>
                    <div className={Module.d1}>
                      <p>附有关行政处罚文书</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[28, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 28, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>省财政厅统一印制或者监制的罚没票据、罚没收据：</p>
                        <Form.Item
                          name={[29, "fieldValue"]}
                          className={Module.item1}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 29, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[30, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 30, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>关于先行登记保存、查封、扣押、没收的涉案财物，后续处置凭证：</p>
                        <Form.Item
                          name={[31, "fieldValue"]}
                          className={Module.item2}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 31, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[32, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 32, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>申请人民法院强制执行的相关文书等：</p>
                        <Form.Item
                          name={[33, "fieldValue"]}
                          className={Module.item2}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 33, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[34, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 34, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>其他：</p>
                        <Form.Item
                          name={[35, "fieldValue"]}
                          className={Module.item2}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 35, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row9}>
                    <div className={Module.d1}>
                      <p>行政复议、行政诉讼情况（可选）</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[36, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 2 }}
                            placeholder="行政复议、行政诉讼情况（可选）"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 36, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>承办人意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[37, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="承办人意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[42, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 42, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="主办人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[41, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 41, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="协办人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          name={[38, "fieldValue"]} className={Module.time}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.time} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>承办机构审核意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[39, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="承办机构审核意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 39, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[43, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 43, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          name={[38, "fieldValue"]} className={Module.time}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.time} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>单位负责人审批意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[40, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="单位负责人审批意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 42, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[45, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 45, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          name={[38, "fieldValue"]} className={Module.time}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.time} placeholder="签名日期" />
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          className={Module.select}
                          name={[44, "fieldValue"]} >
                          <Select placeholder="印章"
                            placement="topLeft"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 44, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}