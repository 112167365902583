import React, { useEffect, useState } from "react";
import Module from '../style/home.module.scss'
import Speak from "./speak";
import { CommentOutlined, QuestionOutlined, UserOutlined, } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Dropdown, Menu, MenuProps, message, Space } from 'antd';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import PathTitleMap from "../const/PathTitleMap";
import { FiArrowLeft } from "react-icons/fi";
import Clock from "./clock";
import { User } from "../entity/User";
import DocumentInfo from "../entity/documentinfo";
import ApiDocument from "../internet/ApiDocument";
import ApiGlobal from "../internet/ApiGlobal";
import MenuEntity from "../entity/MenuData";
import { ApiProjectInfo } from "../internet/ApiProjectInfo";
import { ProjectInfoEntity } from "../entity/ProjectInfo";
import UserEntity from "../entity/UserEntity";
import ApiOpen from "../internet/ApiOpen";



interface MenuType {
    label: React.ReactNode
    key: number
    path?: string
    children?: MenuType[]
}
const rootSubmenuKeys = ['1', '5', '8', '11'];
const Home: React.FC<{ User: UserEntity | null, caseId: number | undefined, badge: number | undefined }> = ({ User, caseId, badge }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [infos, setInfos] = useState<DocumentInfo[]>([])
    const [chat, setChat] = useState<boolean>(true)
    const [projectInfo, setProjectInfo] = useState<ProjectInfoEntity>()
    const [menu, setMenu] = useState<MenuEntity[]>([])
    const apiAdminDocumentType = new ApiDocument(message, navigate)
    const apigloal = new ApiGlobal(message, navigate)
    const apiprojectinfo = new ApiOpen(message, navigate)
    function getMenu() {
        apigloal.getMenu({
            successHandler: menus => {
                if (menus) {
                    setMenu(menus)
                }
            }
        })
    }
    function getProjectInfo() {
        apiprojectinfo.get({
            successHandler: infos => {
                if (infos) {
                    setProjectInfo(infos)
                }
            }
        })
    }
    useEffect(() => {
        apiAdminDocumentType.getInfos(undefined, {
            successHandler: data => {
                if (data) {
                    setInfos(data)
                }
            }
        })
        getMenu()
        getProjectInfo()
    }, [location])
    function repassClick() {
        navigate('/repass')
    }
    function iconClick() {
        navigate(-1)
    }
    const menupass = (
        <Menu
            items={[
                {
                    label: <span className={Module.repass} onClick={repassClick}>修改密码</span>,
                    key: '12332',
                },
            ]}
        />
    );
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.map((_: any, index: number) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return (
            <Breadcrumb.Item key={url}>
                <Link style={{ color: "white" }} to={url}>{PathTitleMap[url]}</Link>
            </Breadcrumb.Item>
        );
    });
    const breadcrumbItems = [
        <Breadcrumb.Item key="home">
        </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);


    const btnChange = () => {
        navigate("/logout")
    }
    const [openKeys, setOpenKeys] = useState(['3']);
    const onOpenChange: MenuProps['onOpenChange'] = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    function actionClick() {
        navigate("instructions")

    }
    function chatClick() {
        setChat(!chat)
        console.log(chat);

    }

    badge = infos.length
    const datas: MenuType[] = menu.map(item => {
        let menuType: MenuType = {
            label: <Link className={Module.link} to={item.path}><span>{item.menuName}</span></Link>,
            key: item.id
        }
        if (item.children) {
            menuType.children = item.children?.map(chil => {
                if (chil.menuName === "待审批文书") {
                    return {
                        label: <Link to={chil.path}><Badge size="small" count={badge} offset={[12, 0]}><span style={{ color: "white" }}>{chil.menuName}</span></Badge></Link>,
                        key: chil.id,
                    }
                } else {
                    return {
                        label: <Link to={chil.path}><span>{chil.menuName}</span></Link>,
                        key: chil.id,
                    }
                }

            })

        } else {
            menuType.children = undefined
        }
        return menuType
    })

    return (

        <div className={Module.all}>
            <div className={Module.wrapper}>
                <div className={Module.left} >
                    <div className={Module.text}>
                        <p>林业行政案件管理系统</p>
                    </div>
                    <div className={Module.sidebar}>
                        <Menu
                            className={Module.menu}
                            openKeys={openKeys}
                            onOpenChange={onOpenChange}
                            defaultSelectedKeys={["3"]}
                            defaultOpenKeys={["3"]}
                            mode="inline"
                            theme="dark"
                            items={datas}
                        />

                    </div>

                    <div className={Module.buttom}>
                        <Button className={Module.btn} type="link" onClick={btnChange}>退出登录</Button>
                        <p>版本号:{projectInfo?.versions}</p>
                        <p>开发单位:{projectInfo?.developmentUnit}</p>
                    </div>
                </div>
                <div className={chat ? Module.center : Module.center1}>
                    <div className={Module.head}>
                        <div className={Module.demo}>
                            <Breadcrumb>
                                {breadcrumbItems}
                            </Breadcrumb>
                        </div>
                        <div className={Module.message}>
                            <div className={Module.icon}> <FiArrowLeft size={30} onClick={iconClick} /></div>
                            <div className={Module.tim}>
                                <Clock />
                                <Dropdown overlayStyle={{ zoom: 0.9 }} overlay={menupass} className={Module.span}>
                                    <Space>
                                        < UserOutlined />{User?.realName}
                                    </Space>
                                </Dropdown>
                                <Button type="text" className={Module.span1} onClick={actionClick}>操作指引<QuestionOutlined /></Button>
                                <Button type="text" className={Module.chat} onClick={chatClick}><CommentOutlined style={{ fontSize: 20 }} /></Button>
                            </div>
                        </div>

                    </div>
                    <div className={Module.context}>
                        <Outlet />
                    </div>
                </div>
                {
                    chat ? <div className={Module.speak}>
                        <Speak caseId1={caseId} />
                    </div> : <></>
                }
            </div>
        </div >


    )
}
export default Home


