export const Roles = {
  root: { id: 1, roleName: "root", roleDescription: "根用户" },
  bind: {
    id: 10000, roleName: "bind", roleDescription: "绑定",
    user: { id: 11000, roleName: "user", roleDescription: "用户" },
    unit: { id: 12000, roleName: "unit", roleDescription: "单位" },
    documentType: {
      id: 13000, roleName: "document_type", roleDescription: "文书类型",
      directory: { id: 13100, roleName: "directory", roleDescription: "目录" },
      cover: { id: 13200, roleName: "cover", roleDescription: "封面" },
    },
    fieldTypePermission: {
      id: 14000, roleName: "field_type_permission", roleDescription: "字段类型权限",
      handleUser: { id: 14100, roleName: "handle_user", roleDescription: "办案人员" },
    },
    fieldType: {
      id: 15000, roleName: "field_type", roleDescription: "字段类型",
      caseBase: {
        id: 15100, roleName: "case_base", roleDescription: "案件信息",
        caseCode: { id: 15101, roleName: "case_code", roleDescription: "案件编号" },
        caseName: { id: 15102, roleName: "case_name", roleDescription: "案件名称" },
        caseCause: { id: 15103, roleName: "case_cause", roleDescription: "案由" },
        caseType: { id: 30768, roleName: "c-case_type", roleDescription: "案件类型" },
        hostUnit: { id: 15104, roleName: "host_unit", roleDescription: "主办单位" },
        caseSource: { id: 15105, roleName: "case_source", roleDescription: "案件来源" },
        handle: { id: 15106, roleName: "handle", roleDescription: "主办人员" },
        handleSub: { id: 15107, roleName: "handle_sub", roleDescription: "协办人员" },
        mainCase: { id: 15108, roleName: "main_case", roleDescription: "主要案情" },
        city: { id: 15109, roleName: "city", roleDescription: "市" },
        district: { id: 15110, roleName: "district", roleDescription: "区" },
        townVillage: { id: 15111, roleName: "town_village", roleDescription: "镇/乡" },
        streetVillage: { id: 15112, roleName: "street_village", roleDescription: "街道/村" },
        communityGroup: { id: 15113, roleName: "community_group", roleDescription: "社区/组" },
        casePlace: { id: 15114, roleName: "case_place", roleDescription: "案发地点" },
        buildTime: { id: 15115, roleName: "build_time", roleDescription: "立案时间" },
        registerTime: { id: 15116, roleName: "register_time", roleDescription: "受案时间" },
        handleUid: { id: 15117, roleName: "handle_uid", roleDescription: "主办人员id" },
        handleSubUid: { id: 15118, roleName: "handle_sub_uid", roleDescription: "协办人员id" },
        handleCode: { id: 15119, roleName: "handle_code", roleDescription: "主办人员执法证号" },
        handleSubCode: { id: 15120, roleName: "handle_sub_code", roleDescription: "协办人员执法证号" },
        law: { id: 15121, roleName: "law", roleDescription: "违法条款" },
        lawFull: { id: 15122, roleName: "law_full", roleDescription: "违法条款全部" },
      },
      evidence: {
        id: 15200, roleName: "evidence", roleDescription: "证据",
        evidenceName: { id: 15201, roleName: "evidence_name", roleDescription: "证据名称" },
        evidenceType: { id: 15202, roleName: "evidence_type", roleDescription: "证据类型" },
        evidenceDescription: { id: 15203, roleName: "evidence_description", roleDescription: "证据描述" },
        pageSize: { id: 15204, roleName: "page_size", roleDescription: "证据页数" },
        pageNumber: { id: 15205, roleName: "page_number", roleDescription: "证据页码" },
        evidenceCategory: { id: 15206, roleName: "evidence_category", roleDescription: "证据分类" },
        evidenceCategoryAction: { id: 15207, roleName: "evidence_category_action", roleDescription: "证据分类行为" },
      },
      suspectPerson: {
        id: 15300, roleName: "suspect_person", roleDescription: "嫌疑人",
        suspectName: { id: 15301, roleName: "suspect_name", roleDescription: "姓名" },
        gender: { id: 15302, roleName: "gender", roleDescription: "性别" },
        age: { id: 15303, roleName: "age", roleDescription: "年龄" },
        race: { id: 15304, roleName: "race", roleDescription: "民族" },
        identificationCard: { id: 15305, roleName: "identification_card", roleDescription: "身份证" },
        domicilePlace: { id: 15306, roleName: "domicile_place", roleDescription: "户籍所在地" },
        mobilePhone: { id: 15307, roleName: "mobile_phone", roleDescription: "联系电话" },
        address: { id: 15308, roleName: "address", roleDescription: "地址" },
        unitName: { id: 15309, roleName: "unit_name", roleDescription: "单位名称" },
        position: { id: 15310, roleName: "position", roleDescription: "单位职位" },
        occupation: { id: 15311, roleName: "occupation", roleDescription: "职业" },
        birthday: { id: 15312, roleName: "birthday", roleDescription: "生日" },
      },
      suspectUnit: {
        id: 15400, roleName: "suspect_unit", roleDescription: "嫌疑单位",
        unitCode: { id: 15401, roleName: "unit_code", roleDescription: "统一社会信用代码" },
        unitName: { id: 15402, roleName: "unit_name", roleDescription: "单位名称" },
        unitType: { id: 15403, roleName: "unit_type", roleDescription: "单位类型" },
        unitAddress: { id: 15404, roleName: "unit_address", roleDescription: "单位地址" },
        unitPhone: { id: 15405, roleName: "unit_phone", roleDescription: "单位电话" },
        representativeName: { id: 15406, roleName: "representative_name", roleDescription: "法人姓名" },
        representativeIdentificationCard: { id: 15407, roleName: "representative_identification_card", roleDescription: "身份证" },
        representativePhone: { id: 15408, roleName: "representative_phone", roleDescription: "法人电话" },
        representativeAddress: { id: 15409, roleName: "representative_address", roleDescription: "法人住址" },
        principalName: { id: 15410, roleName: "principal_name", roleDescription: "负责人姓名" },
        principalIdentificationCard: { id: 15411, roleName: "principal_identification_card", roleDescription: "负责人身份证" },
        principalPhone: { id: 15412, roleName: "principal_phone", roleDescription: "负责人电话" },
        principalAddress: { id: 15413, roleName: "principal_address", roleDescription: "负责人住址" },
      },
      punish: {
        id: 15500, roleName: "punish", roleDescription: "处罚",
        caseType: { id: 15501, roleName: "case_type", roleDescription: "案件类型" },
        criminalType: { id: 15502, roleName: "criminal_type", roleDescription: "违法类型" },
        punishLevel: { id: 15503, roleName: "punish_level", roleDescription: "严重程度" },
        punishAction: { id: 15504, roleName: "punish_action", roleDescription: "违法行为" },
        punishLaw: { id: 15505, roleName: "punish_law", roleDescription: "违法条款" },
        punishContent: { id: 15506, roleName: "punish_content", roleDescription: "处罚内容" },
        punishLawFull: { id: 15507, roleName: "punish_law_full", roleDescription: "违法条款全部" },
      },
      dictionaryType: { id: 15600, roleName: "dictionary_type", roleDescription: "字典" },
      template: { id: 15700, roleName: "template", roleDescription: "模板" },
      serialNumber: {
        id: 15800, roleName: "serial_number", roleDescription: "序号",
        arabicNumber: { id: 15801, roleName: "arabic_number", roleDescription: "阿拉伯数字" },
        chineseNumber: { id: 15802, roleName: "chinese_number", roleDescription: "中文数字" },
      },
      constant: {
        id: 15900, roleName: "constant", roleDescription: "常量",
        dateNow: { id: 15901, roleName: "date_now", roleDescription: "当前日期" },
        yearNow: { id: 15902, roleName: "year_now", roleDescription: "当前年份" },
      },
      department: {
        id: 17100, roleName: "department", roleDescription: "单位信息",
        seal1: { id: 17101, roleName: "seal1", roleDescription: "印章1" },
        seal2: { id: 17102, roleName: "seal2", roleDescription: "印章2" },
        seal3: { id: 17103, roleName: "seal3", roleDescription: "印章3" },
        seal4: { id: 17104, roleName: "seal4", roleDescription: "印章4" },
        seal5: { id: 17105, roleName: "seal5", roleDescription: "印章5" },
        departmentCode: { id: 17106, roleName: "department_code", roleDescription: "单位编号" },
      },
      document: {
        id: 17200, roleName: "document", roleDescription: "文书信息",
        documentCode: { id: 17201, roleName: "document_code", roleDescription: "文书编号" },
        documentDate: { id: 17202, roleName: "document_date", roleDescription: "文书日期" },
        documentRegisterDate: { id: 17203, roleName: "document_register_date", roleDescription: "文书立案日期" },
      },
    },
    template: {
      id: 16000, roleName: "template", roleDescription: "模板",
      case: { id: 16100, roleName: "case", roleDescription: "案件" },
      evidence: { id: 16200, roleName: "evidence", roleDescription: "证据" },
      punish: { id: 16300, roleName: "punish", roleDescription: "处罚" },
      punishItem: { id: 16400, roleName: "punish_item", roleDescription: "处罚内容" },
      suspectPerson: { id: 16500, roleName: "suspect_person", roleDescription: "嫌疑人" },
      suspectUnit: { id: 16600, roleName: "suspect_unit", roleDescription: "嫌疑单位" },
    },
  },
  type: {
    id: 20000, roleName: "type", roleDescription: "类型",
    unit: { id: 21000, roleName: "unit", roleDescription: "单位" },
    seal: { id: 22000, roleName: "seal", roleDescription: "印章" },
    documentApproval: { id: 23000, roleName: "document_approval", roleDescription: "文书审批" },
    position: {
      id: 24000, roleName: "position", roleDescription: "职位",
      manager: { id: 24001, roleName: "manager", roleDescription: "管理人员" },
      personnel: { id: 24002, roleName: "personnel", roleDescription: "办案人员" },
    },
  },
  status: {
    id: 30000, roleName: "status", roleDescription: "状态",
    documentTypeHidden: { id: 30001, roleName: "document_type_hidden", roleDescription: "文书类型隐藏" },
    documentApprovalAccomplish: { id: 30002, roleName: "document_approval_accomplish", roleDescription: "审批完成" },
    caseAccomplish: { id: 30003, roleName: "case_accomplish", roleDescription: "案件完成" },
  },

}