import InternetTool from "./Internetool";
import { Punish } from "../entity/Punish";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiCasePunish {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("case/punish", message, navigate)
  }
  getByCaseId(caseId: number, resultParam?: ResultParam<Array<Punish>>) {
    this.internetTool.fe(
      `?caseId=${caseId}`,
      resultParam
    )
  }
  add(punish: Punish, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("punish", new Blob([JSON.stringify(punish)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  update(punish: Punish, caseTypeId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("punish", new Blob([JSON.stringify(punish)], { type: "application/json" }))
    formData.append("caseTypeId", caseTypeId.toString())
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft<void>(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )

  }
}