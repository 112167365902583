
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Module from '../style/dsp.module.scss'
interface DataType {
    key: number;
    id: string;
    data: string;
    name: string;
}

const columns: ColumnsType<DataType> = [
    {

        title: '审批类别',
        dataIndex: 'id',
        width: 150,
        ellipsis: true

    },
    {
        title: '案件归属',
        dataIndex: 'data',
        width: 200,
        ellipsis: true
    },
    {
        title: '案件主要内容',
        dataIndex: 'name',
        ellipsis: true
    },

];

const data: DataType[] = [
    {
        key: 1,
        id: '行政处罚审批',
        data: "森林林木",
        name: "2018年，盐边县富丰工贸有限责任公司在盐边县新九镇平谷村和平社堆放尾矿，同时在盐边县新九镇平谷村弯板社堆放弃土。盐边县林业局执法人员赵登国、肖峰于2021年11月6日10时02分到现场进行了初步调查。经初查，盐边县新九镇平谷村和平社堆放尾矿和盐边县新九镇平谷村弯板社堆放弃土，涉嫌违反《中华人民共和国森林法实施条例》第十六条第一款第一项的规定。根据《中华人民共和国森林法实施条例》第四十三条第一款和《四川省林业行政处罚裁量标准》之规定，建议对盐边县富丰工贸有限责任公司作出以下行政处罚：",
    },
    {
        key: 2,
        id: '重大处罚审批',
        data: "森林林木",
        name: "2018年，盐边县富丰工贸有限责任公司在盐边县新九镇平谷村和平社堆放尾矿，同时在盐边县新九镇平谷村弯板社堆放弃土。盐边县林业局执法人员赵登国、肖峰于2021年11月6日10时02分到现场进行了初步调查。经初查，盐边县新九镇平谷村和平社堆放尾矿和盐边县新九镇平谷村弯板社堆放弃土，涉嫌违反《中华人民共和国森林法实施条例》第十六条第一款第一项的规定。根据《中华人民共和国森林法实施条例》第四十三条第一款和《四川省林业行政处罚裁量标准》之规定，建议对盐边县富丰工贸有限责任公司作出以下行政处罚：",
    },
    {
        key: 3,
        id: '行政处罚审批',
        data: "森林林木",
        name: "2018年，盐边县富丰工贸有限责任公司在盐边县新九镇平谷村和平社堆放尾矿，同时在盐边县新九镇平谷村弯板社堆放弃土。盐边县林业局执法人员赵登国、肖峰于2021年11月6日10时02分到现场进行了初步调查。经初查，盐边县新九镇平谷村和平社堆放尾矿和盐边县新九镇平谷村弯板社堆放弃土，涉嫌违反《中华人民共和国森林法实施条例》第十六条第一款第一项的规定。根据《中华人民共和国森林法实施条例》第四十三条第一款和《四川省林业行政处罚裁量标准》之规定，建议对盐边县富丰工贸有限责任公司作出以下行政处罚：",
    },
    {
        key: 4,
        id: '文书立案审批',
        data: "森林林木",
        name: "2018年，盐边县富丰工贸有限责任公司在盐边县新九镇平谷村和平社堆放尾矿，同时在盐边县新九镇平谷村弯板社堆放弃土。盐边县林业局执法人员赵登国、肖峰于2021年11月6日10时02分到现场进行了初步调查。经初查，盐边县新九镇平谷村和平社堆放尾矿和盐边县新九镇平谷村弯板社堆放弃土，涉嫌违反《中华人民共和国森林法实施条例》第十六条第一款第一项的规定。根据《中华人民共和国森林法实施条例》第四十三条第一款和《四川省林业行政处罚裁量标准》之规定，建议对盐边县富丰工贸有限责任公司作出以下行政处罚：",
    },
    {
        key: 5,
        id: '行政处罚审批',
        data: "森林林木",
        name: "2018年，盐边县富丰工贸有限责任公司在盐边县新九镇平谷村和平社堆放尾矿，同时在盐边县新九镇平谷村弯板社堆放弃土。盐边县林业局执法人员赵登国、肖峰于2021年11月6日10时02分到现场进行了初步调查。经初查，盐边县新九镇平谷村和平社堆放尾矿和盐边县新九镇平谷村弯板社堆放弃土，涉嫌违反《中华人民共和国森林法实施条例》第十六条第一款第一项的规定。根据《中华人民共和国森林法实施条例》第四十三条第一款和《四川省林业行政处罚裁量标准》之规定，建议对盐边县富丰工贸有限责任公司作出以下行政处罚：",
    },

];

const Dsp: React.FC = () => {
    const [filterparamList,] = useState(data)
    const navegate = useNavigate()
    const rowClick = (record: any, index: number | undefined) => {
        return {
            onClick: () => {
                navegate("/dws", { state: { da: record, ide: index } })
            }
        }
    }
    return (
        <div className={Module.center}>
            <Table
                className={Module.table}
                columns={columns}
                rowKey="id"
                dataSource={filterparamList}
                bordered
                onRow={rowClick}
            />;
        </div>
    )
};

export default Dsp;








