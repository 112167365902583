import InternetTool from "./Internetool"
import {MessageApi} from "antd/es/message";
import {NavigateFunction} from "react-router-dom";
import {ResultParam} from "../tool/ResultParam";
import {Template} from "../entity/Template";

export class ApiAdminTemplate {
  readonly internetTool: InternetTool

  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/template", message, navigate)
  }

  add(template: Template, role: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("role", role.toString())
    formData.append("template", new Blob([JSON.stringify(template)], {type: "application/json"}))
    this.internetTool.ft(
        "",
        {
          body: formData,
          method: "POST"
        },
        resultParam
    )
  }

  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
        `/${id}`,
        {
          method: "DELETE"
        },
        resultParam
    )
  }

  update(template: Template, id: number, role: number | undefined, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("template", new Blob([JSON.stringify(template)], {type: "application/json"}))
    if (role){
      formData.append("role", role.toString())
    }
    this.internetTool.ft(
        `/${id}`,
        {
          body: formData,
          method: "PUT"
        },
        resultParam
    )
  }
}


