class Dictionary {
  id: number | null;
  typeId: number | null;
  dictionaryValue: string | null;
  constructor(id: number | null,
    typeId: number | null,
    dictionaryValue: string | null) {
    this.id = id
    this.typeId = typeId
    this.dictionaryValue = dictionaryValue
  }
}
export default Dictionary
