import CaseEntity from '../entity/CaseEntity'
import CaseSimpleEntity from "../entity/CaseSimpleEntity"
import SuspectPerson from "../entity/SuspectPerson"
import SuspectUnit from "../entity/SuspectUnit"
import Evidence from "../entity/Evidence"
import InternetTool from './Internetool'
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import DocumentEntity from "../entity/DocumentEntity";
import StatisticsCaseEntity from '../entity/StatisticsCase'

export class ApiCase {
  readonly internetTool: InternetTool

  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("case", message, navigate)
  }

  addCase(cases: CaseEntity, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("case", new Blob([JSON.stringify(cases)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  updateCase(cases: CaseEntity, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("case", new Blob([JSON.stringify(cases)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  deleteCase(caseId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("caseId", caseId.toString())
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }

  getCase(caseId: number, resultParam?: ResultParam<CaseEntity>) {
    this.internetTool.ft(
      `/${caseId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  statisticsCase(resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.ft(
      `/statisticsCase`,
      {
        method: "POST"
      },
      resultParam
    )
  }

  getCaseAll(resultParam?: ResultParam<Array<CaseEntity>>) {
    this.internetTool.ft(
      "",
      {
        method: "GET"
      },
      resultParam
    )
  }
  getStatisticsCase(type: number, resultParam?: ResultParam<StatisticsCaseEntity[]>) {
    this.internetTool.ft(
      `/getStatisticsCase?type=${type}`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  getAccomplish(resultParam?: ResultParam<Array<CaseEntity>>) {
    this.internetTool.ft(
      "/accomplish",
      {
        method: "GET"
      },
      resultParam
    )
  }

  getUnfinished(resultParam?: ResultParam<Array<CaseEntity>>) {
    this.internetTool.ft(
      "/unfinished",
      {
        method: "GET"
      },
      resultParam
    )
  }

  getSimple(resultParam?: ResultParam<Array<CaseSimpleEntity>>) {
    this.internetTool.ft(
      "/simple",
      {
        method: "GET"
      },
      resultParam
    )
  }

  getSimpleUnfinished(resultParam?: ResultParam<Array<CaseSimpleEntity>>) {
    this.internetTool.ft(
      "/simple/unfinished",
      {
        method: "GET"
      },
      resultParam
    )
  }

  getSimpleAccomplish(resultParam?: ResultParam<Array<CaseSimpleEntity>>) {
    this.internetTool.ft(
      "/simple/accomplish",
      {
        method: "GET"
      },
      resultParam
    )

  }

  getSimpleById(caseId: number, resultParam?: ResultParam<CaseSimpleEntity>) {
    this.internetTool.ft(
      `/simple/${caseId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  updateHandleUser(handleUid: number, handleSubUid: number, caseId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("handleUid", handleUid.toString())
    formData.append("handleSubUid", handleSubUid.toString())
    formData.append("caseId", caseId.toString())
    this.internetTool.ft<void>(
      "/handle-user",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  completeCase(caseId: number, menu: DocumentEntity, cover: DocumentEntity, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("menu", new Blob([JSON.stringify(menu)], { type: "application/json" }))
    formData.append("cover", new Blob([JSON.stringify(cover)], { type: "application/json" }))
    this.internetTool.ft(
      `/${caseId}/complete`,
      {
        method: "PUT",
        body: formData
      },
      resultParam
    )
  }

  getSuspectPerson(caseId: number, resultParam?: ResultParam<Array<SuspectPerson>>) {
    this.internetTool.fe(
      `/${caseId}/suspect-person`,
      resultParam
    )
  }

  addSuspectPerson(record: SuspectPerson, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("record", new Blob([JSON.stringify(record)], { type: "application/json" }))
    this.internetTool.ft(
      "/suspect-person",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  updateSuspectPerson(suspectPerson: SuspectPerson, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("suspectPerson", new Blob([JSON.stringify(suspectPerson)], { type: "application/json" }))
    this.internetTool.ft(
      "/suspect-person",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  deleteSuspectPerson(suspectPersonId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/suspect-person/${suspectPersonId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  getSuspectUnit(caseId: number, resultParam?: ResultParam<Array<SuspectUnit>>) {
    this.internetTool.fe(
      `/${caseId}/suspect-unit`,
      resultParam
    )
  }

  addSuspectUnit(suspectUnit: SuspectUnit, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("suspectUnit", new Blob([JSON.stringify(suspectUnit)], { type: "application/json" }))
    this.internetTool.ft(
      "/suspect-unit",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  updateSuspectUnit(suspectUnit: SuspectUnit, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("suspectUnit", new Blob([JSON.stringify(suspectUnit)], { type: "application/json" }))
    this.internetTool.ft(
      "/suspect-unit",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  deleteSuspectUnit(suspectUnitId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/suspect-unit/${suspectUnitId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  getEvidence(caseId: number, resultParam?: ResultParam<Array<Evidence>>) {
    this.internetTool.fe(
      `/${caseId}/evidence`,
      resultParam
    )
  }

  addEvidence(evidence: Evidence, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("evidence", new Blob([JSON.stringify(evidence)], { type: "application/json" }))
    this.internetTool.ft(
      "/evidence",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  updateEvidence(evidence: Evidence, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("evidence", new Blob([JSON.stringify(evidence)], { type: "application/json" }))
    this.internetTool.ft(
      "/evidence",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  deleteEvidence(evidenceId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/evidence/${evidenceId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  getCaseType(resultParam?: ResultParam<string[]>) {
    this.internetTool.ft(
      `/type`,
      {
        method: "GET"
      },
      resultParam
    )
  }

}