import { Button, Form, Input, Select } from "antd"
import Module from '../style/applicationForEnforcement.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { MinusOutlined } from "@ant-design/icons";


export default function ApplicationForEnforcement(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>强制执行申请书（经催告后）</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <Form.Item
                    className={Module.item1}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    />
                  </Form.Item>
                  <p>人民法院：</p>
                </div>
                <Form.Item
                  className={Module.item}
                  name={[17, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="具体内容"
                  />
                </Form.Item>
                <p className={Module.a1}>一、当事人基本情况：</p>
                <div className={Module.flex}>
                  <div className={Module.time}>
                    <p >当事人姓名：</p>
                    <Form.Item
                      className={Module.item1}
                      name={[2, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="当事人姓名" />
                    </Form.Item>
                  </div>
                  <div className={Module.time}>
                    <p >身份证号码：</p>
                    <Form.Item
                      className={Module.item1}
                      name={[3, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="当事人身份证号码" />
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.flex}>
                  <div className={Module.time}>
                    <p >名称：</p>
                    <Form.Item
                      className={Module.item1}
                      name={[4, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="名称" />
                    </Form.Item>
                  </div>
                  <div className={Module.time}>
                    <p >法定代表人（负责人）：</p>
                    <Form.Item
                      className={Module.item1}
                      name={[5, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="法定代表人（负责人）" />
                    </Form.Item>
                  </div>
                  <div className={Module.time1}>
                    <p >住所（地址）：</p>
                    <Form.Item
                      className={Module.item1}
                      name={[6, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 162, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="住所（地址）" />
                    </Form.Item>
                  </div>
                </div>
                <p className={Module.a1}>二、申请机关的情况：</p>
                <Form.Item
                  className={Module.item}
                  name={[7, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="申请机关的情况"
                  />
                </Form.Item>
                <p className={Module.a1}>三、申请执行的内容：</p>
                <Form.Item
                  className={Module.item}
                  name={[8, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="申请执行的内容"
                  />
                </Form.Item>
                <p className={Module.a1}>附件：</p>
                <Form.List name={[9, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 9])
                    return (<div className={Module.list}>
                      <>
                        {fields.map((field, index) => (
                          <Form.List name={[field.name]}>
                            {fields => (
                              <div className={Module.list1}>
                                <div className={Module.context1}>
                                  <Form.Item
                                    name={[0, "fieldValue"]}
                                    className={Module.page}
                                    initialValue={index + 1}
                                  >
                                    <Input
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      placeholder="序号" />
                                  </Form.Item>
                                  <Form.Item
                                    label="："
                                    className={Module.item}
                                    name={[1, "fieldValue"]}
                                  >
                                    <Input
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      placeholder="内容" />
                                  </Form.Item>
                                  <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                    style={{ fontSize: 20 }} /></Button>
                                </div>
                              </div>
                            )}
                          </Form.List>
                        ))}
                        {props.genAddButton(fieldEntity, "添加附件", add)}
                      </>
                    </div>)
                  }}
                </Form.List>
                <div className={Module.flex}>

                  <div className={Module.time}>
                    <p style={{ fontWeight: "bolder" }}>联系人： </p>
                    <Form.Item
                      className={Module.item1}
                      name={[10, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="联系人" />
                    </Form.Item>
                  </div>
                  <div className={Module.time}>
                    <p style={{ fontWeight: "bolder" }}>联系电话： </p>
                    <Form.Item
                      className={Module.item1}
                      name={[11, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="联系电话" />
                    </Form.Item>
                  </div>
                </div>

                <div className={Module.time}>
                  <p style={{ fontWeight: "bolder" }}>单位地址： </p>
                  <Form.Item
                    className={Module.item2}
                    name={[12, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="单位地址" />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p style={{ fontWeight: "bolder" }}>行政机关负责人： </p>
                  <Form.Item
                    className={Module.item}
                    name={[15, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="行政机关负责人" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>行政执法主体名称：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[13, "fieldValue"]}>
                      <Select placeholder="行政执法主体名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[14, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[16, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 2162, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

