import { Button, message, Table, Upload } from 'antd';
import Module from '../style/newsummarydata.module.scss'
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ColumnsType } from 'antd/lib/table';
import { ForestSummary } from '../entity/foresSummary';
import { Summary } from '../entity/Summary';
import { ForestPatroLedger } from '../entity/ForestPatrolLedger';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { ApiTool } from '../internet/ApiTool';


const columns: ColumnsType<ForestPatroLedger> = [
  {
    title: '序号',
    align: "center",
    render: (value: any, record: ForestPatroLedger, index: number) => {
      return <span>{index + 1}</span>
    },
    width: 50
  },
  {
    title: '林长姓名及职务',
    dataIndex: 'foresterNameAndJob',
    align: "center",
    width: 200,
    ellipsis: true
  },
  {
    title: '巡林时间',
    dataIndex: 'forestPatrolTime',
    align: "center",
    width: 120

  },
  {
    title: '巡林点位',
    dataIndex: 'forestPatrolPoint',
    align: "center",
    width: 100,
    ellipsis: true
  },
  {
    title: '发现的问题及要求',
    dataIndex: 'findTheProblem',
    align: "center",
    width: 120,
    ellipsis: true
  },
  {
    title: '问题类型',
    dataIndex: 'problemTypes',
    align: "center"
  },
  {
    title: '整改措施',
    dataIndex: 'correctiveMeasures',
    align: "center",
    width: 120,
    ellipsis: true
  },
  {
    title: '整改结果',
    dataIndex: 'rectificationResult',
    align: "center"
  },
  {
    title: '责任人单位',
    dataIndex: 'accountabilityUnit',
    align: "center",
    width: 100,
    ellipsis: true
  },
  {
    title: '责任人及电话',
    dataIndex: 'responsibleAndPhone',
    align: "center",
    width: 120,
    ellipsis: true
  },

];
const columns1: ColumnsType<ForestSummary> = [
  {
    title: '职位',
    dataIndex: 'classes',
    align: "center"
  },
  {
    title: '12月巡林次数',
    dataIndex: 'forestAmount',
    align: "center"
  },
  {
    title: '发现问题个数',
    dataIndex: 'forestPatrolAmount',
    align: "center",

  },
  {
    title: '已整改问题个数',
    dataIndex: 'forestPatrolFrequency',
    align: "center"
  },
  {
    title: '待整改问题个数',
    dataIndex: 'findTheProblemNumber',
    align: "center"
  },
];


const NewSummaryData: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [ledger, setLedger] = useState<ForestPatroLedger[]>([])
  const [forestPatrol, setForestPatrol] = useState<ForestSummary[]>([])
  const [fileList, setFileList] = useState<(RcFile | undefined)[]>([])
  const [fileList1, setFileList1] = useState<UploadFile[]>([])
  const value = (location.state as { data?: Summary })?.data
  const show = (location.state as { show?: boolean })?.show
  const allData = (location.state as { allData?: Summary })?.allData
  const apiTool = new ApiTool(message, navigate)

  function onChage(info: UploadChangeParam) {
    const list = info.fileList.map(i => i.originFileObj)
    setFileList1(info.fileList)
    setFileList(list)
    info.file.status = "success"
  }

  function addFile() {
    if (allData) {
      apiTool.mergeAll(fileList, allData, {
        exit: 0,
        successHandler: data1 => {
          if (data1) {
            const url = "data:zip;base64," + data1.toString()
            const link = document.createElement('a')
            link.href = url
            link.download = "汇总文件.zip"
            link.click()
            setFileList1([])
          }
        }
      })
    }

  }
  function rendering() {
    if (value) {
      setLedger(value.ledgerTable)
      setForestPatrol(value.forestPatrolTable)
    } else if (allData) {
      setLedger(allData.ledgerTable)
      setForestPatrol(allData.forestPatrolTable)
    }
  }
  const props = {
    multiple: true,
    accept: ".xls,.xlsx,.doc,.docx",
    name: "file",
    onChange: onChage,
    maxCount: 2,
    fileList: fileList1,
    beforeUpload: () => false
  }
  useEffect(() => {
    rendering()
  }, [location])
  let btn: JSX.Element
  if (!show) {
    btn = <div
      className={Module.add}
    >
      <Button className={Module.add2} type="primary" onClick={addFile} disabled={!fileList.length} >确认信息并导出</Button>
      <Upload
        className={Module.add1}
        {...props}
      >
        <Button icon={<UploadOutlined />} >选择汇总总文件</Button>
      </Upload>
    </div>
  } else {
    btn = <></>
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <div className={Module.table}>
          <Table
            size="small"
            columns={columns}
            bordered
            dataSource={ledger}
          />
        </div>
        <div className={Module.table1}>
          <Table
            size="small"
            columns={columns1}
            bordered
            dataSource={forestPatrol}
            pagination={false}
          />
          {btn}
        </div>
      </div>
    </div >
  );
};

export default NewSummaryData;