import { Button, Form, Input, Select, Table } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Template } from '../entity/Template'
import Module from '../style/template.module.scss'
import { ApiAdminTemplate } from "../internet/ApiAdminTemplate";
import { message } from "antd/es";
import { ApiTemplate } from "../internet/ApiTemplate";
import ApiRoles from '../internet/ApiRoles'
import Role from '../entity/Role'
import { TemplateParent } from '../entity/Templateparent'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { RoleMultistage } from '../entity/RoleMultistage'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};
const { OptGroup } = Select;
const columns: ColumnsType<Template> = [
  {
    title: "字符串名称",
    dataIndex: "templateName",
  }
]

interface optionType {
  label: string;
  value: number;
}

const TemplateModule: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm<TemplateParent>()
  const [templates, setTemplates] = useState<Template[]>([])
  const [template, setTemplate] = useState<Template>()
  const [roles, setRoles] = useState<Role[]>([])
  const [fillRoles, setFillRoles] = useState<RoleMultistage[]>([])
  const apiAdminTemplate = new ApiAdminTemplate(message, navigate)
  const apiTemplate = new ApiTemplate(message, navigate)
  const apiRoles = new ApiRoles(message, navigate)

  const getRoles = () => {
    apiRoles.getTemplate({
      successHandler: roles => {
        if (roles) {
          setRoles(roles)
        }
      }
    })
  }

  function getFillRoles() {
    apiRoles.getFillRole({
      successHandler: roles => {
        if (roles) {
          setFillRoles(roles)
        }
      }
    })

  }

  const getTemplate = () => {
    apiTemplate.getAll({
      successHandler: templates => {
        setTemplates(templates ?? [])
      }
    })
  }

  useEffect(() => {
    if (!template && templates.length) {
      setTemplate(templates[0])
    }
  }, [template])

  useEffect(() => {
    const newTemplate = templates.find(it => it.id === template?.id)
    if (!newTemplate) {
      setTemplate(templates[0])
    } else {
      setTemplate(newTemplate)
    }
  }, [templates])

  useEffect(() => {
    getFillRoles()
    getTemplate()
    getRoles()
  }, [location])

  useEffect(() => {
    if (template) {
      form.resetFields()
      form.setFieldsValue(template)
      form.setFieldsValue({ items: template.items })
      if (roles.length) {
        const roleId = roles.find(it => it.children?.includes(template.roleId))?.id
        form.setFieldsValue({ roleParent: roleId })
      }
    }
  }, [form, roles, template])
  const rowClick = (type: Template) => {
    return {
      onClick: () => {
        setTemplate(type)
      }
    }
  }

  const addClick = () => {
    navigate("/addtemplatemodule")
  }

  const option1: optionType[] = []
  roles.map(role => {
    const option = {
      label: role.roleDescription,
      value: role.id
    }
    return option1.push(option)
  })

  const updateType = () => {
    if (template) {
      form.validateFields()
        .then(template1 => {
          const templateId = template.id
          const templateRoleId = template.roleId
          const newType = new Template(
            templateId,
            template1.templateName,
            template1.templateValue,
            templateRoleId,
            template1.items
          )
          apiAdminTemplate.update(newType, template.id, template1.roleParent, {
            exit: 0, successHandler: () => {
              message.success("修改成功")
              getTemplate()
              getRoles()
            }
          })
        })
    }
  }

  function deleteType() {
    const templateId = template?.id
    if (templateId) {
      apiAdminTemplate.delete(templateId, {
        exit: 0, successHandler: () => {
          getTemplate()
          form.resetFields()
          setTemplate(undefined)
        }
      })
    }
  }

  const antoOption = fillRoles.map(data => {
    const children: JSX.Element[] = [];
    if (data.children) {
      children.push(...(data.children.map(child =>
        <Select.Option key={child.id} value={child.id}>{child.roleDescription}</Select.Option>
      )))
    }
    return <OptGroup key={data.id} label={data.roleDescription}>
      {children}
    </OptGroup>
  })


  return (
    <div className={Module.all}>
      <div className={Module.left}>
        <Button type="primary" onClick={addClick}>添加</Button>
        <Table
          key={"id"}
          dataSource={templates}
          className={Module.tab}
          size="small"
          columns={columns}
          onRow={rowClick}
        />
      </div>
      <div className={Module.right}>
        <Form
          form={form}
          {...layout}
          className={Module.form}
        >
          <div className={Module.top}>
            <Form.Item
              name="templateName"
              className={Module.item}
              label="字符串模板名称"
              rules={[{ required: true, }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="roleParent"
              className={Module.item}
              label="字符串模板权限"
            >
              <Select options={option1} />
            </Form.Item>
            <Form.Item
              name="templateValue"
              className={Module.item}
              label="字符串模板值"
              rules={[{ required: true, }]}

            >
              <TextArea autoSize={{ minRows: 2, maxRows: 20 }} />
            </Form.Item>
            <Button style={{ float: "right" }} type="primary" onClick={updateType}>修改</Button>
            <Button style={{ float: "right", marginRight: 50 }} type="primary" onClick={deleteType}>删除</Button>
          </div>
          <div className={Module.buttom}>
            <div className={Module.buttom1}>占位符</div>
            <div className={Module.buttom2}>填充方式</div>
            <Form.List name="items">
              {(fields, { add, remove }) => (
                < div className={Module.add}>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} className={Module.ite}>
                      <div className={Module.ite1}>
                        <Form.Item
                          className={Module.item1}
                          {...restField}
                          name={[name, 'itemCode']}
                          rules={[{ required: true, }]}
                        >
                          <Input className={Module.input} />
                        </Form.Item>
                        <Form.Item
                          className={Module.item2}
                          {...restField}
                          name={[name, 'itemRoleId']}
                          rules={[{ required: true, }]}
                        >
                          <Select
                            placement="topLeft"
                            className={Module.select}
                          >
                            {antoOption}
                          </Select>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    </div>
                  ))}
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    添加
                  </Button>
                </div>
              )}
            </Form.List>
          </div>
        </Form>
      </div>
    </div>
  )
}
export default TemplateModule