import { Button, Form, Input, Select } from "antd"
import Module from '../style/administrativeAsk.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { MinusOutlined } from "@ant-design/icons";


const AdministrativeAsk: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>行政处罚告知书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[10, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[0, "fieldValue"]}
                >
                  <Input
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人" />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[11, "fieldValue"]}
                >
                  <Input.TextArea
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    className={Module.textarea} autoSize={{ minRows: 2, maxRows: 30 }}
                    placeholder="某某行为违反的法律规定" />
                </Form.Item>

                <Form.Item
                  className={Module.item}
                  name={[12, "fieldValue"]}
                >
                  <Input.TextArea
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    className={Module.textarea} autoSize={{ minRows: 2, maxRows: 30 }}
                    placeholder="法律规定" />
                </Form.Item>
                <Form.List
                  name={[1, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 1])
                    return <div className={Module.list}>
                      {fields.map((field, index) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                  initialValue={index + 1}
                                >
                                  <Input placeholder="序号"
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}

                                  />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                  <Input placeholder="处罚内容"
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                  />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加处罚", add)}
                    </div>
                  }}
                </Form.List>
                <div className={Module.check}>
                  <Form.Item
                    name={[4, "fieldValue"]}
                    initialValue="⬜"
                    className={Module.item}
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields",4 , "fieldValue"])}
                    />
                  </Form.Item>
                  <p >
                    依据《中华人民共和国行政处罚法》第四十四条的规定，你可在收到本告知书之日起3日内提出陈述、
                    申辩意见，或到盐边县林业局进行陈述、申辩。逾期未陈述、申辩的，视为你放弃陈述、申辩权利。
                  </p>
                </div>
                <div className={Module.check}>
                  <Form.Item
                    name={[5, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])}
                    />
                  </Form.Item>
                  <p>
                    依据《中华人民共和国行政处罚法》第四十四条和第六十四条第一款第（一）项规定，你有权要求举行听证。如你要求听证，
                    应当自收到本告知书之日起5日内向本机关提出申请。逾期不申请听证的，视为你放弃听证权利。
                  </p>
                </div>
                <div className={Module.people1}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>联系人：</p>
                    <Form.Item
                      style={{ width: "35%", marginLeft: 0 }}
                      name={[6, "fieldValue"]}>
                      <Input placeholder="主办人员"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                    、
                    <Form.Item
                      style={{ width: "35%", }}
                      name={[7, "fieldValue"]}>
                      <Input placeholder="协办人员"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>联系电话：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[8, "fieldValue"]}>
                      <Input placeholder="联系电话"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>

                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位地址：</p>
                    <Form.Item
                      style={{ width: "80%" }}
                      name={[9, "fieldValue"]}>
                      <Input placeholder="单位地址"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位名称：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[13, "fieldValue"]}>
                      <Select placeholder="单位名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.id.toString()}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[2, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        placement="topLeft"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[3, "fieldValue"]} >
                      <Select placeholder="印章"
                        placement="topLeft"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}


export default AdministrativeAsk