import { Form, Input, Card, Button, message, Select } from "antd"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ApiAdminDictionaryType } from "../internet/ApiAdminDictionaryType"
import ApiGlobal from "../internet/ApiGlobal"
import BaseType from "../entity/BaseType"
import DictionaryType from "../entity/DictionaryType"
import DictionaryTypeForm from "../form/DcitonaryTypeForm"
import Module from '../style/userinfo.module.scss'
const { Option } = Select;

const AdddirType: React.FC = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm<DictionaryTypeForm>()
    const [baseType, setBaseType] = useState<BaseType[]>([]);
    const locacation = useLocation()
    const apiAdminDictionaryType = new ApiAdminDictionaryType(message, navigate)
    const apiGlobal = new ApiGlobal(message, navigate)
    const onClick = () => {
        form.validateFields()
            .then(typeForm => {
                let type = new DictionaryType(
                    null,
                    parseInt(typeForm.baseTypeId),
                    typeForm.typeName,
                    typeForm.typeDescription,
                    typeForm.regex
                )
                apiAdminDictionaryType.addDictionaryType(type)
            })
    }

    useEffect(() => {
        apiGlobal.getBaseType({
            successHandler: baseType => {
                if (baseType) {
                    setBaseType(baseType)
                }
            }
        })


    }, [locacation])

    const options = baseType?.map(d => <Option key={d.id}>{d.typeDescription}</Option>);
    return (
        <div className={Module.all}>
            <Card className={Module.card}>
                <Form
                    initialValues={new DictionaryTypeForm()}
                    name='addDictionaryType'
                    form={form}
                    className={Module.moform}
                >
                    <h1>添加字典类型</h1>
                    <Form.Item
                        name="typeName"
                        className={Module.item1}
                        label="类型名称"
                        rules={[{
                            required: true,
                            message: "请输入类型名"
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="typeDescription"
                        className={Module.item1}
                        label="&emsp;类型描述">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="baseTypeId"
                        className={Module.item1}
                        label="&emsp;基础类型"
                    >
                        <Select
                            className={Module.select}
                        >
                            {options}
                        </Select>
                    </Form.Item>

                    <Form.Item  >
                        <Button style={{ float: "right" }} type="primary" onClick={onClick}>确认添加</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
export default AdddirType