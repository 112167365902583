import { AutoComplete, Button, Form, Input, Select } from "antd"
import { FormListFieldData } from "antd/lib/form/FormList";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import BaseTypes from "../entity/BaseTypes";
import { DocumentProps } from "../entity/DocumentProps";
import Module from '../style/wenshu.module.scss'
import FieldEntity from "../entity/FieldEntity";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
};
const { Option } = Select
const FormElement: React.FC<DocumentProps> = (props) => {
  const dateFormat = "YYYY年MM月DD日"
  const dateFormat1 = "YYYY"
  const dateFormat2 = "MM"
  const dateFormat3 = "DD"
  const baseTypes = new BaseTypes()


  function getDeleteButton(remove?: ((index: (number | number[])) => void) | undefined, index?: number): JSX.Element {
    if (remove !== undefined && index !== undefined) {
      return <Button className={Module.button} onClick={() => remove(index)}>×</Button>
    } else {
      return <></>
    }
  }

  function configChildren(fieldEntity: FieldEntity, key: number, indexes: Array<string | number> = [], editEnable: boolean = true): JSX.Element {
    return <Form.List name={[key, "children"]}>
      {(fields, { add, remove }) => {
        let button = <></>
        const children = fieldEntity.fieldType?.children;
        if (editEnable && children?.length) {
          button = <Button className={Module.btn} onClick={_ => {
            const fieldEntities = props.createList(children, props.document?.id ?? 0, { caseEntity: props.caseEntity });
            add(fieldEntities)
          }}>添加</Button>
        }
        return <div>
          <h3 className={Module.h3}>{fieldEntity.fieldName} </h3>
          {button}
          {fields.map(field => {
            return configElement(undefined, field.name, editEnable ? remove : undefined, [...indexes, "children"])
          })}
        </div>
      }}
    </Form.List>
  }

  function convertType(baseTypeId: number, fieldEntity: FieldEntity, field: FormListFieldData, indexes: Array<string | number> = []): JSX.Element {
    switch (baseTypeId) {
      case baseTypes.dateYear:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <StringDatePicker disabled={props.getPermission(fieldEntity.permissionRoleId)} className={Module.dates}
            picker="year"
            format={dateFormat1} />
        </Form.Item>
      case baseTypes.dateMonth:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <StringDatePicker disabled={props.getPermission(fieldEntity.permissionRoleId)} className={Module.dates}
            picker="month"
            format={dateFormat2} />
        </Form.Item>
      case baseTypes.dateDay:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <StringDatePicker disabled={props.getPermission(fieldEntity.permissionRoleId)} className={Module.dates}
            picker="week"
            format={dateFormat3} />
        </Form.Item>
      case baseTypes.inter:
      case baseTypes.decimals:
        return <Form.Item
          {...field}
          className={Module.list}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <Input disabled={props.getPermission(fieldEntity.permissionRoleId)} type={"number"} />
        </Form.Item>
      case baseTypes.date:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <StringDatePicker disabled={props.getPermission(fieldEntity.permissionRoleId)} className={Module.dates}
            format={dateFormat} />
        </Form.Item>
      case baseTypes.list:
        return configChildren(fieldEntity, field.name, [...indexes, field.name])
      case baseTypes.seal:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <Select disabled={props.getPermission(fieldEntity.permissionRoleId)}>
            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
          </Select>
        </Form.Item>
      case baseTypes.signature:
        return <div className={Module.btn1}>
          <Form.Item
            {...field}
            className={Module.date}
            name={[field.name, "fieldValue"]}
            label={fieldEntity.fieldName}
          >
            <Select disabled={true}>
              {(props.users ?? []).map(user => <Select.Option key={user.id.toString()}>{user.realName}</Select.Option>)}
            </Select>
          </Form.Item>
          {(() => {
            const arr: JSX.Element[] = []
            if (!(props.getPermission(fieldEntity.permissionRoleId))) {
              arr.push(<Button type="primary" className={Module.sigbtn} key={"useMine"} onClick={() => {
                props.form.setFields([{
                  name: [...indexes, field.name, "fieldValue"],
                  value: props.user?.id?.toString()
                }])
              }}>使用自己签名</Button>)
              arr.push(<Button type="primary" className={Module.desigbtn} key={"reset"} onClick={() => {
                props.form.setFields([{
                  name: [...indexes, field.name, "fieldValue"],
                  value: props.formArchive.getFieldValue([...indexes, field.name, "fieldValue"])
                }])
              }}>恢复默认</Button>)
            }
            return arr
          })()}
        </div>
      case baseTypes.radio:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <Select disabled={props.getPermission(fieldEntity.permissionRoleId)}>
            <Option key={1} value={"□"}>□</Option>
            <Option key={2} value={"☑"}>☑</Option>
          </Select>
        </Form.Item>
      case baseTypes.textarea:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <AutoComplete dataSource={props.strTemplate.getStr(fieldEntity.fillRoleId ?? 0, props.caseEntity)}
            children={<TextArea rows={3} />} />
        </Form.Item>
      case baseTypes.checkBox:
        return <Form.Item
          {...field}
          className={Module.date}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <Input />
        </Form.Item>
      default:
        return <Form.Item
          {...field}
          className={Module.list}
          name={[field.name, "fieldValue"]}
          label={fieldEntity.fieldName}
        >
          <AutoComplete
            disabled={props.getPermission(fieldEntity.permissionRoleId)}
            dataSource={props.strTemplate.getStr(fieldEntity.fillRoleId ?? 0, props.caseEntity)}
          >
            <Input />
          </AutoComplete>
        </Form.Item>
    }
  }

  function configElement(fieldName?: string, key?: number, remove?: (index: number | number[]) => void, indexes: Array<string | number> = []): JSX.Element {
    const names: Array<string | number> = []
    if (key !== undefined) {
      names.push(key)
    }
    if (fieldName) {
      names.push(fieldName)
    }
    return <Form.List name={names}>
      {fields =>
        <div className={Module.lists} key={fieldName}>
          {getDeleteButton(remove, key)}
          {fields.map(field => {
            const fieldEntity = props.form.getFieldValue([...indexes, field.name]) as FieldEntity
            return convertType(fieldEntity.baseTypeId, fieldEntity, field, indexes)
          })}
        </div>
      }
    </Form.List>
  }

  let element: JSX.Element;
  const document = props.document
  if (document) {
    element = <Form
      {...layout}
      className={Module.form}
      form={props.form}
      name="register"
      scrollToFirstError
      disabled={document.typeId === undefined || props.dis}
      onFieldsChange={props.onFieldsChange}
    >
      <h1>{document.documentName}</h1>
      <Form.Item
        className={Module.ite}
        label={"文档名称"}
        name={"documentName"}
        rules={[{ required: true }]}>
        <Input className={Module.input1} />
      </Form.Item>
      <h2>{props.status}</h2>
      {configElement("fields", undefined, undefined, ["fields"])}
    </Form>
  } else {
    element = <></>
  }
  return element

}
export default FormElement