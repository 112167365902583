import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export interface DataType {
    key: number;
    time: string;
    nation: string;
    oname: string;
    tname: string;
    jl: string;
    bname: string;
    bsex: string;
    bid: string;
    bwork: string;
    badr: string
    bphone: number;
    bzz: string;
    byb: string;
    bgx: string;
    bzw: string;
    bzh: string;
    bzh1: string;


}
const columns: ColumnsType<DataType> = [
    {
        title: '询问时间',
        dataIndex: 'time',
        width: 150,
        align: 'center',
        fixed: 'left',


    },
    {
        title: '询问地点',
        dataIndex: 'nation',
        width: 150,
        fixed: 'left',

    },
    {
        title: '询问人',

        children: [
            {
                title: '询问人',
                dataIndex: 'oname',
                width: 100,

            },
            {
                title: '执法证号',
                dataIndex: 'bzh1',
                width: 100,
            },
        ]
    },
    {
        title: '询问人',

        children: [
            {
                title: '询问人',
                dataIndex: 'tname',
                width: 100,

            },
            {
                title: '执法证号',
                dataIndex: 'bzh',
                width: 100,
            },
        ]
    },
    {
        title: "记录人",
        dataIndex: "jl",
        width: 100

    },
    {
        title: "被询问人基本情况",
        children: [
            {
                title: "被询问人姓名",
                dataIndex: "bname",
                width: 100
            },
            {
                title: "性别",
                dataIndex: "bsex",
                width: 100
            },
            {
                title: "身份证号码",
                dataIndex: "bid",
                width: 100,
                ellipsis: true
            },
            {
                title: "工作单位",
                dataIndex: "badr",
                width: 100,
                ellipsis: true

            },
            {
                title: "职业",
                dataIndex: "bwork",
                width: 50
            },
            {
                title: "联系电话",
                dataIndex: "bphone",
                width: 100
            },
            {
                title: "住址",
                dataIndex: "bzz",
                width: 150,
                ellipsis: true
            },
            {
                title: "邮编",
                dataIndex: "byb",
                width: 100
            },
            {
                title: "所属职务",
                dataIndex: "bzw",
                width: 100
            },
            {
                title: "与当事人关系",
                dataIndex: "bgx",
                width: 100
            },

        ]

    },
];
const data: DataType[] = [
    {
        key: 1,
        time: " 2002.12.10",
        nation: "公安局",
        oname: "张三",
        tname: "李四",
        jl: "俄舞",
        bname: "李旺",
        bsex: "女",
        bid: "511324200101020205",
        bwork: "村长",
        badr: "四川省攀枝花市XXX有限公司",
        bphone: 18512313212,
        bzz: "四川省攀枝花市XXX乡",
        byb: "521212",
        bzw: "人大代表",
        bgx: "当事人",
        bzh: "54454",
        bzh1: "54454",
    },
    {
        key: 2,
        time: " 2002.12.10",
        nation: "林业局",
        oname: "张三",
        tname: "李四",
        jl: "俄舞",
        bname: "李旺",
        bsex: "女",
        bid: "511324200101020205",
        bwork: "村长",
        badr: "四川省攀枝花市XXX有限公司",
        bphone: 18512313212,
        bzz: "四川省攀枝花市XXX乡",
        byb: "521212",
        bzw: "人大代表",
        bgx: "当事人",
        bzh: "54454",
        bzh1: "54454",
    },
    {
        key: 3,
        time: " 2002.12.10",
        nation: "公安局",
        oname: "张三",
        tname: "李四",
        jl: "俄舞",
        bname: "李旺",
        bsex: "女",
        bid: "511324200101020205",
        bwork: "村长",
        badr: "四川省攀枝花市XXX有限公司",
        bphone: 18512313212,
        bzz: "四川省攀枝花市XXX乡",
        byb: "521212",
        bzw: "人大代表",
        bgx: "当事人",
        bzh: "54454",
        bzh1: "54454",
    },
    {
        key: 4,
        time: " 2002.12.10",
        nation: "公安局",
        oname: "张三",
        tname: "李四",
        jl: "俄舞",
        bname: "李旺",
        bsex: "女",
        bid: "511324200101020205",
        bwork: "村长",
        badr: "四川省攀枝花市XXX有限公司",
        bphone: 18512313212,
        bzz: "四川省攀枝花市XXX乡",
        byb: "521212",
        bzw: "人大代表",
        bgx: "当事人",
        bzh: "54454",
        bzh1: "54454",
    },
]

const Recordlive = () => {
    const navegate = useNavigate()
    const rowClick = (record: DataType) => {
        return {
            onClick: () => {
                navegate("/see", { state: { record: record } })
            }
        }
    }

    return (
        <Table
            bordered
            scroll={{ y: 100, x: 1300 }}
            columns={columns}
            pagination={false}
            dataSource={data}
            onRow={rowClick}
        />
    )
}
export default Recordlive