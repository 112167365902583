import Law from "../entity/Law";
import LawArticle from "../entity/LawArticle";
import LawChapter from "../entity/LawChapter";
import { LawItem } from "../entity/LawItem";
import { LawParagraph } from "../entity/LawParagraph";
import { LawSubparagraph } from "../entity/LawSubparagraph";

interface Option {
  id: number;
  value: string | number;
  label: string;
  children?: Option[];
}
export const LawConversion = {
  law1: function (law: Law[]): Option[] {
    return law.map(law1 => {
      return {
        id: law1.id,
        label: law1.lawValue,
        value: law1.id,
        children: this.chapters(law1.chapters ?? [])
      }
    })
  },
  chapters: function (chapters: LawChapter[]): Option[] {
    return chapters.map(chapter => {
      return {
        id: chapter.id,
        label: chapter.chapterValue,
        value: chapter.id,
        children: this.articles(chapter.articles ?? [])
      }
    })
  },
  articles: function (articles: LawArticle[]): Option[] {
    return articles.map(article => {
      return {
        id: article.id,
        label: (article.paragraphs ?? [])[0].paragraphValue ?? "",
        value: article.id,
        children: this.paragraphs(article.paragraphs ?? [])
      }
    })
  },
  paragraphs: function (paragraphs: LawParagraph[]): Option[] {
    return paragraphs.map(paragraph => {
      return {
        id: paragraph.id,
        label: paragraph.paragraphValue,
        value: paragraph.id,
        children: this.subParagraphs(paragraph.subparagraphs ?? [])
      }
    })
  },
  subParagraphs: function (subParagraphs: LawSubparagraph[]): Option[] {
    return subParagraphs.map(subParagraph => {
      return {
        id: subParagraph.id,
        label: subParagraph.subparagraphValue,
        value: subParagraph.id,
        children: this.items(subParagraph.items ?? [])
      }
    })
  },
  items: function (items: LawItem[]): Option[] {
    return items.map(item => {
      return {
        id: item.id,
        label: item.itemValue,
        value: item.id,
      }
    })
  },


  law11: function (law: Law[]): Option[] {
    return law.map(law1 => {
      let allArticle1: LawArticle[] = []
      law1.chapters?.forEach(article => {
        if (article.articles) {
          allArticle1.push(...article.articles)
        }
      });
      return {
        id: law1.id,
        label: law1.lawValue,
        value: law1.id,
        children: this.articles1(allArticle1)
      }
    })
  },
  articles1: function (articles: LawArticle[]): Option[] {
    return articles.map(article => {
      let allParagraphs: LawParagraph[] = []
      article.paragraphs?.forEach(paragraphs => {
        allParagraphs.push(paragraphs)
      });
      return {
        id: article.id,
        label: "第" + article.articleIndex + "条",
        value: article.id,
        children: this.paragraphs1(allParagraphs)
      }
    })
  },
  paragraphs1: function (paragraphs: LawParagraph[]): Option[] {
    return paragraphs.map(paragraph => {
      let allParagraphs: LawSubparagraph[] = []
      paragraph.subparagraphs?.forEach(subparagraphs => {
        allParagraphs.push(subparagraphs)
      });
      return {
        id: paragraph.id,
        label: "第" + paragraph.paragraphIndex + "款",
        value: paragraph.id,
        children: this.subParagraphs1(paragraph.subparagraphs ?? [])
      }
    })
  },
  subParagraphs1: function (subParagraphs: LawSubparagraph[]): Option[] {
    return subParagraphs.map(subParagraph => {
      let allItems: LawItem[] = []
      subParagraph.items?.forEach(item => {
        allItems.push(item)
      });
      return {
        id: subParagraph.id,
        label: "第" + subParagraph.subparagraphIndex + "项",
        value: subParagraph.id,
        children: this.items1(allItems)
      }
    })
  },
  items1: function (items: LawItem[]): Option[] {
    return items.map(item => {
      return {
        id: item.id,
        label: "第" + item.itemIndex + "目",
        value: item.id,
      }
    })
  },
}