import { Form, Input, Select } from "antd"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/addressForService.module.scss'
const AddressForService: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>送达地址确认书</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.a1}><p>案号</p></div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[17, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="案号" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.a1}><p>案由</p></div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[0, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="案由" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}>
                      <p>告知事项</p>
                    </div>
                    <div className={Module.c2}>
                      <span>1.为便于当事人及时收到相关文书，当事人应当如实提供确切的送达地址。 </span>
                      <span>2．确认的送达地址适用于行政执法全过程程序。如果送达地址有变更，应当及时书面告知本机关变更后的送达地址。</span>
                      <br />
                      <span>3．如果提供的地址不确切，或者不及时告知变更后的地址，使文书无法送达或者未及时送达，当事人将自行承担由此可能产生的后果。</span>
                      <br />
                      <span>4．接受电子送达方式的，以电子化文书到达受送达人特定系统视为送达。</span>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}><p>送达地址及方式</p></div>
                    <div className={Module.d2}>
                      <div className={Module.c1}>
                        <div className={Module.a1}><p>指定签收人</p></div>
                        <div className={Module.a2}>
                          <Form.Item
                            className={Module.item}
                            name={[1, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="指定签收人" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.c2}>
                        <div className={Module.a1}><p>证件类型</p></div>
                        <div className={Module.a2}>
                          <Form.Item
                            className={Module.item}
                            name={[2, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="证件类型" />
                          </Form.Item>
                        </div>
                        <div className={Module.a3}><p>证件号码</p></div>
                        <div className={Module.a4}>
                          <Form.Item
                            className={Module.item}
                            name={[3, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="证件号码" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.c3}>
                        <div className={Module.a1}><p>确认送达地址</p></div>
                        <div className={Module.a2}>
                          <Form.Item
                            className={Module.item}
                            name={[4, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="确认送达地址" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.c4}>
                        <div className={Module.a1}><p>是否接受电子送达</p></div>
                        <div className={Module.a2}>
                          <div className={Module.e1}>
                            <Form.Item
                              name={[5, "fieldValue"]}
                              initialValue="⬜"
                              className={Module.item}
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])}
                              />
                            </Form.Item>
                            <p>是</p>
                            <Form.Item
                              name={[6, "fieldValue"]}
                              initialValue="⬜"
                              className={Module.item}
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 6, "fieldValue"])}
                              />
                            </Form.Item>
                            <p>否</p>
                            <Form.Item
                              name={[7, "fieldValue"]}
                              initialValue="⬜"
                              className={Module.item}
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])}
                              />
                            </Form.Item>
                            <p>手机号码：</p>
                            <Form.Item
                              name={[8, "fieldValue"]}
                              className={Module.item1}
                            >
                              <Input placeholder="手机号码"
                                disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                            </Form.Item>
                          </div>
                          <div className={Module.e1}>
                            <Form.Item
                              name={[9, "fieldValue"]}
                              initialValue="⬜"
                              className={Module.item}
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 9, "fieldValue"])}
                              />
                            </Form.Item>
                            <p>传真号码：</p>
                            <Form.Item
                              name={[10, "fieldValue"]}
                              className={Module.item1}
                            >
                              <Input placeholder="传真号码" disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                            </Form.Item>
                          </div>
                          <div className={Module.e1}>
                            <Form.Item
                              name={[11, "fieldValue"]}
                              initialValue="⬜"
                              className={Module.item}
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 11, "fieldValue"])}
                              />
                            </Form.Item>
                            <p>电子邮件地址：</p>
                            <Form.Item
                              name={[12, "fieldValue"]}
                              className={Module.item1}
                            >
                              <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                placeholder="电子邮件地址" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className={Module.c5}>
                        <div className={Module.a1}><p>手机号码</p></div>
                        <div className={Module.a2}>
                          <Form.Item
                            className={Module.item}
                            name={[8, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="手机号码" />
                          </Form.Item>
                        </div>
                        <div className={Module.a3}><p>邮编</p></div>
                        <div className={Module.a4}>
                          <Form.Item
                            className={Module.item}
                            name={[13, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="邮编" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.c6}>
                        <div className={Module.a1}><p>其他联系方式</p></div>
                        <div className={Module.a2}>
                          <Form.Item
                            className={Module.item}
                            name={[14, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="其他联系方式" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}><p>备注</p></div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.text}
                        name={[15, "fieldValue"]}
                      >
                        <Input.TextArea
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          autoSize={{ minRows: 2, maxRows: 5 }} placeholder="备注"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginLeft: "2%" }}
                        className={Module.select}
                        name={[16, "fieldValue"]} >
                        <Select placeholder="印章"
                          placement="topLeft"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        >
                          {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default AddressForService