import { Form, Input, Card, Button, message, Select } from "antd"
import Module from '../style/addword.module.scss'
const AddWord: React.FC = () => {
    const [form] = Form.useForm()
    const btnClick = () => {
        message.success("修改成功")
    }
    return (
        <div className={Module.all}>
            <Card className={Module.card}>
                <Form
                    name='添加嫌疑人员'
                    form={form}
                    className={Module.form}
                >
                    <h1>添加文档类型</h1>
                    <Form.Item
                        name="文档名称"
                        className={Module.item1}
                        label="文档名称">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="文档类型"
                        className={Module.item1}
                        label="文档类型">
                        <Select className={Module.select}></Select>
                    </Form.Item>

                    <Form.Item  >
                        <Button type="primary" onClick={btnClick}>确认添加</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
export default AddWord