import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCase } from "../internet/ApiCase";
import CaseSimpleEntity from "../entity/CaseSimpleEntity";
import Module from '../style/jzlist.module.scss'
import moment from "moment";
import { message } from "antd";

const columns: ColumnsType<CaseSimpleEntity> = [
  {

    title: "案件编号",
    dataIndex: 'caseCode',

  },
  {
    title: '案件来由',
    dataIndex: 'caseCause',
  },
  {
    title: '立案时间',
    dataIndex: 'caseRegisterTimer',
    render: (value: any, record: CaseSimpleEntity) => {
      if (record.caseRegisterTimer) {
        const d = record.caseRegisterTimer
        return moment(d, 'YYYY-MM-DD ').format('YYYY-MM-DD ')
      }
      return undefined
    }
  },
  {
    title: '主办人员',
    dataIndex: 'handleName',
  },
  {
    title: '协办人员',
    dataIndex: 'handleSubName',
  },
  {
    title: '实际案情',
    dataIndex: 'mainCase',
    width: 200,
    ellipsis: true
  },

];
const Jzlist: React.FC<{ setCaseId: (caseId: number | undefined) => void }> = () => {
  const navigate = useNavigate()
  const rowClick = (caseInfo: CaseSimpleEntity) => {
    return {
      onClick: () => {
        navigate("/jzong", { state: { caseId: caseInfo.id, complete: true } })
      }
    }
  }
  const apiCase = new ApiCase(message, navigate)
  const location = useLocation()
  const [data, setData] = useState<CaseSimpleEntity[]>([])
  useEffect(() => {
    apiCase.getSimpleAccomplish({
      successHandler: data => {
        if (data) {
          setData(data)
        }
      }
    })

  }, [location])
  return (
    <div className={Module.all}>

      <Table
        pagination={false}
        rowKey="id"
        className={Module.table}
        columns={columns}
        dataSource={data}
        onRow={rowClick}
        bordered
      />

    </div>
  )
}
export default Jzlist