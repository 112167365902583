import { Affix, Button, Form, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseType from '../entity/BaseType';
import DocumentTypeEntity from '../entity/DocumentTypeEntity';
import Module from '../style/wordsee.module.scss'
import { ApiDocumentType } from "../internet/ApiDocumentType";
import FieldTypeEntity from "../entity/FieldTypeEntity";
import Role from '../entity/Role';
import ApiRoles from "../internet/ApiRoles";
import { RoleMultistage } from "../entity/RoleMultistage";
import ApiGlobal from '../internet/ApiGlobal';
import { ApiAdminDocumentType } from '../internet/ApiAdminDocumentType';
import { Roles } from "../tool/Roles";

const { Option, OptGroup } = Select;
const WordSee: React.FC = () => {
  const [baseTypes, setBaseTypes] = useState<BaseType[]>([]);
  const location = useLocation()
  const navigate = useNavigate()
  const documentTypeId = (location.state as { documentTypeId?: number })?.documentTypeId
  const roles = (location.state as { roles?: Role[] })?.roles
  const [documentType, setDocumentType] = useState<DocumentTypeEntity | undefined>()
  const [form] = Form.useForm<{ typeName: string, typeDescription: string, fields: FieldTypeEntity[], typeRole: number }>()
  const [permissionRoles, setPermissionRoles] = useState<Role[]>([])
  const [fillRoles, setFillRoles] = useState<RoleMultistage[]>([])
  const masterId = (location.state as { masterId?: number })?.masterId
  const apiAdminDocumentType = new ApiAdminDocumentType(message, navigate)
  const apiDocumentType = new ApiDocumentType(message, navigate)
  const apiRoles = new ApiRoles(message, navigate)
  const apiGlobal = new ApiGlobal(message, navigate)

  console.log(documentType?.fieldTypes);

  function updateType() {
    form.validateFields()
      .then(values => {
        if (documentType) {
          const newType = DocumentTypeEntity.config(documentType, values)
          console.log(newType)
          apiAdminDocumentType.updateDocumentType(newType, values.typeRole, {
            otherHandler: code => {
              if (code === "name_exist") {
                message.error("文件名称已存在")
                return true
              } else {
                return false
              }
            }
          })
        }
      }
      )
  }
  function updateDocument() {
    navigate("/wordmain", { state: { documentId: documentType?.id, } })
  }
  function accessories() {
    navigate("/accessories", { state: { masterId: documentType?.id, } })
  }

  function deleteDocumentType() {
    if (documentTypeId) {
      apiAdminDocumentType.deleteDocumentTypes(documentTypeId)
    }
  }

  function getDocumentType(documentTypeId: number) {
    apiDocumentType.getDocumentType(documentTypeId, {
      successHandler: documentType => {
        if (documentType) {
          const roleIds = roles?.map(roleId => roleId.id)?.filter(roleId => (documentType.roleList ?? []).includes(roleId))
          let roleId: number;
          if (roleIds?.length) {
            roleId = roleIds[0]
          } else {
            roleId = 0
          }
          const type = DocumentTypeEntity.create(documentType)
          form.setFieldsValue({
            typeName: type.typeName,
            typeDescription: type.typeDescription,
            fields: type.fieldTypes ?? [],
            typeRole: roleId
          })
          setDocumentType(type)
        }
      }
    })

  }

  function getPermissionRoles(documentTypeId: number) {
    apiDocumentType.getPermissionRoles(documentTypeId, {
      successHandler: roles => {
        if (roles) {
          setPermissionRoles(roles)
        }
      }
    })
  }

  function getFillRoles() {
    apiRoles.getFillRole({
      successHandler: roles => {
        if (roles) {
          setFillRoles(roles.filter(it => it.id !== Roles.bind.fieldType.dictionaryType.id))
        }
      }
    })

  }

  function getBaseTypes() {
    apiGlobal.getBaseType({
      successHandler: types => {
        if (types) {
          setBaseTypes(types)
        }
      }
    })
  }

  useEffect(() => {
    if (!documentTypeId) {
      navigate(-1)
      return
    }
    getDocumentType(documentTypeId)
    getBaseTypes()
    getPermissionRoles(documentTypeId)
    getFillRoles()
  }, [location])
  const options = baseTypes.map(d => <Option key={d.id} value={d.id}>{d.typeDescription}</Option>);
  const options1 = roles?.map(role => <Option key={role.id} value={role.id}>{role.roleDescription}</Option>);
  const antoOption = fillRoles.map(data => {
    const children: JSX.Element[] = [];
    if (data.children) {
      children.push(...(data.children.map(child =>
        <Select.Option key={child.id} value={child.id}>{child.roleDescription}</Select.Option>
      )))
    }
    return <OptGroup key={data.id} label={data.roleDescription}>
      {children}
    </OptGroup>
  })

  function configElement(indexes: number[], fieldName: string, types: FieldTypeEntity[]): JSX.Element {
    return (
      <Form.List name={[...indexes, fieldName]}>
        {fields => (
          <>
            {fields.map(field => (
              <div className={Module.list} key={field.name}>
                <Form.Item
                  {...field}
                  className={Module.ite1}
                  name={[field.name, "fieldName"]}
                  label={"字段占位符"}
                >
                  <Input disabled className={Module.input} />
                </Form.Item>
                <Form.Item
                  {...field}
                  className={Module.ite}
                  name={[field.name, "fieldDescription"]}
                  label={"字段名称"}

                >
                  <Input className={Module.input} />
                </Form.Item>
                <Form.Item
                  {...field}
                  className={Module.ite}
                  name={[field.name, "defaultStr"]}
                  label={"默认值"}
                >
                  <Input className={Module.input} />
                </Form.Item>
                <Form.Item
                  {...field}
                  className={Module.ite}
                  name={[field.name, "fieldBaseTypeId"]}
                  label={"字段类型"}>
                  <Select className={Module.select}
                    disabled={types[field.name]?.children?.length !== undefined}>
                    {options.filter(option => {
                      return indexes.length === 0 || option.key !== "7"
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  className={Module.ite}
                  name={[field.name, "fillRoleId"]}
                  label={"自动填充"}
                >
                  <Select allowClear={true}>
                    {antoOption}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  className={Module.ite}
                  name={[field.name, "permissionRoleId"]}
                  label={"填充权限"}
                >
                  <Select placement="topLeft" allowClear={true} placeholder="所有人">
                    {permissionRoles.map(role =>
                      <Select.Option key={role.id} value={role.id}>
                        {role.roleDescription}
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
                {configElement([field.name], "children", (types[field.name].children ?? []))}
              </div>
            ))}
          </>
        )}
      </Form.List>
    )
  }

  const layout = {
    labelCol: {
      span: 6
    },
    wrapperCol: { span: 16 },
  };
  const roleClick = () => {
    navigate("/wordroles", { state: { documentTypeId: documentTypeId } })
  }
  let btn;
  if (masterId) {
    btn = <></>
  } else {
    btn = <Form.Item
      name="typeRole"
      label="文书类型权限"
    >
      <Select>
        {options1}
      </Select>
    </Form.Item>
  }

  return (
    <div className={Module.all}>
      <div className={Module.button}>
        <Button type="primary" className={Module.btn2} onClick={updateType}>修改</Button>
        <Button type="primary" className={Module.btn2} onClick={deleteDocumentType}>删除</Button>
        <Button type="primary" className={Module.btn2} onClick={updateDocument}>修改文书模板格式</Button>
        <Button type="primary" style={{ float: "right", marginLeft: 10 }} onClick={roleClick} className={Module.rolebtn}>管理权限</Button>
        <Button type="primary" style={{ float: "right" }} className={Module.btn2} onClick={accessories}>管理附件</Button>
      </div>
      <div className={Module.center}>
        <Form
          form={form}
          className={Module.form1}
          key="typeForm"
          {...layout}
        >
          <Form.Item
            name="typeName"
            label="文书类型名称"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="typeDescription"
            label="案号"
          >
            <Input />
          </Form.Item>

          {configElement([], "fields", form.getFieldValue("fields"))}
        </Form>

      </div>
    </div>
  );
};

export default WordSee;