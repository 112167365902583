import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { useLocation, useNavigate } from "react-router-dom";
import Module from '../style/pdf.module.scss'
import ApiDocument from "../internet/ApiDocument";
import { Button, Popconfirm, message } from "antd";
import DocumentEntity from "../entity/DocumentEntity";


const Pdf: React.FC = () => {
  const location = useLocation()
  const state = (location.state as { documentId?: number, status?: string, approval?: boolean })
  const documentId = state?.documentId
  const status = state?.status
  const masterId = (location.state as { masterId?: number })?.masterId
  const accessorieComplate = (location.state as { accessorieComplate?: boolean })?.accessorieComplate
  const caseId = (location.state as { caseId?: number })?.caseId
  const document = (location.state as { document?: DocumentEntity })?.document
  const approval = state?.approval
  console.log(approval);
  const [pdfUrl, setPdfUrl] = useState<string>()
  const navigate = useNavigate()
  const apiDocument = new ApiDocument(message, navigate)

  function getDocumentPdf(documentId: number) {
    apiDocument.getPdf(documentId, {
      exit: 0, successHandler: byteArray => {
        if (byteArray) {
          const url = "data:application/pdf;base64," + byteArray.toString()
          setPdfUrl(url)
        }
      }
    })
  }

  function addApproval(documentId: number) {
    return () => {
      apiDocument.addApproval(documentId, {
      })

    }
  }
  function completeDocument(documentId: number) {
    return () => apiDocument.complete(documentId, {
      exit: 0, successHandler: () => {
        message.success("文书已完成")
      }
    })


  }
  function addAccessorie() {
    navigate("/accessrieslist", { state: { masterId: masterId, documentId: documentId, status: status, caseId: caseId } })
  }
  function reuplode() {
    navigate("/uploaddocument", { state: { documentId: documentId, recomplate: true } })
  }
  function generatePdfButton(approval: boolean | undefined, documentId: number | undefined) {
    if (documentId) {
      if (approval) {
        return [<Button name={"submit"} type="primary" className={Module.btn1}
          onClick={approvalDocument(documentId)}>审批</Button>,
        <Button name={"cancel"} type="primary" className={Module.btn1}
          onClick={cancelApproval(documentId)}>取消审批</Button>]
      } else {
        if (status === "待审批") {
          return <Popconfirm
            placement="topLeft"
            title="是否提交审批？"
            onConfirm={addApproval(documentId)}
            okText="确认提交"
            cancelText="取消"
          >
            <Button type="primary" className={Module.btn1}
            >提交审批</Button>
          </Popconfirm>

        }
        if (status !== "已完成") {

          return <Popconfirm
            placement="topLeft"
            title="完成后无法修改，只能上传pdf进行修改"
            onConfirm={completeDocument(documentId)}
            okText="确认完成"
            cancelText="取消"
          >
            <Button type="primary" className={Module.btn1}
            >完成文书</Button>
          </Popconfirm>
        }
      }
    }
  }


  function cancelApproval(documentId: number) {
    return () => {
      apiDocument.cancelApproval(documentId, { exit: 2 })
    }
  }

  function approvalDocument(documentId: number) {
    return () => {
      apiDocument.updateApproval(documentId, { exit: 2 })

    }
  }

  const getPath = () => {
    if (documentId) {
      getDocumentPdf(documentId)
    }
  }
  useEffect(() => {
    if (!documentId) {
      navigate(-1)
    } else {
      getPath()
    }

  }, [location])
  let btn;
  if (!accessorieComplate) {
    btn = <>
      <Button type="primary" style={{ float: "right" }}
        onClick={addAccessorie}>附件管理</Button>
    </>
  } else {
    btn = <></>
  }
  if (documentId) {
    return (
      <div className={Module.all}>
        {btn}
        <Button name={"upload"} type="primary" className={Module.btn1}
          style={{ float: "right", marginRight: 20 }}
          onClick={reuplode}>上传pdf</Button>
        {generatePdfButton(approval, documentId)}
        <Iframe
          url={pdfUrl ?? ""}
          className={Module.pdf}
          position="relative"
        />,
      </div>
    )
  } else {
    return <></>
  }
}
export default Pdf