import { ForestSummary } from "./foresSummary";
import { ForestPatroLedger } from "./ForestPatrolLedger";

export class Summary {
  name: string | null = null;
  ledgerTable: Array<ForestPatroLedger> = [];
  forestPatrolTable: Array<ForestSummary> = []
  constructor(name: string | null, ledgerTable: Array<ForestPatroLedger>, forestPatrolTable: Array<ForestSummary>) {
    this.name = name
    this.forestPatrolTable = forestPatrolTable
    this.ledgerTable = ledgerTable
  }
}