import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/sampleRecord.module.scss'
export default function SampleRecord(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>抽样取证记录</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.clumn1}>
                      <p>当事人</p>
                    </div>
                    <div className={Module.clumn2}>
                      <Form.Item
                        name={[0, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>主体资格证照名称</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[1, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="主体资格证照名称"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>统一社会信用代码（注册号）</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[2, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="统一社会信用代码（注册号）"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}><p>住址</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[3, "fieldValue"]}
                        className={Module.item1}
                      >
                        <Input
                          placeholder="住址"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>

                  </div>
                  <div className={Module.row4}>
                    <div className={Module.c1}><p>法定代表人（负责人、经营者）</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[4, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="法定代表人（负责人、经营者）"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>身份证（其他有效证件）号码</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[5, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="身份证（其他有效证件）号码"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>联系电话</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[6, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="联系电话"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>其他联系方式</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[7, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="其他联系方式"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.c1}><p>被抽样产品及抽样 情况</p></div>
                    <div className={Module.c2}>
                      <div className={Module.d1}>
                        <div className={Module.c3}><p>产品名称</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[8, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="产品名称"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>型号规格</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[9, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="型号规格"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d2}>
                        <div className={Module.c3}><p>标称商标</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[10, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="标称商标"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>保质期</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[11, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="保质期"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d2}>
                        <div className={Module.c3}><p>标称生产者</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[12, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="标称生产者"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>标称价格</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[13, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="标称价格"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d3}>
                        <div className={Module.c3}><p>生产日期或出厂批号</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[14, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="生产日期或出厂批号"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>产品执行标准编号</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[15, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="产品执行标准编号"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d2}>
                        <div className={Module.c3}><p>标称存储条件</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[16, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="标称存储条件"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>生产许可证编号</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[17, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="生产许可证编号"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d2}>
                        <div className={Module.c3}><p>标称产品等级</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[18, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="标称产品等级"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>包装方式</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[19, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="包装方式"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d4}>
                        <div className={Module.a1}>
                          <p>抽样方式</p>
                        </div>
                        <div className={Module.a2}>
                          <div className={Module.e2}>
                            <Form.Item
                              name={[20, "fieldValue"]}
                              className={Module.item}
                              initialValue="⬜"
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 20, "fieldValue"])}
                              />
                            </Form.Item>
                            <p className={Module.h2}>按规定方式抽样（抽样依据的标准编号）</p>
                          </div>
                          <div className={Module.e2}>
                            <Form.Item
                              name={[21, "fieldValue"]}
                              className={Module.item}
                              initialValue="⬜"
                            >
                              <Input readOnly
                                onClick={() => props.changeCheckbox(["fields", 21, "fieldValue"])}
                              />
                            </Form.Item>
                            <p className={Module.h2}>以其他方式抽样（可使用附页）</p>
                          </div>
                        </div>
                      </div>
                      <div className={Module.d2}>
                        <div className={Module.c3}><p>抽取样品数量</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[22, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="抽取样品数量"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.c5}><p>样品基数</p></div>
                        <div className={Module.c6}>
                          <Form.Item
                            name={[23, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="样品基数"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d5}>
                        <div className={Module.c3}><p>抽取地点</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[24, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="抽取地点"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d5}>
                        <div className={Module.c3}><p>抽取样品过程</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[25, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="抽取样品过程"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d5}>
                        <div className={Module.c3}><p>样品封样情况</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[26, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="样品封样情况"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d6}>
                        <div className={Module.c3}><p>样品储存条件</p></div>
                        <div className={Module.c4}>
                          <Form.Item
                            name={[27, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="样品储存条件"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 27, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>主办人员</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[28, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="主办人员"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 28, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>主办人员执法证号</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[29, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="主办人员执法证号"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 29, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>协办人员</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[30, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="协办人员"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 30, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.c3}><p>协办人员执法证号</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[31, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="协办人员执法证号"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 31, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>当事人签名或盖章</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e2}>
                      </div>
                    </div>
                    <div className={Module.d3}>
                      <p>受委托抽样人员签名或盖章</p>
                    </div>
                    <div className={Module.d4}>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[37, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 37, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="受委托抽样人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[32, "fieldValue"]} className={Module.sig}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 32, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          className={Module.select}
                          name={[35, "fieldValue"]} >
                          <Select placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 35, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                  </div>
                  <div className={Module.row11}>
                    <div className={Module.d1}>
                      <p>见证人签名</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[38, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="见证人签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[32, "fieldValue"]} className={Module.sig}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 32, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                        </Form.Item>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          className={Module.select}
                          name={[39, "fieldValue"]} >
                          <Select placeholder="印章"
                            placement="topLeft"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 39, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.d3}><p>备注</p></div>
                    <div className={Module.d4}>
                      <Form.Item
                        name={[33, "fieldValue"]}
                        className={Module.item1}
                      >
                        <Input.TextArea
                          placeholder="备注"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 33, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}