import { DeleteOutlined } from "@ant-design/icons"
import { Button, Form, Input, message } from "antd"
import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Module from '../style/userinfo.module.scss'
import Role from "../entity/Role"
import { ApiAdminDocumentType } from "../internet/ApiAdminDocumentType";

const WordTypeMain: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const role = (location.state as { role?: Role })?.role
  const [form] = Form.useForm<{ roleDescription: string }>();
  const apiAdminDocumentType = new ApiAdminDocumentType(message, navigate)
  useEffect(() => {
    if (role) {
      form.setFieldsValue(role)
    }
  }, [location])

  function addTypeRole() {
    form.validateFields()
      .then(values => {
        apiAdminDocumentType.addTypeRole(values.roleDescription)
      })
  }

  function updateTypeRole(roleId: number) {
    form.validateFields()
      .then(values => {
        apiAdminDocumentType.updateTypeRole(roleId, values.roleDescription)
      })
  }

  function deleteTypeRole(roleId: number) {
    apiAdminDocumentType.deleteTypeRole(roleId)
  }


  const buttons: JSX.Element[] = []
  if (role) {
    buttons.push(
      <Button className={Module.btn1} type="primary"
        onClick={() => deleteTypeRole(role.id)}><DeleteOutlined />删除</Button>)
    buttons.push(
      <Button className={Module.btn} type="primary"
        onClick={() => updateTypeRole(role.id)}>修改</Button>
    )
  } else {
    buttons.push(
      <Button className={Module.btn} type="primary" onClick={addTypeRole}>添加</Button>
    )
  }
  return (
    <div className={Module.all}>
      <Form
        name='权限信息'
        form={form}
        className={Module.form}
      >
        <Form.Item
          name="roleDescription"
          className={Module.item}
          rules={[{
            required: true
          }]}
          label="文书模板类型名称">
          <Input />
        </Form.Item>

        <Form.Item>
          {buttons}
        </Form.Item>
      </Form>
    </div>
  )
}
export default WordTypeMain