import React from "react"
import Module from '../style/instructions.module.scss'
import { Image } from "antd"


const Instructions: React.FC = () => {
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <p className={Module.title}>林业局管理系统操作指引</p>
        <div className={Module.directory} id="directory">
          <a href="#login"><span>一,登录界面</span></a>
          <a href="#add"><span>二,案件办理</span></a>
          <a href="#write"><span>三,添写文书</span></a>
          <a href="#sp"><span>四,审批文书</span></a>
          <a href="#fj"><span>五,添加附件</span></a>
          <a href="#fj"><span>五,添加附件</span></a>
          <a href="#adduser"><span>六,添加用户</span></a>
          <a href="#addrole"><span>七,设置用户权限</span></a>
        </div>
        <div className={Module.content}>
          <div className={Module.action1} id="login">
            <a href="#directory"> <p className={Module.subtitle}>一,登录</p></a>
            <div>
              <p className={Module.text}>1.打开网页输入118.121.222.120:20080显示以下页面</p>
              <Image width={400} src={require("../image/图片1.png")} />

              <p className={Module.text}>2.在页面输入登录的账号及密码</p>
              <Image width={400} src={require("../image/图片2.png")} />

              <p className={Module.text}>3.点击登录进入主页</p>
              <Image width={400} src={require("../image/图片3.png")} />
            </div>
          </div>
          <div id="add" className={Module.action2}>
            <a href="#directory">  <p className={Module.subtitle}>二,案件办理</p></a>
            <p className={Module.text}>4.点击侧边栏案件办理按钮</p>
            <Image width={400} src={require("../image/图片4.png")} />
            <p className={Module.text}>
              5.进入案件办理界面，点击添加案件，.进入添加案件界面，录入案情基本信息，
              再次在案件列表中点击需办理案件，添加嫌疑人信息及证据跟处罚，分配主办和协办人员，填写好之后先点击保存。
            </p>
            <div className={Module.pban}>
              <div className={Module.a1}>
                <p>第一步</p>
                <Image width={400} src={require("../image/图片5.png")} />
              </div>
              <div>
                <p>第二步</p>
                <Image width={400} src={require("../image/图片6.png")} />
              </div>
              <div>
                <p>第三步</p>
                <Image width={400} src={require("../image/图片7.png")} />
              </div>
              <div>
                <p>第四步</p>
                <Image width={400} src={require("../image/图片8.png")} />
              </div>
            </div>
          </div>
          <div id="write" className={Module.action2}>
            <a href="#directory">  <p className={Module.subtitle}>三，填写文书</p></a>
            <p className={Module.text}>6.点击下图右上角添加文书，进入文书列表</p>
            <Image width={400} src={require("../image/图片9.png")} />
            <p className={Module.text}>7.进入文书列表，点击所需添加的文书</p>
            <Image width={400} src={require("../image/图片10.png")} />
            <p className={Module.text}>8.假如点击案件调查终结报告，如下图，自动生成基本信息，查验纠正后点击保存，
              也可通过点击上传pdf上传已完成的文书。保存后自动跳转案件基本信息界面</p>
            <Image width={400} src={require("../image/图片11.png")} />
            <p className={Module.text}>9.点击文书列表查看已添加的文书</p>
            <Image width={400} src={require("../image/图片12.png")} />
            <Image width={400} src={require("../image/图片13.png")} />
            <Image width={400} src={require("../image/图片14.png")} />
            <p className={Module.text}>10.再次点击添加的文书，进入文书界面，如下图，确认信息无误后点击生成文书按钮，如需删除点击删除按钮，如需修改在此页面修改后点击保存按钮，
              案件状态显示案件当时的状态，如有附件点击右上角附件管理</p>
            <Image width={400} src={require("../image/图片15.png")} />
            <p className={Module.text}>11.点击生成文书后会出现查看PDF按钮</p>
            <Image width={400} src={require("../image/图片16.png")} />
            <p className={Module.text}>12.点击查看PDF查看生成的文书PDF界面，点击完成文书完成PDF，如有审批点击提交审批，</p>
            <Image width={400} src={require("../image/图片17.png")} />
            <Image width={400} src={require("../image/图片18.png")} />
            <p style={{ fontSize: 15, fontWeight: "bolder" }} className={Module.text} id="sp">13.领导审批，点击左边侧边栏待审批文书按钮，进入待审批文书列表</p>
            <Image width={400} src={require("../image/图片19.png")} />
            <p className={Module.text} id="sp">14.点击审批文书进入以下界面，点击查看PDF按钮查看PDF如没问题点击审批按钮，有问题点击取消审批按钮</p>
            <Image width={400} src={require("../image/图片20.png")} />
            <Image width={400} src={require("../image/图片21.png")} />
            <p className={Module.text} id="sp">15.点击审批返回审批列表继续审批。审批完的文书在案件办理的案件基本信息中点击文书列表查看已审批文书</p>
            <Image width={400} src={require("../image/图片22.png")} />
            <p className={Module.text} id="sp">16.点击已审批的文书进入文书信息界面，点击查看PDF按钮，点击完成文书即可完成文书</p>
            <Image width={400} src={require("../image/图片23.png")} />
            <Image width={400} src={require("../image/图片24.png")} />
            <p className={Module.text} id="sp">17.完成后自动跳转至案件基本信息界面，添加其他文书按照6-16步骤。
              添加所有文书并且完成文书（步骤16）后（领导也以全部审批），点击结案按钮</p>
            <Image width={400} src={require("../image/图片25.png")} />
            <p className={Module.text} id="sp">18.在未结案之前，文书可以以PDF上传及当事人签名后回传文书，点击文书列表，点击想修改的文书，点击上传PDF上传所修改的文书PDF</p>
            <Image width={400} src={require("../image/图片26.png")} />
            <Image width={400} src={require("../image/图片27.png")} />
          </div>
          <div className={Module.action1} id="fj">
            <a href="#directory"> <p className={Module.subtitle}>五，添加附件</p></a>
            <div>
              <p className={Module.text}>19.在添加文书界面的时候如附件点击文书列表文书信息的右上角的附件管理，
                点击进入附件文书列表界面，点击所需附件后进入附件信息填写基本信息后点击保存。</p>
              <Image width={400} src={require("../image/图片28.png")} />
              <Image width={400} src={require("../image/图片29.png")} />
              <p className={Module.text}>20.如是PDF图片点击右下角添加附件上传</p>
              <Image width={400} src={require("../image/图片30.png")} />
              <Image width={400} src={require("../image/图片31.png")} />
              <Image width={400} src={require("../image/图片32.png")} />
            </div>
          </div>
          <div className={Module.action1} id="adduser">
            <a href="#directory"> <p className={Module.subtitle}>六，添加用户</p></a>
            <div>
              <p className={Module.text}>21：在用户设置里面点击添加按钮，填写用户信息，点击确认添加按钮添加用户</p>
              <Image width={400} src={require("../image/图片33.png")} />
              <Image width={400} src={require("../image/图片34.png")} />
            </div>
          </div>
          <div className={Module.action1} id="addrole">
            <a href="#directory"> <p className={Module.subtitle}>七，设置用户权限</p></a>
            <div>
              <p className={Module.text}>22：管理人员在用户设置里面点击修改权限按钮，可以给用户设置不同的权限，主要分为办案人员权限和管理人员权限，点击右侧的加号尽快添加，确认添加权限后点击确认添加即可添加成功</p>
              <Image width={400} src={require("../image/图片35.png")} />
              <Image width={400} src={require("../image/图片36.png")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export default Instructions