import Dictionary from "./Dictionary";
class DictionaryType {
  id: number | null;
  baseTypeId: number = 1;
  typeName: string | null;
  typeDescription: string | null;
  regex: string | null;
  dictionaries?: Array<Dictionary>
  constructor(
    id: number | null = null,
    baseTypeId: number = 1,
    typeName: string | null = null,
    typeDescription: string | null = null,
    regex: string | null = null,
    dictionaries?: Array<Dictionary>
  ) {
    this.id = id
    this.baseTypeId = baseTypeId
    this.typeName = typeName
    this.typeDescription = typeDescription
    this.regex = regex
    this.dictionaries = dictionaries

  }
}
export default DictionaryType