import UserEntity from "../entity/UserEntity";
import InternetTool from "./Internetool";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiUser {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("user", message, navigate)
  }
  getUser(resultParam?: ResultParam<UserEntity>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )
  }
}