import { AutoComplete, Button, Card, Cascader, Form, Input, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PunishItem } from '../entity/PunishItem';
import Module from '../style/punishcontext.module.scss'
import { Punish } from "../entity/Punish";
import { LawLevels } from "../tool/LawLevels";
import { LawPunishArticle } from "../entity/LawPunishArticle";
import { ApiCasePunish } from "../internet/ApiCasePunish";
import { ApiLaw } from '../internet/ApiLaw';
import { LawPunishType } from "../entity/LawPunishType";
import { FormInstance } from "antd/es";
import LawPunish from "../entity/LawPunish";
import { LawConversion } from '../tool/lawConversion';
import { ApiAdminLaw } from '../internet/ApiAdminLaw';
import Law from '../entity/Law';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

interface PunishForm {
  punishTypeId: number,
  punishId: number,
  punishLevel: number,
  articleId: number,
  punishArticleLawId: number,
  punishArticleId: number,
  lawId: Array<number>,
  items: PunishItem[]
}

const PunishContext: React.FC = () => {
  const [form] = Form.useForm<PunishForm>();
  const location = useLocation()
  const state = (location.state as { punish?: Punish, caseId?: number, caseTypeId?: number })
  const oldPunish = state?.punish
  const caseId = state?.caseId
  const caseTypeId = state?.caseTypeId
  const [lawPunishTypes, setLawPunishTypes] = useState<LawPunishType[]>([])
  const [laws, setLaws] = useState<Law[]>([])
  const navigate = useNavigate()
  const apiCasePunish = new ApiCasePunish(message, navigate)
  const apiLaw = new ApiLaw(message, navigate)
  const apiAdminLaw = new ApiAdminLaw(message, navigate)
  const punishTypeId = Form.useWatch<number | undefined>("punishTypeId", form)
  const punishId = Form.useWatch<number | undefined>("punishId", form)
  const punishLevel = Form.useWatch<number | undefined>("punishLevel", form)
  const articleId = Form.useWatch<number | undefined>("articleId", form)
  const punishType = lawPunishTypes.find(type => type)
  const punish = punishType?.punishes?.find(punish => punish.id === punishId)
  const articles = punish?.articles
  const article = articles?.find(article => article.id === articleId)
  const laws1 = LawConversion.law11(laws)
  function addPunish(caseId: number) {
    form.validateFields()
      .then(punishForm => {
        const punishArticleId = article?.id
        if (punishArticleId) {
          if (punishForm.lawId) {
            const chapterId = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).find(c => c.id === (punishForm.lawId ?? [])[1])?.chapterId ?? 0
            punishForm.lawId.splice(1, 0, chapterId);
          }
          const punish = new Punish(
            0, caseId, punishForm.punishArticleLawId, punishArticleId, punishForm.lawId,
            punishForm.items
          )
          apiCasePunish.add(punish)
        }
      })
  }
  function getLaws() {
    apiAdminLaw.getLaws({
      successHandler: laws => {
        if (laws) {
          setLaws(laws)
        }
      }
    })
  }
  function updatePunish(oldPunish: Punish, caseId: number) {
    form.validateFields()
      .then(punishForm => {
        const punishArticleId = article?.id
        if (punishArticleId) {
          if (punishForm.lawId) {
            const chapterId = laws.flatMap(a => a.chapters ?? []).flatMap(b => b.articles ?? []).find(c => c.id === (punishForm.lawId ?? [])[1])?.chapterId ?? 0
            punishForm.lawId.splice(1, 0, chapterId);
          }
          const punish = new Punish(
            oldPunish.id, caseId, punishForm.punishArticleLawId, punishArticleId, punishForm.lawId,
            punishForm.items
          )



          apiCasePunish.update(punish, punishForm.punishTypeId, {
            exit: 1, successHandler: (res) => {
            }
          })
        }

      })
  }

  function deletePunish(punishId: number) {
    apiCasePunish.delete(punishId)
  }

  let button: JSX.Element = <></>
  if (caseId) {
    if (oldPunish) {
      button = (
        <Form.Item>
          <Button type="primary" onClick={() => deletePunish(oldPunish.id)}>删除</Button>
          <Button type="primary" onClick={() => updatePunish(oldPunish, caseId)}>确认修改</Button>
        </Form.Item>
      )
    } else {
      button = (
        <Form.Item>
          <Button type="primary" onClick={() => addPunish(caseId)}>添加</Button>
        </Form.Item>
      )
    }
  }

  function setForm() {
    if (oldPunish) {
      console.log(oldPunish);
      if (lawPunishTypes.length) {
        lawPunishTypes.forEach(lawPunishType => {
          (lawPunishType.punishes ?? []).forEach(lawPunish => {
            (lawPunish.articles ?? []).forEach(article => {
              if (article.id === oldPunish.punishArticleId) {
                if (oldPunish.lawId) {
                  oldPunish.lawId.splice(1, 1);
                }
                form.setFieldsValue({
                  punishTypeId: caseTypeId,
                  punishId: lawPunish.id,
                  punishLevel: article.punishLevel,
                  articleId: article.id,
                  lawId: oldPunish.lawId,
                  items: oldPunish.items ?? PunishItem.convertLawPunishItems(article.items ?? [], oldPunish?.id ?? 0)
                })
                return
              }
            })
          })
        })
      }
    }
  }

  useEffect(() => {
    setForm()
  }, [oldPunish, lawPunishTypes])


  function getLawPunishes() {
    apiLaw.getPunishTypes({
      successHandler: lawPunishTypes => {
        if (lawPunishTypes) {
          setLawPunishTypes(lawPunishTypes)
        }
      }
    })
  }


  useEffect(() => {
    getLaws()
    if (!caseId) {
      navigate(-1)
    }
    getLawPunishes()
  }, [location])


  function onValuesChange(
    changed: PunishForm, _: PunishForm
  ) {
    if (changed.punishId) {
      initByPunish(form, punishType?.punishes?.find(it => it.id === changed.punishId))
    } else if (changed.articleId) {
      initByArticle(form, articles?.find(it => it.id === changed.articleId))
    }
  }

  function initByArticle(form: FormInstance<PunishForm>, article?: LawPunishArticle) {
    const punishArticleLaws = article?.punishArticleLaws;
    let lawId = undefined
    if (punishArticleLaws?.length) {
      lawId = punishArticleLaws[0].id
    }
    form.setFieldsValue({
      punishArticleLawId: lawId,
      items: PunishItem.convertLawPunishItems(article?.items ?? [])
    })
  }

  function initByPunishLevel(form: FormInstance<PunishForm>, articles?: LawPunishArticle[]) {
    let article = undefined
    if (articles?.length) {
      article = articles[0]
    }
    form.setFieldsValue({
      articleId: article?.id
    })
    initByArticle(form, article)
  }

  function initByPunish(form: FormInstance<PunishForm>, punish?: LawPunish) {
    let punishLevel: number | undefined = undefined
    if (punish?.articles?.length) {
      punishLevel = punish.articles[0].punishLevel
    }
    form.setFieldsValue({
      punishLevel: punishLevel
    })
    initByPunishLevel(form, punish?.articles?.filter(article => article.punishLevel === punishLevel))
  }

  function initByPunishType(form: FormInstance<PunishForm>, punishType?: LawPunishType) {
    let punish = undefined
    if (punishType?.punishes?.length) {
      punish = punishType.punishes[0]
    }
    form.setFieldsValue({
      punishId: punish?.id
    })
    initByPunish(form, punish)
  }
  return (
    <div className={Module.all}>
      <Card className={Module.card}>
        <Form
          {...layout}
          name='添加'
          form={form}
          className={Module.form}
          onValuesChange={onValuesChange}
        >
          <h1 style={{ fontSize: 20 }}>处罚</h1>
          <Form.Item
            name="punishTypeId"
            className={Module.item}
            label="案件类型"
            rules={[{ required: true, }]}
          >
            <Select
              options={lawPunishTypes.map(type => ({
                value: type.id,
                label: type.typeName
              }))}
            />
          </Form.Item>
          <Form.Item
            name="punishId"
            className={Module.item}
            label="违法行为"
            rules={[{ required: true, }]}
          >
            <Select
              options={punishType?.punishes?.map(punish => ({
                value: punish.id,
                label: punish.lawPunishName
              }))}
            />
          </Form.Item>
          <Form.Item
            name="punishLevel"
            className={Module.item}
            label="裁量阶次"
          >
            <Select
              allowClear
              options={punish?.articles
                ?.map(article => article.punishLevel)
                ?.filter((level, index, arr) => index === arr.indexOf(level))
                ?.map(level => ({
                  value: level,
                  label: LawPunishArticle.punishLevels[level]
                }))}
            />
          </Form.Item>
          <Form.Item
            name="articleId"
            className={Module.item}
            label="适用情形"
            rules={[{ required: true, }]}
          >
            <Select options={articles?.map(article => ({
              value: article.id,
              label: article.articleValue
            }))} />
          </Form.Item>
          <Form.Item
            name="lawId"
            className={Module.item}
            label="处罚依据"
            rules={[{ required: true, }]}
          >
            <Cascader className={Module.select}
              dropdownMenuColumnStyle={{ maxWidth: 300, maxHeight: 300, textOverflow: "ellipsis", whiteSpace: "pre-wrap" }}
              changeOnSelect options={laws1} />
          </Form.Item>
          <Form.List name={"items"}>
            {(fields,) =>
              <div className={Module.lists}>
                {fields.map(field =>
                  <Form.Item
                    className={Module.item1}
                    name={[field.name, "itemValue"]}
                    label="处罚内容"
                    rules={[{ required: true, }]}
                  >
                    <AutoComplete
                      options={[{ value: article?.items?.[field.name]?.punishValue }]}
                    >
                      <Input className={Module.input1} />
                    </AutoComplete>
                  </Form.Item>
                )}
              </div>
            }
          </Form.List>
          {button}
        </Form>
      </Card>
    </div>
  );
};

export default PunishContext;