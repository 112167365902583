import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Tree } from "antd";
import React, { Key, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Module from '../style/unit.module.scss'
import UnitEntity from "../entity/UnitEntity";
import { ModalControl } from "./modalControl";
import { DataNode } from "./dataNode";
import type { EventDataNode } from 'antd/lib/tree';
import ApiAdminUnit from "../internet/ApiAdminUnit";


const Unit: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [nodes, setNodes] = useState<DataNode<UnitEntity>[]>([])
  const [addModalControl, setAddModalControl] = useState<ModalControl<UnitEntity>>(ModalControl.hide())
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [addForm] = Form.useForm<{ value: string }>();
  const apiAdminUnit = new ApiAdminUnit(message, navigate)

  function showAddModal(entity: UnitEntity | null = null, e: React.MouseEvent | null = null) {
    e?.stopPropagation()
    setAddModalControl(ModalControl.show(entity))
  }

  function hideAddModal() {
    addForm.resetFields()
    setAddModalControl(ModalControl.hide())
  }

  function addUnit(parentId: number | null) {
    addForm.validateFields()
      .then(item => {
        let unitEntity = new UnitEntity(0, item.value, null, parentId, []);
        apiAdminUnit.addUnit(unitEntity, {
          exit: 0, successHandler: (res => {
            getUnits()
            setAddModalControl(ModalControl.hide())
          })
        })

      })
  }

  function convertUnit2Node(unit: UnitEntity): DataNode<UnitEntity> {
    return {
      entity: unit,
      title: unit.unitName,
      key: unit.id.toString(),
      children: (unit.children ?? []).map(child => {
        return convertUnit2Node(child)
      })
    }
  }

  function getUnits() {
    apiAdminUnit.getUnits({
      successHandler: newUnits => {
        if (newUnits) {
          let nodes = newUnits.map(u => {
            return convertUnit2Node(UnitEntity.create(u))
          });
          setNodes(nodes)
          setExpandedKeys(expandAll(nodes))
        }
      }
    })

  }

  useEffect(getUnits, [location])

  const nodeReader = (node: DataNode<UnitEntity>) => {
    let nodeArr = node.title
    nodeArr =
      <div>
        <span>{nodeArr}</span>
        <span>
          <PlusOutlined style={{ marginLeft: 15 }} onClick={(e) => showAddModal(node.entity, e)} />
        </span>
      </div>
    return nodeArr
  }

  function unitOnSelect(_: Key[], info: { node: EventDataNode<DataNode<UnitEntity>> },) {
    const unitEntity = info.node.entity
    navigate("/unitlist", { state: { entity: unitEntity } })
  }

  function expandAll(data: DataNode<any>[]): string[] {
    return data.flatMap(node => {
      let childKeys: string[] = [];
      if (node.children) {
        childKeys = expandAll(node.children);
      }
      childKeys.push(node.key)
      return childKeys;
    })
  }

  function onExpand(expandedKeys: Key[]) {
    setExpandedKeys(expandedKeys.map(key => {
      return key.toString()
    }))
  }

  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <div className={Module.tree}>
          <Button type="primary" onClick={() => {
            showAddModal()
          }}>添加单位</Button>
          <Tree
            titleRender={nodeReader}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            treeData={nodes}
            onSelect={unitOnSelect}
          />
          <Modal title="添加单位名称" visible={addModalControl.visible} onOk={() => {
            addUnit(addModalControl.entity?.id ?? null)
          }} onCancel={hideAddModal}
            width={300} mask={false}>
            <Form form={addForm}>
              <Form.Item
                label="名称"
                name={"value"}
                rules={[{
                  required: true,
                  message: "清输入单位名称"
                }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  )
}


export default Unit