import { AutoComplete, Button, Form, Input, Select } from "antd"
import Module from '../style/brainstormingRecord.module.scss'
import { MinusOutlined } from "@ant-design/icons";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";

export function BrainstormingRecord(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div >
              <h1 className={Module.h1}>行政处罚案件单位负责人集体讨论笔录</h1>
              <div className={Module.center}>
                <div className={Module.flex}>
                  <p>案件名称:</p>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案件名称" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>当事人:</p>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="当事人" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>时间:</p>
                  <Form.Item
                    className={Module.item1}
                    name={[2, "fieldValue"]}
                  >
                    <StringDatePicker
                      showTime
                      bordered={false}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format="YYYY年MM月DD日HH时mm分" placeholder="开始时间"
                    />
                  </Form.Item>
                  <p>至</p>
                  <Form.Item
                    className={Module.item1}
                    name={[3, "fieldValue"]}
                  >
                    <StringDatePicker
                      showTime
                      bordered={false}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format="HH时mm分" placeholder="结束时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>地点:</p>
                  <Form.Item
                    className={Module.item2}
                    name={[4, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="地点" />
                  </Form.Item>
                </div>
                <p style={{ fontWeight: "bolder" }}>集体讨论原因：</p>
                <Form.Item
                  className={Module.item}
                  name={[5, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="违法行为" />
                </Form.Item>
                <p>现提请行政机关的负责人集体讨论决定。</p>
                <div className={Module.flex}>
                  <p>主持人:</p>
                  <Form.Item
                    className={Module.item}
                    name={[6, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主持人" />
                  </Form.Item>
                  <p>职务:</p>
                  <Form.Item
                    className={Module.item}
                    name={[7, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>记录人:</p>
                  <Form.Item
                    className={Module.item}
                    name={[8, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="记录人" />
                  </Form.Item>
                  <p>职务:</p>
                  <Form.Item
                    className={Module.item}
                    name={[9, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <p style={{ fontWeight: "bolder" }}>参加人及职务：</p>
                <Form.List
                  name={[10, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 10])
                    return <div className={Module.list}>
                      {fields.map((field, name) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                >
                              <AutoComplete
                             filterOption
                            dataSource={props.users?.map(i=>i.realName)}
                            children={<Input.TextArea placeholder="参加人姓名"style={{ border:"1px solid black"}}  autoSize={{ minRows: 1, maxRows: 5 }} />}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                        <AutoComplete
                             filterOption
                            dataSource={["局党组成员、副局长","县森防指专职副指挥长","副局长、总工程师"]}
                            children={<Input.TextArea placeholder="参加人职务"style={{ border:"1px solid black"}}  autoSize={{ minRows: 1, maxRows: 5 }} />}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加参加人", add)}
                    </div>
                  }}
                </Form.List>
                <p style={{ fontWeight: "bolder" }}>列席人及职务：</p>
                <Form.List
                  name={[11, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 11])
                    return <div className={Module.list}>
                      {fields.map((field, name) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                >
                                       <AutoComplete
                             filterOption
                            dataSource={props.users?.map(i=>i.realName)}
                            children={<Input.TextArea placeholder="列席人姓名"  style={{ border:"1px solid black"}}  autoSize={{ minRows: 1, maxRows: 5 }} />}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                       <AutoComplete
                             filterOption
                            dataSource={["行政执法监督股副股长","林长制工作股（法制审核）股长","森林草原资源管理股股长","林业科技推广服务中心工作人员"]}
                            children={<Input.TextArea placeholder="列席人职务"style={{ border:"1px solid black"}}  autoSize={{ minRows: 1, maxRows: 5 }} />}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加列席人", add)}
                    </div>
                  }}
                </Form.List>
                <p style={{ fontWeight: "bolder" }}>案件承办人汇报案件情况：</p>
                <p>当事人基本情况：</p>
                <Form.Item
                  className={Module.item}
                  name={[12, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人基本情况" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>案件来源、调查经过及采取行政强制措施的情况：</p>
                <Form.Item
                  className={Module.item}
                  name={[13, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="主要案情" />
                </Form.Item>
                <div className={Module.flex}>
                  <Form.Item
                    className={Module.item1}
                    name={[14, "fieldValue"]}
                  >
                    <StringDatePicker
                      bordered={false}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="立案时间"
                    />
                  </Form.Item>
                  <p>日经批准予以立案，</p>
                  <Form.Item
                    className={Module.item3}
                    name={[15, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主办人员" />
                  </Form.Item>
                  <p>，</p>
                  <Form.Item
                    className={Module.item3}
                    name={[16, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="协办人员" />
                  </Form.Item>
                  <p>承办此案。</p>
                </div>
                <p style={{ fontWeight: "bolder" }}>调查认定的事实：</p>
                <Form.Item
                  className={Module.item}
                  name={[17, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="基础案情" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>上述行为涉嫌违反了以下规定：</p>
                <Form.Item
                  className={Module.item}
                  name={[18, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="法律规定" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>上述事实，主要有以下证据证明：</p>
                <Form.List name={[19, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 19])
                    return (<div className={Module.list}>
                      <>
                        {fields.map((field) => (
                          <Form.List name={[field.name]}>
                            {fields => (
                              <div className={Module.list1}>
                                <div className={Module.context}>
                                  <Form.Item
                                    label="证据内容"
                                    name={[0, "fieldValue"]}
                                    className={Module.item}
                                  >
                                    <Input
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    />
                                  </Form.Item>
                                  <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                    style={{ fontSize: 20 }} /></Button>
                                </div>
                              </div>
                            )}
                          </Form.List>
                        ))}
                        {props.genAddButton(fieldEntity, "添加证据", add)}
                      </>
                    </div>)
                  }}
                </Form.List>
                <p style={{ fontWeight: "bolder" }}>案件性质：</p>
                <Form.Item
                  className={Module.item}
                  name={[17, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="基础案情" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>上述行为涉嫌违反了以下规定：</p>
                <Form.Item
                  className={Module.item}
                  name={[20, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="法律规定" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>自由裁量理由等其他需要说明的事项：</p>
                <Form.Item
                  className={Module.item}
                  name={[21, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="自由裁量理由等其他需要说明的事项" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>处理意见及依据：</p>
                <Form.Item
                  className={Module.item}
                  name={[22, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="违法行为" />
                </Form.Item>
                <p >上述行为涉嫌违反了以下规定：</p>
                <Form.Item
                  className={Module.item}
                  name={[20, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="法律规定" />
                </Form.Item>
                <p >依据以下规定：</p>
                <Form.Item
                  className={Module.item}
                  name={[18, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="依据的法律规定" />
                </Form.Item>
                <div className={Module.flex}>
                  <p>拟对</p>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="当事人" />
                  </Form.Item>
                  <p>作出以下行政处罚：</p>
                </div>
                <Form.List
                  name={[23, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 23])
                    return <div className={Module.list2}>
                      {fields.map((field, name) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="序号" />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="处罚内容" />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加处罚", add)}
                    </div>
                  }}
                </Form.List>
                <p style={{ fontWeight: "bolder" }}>参加讨论人员意见和理由：</p>
                <Form.List
                  name={[24, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 24])
                    return <div className={Module.list3}>
                      {fields.map((field, name) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                >
                                  <AutoComplete
                             filterOption
                            dataSource={props.users?.map(i=>i.realName)}
                            children={<Input.TextArea placeholder="姓名"  style={{ border:"1px solid black"}}  autoSize={{ minRows: 1, maxRows: 5 }} />}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                  <AutoComplete
                             filterOption
                            dataSource={["本案主体合法、事实清楚、证据确凿充分、定性准确、处罚适当、程序合法，同意办案人员提出的处罚意见","同意办案人员提出的处罚意见"]}
                            children={<Input.TextArea placeholder="意见"  style={{ border:"1px solid black"}}  autoSize={{ minRows: 1, maxRows: 5 }} />}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加参加讨论人员", add)}
                    </div>
                  }}
                </Form.List>
                <p style={{ fontWeight: "bolder" }}>结论性意见：</p>
                <p>根据以下规定：</p>
                <Form.Item
                  className={Module.item}
                  name={[18, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="法律规定一" />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[25, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="法律规定二" />
                </Form.Item>
                <div className={Module.flex}>
                  <p>同意给予</p>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="当事人" />
                  </Form.Item>
                  <p>如下行政处罚：</p>
                </div>
                <Form.List
                  name={[26, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 26])
                    return <div className={Module.list2}>
                      {fields.map((field, name) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="序号" />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="处罚内容" />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加处罚", add)}
                    </div>
                  }}
                </Form.List>
                <div className={Module.flex}>
                  <p>印章管理：</p>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    className={Module.select1}
                    name={[28, "fieldValue"]} >
                    <Select placeholder="印章"
                    placement="topLeft"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 28, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    >
                      {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <p>生成日期：</p>
                  <Form.Item
                    name={[27, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 27, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="生成时间"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}

