import { CommentItem } from "../page/speak"

class ChatEntity {
    id: number

    realName: string

    chatContent: string

    chatTime: Date

    caseId: number | null = null

    constructor(id: number, realName: string, chatContent: string, chatTime: Date, caseId: number | null) {
        this.caseId = caseId
        this.id = id
        this.chatContent = chatContent
        this.chatTime = chatTime
        this.realName = realName
    }

    toItem(): CommentItem {
        return {
            author: this.realName,
            content: this.chatContent,
            datetime: this.chatTime.toLocaleString(),
        }



    }
}
export default ChatEntity
