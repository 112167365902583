import { message } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCase } from '../internet/ApiCase';
import Evidence from '../entity/Evidence';


const columns: ColumnsType<Evidence> = [
    {
        title: '证据名称',
        dataIndex: 'evidenceName',
        width: 100,
    },
    {
        title: '证据类型',
        dataIndex: 'evidenceType',
        width: 100,
    },
    {
        title: '证据描述',
        dataIndex: 'evidenceDescription',
        width: 200,
    },
];
const Lawfzj = (props: { caseId?: number }) => {
    const [datas, setData] = useState<Evidence[]>([])
    const location = useLocation()
    const navigate = useNavigate()
    const caseId = props.caseId as number
    const apiCase = new ApiCase(message, navigate)
    useEffect(() => {
        if (caseId) {
            apiCase.getEvidence(caseId, {
                successHandler: data => {
                    if (data) {
                        setData(data)
                    }
                }
            })

        }
    }, [location])
    const evidenceChange = (evidence: Evidence) => {
        return {
            onClick: () => {
                navigate("/zjlist", { state: { evidence: evidence, caseId: caseId } })
            }
        }
    }
    return (
        <div>
            <Table
                rowKey={(item) => item.id ?? ""}
                onRow={evidenceChange}
                dataSource={datas}
                columns={columns}
            />
        </div>
    )
}
export default Lawfzj