import Module from '../style/singanar.module.scss'
import { Button, Form, Input, message, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Seal from '../entity/Seal';
import { ApiAdminSeal } from "../internet/ApiAdminSeal";


const ModifySeal: React.FC = () => {
  const location = useLocation()
  const seal = (location.state as { seal?: Seal })?.seal
  const unitId = (location.state as { unitId?: number })?.unitId
  const [imageUrl, setImageUrl] = useState<string>(require("../image/file_not_exist.png"))
  const navigate = useNavigate()
  const [file, setFile] = useState<File>()
  const [form] = Form.useForm<{ sealName: string }>()
  const apiAdminSeal = new ApiAdminSeal(message, navigate)
  const updateImage = (sealId: number) => {
    if (file) {
      apiAdminSeal.updateFile(sealId, file)
    } else {
      message.error("清先选择文件")
    }
  }

  function addSeal(unitId: number) {
    if (file) {
      form.validateFields()
        .then(values => {
          apiAdminSeal.add(values.sealName, file, unitId)
        })
    } else {
      message.error("清先选择文件")
    }

  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('你需要传入PNG格式图片');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('文件格式必须小于2m!');
    }
    if (isJpgOrPng && isLt2M) {
      const imageUrl = URL.createObjectURL(file)
      setImageUrl(imageUrl)
      setFile(file)
    }
    return isJpgOrPng && isLt2M;
  };

  function updateSeal(seal: Seal) {
    form.validateFields()
      .then(values => {
        seal.sealName = values.sealName
        apiAdminSeal.update(seal)
      })
  }

  const onUpload = (_: string | RcFile | Blob) => {
  };
  const getImg = (sealId: number) => {
    apiAdminSeal.getImg(sealId, {
      successHandler: byteArray => {
        if (byteArray) {
          const url = "data:image/png;base64," + byteArray.toString()
          setImageUrl(url)
        }
      }
    })
  }

  function deleteSeal(sealId: number) {
    apiAdminSeal.delete(sealId)
  }

  useEffect(() => {
    if (seal) {
      getImg(seal.id)
      form.setFieldsValue(seal)
    } else if (!unitId) {
      navigate(-1)
    }
  }, [location])
  let btn: JSX.Element;
  let btn1: JSX.Element = <></>;
  let btn2: JSX.Element = <></>;
  if (seal) {
    btn = (
      <Button type="primary" style={{ marginLeft: 30 }} className={Module.addBtn}
        onClick={() => updateImage(seal.id)}>修改印章</Button>)
    btn1 = <Button style={{ float: "right" }} type="primary" className={Module.delectbtn}
      onClick={() => deleteSeal(seal.id)}>删除印章</Button>
    btn2 = <Button type="primary" style={{ marginLeft: 30 }} className={Module.addBtn}
      onClick={() => updateSeal(seal)}>修改名称</Button>
  } else if (unitId) {
    btn = <Button style={{ marginLeft: 30 }} type="primary" className={Module.addBtn}
      onClick={() => addSeal(unitId)}>添加</Button>
  } else {
    btn = <></>
  }
  return (
    <div className={Module.all}>
      <div className={Module.dele}>{btn1}</div>
      <Form layout="inline" className={Module.form} form={form}>
        <Form.Item className={Module.item}
          label="印章名称"
          name={"sealName"}
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className={Module.item1}>
          {btn2}
        </Form.Item>
      </Form>
      <div className={Module.img}>
        <Button type="link">点击下方图片修改</Button>
        <Upload
          accept={"image/png"}
          name="avatar"
          listType="picture-card"
          maxCount={1}
          showUploadList={false}
          customRequest={option => {
            onUpload(option.file)
          }}
          beforeUpload={beforeUpload}
        >
          <img src={imageUrl} alt="" style={{ width: '100%' }} />
        </Upload>
        {btn}
      </div>
    </div>
  );
};

export default ModifySeal;