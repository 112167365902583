import InternetTool from "./Internetool"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import { Template } from "../entity/Template";
export class ApiTemplate {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("template", message, navigate)
  }

  getByid(id: number, resultParam?: ResultParam<Array<Template>>) {
    this.internetTool.fe
      (
        `/${id}`,
        resultParam
      )
  }
  getAll(resultParam?: ResultParam<Array<Template>>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )
  }
}