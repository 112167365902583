import { Button, Form, Input, Select } from "antd"
import Module from '../style/punishRecord.module.scss'
import { MinusOutlined } from "@ant-design/icons";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";

export function PunishRecord(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div >
              <h1 className={Module.h1}>行政处罚听证笔录</h1>
              <div className={Module.center}>
                <div className={Module.flex}>
                  <p>案件名称:</p>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案件名称" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>案由:</p>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案由" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>听证时间:</p>
                  <Form.Item
                    className={Module.item1}
                    name={[2, "fieldValue"]}
                  >
                    <StringDatePicker
                      bordered={false}
                      showTime
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format="YYYY年MM月DD日hh时mm分" placeholder="开始时间"
                    />
                  </Form.Item>
                  <p>至</p>
                  <Form.Item
                    className={Module.item1}
                    name={[3, "fieldValue"]}
                  >
                    <StringDatePicker
                      bordered={false}
                      showTime
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format="hh时mm分" placeholder="结束时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>听证地点:</p>
                  <Form.Item
                    className={Module.item2}
                    name={[4, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="听证地点" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>听证方式:</p>
                  <Form.Item
                    className={Module.item}
                    name={[5, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="听证方式" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>听证主持人：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[6, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="姓名" />
                  </Form.Item>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[7, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[8, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <p style={{ fontWeight: "bolder" }}>听证员及工作单位和职务（2-4名）：</p>
                <Form.List
                  name={[9, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 9])
                    return <div className={Module.list2}>
                      {fields.map((field, name) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="姓名" />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="工作单位" />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[2, "fieldValue"]}
                                >
                                  <Input
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "children", field.name, 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    placeholder="职务" />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加听证员", add)}
                    </div>
                  }}
                </Form.List>

                <div className={Module.flex}>
                  <p>书记员：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[10, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="姓名" />
                  </Form.Item>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[11, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[12, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>翻译人员：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[13, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="姓名" />
                  </Form.Item>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[14, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[15, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>案件承办人：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[16, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="姓名" />
                  </Form.Item>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[17, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[18, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>案件承办人：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[19, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="姓名" />
                  </Form.Item>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[20, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[21, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                </div>
                <p style={{ fontWeight: "bolder" }}>一、当事人基本情况</p>
                <div className={Module.flex}>
                  <p>当事人：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[22, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="当事人姓名" />
                  </Form.Item>
                  <p>法定代表人（负责人）：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[23, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="法定代表人（负责人）" />
                  </Form.Item>
                  <p>性别：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[24, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="性别" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[25, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务或职业：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[26, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务或职业" />
                  </Form.Item>
                  <p>身份证号：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[27, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 27, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="身份证号" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>住址（住所）：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[28, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 28, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="住址（住所）" />
                  </Form.Item>
                  <p>邮编：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[29, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 29, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="邮编" />
                  </Form.Item>
                  <p>联系电话：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[30, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 30, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="联系电话" />
                  </Form.Item>
                </div>
                <br />
                <div className={Module.flex}>
                  <p>委托代理人（可选）：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[31, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 31, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="姓名" />
                  </Form.Item>
                  <p>性别：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[32, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 32, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="性别" />
                  </Form.Item>
                  <p>身份证号：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[33, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 33, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="身份证号" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>工作单位：</p>
                  <Form.Item
                    className={Module.item3}
                    name={[34, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 34, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="工作单位" />
                  </Form.Item>
                  <p>职务：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[35, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 35, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务" />
                  </Form.Item>
                  <p>联系电话：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[36, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 36, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="联系电话" />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>其他参加人：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[37, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="其他参加人" />
                  </Form.Item>

                </div>

                <p style={{ fontWeight: "bolder" }}>一、听证请求：</p>
                <Form.Item
                  className={Module.item}
                  name={[38, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="听证请求" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>二、事实、证据和适用听证程序的行政处罚建议：</p>
                <Form.Item
                  className={Module.item}
                  name={[39, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 39, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="事实、证据和适用听证程序的行政处罚建议" />
                </Form.Item>
                <p style={{ fontWeight: "bolder" }}>三、当事人陈述、申辩、质证意见：</p>
                <Form.Item
                  className={Module.item}
                  name={[40, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 40, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人陈述、申辩、质证意见" />
                </Form.Item>
                <p>听证申请人应当逐页签字确认以上是笔录尾页：有关参加人对听证笔录阅核后，应注明“上述听证笔录内容已阅，记录属实。”并签名。</p>
                <div className={Module.flex}>
                  <p>案件调查人签名：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[42, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 42, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案件调查人签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    name={[41, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 41, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="签名时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>听证主持人签名：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[43, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 43, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="听证主持人签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    name={[41, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 41, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="签名时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>听证员签名：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[44, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 44, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="听证员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    name={[41, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 41, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="签名时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p>书记员签名：</p>
                  <Form.Item
                    className={Module.item4}
                    name={[45, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 45, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="书记员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    name={[41, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 41, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="签名时间"
                    />
                  </Form.Item>
                </div>

              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}

