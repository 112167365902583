import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd"
import Module from '../style/evidencelist.module.scss'
import { DocumentProps } from "../entity/DocumentProps";
import React from "react";
import FieldEntity from "../entity/FieldEntity";

const EvidenceList: React.FC<DocumentProps> = (props) => {

  return (
    <div className={Module.all}>

      <Form
        form={props.form}
        disabled={props.dis}
        className={Module.form}
        onFieldsChange={props.onFieldsChange}
      >
        <h1 className={Module.h1}>证据清单</h1>
        <Form.List name={"fields"}>
          {fields => {
            const fieldEntity = props.form.getFieldValue(["fields", 0])
            const field = fields[0]
            return <div className={Module.center}>
              <div className={Module.top}>
                <div className={Module.top1}>
                  <span>序号</span>
                </div>
                <div className={Module.top2}>
                  <span>证据名称</span>
                </div>
                <div className={Module.top3}>
                  <span>页号</span>
                </div>
                <div className={Module.top4}>
                  <span>备注</span>
                </div>
                <div className={Module.top5}>
                  <Button type="text"></Button>
                </div>
              </div>
              <Form.List name={[field.name, "children"]}>
                {(fields, { add, remove }) => {
                  return <div className={Module.list}>
                    {fields.map(field => {
                      return <Form.List name={field.name}>
                        {fields => {
                          return <div className={Module.buttom}>
                            <div className={Module.buttom1}>
                              <Form.Item
                                {...fields[0]}
                                name={[fields[0].name, "fieldValue"]}
                              >
                                <Input
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", field.name, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                  type={"number"} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom2}>
                              <Form.Item
                                {...fields[3]}
                                name={[fields[3].name, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", field.name, "children", field.name, 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom3}>
                              <Form.Item
                                {...fields[1]}
                                name={[fields[1].name, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", field.name, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom4}>
                              <Form.Item
                                {...fields[2]}
                                name={[fields[2].name, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", field.name, "children", field.name, 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom5}>
                              <Button type="text" onClick={() => remove(field.name)}><CloseOutlined
                                style={{ fontSize: 20 }} /></Button>
                            </div>
                          </div>
                        }}
                      </Form.List>
                    })}
                    {props.genAddButton(fieldEntity, "添加证据", add)}
                  </div>
                }}
              </Form.List>
              <div className={Module.textsig}>
                <p style={{ fontWeight: "bolder" }}>印章管理：
                </p>
                <Form.Item
                  style={{ marginLeft: "2%" }}
                  className={Module.select}
                  name={[1, "fieldValue"]} >
                  <Select
                    placeholder="印章"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  >
                    {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                  </Select>
                </Form.Item>
              </div>
            </div>
          }}
        </Form.List>

      </Form >
    </div>

  )

}


export default EvidenceList