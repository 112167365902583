import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/significantPenaltiesTable.module.scss'
export default function SignificantPenaltiesTable(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>重大行政处罚备案审批表</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row3}>
                    <div className={Module.a1} >
                      <p>案件名称</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[0, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="案件名称" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>行政决定作出部门</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.input}
                        name={[1, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="行政决定作出部门" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>当事人</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.time}>
                        <p>公民</p>
                        <Form.Item
                          name={[2, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 2, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>法人</p>
                        <Form.Item
                          name={[3, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 3, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>其他组织</p>
                        <Form.Item
                          name={[4, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 4, "fieldValue"])}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>当事人地址</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.input}
                        name={[5, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="当事人地址" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>种类</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.input}
                        name={[6, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="种类" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>文书名称编（文）号</p>
                    </div>
                    <div className={Module.d3}>
                      <Form.Item
                        className={Module.input}
                        name={[7, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="文书名称" />
                      </Form.Item>
                      <Form.Item
                        className={Module.input}
                        name={[8, "fieldValue"]}
                      >
                        <Input style={{width:300}} disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="文号" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>报备人员审查意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <p>是否符合报备条件：</p>
                        <p>是</p>
                        <Form.Item
                          name={[9, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 9, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>否</p>
                        <Form.Item
                          name={[10, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 10, "fieldValue"])}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[17, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="主办人员签名">
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[16, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="协办人员签名">
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[11, "fieldValue"]}
                          className={Module.select1}
                        >
                          <StringDatePicker
                            placeholder="签名日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>分管领导审核意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <p>是否符合报备条件：</p>
                        <p>是</p>
                        <Form.Item
                          name={[12, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 12, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>否</p>
                        <Form.Item
                          name={[13, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 13, "fieldValue"])}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[18, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="分管领导签名">
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[11, "fieldValue"]}
                          className={Module.select1}
                        >
                          <StringDatePicker
                            placeholder="签名日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>主要领导审定意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <p>是否符合报备条件：</p>
                        <p>是</p>
                        <Form.Item
                          name={[14, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 14, "fieldValue"])}
                          />
                        </Form.Item>
                        <p>否</p>
                        <Form.Item
                          name={[21, "fieldValue"]}
                          initialValue="⬜"
                          className={Module.item}
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 21, "fieldValue"])}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[20, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="主要领导签名">
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[11, "fieldValue"]}
                          className={Module.select1}
                        >
                          <StringDatePicker
                            placeholder="签名日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                        <Form.Item
                          className={Module.select1}
                          name={[19, "fieldValue"]} >
                          <Select placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>


                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>备注</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[15, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="备注" className={Module.text} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}