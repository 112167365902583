import InternetTool from "./Internetool"
import DictionaryType from "../entity/DictionaryType"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiAdminDictionaryType {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/dictionary_type", message, navigate)
  }
  getDictionaryTypes(resultParam?: ResultParam<Array<DictionaryType>>) {
    this.internetTool.ft(
      "",
      {
        method: "GET"
      },
      resultParam
    )

  }
  getDictionaryType(typeId: number, resultParam?: ResultParam<DictionaryType>) {
    this.internetTool.ft(
      `/${typeId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  updateDictionaryType(type: DictionaryType, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("dictionaryType", new Blob([JSON.stringify(type)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  deleteDictionaryType(typeId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${typeId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  addDictionaryType(dictionaryType: DictionaryType, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("dictionaryType", new Blob([JSON.stringify(dictionaryType)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
}