import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, message, Tabs } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Module from '../style/users.module.scss'
import UserEntity from "../entity/UserEntity";
import TypeList from "../page/TypeList";
import { useSessionStorageState } from "ahooks"
import ApiAdminUser from "../internet/ApiAdminUser";

const { TabPane } = Tabs;

export interface Options {
  label: string,
  value: string | number

}

const Users: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [users, setUsers] = useState<UserEntity[]>([])
  const [activeKey, setActiveKey] = useSessionStorageState<string>("user-config-active-key",
    { defaultValue: "1" })
  const apiAdminUser = new ApiAdminUser(message, navigate)
  const columns: ColumnsType<UserEntity> = [
    {
      title: '姓名',
      dataIndex: 'realName',
      width: 80,
      ellipsis: true
    },
    {
      title: '用户名',
      dataIndex: 'username',
      width: 60,
      ellipsis: true
    },
    {
      title: '身份证号',
      dataIndex: 'identificationCard',
      width: 100,
      ellipsis: true
    },
    {
      title: '手机号',
      dataIndex: 'mobilePhone',
      width: 80,
      ellipsis: true
    },
    {
      title: '执法证号',
      dataIndex: 'lawEnforcementCard',
      width: 100,
      ellipsis: true

    },
    {
      title: '操作',
      dataIndex: '',
      render: (_, user) => <div><Button type="link" onClick={(e) => {
        updateRoles(e, user)
      }}>修改权限</Button>
        <Button type="link" onClick={(e) => {
          updateSignature(e, user.id)
        }}>修改签名</Button>
        <Button type="link" onClick={(e) => {
          updatePassword(e, user.id)
        }}>修改密码</Button>
      </div>,
      width: 240,
      align: "center"
    },
  ];

  function getUsers() {
    apiAdminUser.getUsers({
      successHandler: newUsers => {
        const newUsers1 = newUsers?.map(user => UserEntity.create(user))
          .filter(user => user)
        setUsers(newUsers1 as UserEntity[])
      }
    })
  }

  useEffect(() => {
    getUsers()
  }, [location])

  const updateRoles = (e: React.MouseEvent<HTMLElement, MouseEvent>, user: UserEntity) => {
    e.stopPropagation()
    navigate("/chomod", { state: { uid: user.id } })
  }
  const updateSignature = (e: React.MouseEvent<HTMLElement, MouseEvent>, uid: number) => {
    e.stopPropagation()
    navigate("/singnar", { state: { uid: uid } })
  }
  const updatePassword = (e: React.MouseEvent<HTMLElement, MouseEvent>, uid: number) => {
    e.stopPropagation()
    navigate("/repasswordusers", { state: { uid: uid } })
  }

  const addUser = () => {
    navigate("/userlist")
  }

  const updateUser = (user: UserEntity) => {
    return {
      onClick: () => {
        navigate("/userinfo", { state: { user: user } })
      }
    }
  }

  const onChangeActiveKey = (activeKey: string) => {
    setActiveKey(activeKey)
  }


  return (
    <div className={Module.all}>
      <Card className={Module.card}>
        <Tabs className={Module.tab} activeKey={activeKey.toString()} onChange={onChangeActiveKey}
          tabPosition="left"
          size="middle">
          <TabPane tab="用户设置" key="1">
            <div className={Module.ta}>
              <Form
                className={Module.form}
              >
                <Button onClick={addUser}><PlusOutlined />添加</Button>
              </Form>
              <Table
                className={Module.table1}
                rowKey={record => record.id}
                size="small"
                pagination={{ defaultPageSize: 10 }}
                columns={columns}
                dataSource={users}
                onRow={updateUser}
              />
            </div>
          </TabPane>
          <TabPane tab="权限设置" key="2"><TypeList /></TabPane>
        </Tabs>
      </Card>
    </div>
  )
}
export default Users