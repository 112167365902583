import { AutoComplete, Button, Form, Input, message } from "antd"
import Module from '../style/liveReconnaissanceRecord.module.scss'
import React, { useEffect, useState } from "react";
import { MinusOutlined } from "@ant-design/icons";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { ApiAdminDictionaryType } from "../internet/ApiAdminDictionaryType";
import { useLocation, useNavigate } from "react-router-dom";
import DictionaryType from "../entity/DictionaryType";

const LiveReconnaissanceRecord: React.FC<DocumentProps> = (props) => {
  const navegate = useNavigate()
  const location = useLocation()
  const [types, setTypes] = useState<DictionaryType[]>([])
  const apiAdminDictionaryType = new ApiAdminDictionaryType(message, navegate)
  const getTypes = () => {
    apiAdminDictionaryType.getDictionaryTypes({
      successHandler: newTypes => {
        if (newTypes != null) {
          setTypes(newTypes)
        }
      }
    })
  }
  useEffect(() => {
    getTypes()
  }, [location])

  function optionAsk(dictionary: DictionaryType[]) {
    return dictionary.map(dictionarys => {
      if (dictionarys.typeName === "询问笔录问") {
        if (dictionarys.dictionaries) {
          return dictionarys.dictionaries.map(d => {
            return d.dictionaryValue
          })
        }
      }
    })
  }
  function optionAnswer(dictionary: DictionaryType[]) {
    return dictionary.map(dictionarys => {
      if (dictionarys.typeName === "询问笔录答") {
        if (dictionarys.dictionaries) {
          return dictionarys.dictionaries.map(d => {
            return d.dictionaryValue
          })
        }

      } else {
        return []
      }
    })
  }
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div >
              <h1 className={Module.h1}>现场检查（勘查）笔录</h1>
              <div className={Module.center}>
                <div className={Module.text1}>
                  <div className={Module.textsig1}>
                    <p >检查（勘验）时间：
                    </p>
                    <Form.Item
                      name={[0, "fieldValue"]} style={{ marginRight: 0 }} className={Module.select1} >
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        className={Module.time} showTime placeholder="开始时间" format="YYYY年MM月DD日hh时mm分" />
                    </Form.Item>
                    <Form.Item
                      name={[1, "fieldValue"]} className={Module.select1}>
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        className={Module.time} showTime placeholder="结束时间" format="DD日hh时mm分" />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p >检查（勘验）地点：
                    </p>
                    <Form.Item
                      name={[2, "fieldValue"]} className={Module.select1} >
                      <Input placeholder="检查（勘验）地点"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p >检查（勘验）内容：
                    </p>
                    <Form.Item
                      name={[3, "fieldValue"]} className={Module.select1} >
                      <Input placeholder="检查（勘验）内容"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.people}>
                    <div className={Module.textsig}>
                      <p>执法人员：</p>
                      <Form.Item
                        name={[4, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="执法人员姓名"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.textsig}>
                      <p>执法证号：</p>
                      <Form.Item
                        name={[5, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="执法证号"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.people}>
                    <div className={Module.textsig}>
                      <p>执法人员：</p>
                      <Form.Item
                        name={[6, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="执法人员姓名"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.textsig}>
                      <p>执法证号：</p>
                      <Form.Item
                        name={[7, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="执法证号"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.people}>
                    <div className={Module.textsig}>
                      <p>见证人（可选）：</p>
                      <Form.Item
                        name={[8, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="见证姓名"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.textsig}>
                      <p>身份证号：</p>
                      <Form.Item
                        name={[9, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="身份证号"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.people}>
                    <div className={Module.textsig}>
                      <p>住所：</p>
                      <Form.Item
                        name={[10, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="住所"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.textsig}>
                      <p>联系电话：</p>
                      <Form.Item
                        name={[11, "fieldValue"]} className={Module.select1} >
                        <Input placeholder="联系电话"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <p className={Module.p1}>一、被检查对象基本情况</p>

                  <div className={Module.ite}>
                    <Form.Item
                      name={[12, "fieldValue"]}
                      className={Module.item1}
                      initialValue="⬜"
                    >
                      <Input readOnly
                        onClick={() => props.changeCheckbox(["fields", 12, "fieldValue"])}
                      />
                    </Form.Item>
                    <p className={Module.p2}>被检查（勘验）单位基本信息：</p>
                  </div>
                  <div>
                    <div className={Module.people}>
                      <div className={Module.textsig}>
                        <p>单位名称：</p>
                        <Form.Item
                          name={[13, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）单位名称"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.textsig}>
                        <p>法定代表人：</p>
                        <Form.Item
                          name={[14, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="法定代表人（负责人）姓名"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people}>
                      <div className={Module.textsig}>
                        <p>住所：</p>
                        <Form.Item
                          name={[15, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="住所"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.textsig}>
                        <p>统一社会信用代码：</p>
                        <Form.Item
                          name={[16, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="统一社会信用代码"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.textsig}>
                      <p>联系电话：</p>
                      <Form.Item
                        name={[17, "fieldValue"]} className={Module.select1} >
                        <Input style={{ width: "50%" }} placeholder="联系电话"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <p className={Module.p2}>现场负责人（可选）：</p>
                    <div className={Module.textsig}>
                      <p>姓名：</p>
                      <Form.Item
                        name={[18, "fieldValue"]} className={Module.select1} >
                        <Input style={{ width: "50%" }} placeholder="现场负责人姓名"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <p className={Module.p2}>在场人（可选）：</p>
                    <div className={Module.people}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="姓名"
                          name={[19, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="在场人姓名"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="职务"
                          name={[20, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="职务"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people}>
                      <div style={{ width: "100%" }} className={Module.textsig}>
                        <Form.Item
                          label="身份证号码"
                          style={{ width: "100%" }}
                          name={[21, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="身份证号码"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ width: "100%" }}
                          label="工作岗位"
                          name={[22, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="工作岗位"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.ite}>
                    <Form.Item
                      name={[23, "fieldValue"]}
                      className={Module.item1}
                      initialValue="⬜"
                    >
                      <Input readOnly
                        onClick={() => props.changeCheckbox(["fields", 23, "fieldValue"])}
                      />
                    </Form.Item>
                    <p className={Module.p2}>被检查（勘验）人基本信息：</p>
                  </div>
                  <div>
                    <div className={Module.people}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="姓名"
                          name={[24, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人姓名"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="性别"
                          name={[25, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人性别"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.textsig}>
                        <p>职业：</p>
                        <Form.Item
                          name={[26, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人职业"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people}>
                      <div className={Module.textsig}>
                        <p>身份证号码：</p>
                        <Form.Item
                          name={[27, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人身份证号码"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 27, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.textsig}>
                        <p>工作单位</p>
                        <Form.Item
                          name={[28, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人工作单位"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 28, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people1}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="联系电话"
                          name={[29, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人联系电话"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 29, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="住址"
                          name={[30, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人住址"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 30, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="邮编"
                          name={[31, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="被检查（勘验）人邮编"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 31, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <p className={Module.p2}>二、见证人基本情况（可选）：</p>
                  <div>
                    <div className={Module.people1}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="见证人姓名"
                          name={[8, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人姓名"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />

                        </Form.Item>
                        <Form.Item
                          label="性别"
                          name={[32, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人性别"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 32, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="职业"
                          name={[33, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人职业"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 33, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people1}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="身份证号码"
                          name={[9, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人身份证号码"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />

                        </Form.Item>
                        <Form.Item
                          label="工作单位"
                          name={[34, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人工作单位"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 34, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="联系电话"
                          name={[11, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人联系电话"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people1}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="住址"
                          name={[10, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="见证人住址"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                        <Form.Item
                          label="邮编"
                          name={[35, "fieldValue"]} className={Module.select1} >
                          <Input placeholder="邮编"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <p className={Module.p1}>三、告知事项：</p>
                    <Form.List name={[36, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 36])
                        return (<div className={Module.list}>
                          {fields.map((field) => (
                            <Form.List name={[field.name]}>
                              {fields => (
                                <div className={Module.list1}>
                                  <Form.Item
                                    label="问"
                                    name={[0, "fieldValue"]}
                                    className={Module.item}
                                  >
                                    <AutoComplete
                                      dataSource={optionAsk(types)[0]}
                                      children={<Input />}
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 36, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    />
                                  </Form.Item>
                                  <div className={Module.list2}>
                                    <Form.Item
                                      label="答"
                                      name={[1, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAnswer(types)[1]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 36, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                      style={{ fontSize: 20 }} /></Button>
                                  </div>
                                </div>
                              )}
                            </Form.List>
                          ))}
                          {props.genAddButton(fieldEntity, "添加问答", add)}
                        </div>)
                      }}
                    </Form.List>
                    <p className={Module.p1}>四、检查（勘验）有关情况：</p>
                    <Form.List name={[37, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 37])
                        return (<div className={Module.list2}>
                          < div className={Module.context}>
                            {fields.map((field) => (
                              <Form.List name={[field.name]}>
                                {fields => (
                                  <div className={Module.list3}>
                                    <Form.Item
                                      label="检查（勘验）有关情况"
                                      name={[0, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <Input.TextArea className={Module.input}
                                        autoSize={{ minRows: 2, maxRows: 20 }}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 37, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                      style={{ fontSize: 20 }} /></Button>
                                  </div>
                                )}
                              </Form.List>
                            ))}
                            {props.genAddButton(fieldEntity, "添加勘验情况", add)}
                          </div>
                        </div>)
                      }}
                    </Form.List>
                    <p className={Module.p1}>五、询问内容（可选）：</p>
                    <Form.List name={[38, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 38])
                        return (<div className={Module.list}>
                          <>
                            {fields.map((field) => (
                              <Form.List name={[field.name]}>
                                {fields => (
                                  <div className={Module.list1}>
                                    <Form.Item
                                      label="问"
                                      name={[0, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAsk(types)[0]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <div className={Module.list2}>
                                      <Form.Item
                                        label="答"
                                        name={[1, "fieldValue"]}
                                        className={Module.item}
                                      >
                                        <AutoComplete
                                          dataSource={optionAnswer(types)[1]}
                                          children={<Input />}
                                          disabled={props.getPermission((props.form.getFieldValue(["fields", 38, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        />
                                      </Form.Item>
                                      <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                        style={{ fontSize: 20 }} /></Button>
                                    </div>
                                  </div>
                                )}
                              </Form.List>
                            ))}
                            {props.genAddButton(fieldEntity, "添加问答", add)}
                          </>
                        </div>)
                      }}
                    </Form.List>
                    <p className={Module.p1}>六、告知权利：</p>
                    <Form.List name={[39, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 39])
                        return (<div className={Module.list}>
                          <>
                            {fields.map((field) => (
                              <Form.List name={[field.name]}>
                                {fields => (
                                  <div className={Module.list1}>
                                    <Form.Item
                                      label="问"
                                      name={[0, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAsk(types)[0]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 39, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <div className={Module.list2}>
                                      <Form.Item
                                        label="答"
                                        name={[1, "fieldValue"]}
                                        className={Module.item}
                                      >
                                        <AutoComplete
                                          dataSource={optionAnswer(types)[1]}
                                          children={<Input />}
                                          disabled={props.getPermission((props.form.getFieldValue(["fields", 39, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        />
                                      </Form.Item>
                                      <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                        style={{ fontSize: 20 }} /></Button>
                                    </div>
                                  </div>
                                )}
                              </Form.List>
                            ))}
                            {props.genAddButton(fieldEntity, " 添加问答", add)}
                          </>
                        </div>)
                      }}
                    </Form.List>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}


export default LiveReconnaissanceRecord