import { Form, Input, Select } from "antd"
import Module from '../style/punishHearingNotice.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function PunishHearingNotice(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>行政处罚听证通知书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[1, "fieldValue"]}
                >
                  <Input
                    placeholder="当事人"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[7, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 20 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="听证内容及听证员"
                  />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[8, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 20 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="听证内容"
                  />
                </Form.Item>
                <p>1.当事人可亲自参加听证，也可以委托1-2名代理人参加听证。委托代理人参加听证的，应在听证举行前提交由当事人或当事人的法定代表人签署的授权委托书，载明委托的事项、权限和期限。</p>
                <p>2.参加听证时应携带当事人或委托代理人的身份证明原件及其复印件和有关证据材料。</p>
                <p>3.当事人有证人出席作证的，应通知有关证人出席作证，并事先告知本机关联系人。</p>
                <div className={Module.flex}>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                    label="联系人"
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="联系人"
                    />
                  </Form.Item>
                  <Form.Item
                    className={Module.item}
                    name={[3, "fieldValue"]}
                    label="联系电话"
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="联系电话"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <Form.Item
                    className={Module.item2}
                    name={[9, "fieldValue"]}
                    label="单位地址"
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="单位地址"
                    />
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p style={{ fontWeight: "bolder" }}>行政执法主体名称：</p>
                  <Form.Item
                    style={{ width: "30%" }}
                    name={[4, "fieldValue"]}>
                    <Select placeholder="行政执法主体名称"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    >
                      {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", }}
                      name={[5, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      className={Module.select1}
                      style={{ width: "60%", }}
                      name={[6, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

