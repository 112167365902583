import { DeleteOutlined } from "@ant-design/icons"
import { Button, Form, Input, message } from "antd"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Module from '../style/userinfo.module.scss'
import UserEntity from "../entity/UserEntity";
import ApiAdminUser from "../internet/ApiAdminUser"

const UserInfo: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = (location.state as { user: UserEntity }).user
  const [form] = Form.useForm<UserEntity>();
  const apiAdminUser = new ApiAdminUser(message, navigate)
  const updateUser = () => {
    form.validateFields()
      .then(user1 => {
        const newUser = new UserEntity(user.id, user1.realName, user1.username, user1.mobilePhone, user1.identificationCard, user1.lawEnforcementCard)
        apiAdminUser.updateUser(newUser)
      })
  }

  function deleteUser() {
    apiAdminUser.deleteUser(user.id)
  }

  useEffect(() => {
    form.setFieldsValue(user)
  }, [location])





  return (
    <div className={Module.all}>
      <Form
        name='用户信息'
        form={form}
        className={Module.form}
      >
        <h1>用户信息</h1>
        <Form.Item
          name="realName"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="&emsp;&emsp;姓名">
          <Input />
        </Form.Item>
        <Form.Item
          name="username"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="&emsp;用户名">
          <Input />
        </Form.Item>
        <Form.Item
          name="identificationCard"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="身份证号"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mobilePhone"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="&emsp;手机号">
          <Input />
        </Form.Item>
        <Form.Item
          name="lawEnforcementCard"
          className={Module.item}
          rules={[
            {
              required: true,
              message: '该字段不能为空',
            }
          ]}
          label="执法证号">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className={Module.btn1} type="primary" onClick={deleteUser}><DeleteOutlined />删除</Button>
          <Button className={Module.btn} type="primary" onClick={updateUser}>确认修改</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default UserInfo