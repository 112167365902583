import { Button, message, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ApiDocument from "../internet/ApiDocument"
import { ApiDocumentType } from "../internet/ApiDocumentType"
import DocumentInfo from "../entity/documentinfo"
import DocumentTypeEntity from "../entity/DocumentTypeEntity"
import Module from '../style/WritList.module.scss'
import DocumentEntity from "../entity/DocumentEntity"


const AddAccessories = () => {
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([])
  const [documentInfos, setDocumentInfos] = useState<DocumentInfo[]>([])
  const navigate = useNavigate()
  const apiDocument = new ApiDocument(message, navigate)
  const apiDocumentType = new ApiDocumentType(message, navigate)
  const location = useLocation()
  const caseId = (location.state as { caseId?: number })?.caseId
  const infos = (location.state as { infos?: DocumentInfo[] })?.infos
  const masterId = (location.state as { masterId?: number })?.masterId
  const documentId = (location.state as { documentId?: number })?.documentId
  const document = (location.state as { document?: DocumentEntity })?.document
  function getInfos() {
    if (documentId) {
      apiDocument.getAttachmentInfos(documentId, {
        successHandler: infos => {
          if (infos) {
            setDocumentInfos(infos)
          }
        }
      })
    }
  }
  function getDocumentTypes() {
    if (masterId) {
      apiDocumentType.getSubDocumentType(masterId, {
        successHandler: types => {
          if (types) {
            setDocumentTypes(types)
          }
        }
      })
    }

  }


  useEffect(() => {
    getInfos()
    getDocumentTypes()
  }, [location])

  function compareStatus(status: string) {
    if (status === "已完成") {
      return 0
    } else if (status === "已审批") {
      return 1
    }
    else if (status === "审批中") {
      return 2
    }
    else if (status === "未审批") {
      return 3
    }
    else {
      return 4
    }
  }
  const filter = (record: DocumentTypeEntity) => {
    let flag
    const documentInfos1 = documentInfos.filter(info => (info.typeId === record.id || info.documentName === record.typeName))
    const status = documentInfos1.map(info => {
      return info.status
    }).sort((a, b) => {
      return compareStatus(b) - compareStatus(a)
    })

    if (!status.length) {
      flag = <span className={Module.span1} style={{ marginRight: 10, }} ><span className={Module.text1}></span></span>
    } else {
      if (status[0] === "已完成") {
        flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "#8eb855" }} >
          <span className={Module.text}>{status.length}</span>
        </span>
      }
      else {
        flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "#d81322" }} >
          <span className={Module.text}>{status.length}</span>
        </span>
      }
    }
    return (<span>{flag}{record.typeName}</span>)
  }
  const columns: ColumnsType<DocumentTypeEntity> = [
    {
      title: "",
      dataIndex: "typeName",
      render: (value: any, record: DocumentTypeEntity, index: number) => filter(record)
    }
  ]


  const onClick = (documentType: DocumentTypeEntity) => {
    return {
      onClick: () => {
        navigate("/wenshu", {
          state: {
            documentTypeId: documentType.id,
            caseId: caseId,
            infos: infos,
            menu: document,
            masterId: documentId
          }
        })
      }
    }
  }
  function uploadDocument() {
    navigate("/uploaddocument", { state: { masterId: masterId, documentId: documentId, caseId: caseId } })
  }
  return (
    <div className={Module.all}>
      <div>
        <Tag color="green">已生成</Tag>
        <Tag color="red">未生成</Tag>
      </div>
      <div className={Module.center}>
        <Table
          size="small"
          columns={columns}
          pagination={false}
          dataSource={documentTypes}
          rowKey="id"
          onRow={onClick}
        />
        <Button type="primary" style={{ marginTop: 50, float: "right" }} onClick={uploadDocument}>上传附件</Button>
      </div>
    </div>
  )
}

export default AddAccessories