import { Form, Input, Select } from "antd"
import Module from '../style/saveEvidenceBookNotice.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function SaveEvidenceBookNotice(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>先行登记保存证据物品处理通知书（决定书）</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[1, "fieldValue"]}
                >
                  <Input
                    placeholder="当事人"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[18, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="保存证据内容"
                  />
                </Form.Item>
                <div className={Module.input}>
                  <Form.Item
                    name={[2, "fieldValue"]}
                    className={Module.item1}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 2, "fieldValue"])} />
                  </Form.Item>
                  <div className={Module.flex}>
                    <p style={{ fontWeight: "bolder" }}>于 </p>
                    <Form.Item
                      className={Module.item1}
                      name={[3, "fieldValue"]}
                    >
                      <StringDatePicker
                        bordered={false}
                        className={Module.date}
                        format={"YYYY年MM月DD日"}
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="开始期限" />
                    </Form.Item>
                    <p>至</p>
                    <Form.Item
                      className={Module.item1}
                      name={[4, "fieldValue"]}
                    >
                      <StringDatePicker
                        bordered={false}
                        className={Module.date}
                        format={"YYYY年MM月DD日"}
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="截止期限" />
                    </Form.Item>
                    <p>日送鉴定机构鉴定。</p>
                  </div>
                </div>
                <div className={Module.input}>
                  <Form.Item
                    name={[5, "fieldValue"]}
                    className={Module.item1}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])} />
                  </Form.Item>
                  <p>退还当事人。</p>
                </div>
                <div className={Module.input}>
                  <Form.Item
                    name={[6, "fieldValue"]}
                    className={Module.item1}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 6, "fieldValue"])} />
                  </Form.Item>
                  <div className={Module.flex}>
                    <p style={{ fontWeight: "bolder" }}>随案件移送 </p>
                    <Form.Item
                      className={Module.item2}
                      name={[7, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="移送地点" />
                    </Form.Item>
                    <p>处理。</p>
                  </div>

                </div>
                <div className={Module.input}>
                  <Form.Item
                    name={[8, "fieldValue"]}
                    className={Module.item1}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 8, "fieldValue"])} />
                  </Form.Item>
                  <div className={Module.flex}>
                    <p style={{ fontWeight: "bolder" }}>其他</p>
                    <Form.Item
                      className={Module.item2}
                      name={[9, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="其他" />
                    </Form.Item>
                  </div>

                </div>

                <div className={Module.time}>
                  <p style={{ fontWeight: "bolder" }}>执法人员签名：</p>
                  <Form.Item
                    className={Module.item}
                    name={[15, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主办人员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={Module.item}
                    name={[16, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="协办人员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <div className={Module.time}>
                    <p style={{ fontWeight: "bolder" }}>联系人： </p>
                    <Form.Item
                      className={Module.item1}
                      name={[10, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="联系人" />
                    </Form.Item>
                  </div>
                  <div className={Module.time}>
                    <p style={{ fontWeight: "bolder" }}>联系电话： </p>
                    <Form.Item
                      className={Module.item1}
                      name={[11, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="联系电话" />
                    </Form.Item>
                  </div>
                </div>

                <div className={Module.time}>
                  <p style={{ fontWeight: "bolder" }}>单位地址： </p>
                  <Form.Item
                    className={Module.item2}
                    name={[12, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="单位地址" />
                  </Form.Item>
                </div>

                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>行政执法主体名称：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[13, "fieldValue"]}>
                      <Select placeholder="行政执法主体名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[14, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[17, "fieldValue"]} >
                      <Select placeholder="印章"
                        placement="topRight"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

