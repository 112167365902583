import { message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCase } from "../internet/ApiCase";
import CaseSimpleEntity from "../entity/CaseSimpleEntity";
import moment from "moment";
import ApiDocument from "../internet/ApiDocument";


const AllList: React.FC<{ setCaseId: (caseId: number | undefined) => void }> = (props) => {
    const [data, setData] = useState<CaseSimpleEntity[]>([])
    const [length, setLenth] = useState<number | undefined>()
    const location = useLocation()
    const navigate = useNavigate()
    const apiCase = new ApiCase(message, navigate)
    const apiDocument = new ApiDocument(message, navigate)
    function lengths(caseId: number | undefined) {
        apiDocument.getInfos(caseId, {
            successHandler: infos => {
                if (infos) {
                    setLenth(infos.length)
                }
            }
        })
    }
    const columns: ColumnsType<CaseSimpleEntity> = [
        // {
        //     width: 80,
        //     title: "文书个数",
        //     align: "center",
        //     render: (value: any, record: CaseSimpleEntity) => {
        //         lengths(record.id)
        //         return <span>{length}</span>

        //     }
        // },
        {
            title: "案件编号",
            dataIndex: 'caseCode',
            width: 80,
            align: "center"
        },
        {
            title: '案件来由',
            dataIndex: 'caseCause',
        },
        {
            title: '立案时间',
            dataIndex: 'caseRegisterTimer',
            render: (value: any, record: CaseSimpleEntity) => {
                if (record.caseRegisterTimer) {
                    const d = record.caseRegisterTimer
                    return moment(d, 'YYYY-MM-DD ').format('YYYY-MM-DD ')
                }
                return undefined
            }
        },
        {
            title: '主办人员',
            dataIndex: 'handleName',
        },
        {
            title: '协办人员',
            dataIndex: 'handleSubName',
        },
        {
            title: '实际案情',
            dataIndex: 'mainCase',
            width: 200,
            ellipsis: true
        },

    ];

    const rowClick = (caseId: CaseSimpleEntity) => {
        return {
            onClick: () => {
                navigate("/law", { state: { caseId: caseId.id, value: true } })
                props.setCaseId(caseId.id)
            }
        }
    }
    useEffect(() => {
        apiCase.getSimpleUnfinished({
            successHandler: data => {
                if (data) {
                    setData(data)
                }
            }
        })

    }, [location])

    return (
        <Table
            columns={columns}
            rowKey="id"
            onRow={rowClick}
            dataSource={data}
            bordered
        />
    )
}
export default AllList