import { useEffect, useState } from "react"

import moment from "moment";


const Clock: React.FC = () => {
    const [now, setNow] = useState(moment())
    useEffect(() => {
        const t = setInterval(() => {
            setNow(moment())
        }, 1000)
        return () => {
            clearTimeout(t)
        }
    }, [])
    return (
        <div >
            <span style={{ fontSize: 15, marginRight: 10 }} key={1} >{now.format('YYYY/MM/DD')}</span>
            <span style={{ fontSize: 15 }} key={2} >{now.format('HH:mm:ss')}</span>
        </div>
    )

}
export default Clock