import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentTypeEntity from "../entity/DocumentTypeEntity";
import { ApiDocumentType } from "../internet/ApiDocumentType";
const columns: ColumnsType<DocumentTypeEntity> = [
  {
    title: "附件名称",
    dataIndex: "typeName",
  }
]
export default function Accessories(): JSX.Element {
  const navigate = useNavigate()
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([])
  const apiDocumentType = new ApiDocumentType(message, navigate)
  const location = useLocation()
  const masterId = (location.state as { masterId?: number })?.masterId
  function getDocumentTypes(masterId: number) {
    apiDocumentType.getSubDocumentType(masterId, {
      successHandler: type => {
        if (type) {
          setDocumentTypes(type)
        }
      }
    })
  }
  const onClick = (documentType: DocumentTypeEntity) => {
    return {
      onClick: () => {
        navigate("/wordsee", { state: { documentTypeId: documentType.id } })
      }
    }
  }
  const addWord = () => {
    navigate("/wordmain", { state: { masterId: masterId } })
  }
  useEffect(() => {
    if (masterId) {
      getDocumentTypes(masterId)
    }
  }, [location])
  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        rowKey="id"
        dataSource={documentTypes}
        size="small"
        onRow={onClick}
      />

      <Button onClick={addWord} type='primary'>增加</Button>
    </div>
  )

}