class BaseTypes {
  str = 1
  inter = 2
  date = 3
  dateYear = 4
  dateMonth = 5
  dateDay = 6
  list = 7
  decimals = 8
  seal = 9
  signature = 10
  checkBox = 11
  textarea = 12
  radio = 13
  radioParent = 14
}
export default BaseTypes