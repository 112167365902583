import { Button, Form, Input, Select } from "antd"
import Module from '../style/administrativePenaltyDecision.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { MinusOutlined } from "@ant-design/icons";
export default function AdministrativePenaltyDecision(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>行政处罚决定书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[12, "fieldValue"]}
                  >
                    <Input
                      className={Module.input}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[0, "fieldValue"]}
                >
                  <Input
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人" />
                </Form.Item>
                <div className={Module.time}>
                  <p style={{ textIndent: 30 }} >本机关于</p>
                  <Form.Item
                    className={Module.item1}
                    name={[1, "fieldValue"]}
                  >
                    <StringDatePicker
                      bordered={false}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="调查时间"
                    />
                  </Form.Item>
                  <p>对</p>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案件名称"
                    />
                  </Form.Item>
                  <p>进行调查</p>
                </div>
                <Form.Item
                  className={Module.item}
                  name={[3, "fieldValue"]}
                >
                  <Input.TextArea className={Module.textarea}
                    autoSize={{ minRows: 2, maxRows: 30 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  />
                </Form.Item>

                <Form.Item
                  className={Module.item}
                  name={[4, "fieldValue"]}
                >
                  <Input.TextArea className={Module.textarea} rows={3}

                    autoSize={{ minRows: 2, maxRows: 30 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  />
                </Form.Item>

                <p className={Module.a1}>行政处罚：</p>
                <Form.List
                  name={[5, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 5])
                    return <div className={Module.list}>
                      {fields.map((field, index) => {
                        return <div className={Module.list1}>
                          <Form.List name={field.name}>
                            {fields => (
                              < div className={Module.context1}>
                                <Form.Item
                                  name={[0, "fieldValue"]}
                                  className={Module.page}
                                  initialValue={index + 1}
                                >
                                  <Input placeholder="序号"
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="："
                                  className={Module.item}
                                  name={[1, "fieldValue"]}
                                >
                                  <Input placeholder="处罚内容"
                                    disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                  />
                                </Form.Item>
                                <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                  style={{ fontSize: 20 }} /></Button>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      })}
                      {props.genAddButton(fieldEntity, "添加处罚", add)}
                    </div>
                  }}
                </Form.List>
                <p className={Module.a1}>如有罚款：</p>
                <Form.Item
                  className={Module.item}
                  name={[6, "fieldValue"]}
                  initialValue="你单位应当自收到本决定书之日起15日内将罚款缴至攀枝花农商银行月潭分理处（账号：88040120001492802）。到期不缴纳罚款的，依据《中华人民共和国行政处罚法》第七十二条第一款第（一）项的规定，每日按罚款数额的百分之三加处罚款，加处罚款的数额不得超出罚款的数额"
                >
                  <Input.TextArea className={Module.textarea}
                    autoSize={{ minRows: 2, maxRows: 30 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  />
                </Form.Item>
                <p>你单位如不服本决定，可以自收到本决定书之日起60日内申请行政复议</p>
                <div className={Module.time}>
                  <Form.Item
                    label="行政复议单位名称"
                    className={Module.item}
                    name={[7, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="行政复议单位名称"
                    />
                  </Form.Item>
                </div>
                <p>也可以自收到本决定书之日起6个月内依法提起行政诉讼</p>
                <div className={Module.time}>
                  <Form.Item
                    label="行政诉讼单位名称"
                    className={Module.item}
                    name={[8, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="行政诉讼单位名称"
                    />
                  </Form.Item>
                </div>
                <p>逾期不申请行政复议，也不提起行政诉讼，又不履行本处罚决定的，本机关将依法申请人民法院强制执行。</p>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位名称：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[9, "fieldValue"]}>
                      <Select placeholder="单位名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.id.toString()}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[10, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日"
                        placement="topLeft"
                        placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[11, "fieldValue"]} >
                      <Select placeholder="印章"
                        placement="topLeft"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}
                        >{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}


