import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/blacklistManagementConfirmation.module.scss'
export default function BlacklistManagementConfirmation(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>纳入联合惩戒对象“黑名单”管理确认书</h1>
              <div className={Module.center}>
                <div className={Module.case1}>
                  <div className={Module.casenumber}>
                    <p >填报单位：</p>
                    <Form.Item
                      className={Module.item}
                      name={[0, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="填报单位" />
                    </Form.Item>
                  </div>
                  <div className={Module.casenumber}>
                    <p >填报时间：</p>
                    <Form.Item
                      className={Module.item}
                      name={[1, "fieldValue"]}
                    >
                      <StringDatePicker
                        bordered={false}
                        format="YYYY年MM月DD日"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="填报时间" />
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.table}>
                  <div className={Module.row3}>
                    <div className={Module.a1} >
                      <p>法人或组织名称（自然人姓名）</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[2, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="法人或组织名称（自然人姓名）" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>统一社会信用代码/公民身份证号码</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.input}
                        name={[3, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="法人或组织名称（自然人姓名）" />
                      </Form.Item>
                    </div>
                    <div className={Module.d3}>
                      <p>经营范围</p>
                    </div>
                    <div className={Module.d4}>
                      <Form.Item
                        className={Module.input}
                        name={[4, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="经营范围" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>法定代表人（主要负责人）姓名</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.input}
                        name={[5, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="法定代表人（主要负责人）姓名" />
                      </Form.Item>
                    </div>
                    <div className={Module.d3}>
                      <p>联系电话</p>
                    </div>
                    <div className={Module.d4}>
                      <Form.Item
                        className={Module.input}
                        name={[6, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="联系电话" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1} >
                      <p>拟纳入对象（单位或个人）</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[7, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="拟纳入对象（单位或个人）" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1} >
                      <p>纳入联合惩戒对象“黑名单”管理的理由</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[8, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="纳入联合惩戒对象“黑名单”管理的理由" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1} >
                      <p>认定依据</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[9, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="认定依据" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>认定部门提交意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <p>已按程序告知或公示，确认纳入联合惩戒对象“黑名单”管理</p>
                        <div className={Module.input1}>
                          <p>期限：</p>
                          <Form.Item
                            className={Module.item}
                            name={[10, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="期限" />
                          </Form.Item>
                        </div>
                        <div className={Module.input2}>
                          <Form.Item
                            className={Module.item}
                            name={[11, "fieldValue"]}
                          >
                            <StringDatePicker
                              format="YYYY年MM月DD日"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="开始时间" />
                          </Form.Item>
                          <Form.Item
                            className={Module.item}
                            name={[12, "fieldValue"]}
                          >
                            <StringDatePicker
                              format="YYYY年MM月DD日"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="截止时间" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          className={Module.select1}
                          name={[13, "fieldValue"]}
                        >
                          <StringDatePicker
                            placeholder="日期"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format="YYYY年MM月DD日" />
                        </Form.Item>
                        <Form.Item
                          className={Module.select1}
                          name={[15, "fieldValue"]} >
                          <Select placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>备注</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[14, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="备注" className={Module.text} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}