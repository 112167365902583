import DictionaryType from "../entity/DictionaryType"

class DictionaryTypeForm {
    baseTypeId: string = '1'
    typeName: string = ""
    typeDescription: string | null = null
    regex: string | null = null
    constructor(
        baseTypeId: string = '1',
        typeName: string = "",
        typeDescription: string | null = null,
        regex: string | null = null
    ) {
        this.baseTypeId = baseTypeId
        this.typeName = typeName
        this.typeDescription = typeDescription
        this.regex = regex
    }
    static create(type: DictionaryType): DictionaryTypeForm {
        return new DictionaryTypeForm(
            type.baseTypeId.toString(),
            type.typeName ?? "",
            type.typeDescription,
            type.regex

        )

    }
    toType(type: DictionaryTypeForm): DictionaryType {
        return {
            baseTypeId: parseInt(this.baseTypeId),
            typeDescription: this.typeDescription,
            typeName: this.typeName,
            regex: this.regex,
            id: null,
            dictionaries: []

        }
    }

}
export default DictionaryTypeForm