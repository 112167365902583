import InternetTool from "./Internetool";
import DocumentTypeEntity from "../entity/DocumentTypeEntity"
import Role from "../entity/Role";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";

export class ApiAdminDocumentType {
  readonly internetTool: InternetTool

  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/document-type", message, navigate)
  }

  addTypeRole(roleDescription: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("role-description", roleDescription)
    this.internetTool.ft(
      "/type-role",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  addDocumentType(documentTypeName: string, template: File, fileType: string, documentRole?: number, masterId?: number, resultParam?: ResultParam<number>) {
    let formData = new FormData()
    formData.append("template", template)
    formData.append("documentTypeName", documentTypeName)
    if (documentRole) {
      formData.append("documentRole", documentRole.toString())
    }
    formData.append("fileType", fileType)
    if (masterId) {
      formData.append("masterId", masterId.toString())
    }
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  updateTypeRole(roleId: number, roleDescription: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("role-description", roleDescription)
    this.internetTool.ft(
      `/type-role/${roleId}`,
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  updateDocumentType(documentType: DocumentTypeEntity, role?: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("documentType", new Blob([JSON.stringify(documentType)], { type: "application/json" }))
    if (role) {
      formData.append("role", role.toString())
    }
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  deleteTypeRole(roleId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/type-role/${roleId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  deleteDocumentTypes(documentTypeId: number, resultParam?: ResultParam<null>) {
    this.internetTool.ft(
      `/${documentTypeId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  addDocumentTypeRole(roleName: string, documentTypeId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("roleName", roleName)
    this.internetTool.ft(
      `/${documentTypeId}/role`,
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  deleteDocumentTypeRole(roleId: number, documentTypeId: number, resultParam?: ResultParam<null>) {
    this.internetTool.ft(
      `/${documentTypeId}/role/${roleId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  updateDocumentTypeRole(roleId: number, roleName: string, documentTypeId: number, resultParam?: ResultParam<null>) {
    const formData = new FormData()
    formData.append("roleName", roleName)
    formData.append("roleId", roleId.toString())
    this.internetTool.ft(
      `/${documentTypeId}/role`,
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  getDocumentTypeRole(documentTypeId: number, resultParam?: ResultParam<Array<Role>>) {
    this.internetTool.fe
      (
        `/${documentTypeId}/role`,
        resultParam
      )
  }
  updateDocumentTypeFile(file: File, id: number, fileType: string, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("fileType", fileType)
    this.internetTool.ft
      (
        `/${id}/file`,
        {
          body: formData,
          method: "PUT"
        },
        resultParam
      )
  }
}
