import { Form, Input, Select } from "antd"
import Module from '../style/consentToRecusal.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function ConsentToRecusal(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>同意回避申请决定书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>案件编号：</p>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案件编号"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>申请人：</p>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="申请人姓名"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>地址：</p>
                  <Form.Item
                    className={Module.item2}
                    name={[3, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="地址"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>联系电话：</p>
                  <Form.Item
                    className={Module.item}
                    name={[4, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="联系电话"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>被申请人：</p>
                  <Form.Item
                    className={Module.item}
                    name={[5, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="被申请人姓名"
                    />
                  </Form.Item>
                </div>
                <div className={Module.check}>
                  <div className={Module.time}>
                    <p className={Module.a1}>工作单位：</p>
                    <Form.Item
                      className={Module.item}
                      name={[6, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="工作单位"
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.time}>
                    <p className={Module.a1}>职务：</p>
                    <Form.Item
                      className={Module.item}
                      name={[7, "fieldValue"]}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="职务"
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item
                  className={Module.item}
                  name={[10, "fieldValue"]}
                >
                  <Input.TextArea className={Module.textarea}
                    autoSize={{ minRows: 2, maxRows: 15 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="移送内容" />
                </Form.Item>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>行政执法主体：</p>
                    <Form.Item
                      style={{ width: "20%", marginLeft: "2%" }}
                      name={[11, "fieldValue"]}>
                      <Select placeholder="行政执法主体"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[8, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[9, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

