import { Button, Form, Input, List, message } from "antd"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCase } from "../internet/ApiCase";
import { ApiDepartment } from "../internet/ApiDepartment";
import UserEntity from "../entity/UserEntity";
import Module from "../style/distributionpeople.module.scss"




const DistributionPeople: React.FC = () => {
    const location = useLocation()
    const state = location.state as { caseId: number, handleUid: number, subHandleUid: number }
    const [form] = Form.useForm<{ handle?: string, handleSub?: string }>()
    const [handleUid, setHandleUid] = useState<number | undefined>(state?.handleUid)
    const [subHandleUid, setSubHandleUid] = useState<number | undefined>(state?.subHandleUid)
    const [data, setData] = useState<UserEntity[] | undefined>()
    const caseId = state?.caseId
    const navigate = useNavigate()
    const apiCase = new ApiCase(message, navigate)
    const apiDepartment = new ApiDepartment(message, navigate)
    const renderItem = (item: UserEntity) => {
        return <List.Item
            key={item.username}
            actions={[
                <div>
                    <Button onClick={() => {
                        if (form.getFieldValue("handleSub") === item.realName) {
                            message.warn("主办人员与协办人员姓名起冲突")
                            const hands = { handle: item.realName, handleSub: undefined }
                            form.setFieldsValue(hands)
                            setHandleUid(item.id)
                            setSubHandleUid(undefined)
                        }

                        else {
                            form.setFieldsValue({ handle: item.realName })
                            setHandleUid(item.id)
                        }

                    }} type="link">主办人员</Button>
                    <Button onClick={() => {
                        if (form.getFieldValue("handle") === item.realName) {
                            message.warn("主办人员与协办人员姓名起冲突")
                            form.setFieldsValue({ handleSub: item.realName, handle: undefined })
                            setSubHandleUid(item.id)
                            setHandleUid(undefined)
                        } else {
                            form.setFieldsValue({ handleSub: item.realName })
                            setSubHandleUid(item.id)
                        }
                    }} type="link">协办人员</Button>
                </ div >
            ]}
        >
            <span>
                {item.realName}
            </span>
        </List.Item>
    }

    const addPeople = () => {
        form.validateFields()
            .then(() => {
                if (handleUid && subHandleUid) {
                    apiCase.updateHandleUser(handleUid, subHandleUid, caseId)
                }
                else {
                    message.error("分配失败")
                }

            })

    }
    const peoples = () => {
        apiDepartment.getUsers({
            successHandler: users => {
                if (users) {
                    const handleUser = users.find(user => user.id === handleUid)
                    const subHandleUser = users.find(user => user.id === subHandleUid)
                    if (!handleUser) {
                        setHandleUid(undefined)
                    } else {
                        form.setFieldsValue({ handle: handleUser.realName })
                    }
                    if (!subHandleUser) {
                        setSubHandleUid(undefined)
                    } else {
                        form.setFieldsValue({ handleSub: subHandleUser.realName })
                    }
                    setData(users)
                }
            }
        })
    }
    useEffect(() => {
        peoples()
    }, [])
    return (
        <div className={Module.all}>
            <h1>分配人员</h1>
            <Form

                form={form}
                className={Module.form}
                layout="inline"
            >
                <Form.Item
                    name={"handle"}
                    label="主办人员"
                    rules={[
                        {
                            required: true,
                            message: '该字段不能为空',
                        }
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item

                    name={["handleSub"]}
                    label="协办人员"
                    rules={[
                        {
                            required: true,
                            message: '该字段不能为空',
                        }
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                >
                    <Button type="primary" onClick={addPeople}>确认添加</Button>
                </Form.Item>
            </Form>
            <div className={Module.center}>
                <List
                    dataSource={data}
                    header="单位人员"
                    bordered
                    size="small"
                    renderItem={renderItem}
                />
            </div>
        </div>
    )
}
export default DistributionPeople