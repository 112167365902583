import { Form, Input, Select } from "antd"
import Module from '../style/approveTheStagingDesion.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function ApproveTheStagingDesion(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>批准分期缴纳罚款申请决定书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[1, "fieldValue"]}
                >
                  <Input
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人" />
                </Form.Item>
                <div className={Module.time}>
                  <p className={Module.a1}>申请时间：</p>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="申请时间"
                    />
                  </Form.Item>
                </div>
                <p>你（单位）因公司周转困难，无法一次性交清罚款为由, 对《林业行政处罚决定书》</p>
                <div className={Module.time}>
                  <p className={Module.a1}>《林业行政处罚决定书》案号：</p>
                  <Form.Item
                    className={Module.item}
                    name={[3, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="林业行政处罚决定书案号"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p >关于罚款交纳的期限提出</p>
                  <div className={Module.check}>
                    <p>【</p>
                    <Form.Item
                      name={[4, "fieldValue"]}
                      className={Module.item}
                      initialValue="⬜"
                    >
                      <Input readOnly
                        onClick={() => props.changeCheckbox(["fields", 4, "fieldValue"])}
                      />
                    </Form.Item>
                    <p>延期</p>
                    <Form.Item
                      name={[5, "fieldValue"]}
                      className={Module.item}
                      initialValue="⬜"
                    >
                      <Input readOnly
                        onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])}
                      />
                    </Form.Item>
                    <p>分期</p>
                    <p>】</p>
                  </div>
                  <p> 经审查，认为此申请</p>
                </div>
                <div className={Module.time}>
                  <div className={Module.check}>
                    <p>【</p>
                    <Form.Item
                      name={[6, "fieldValue"]}
                      className={Module.item}
                      initialValue="⬜"
                    >
                      <Input readOnly
                        onClick={() => props.changeCheckbox(["fields", 6, "fieldValue"])}
                      />
                    </Form.Item>
                    <p>符合</p>
                    <Form.Item
                      name={[7, "fieldValue"]}
                      className={Module.item}
                      initialValue="⬜"
                    >
                      <Input readOnly
                        onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])}
                      />
                    </Form.Item>
                    <p>不符合</p>
                    <p>】</p>
                  </div>
                  <p>法定情形。</p>
                </div>
                <p className={Module.a1}>根据以下规定：</p>
                <Form.Item
                  className={Module.item}
                  name={[8, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 15 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="法律规定"
                  />
                </Form.Item>
                <p className={Module.a1}>现决定：</p>
                <div className={Module.check}>
                  <Form.Item
                    name={[9, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 9, "fieldValue"])}
                    />
                  </Form.Item>
                  <p> 1.驳回</p>
                  <p>【</p>
                  <Form.Item
                    name={[10, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 10, "fieldValue"])}
                    />
                  </Form.Item>
                  <p>延期</p>
                  <Form.Item
                    name={[11, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 11, "fieldValue"])}
                    />
                  </Form.Item>
                  <p>分期</p>
                  <p>】</p>
                  <p>缴纳罚款的申请中关于罚款交纳的期限不变。</p>
                </div>
                <div className={Module.check}>
                  <Form.Item
                    name={[12, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 12, "fieldValue"])}
                    />
                  </Form.Item>
                  <p> 2.缴纳罚款的期限延至</p>
                  <Form.Item
                    style={{ width: "50%", marginLeft: "2%" }}
                    name={[13, "fieldValue"]}
                  >
                    <StringDatePicker format="YYYY年MM月DD日" placeholder="截止时间"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    />
                  </Form.Item>
                </div>
                <div className={Module.check}>
                  <Form.Item
                    name={[14, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 14, "fieldValue"])}
                    />
                  </Form.Item>
                  <p> 3.处罚人可分次缴纳罚款</p>
                  <Form.Item
                    name={[15, "fieldValue"]}
                    className={Module.item1}
                  >
                    <Input
                      placeholder="次数"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}

                    />
                  </Form.Item>
                  <Form.Item
                    name={[16, "fieldValue"]}
                  >
                    <StringDatePicker format="YYYY年MM月DD日" placeholder="截止时间"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    />
                  </Form.Item>
                  全额缴清
                </div>
                <p>限你单位在收到本决定书之日起，在规定的时间内
                  ，到原《林业行政处罚决定书》确定的银行缴纳罚款。到期不缴纳罚款的
                  ，每日按罚款数额的百分之三加处罚款。如不履行本决定的，
                  本机关将依法强制执行或者依法申请人民法院强制执行。</p>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位名称：</p>
                    <Form.Item
                      style={{ width: "20%" }}
                      name={[17, "fieldValue"]}>
                      <Select placeholder="单位名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%" }}
                      name={[18, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[19, "fieldValue"]} >
                      <Select placeholder="印章"
                        placement="topLeft"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div >
  )

}

