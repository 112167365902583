import { DatePicker, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import Module from '../style/jzmain.module.scss'

const jzmain: React.FC = () => {

    return (
        <div className={Module.all} >
            <div className={Module.center} >
                <Form
                    className={Module.form}
                    name="register"
                    scrollToFirstError
                >
                    <h1>案件详情</h1>
                    <Form.Item
                        label="案件编号："
                        className={Module.ite1}
                        name="id"
                        initialValue={"2021212"}
                    >
                        <Input disabled className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="案件名称："
                        className={Module.ite1}
                        name="name"
                        initialValue={"林木盗窃"}
                    >
                        <Input className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="主办单位"
                        className={Module.ite1}
                        name="people"
                    >
                        <Input className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="受案单位："
                        className={Module.ite1}
                        name="npeople"
                    >
                        <Input className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="办案日期："
                        className={Module.ite1}
                        name="date"
                    >
                        <DatePicker className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="案件来源："
                        className={Module.ite1}
                        name="come"
                    >
                        <Input className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="&emsp;&emsp;案由："
                        className={Module.ite1}
                        name="reason"
                    >
                        <Input className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="违法条款："
                        className={Module.ite1}
                        name="item"
                    >
                        <Input className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="&emsp;&emsp;&emsp;市："
                        className={Module.ite1}
                        name="shi"

                    >
                        <Input className={Module.input} />
                    </Form.Item>

                    <Form.Item
                        label="&emsp;&emsp;&emsp;区："
                        className={Module.ite1}
                        name="qu"
                    >
                        <Input className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="&emsp;&emsp;乡镇："
                        className={Module.ite1}
                        name="xz"
                    >
                        <Input className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="&nbsp; 街道/村："
                        className={Module.ite1}
                        name="jd"
                    >
                        <Input className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="&nbsp; 社区/组："
                        className={Module.ite1}
                        name="sq"
                    >
                        <Input className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="案发地点："
                        className={Module.ite1}
                        name="adress"
                    >
                        <Input className={Module.input} />
                    </Form.Item>


                    <Form.Item
                        label="主要案情："
                        className={Module.ite1}
                        name="writ"
                    >
                        <TextArea autoSize={{ minRows: 5, maxRows: 8 }} className={Module.input} />
                    </Form.Item>

                </Form>
            </div>
        </div>
    )
}
export default jzmain