import ChatEntity from "../entity/Chat"
import InternetTool from "./Internetool"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiChat {
    readonly internetTool: InternetTool
    constructor(message: MessageApi, navigate: NavigateFunction) {
        this.internetTool = new InternetTool("chat", message, navigate)
    }
    selectByDate(chatTime: Date, resultParam?: ResultParam<Array<ChatEntity>>) {
        let formData = new FormData()
        formData.append("chatTime", chatTime.toString())
        this.internetTool.ft(
            "/selectByDate",
            {
                body: formData,
                method: "GET"
            },
            resultParam
        )
    }
    selectById(caseId: number, resultParam?: ResultParam<Array<ChatEntity>>) {
        let formData = new FormData()
        formData.append("caseId", caseId.toString())
        this.internetTool.ft(
            "/selectById",
            {
                body: formData,
                method: "GET"
            },
            resultParam
        )
    }
    addChat(chatContent: string, caseId: number | undefined, resultParam?: ResultParam<void>) {
        let formData = new FormData()
        formData.append("chatContent", chatContent)
        if (caseId) {
            formData.append("caseId", caseId.toString())
        }
        this.internetTool.ft(
            "",
            {
                body: formData,
                method: "POST"
            },
            resultParam
        )
    }
    getAllChat(caseId: number | undefined, resultParam?: ResultParam<Array<ChatEntity>>) {
        let path: string
        if (caseId) {
            path = `/case?caseId=${caseId}`
        } else {
            path = "/case"
        }
        this.internetTool.fe(
            path,
            resultParam
        )
    }
    getNewChat(chatTime: Date, caseId: number | undefined, resultParam?: ResultParam<Array<ChatEntity>>) {
        let path = `/date?chatTime=${chatTime.getMilliseconds()}`
        if (caseId) {
            path += `&caseId=${caseId}`
        }
        this.internetTool.fe
            (
                path,
                resultParam
            )
    }

}