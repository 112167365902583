import Module from "../style/users.module.scss";
import { Button, message, Segmented } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import Role from "../entity/Role";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiRoles from "../internet/ApiRoles";


const columns1: ColumnsType<Role> = [
  {
    title: "",
    dataIndex: "roleDescription"
  }
]

const TypeList: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [typeRole, setTypeRole] = useState<number>()
  const [roles, setRoles] = useState<Role[]>()
  const [typeRoles, setTypeRoles] = useState<Role[]>()
  const apiRoles = new ApiRoles(message, navigate)
  const updateRole = (role: Role) => {
    return {
      onClick: () => {
        navigate("/userpermissions", { state: { role: role } })
      }
    }
  }
  const addRole = () => {
    navigate("/userpermissions")
  }

  const onOptionChange = (value: string | number) => {
    const value1 = value as number
    setTypeRole(value1)
  }

  function getTypeRoles() {
    apiRoles.getTypeRoles(undefined, {
      successHandler: typeRoles => {
        if (typeRoles) {
          const roles = Role.createList(typeRoles)
          setTypeRoles(roles)
          if (roles.length > 0) {
            setTypeRole(roles[0].id)
          }
        }
      }
    })

  }



  const getDistributableRoles = () => {
    apiRoles.getDistributableRoles({
      successHandler: distributableRoles => {
        if (distributableRoles) {
          const roles = Role.createList(distributableRoles)
          setRoles(roles)
        }
      }
    })
  }

  useEffect(() => {
    getTypeRoles()
    getDistributableRoles()
  }, [location])

  let element
  if (typeRole && typeRoles) {
    element = (
      <div className={Module.ta}>
        <Segmented value={typeRole} block options={typeRoles.map(typeRole => typeRole.toOption())}
          onChange={onOptionChange} />
        <Table
          expandable={{ childrenColumnName: "children1234" }}
          bordered
          size="small"
          rowKey={"id"}
          columns={columns1}
          dataSource={roles?.filter(role => (role.parents ?? []).includes(typeRole))}
          onRow={updateRole}
        />
        <Button type="primary" className={Module.addbtn} onClick={addRole}>增加</Button>
      </div>
    )
  } else {
    element = <div />
  }
  return element
}

export default TypeList