
import { FileWordOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import Module from '../style/toolspic.module.scss'



export default function ToolsPic(): JSX.Element {
  const navigate = useNavigate()

  function iconClick() {
    navigate('/summarytable')
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <div className={Module.tool} >
          <div className={Module.tool1} >
            <FileWordOutlined onClick={iconClick} className={Module.icon} />
            <p>巡林记录汇总表</p>
          </div>
        </div>
      </div>
    </div>
  )
}