import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react';

interface DataType {
    key: number;
    id: string;
    name: string;
    date: string;
    nation: string;
}
const columns: ColumnsType<DataType> = [
    {
        title: '意见编号',
        dataIndex: 'id',
    },
    {
        title: '拟处罚意见',
        dataIndex: 'name',
    },
    {
        title: '执行日期',
        dataIndex: 'date',
    },
    {
        title: '执行状态',
        dataIndex: 'nation',
        width: 250,
    },
];
const data: DataType[] = [
    {
        key: 1,
        id: "202155454",
        name: "判处罚款1万元并且拘留一周",
        date: "2021.12.12",
        nation: "正在执行"

    },
    {
        key: 2,
        id: "202155454",
        name: "判处罚款1万元并且拘留一周",
        date: "2021.12.12",
        nation: "正在执行"

    },
    {
        key: 3,
        id: "202155454",
        name: "判处罚款1万元并且拘留一周",
        date: "2021.12.12",
        nation: "正在执行"

    },
    {
        key: 4,
        id: "202155454",
        name: "判处罚款1万元并且拘留一周",
        date: "2021.12.12",
        nation: "正在执行"

    },
]

const Lawfyj = () => {

    return (
        <Table
            dataSource={data}
            columns={columns}
            pagination={{ pageSize: 40 }}
        />
    )
}
export default Lawfyj