import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/putrecordapproval.module.scss'
const PutRecordApproval: React.FC<DocumentProps> = (props) => {

  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>立案审批表</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[20, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案号前缀" />
                  </Form.Item>
                </div>
                <div className={Module.table}>
                  <div className={Module.row3}>
                    <div className={Module.a1} >
                      <p>案件来源</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[0, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案件来源" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row33}>
                    <div className={Module.a1} >
                      <p>案由</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[1, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案由" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row22}>
                    <div className={Module.a1} >
                      <p>案发时间</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[2, "fieldValue"]}
                      >
                        <StringDatePicker disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} format="YYYY-MM-DD" placeholder="案发时间" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row11}>
                    <div className={Module.a1} >
                      <p>案发地点</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.input}
                        name={[3, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案发地点" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row88}>
                    <div className={Module.d1}>
                      <p>当事人</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <div className={Module.f1}><p>名称（姓名）</p></div>
                        <div className={Module.f2}>
                          <Form.Item
                            className={Module.input}
                            name={[4, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="名称（姓名）" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e2}>
                        <div className={Module.f1}><p>法定代表人或负责人</p></div>
                        <div className={Module.f2}>
                          <Form.Item
                            className={Module.input}
                            name={[5, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="法定代表人或负责人" />
                          </Form.Item>
                        </div>
                        <div className={Module.f3}><p>联系电话</p></div>
                        <div className={Module.f4}>
                          <Form.Item
                            className={Module.input}
                            name={[6, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="联系电话" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e3}>
                        <div className={Module.f1}><p>住所（住址）</p></div>
                        <div className={Module.f2}>
                          <Form.Item
                            className={Module.input}
                            name={[7, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="住所（住址）" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>案情及立案理由</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        name={[8, "fieldValue"]}
                      >
                        <Input.TextArea
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          autoSize={{ minRows: 2, maxRows: 4 }} placeholder="案情及立案理由" className={Module.text} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>承办人员意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[9, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="承办人员意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time1}>
                        <Form.Item
                          name={[16, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="主办人员签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[21, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="协办人员签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[10, "fieldValue"]}
                        >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.time2} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>承办大队负责人意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[11, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="承办大队负责人意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[17, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="承办大队负责人签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[10, "fieldValue"]}
                        >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.time1} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>案件管理部门负责人意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[12, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="案件管理部门负责人意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[18, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="案件管理部门负责人签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[10, "fieldValue"]}
                        >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.time1} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>行政执法机关负责人意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[13, "fieldValue"]}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="行政执法机关负责人意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[19, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="行政执法机关负责人签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[10, "fieldValue"]}
                        >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.time1} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.d1}>
                      <p>备注</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[14, "fieldValue"]}
                        >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="备注" />
                        </Form.Item>
                      </div>
                      <div className={Module.time1}>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          className={Module.select}
                          name={[15, "fieldValue"]} >
                          <Select placeholder="印章"
                            placement="topLeft"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default PutRecordApproval