import { FolderAddOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useLocation, useNavigate } from 'react-router-dom';
import Module from '../style/direact.module.scss';
import DictionaryType from '../entity/DictionaryType';
import Datasoure from './datasoure';
import BaseType from '../entity/BaseType';
import DictionaryTypeForm from '../form/DcitonaryTypeForm';
import { ApiAdminDictionaryType } from '../internet/ApiAdminDictionaryType';
import ApiGlobal from '../internet/ApiGlobal';
const columns: ColumnsType<DictionaryType> = [
    {
        title: "字典类型",
        dataIndex: "typeName",
    }
]
const { Option } = Select;
const Direact: React.FC = () => {
    const navegate = useNavigate()
    const [form] = Form.useForm<DictionaryTypeForm>()
    const [types, setTypes] = useState<DictionaryType[]>([])
    const [baseType, setBaseType] = useState<BaseType[]>();
    const [type, setType] = useState<DictionaryType | null>(null)
    const location = useLocation()
    const apiAdminDictionaryType = new ApiAdminDictionaryType(message, navegate)
    const apiGlobal = new ApiGlobal(message, navegate)
    const rowClick = (type: DictionaryType) => {
        return {
            onClick: () => {
                setTypeFuntion(type)
            }
        }
    }
    const setTypeFuntion = (type: DictionaryType | null) => {
        if (type != null) {
            form.resetFields()
            form.setFieldsValue(DictionaryTypeForm.create(type))
        }
        setType(type)
    }
    const addDictionary = () => {
        if (type != null) {
            navegate("/adddir", { state: { typeId: type.id } })
        }
    }
    function addDictionaryType() {
        navegate("/adddirtype")
    }
    const getTypes = () => {
        apiAdminDictionaryType.getDictionaryTypes({
            successHandler: newTypes => {
                if (newTypes != null) {
                    setTypes(newTypes)
                    if (newTypes != null && newTypes.length > 0) {
                        setTypeFuntion(newTypes[0])
                    }
                    else {
                        setTypeFuntion(null)
                    }
                }
            }
        })
    }

    const optionValue = () => {
        apiGlobal.getBaseType({
            successHandler: res => {
                if (res) {
                    setBaseType(res)
                }
            }
        })


    }
    const updateType = () => {
        if (type) {
            form.validateFields()
                .then(typeForm => {
                    const newType = new DictionaryType(
                        type.id,
                        parseInt(typeForm.baseTypeId),
                        typeForm.typeName,
                        typeForm.typeDescription,
                        typeForm.regex,
                        []
                    )
                    apiAdminDictionaryType.updateDictionaryType(newType,{exit:0,successHandler:()=>{
                        getTypes()
                    }})
                })
        }
    }
    function deleteType() {
        const typeId = type?.id
        if (typeId) {
            apiAdminDictionaryType.deleteDictionaryType(typeId,{exit:0,successHandler:()=>{
                getTypes()
                
            }})    
        }
    }
    useEffect(() => {
        getTypes()
        optionValue()
    }, [location])

    const options = baseType?.map(d => <Option key={d.id}>{d.typeDescription}</Option>);
    let typeElement
    if (type !== null) {
        typeElement = (
            <div className={Module.info}>
                <div className={Module.moType}>
                    <Form
                        name='updateDictionaryType'
                        form={form}

                    >
                        <Form.Item
                            name="typeName"
                            className={Module.item1}
                            label="类型名称"
                            rules={[{
                                required: true,
                                message: "请输入类型名"
                            }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="typeDescription"
                            className={Module.item1}
                            label="&emsp;类型描述">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="baseTypeId"
                            className={Module.item1}
                            label="&emsp;基础类型"
                        >
                            <Select
                                className={Module.select}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={Module.ite}
                        >
                            <Button type="primary" onClick={updateType} >修改</Button>
                            <Button type="primary" onClick={deleteType}>删除</Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={Module.ta}>
                    <Form className={Module.form}>
                        <Form.Item>
                            <Button onClick={addDictionary}><FolderAddOutlined className={Module.icon} />增加</Button>
                        </Form.Item>
                    </Form>
                    <Datasoure type={type} setType={setType} update={() => { getTypes() }} />
                </div>
            </div>
        )
    } else {
        typeElement = ""
    }

    return <div className={Module.all}>
        <div className={Module.tree}>
            <Button onClick={addDictionaryType}><FolderAddOutlined className={Module.icon} />增加</Button>
            <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={types}
                onRow={rowClick}
            />

        </div>
        {typeElement}
    </div>
};

export default Direact;