import InternetTool from "./Internetool";
import Seal from "../entity/Seal";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiAdminSeal {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/seal", message, navigate)
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft<void>(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  add(sealName: string, file: File, unitId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("sealName", sealName)
    formData.append("unitId", unitId.toString())
    formData.append("file", file)
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )

  }
  update(seal: Seal, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("seal", new Blob([JSON.stringify(seal)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateFile(id: number, file: File, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("id", id.toString())
    formData.append("file", file)
    this.internetTool.ft(
      `/${id}/file`,
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  get(resultParam?: ResultParam<Array<Seal>>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )
  }
  getByUnit(id: number, resultParam?: ResultParam<Array<Seal>>) {
    this.internetTool.fe
      (
        `/unit/${id}`,
        resultParam
      )

  }
  getById(id: number, resultParam?: ResultParam<Seal>) {
    this.internetTool.fe<Seal>
      (
        `/${id}`,
        resultParam
      )
  }
  getImg(id: number, resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.fe
      (
        `/${id}/img`,
        resultParam
      )

  }
}