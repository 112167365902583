import React from 'react';
import { useNavigate } from 'react-router-dom';
import Module from '../style/cover.module.scss'
const Cover: React.FC = () => {
    const navegate = useNavigate()
    const onClick = () => {
        navegate("/jzmain",)
    }
    return (
        <div className={Module.all} >
            <div className={Module.cen} onClick={onClick}>
                <h1>盐 边 县 林 业 局</h1>
                <h2>XXXX处罚案卷</h2>
                <label>案件编号：<input disabled defaultValue={"边林罚立字〔2021〕30号"} /></label>
                <br />
                <label>类&emsp;&emsp;别：<input defaultValue={"毁坏林木、林地案件"} /></label>
                <br />
                <label>案&emsp;&emsp;由：<input defaultValue={"盐边县富丰工贸有限责任公司擅自改变林地用途"} /></label>
                <br />
                <label>当事人：&emsp;<input defaultValue={"盐边县富丰工贸有限责任公司"} /></label>
                <br />
                <label>立案时间：<input defaultValue={"2020年1月23日"} /></label>
                <br />
                <label>结案时间：<input defaultValue={"2021年1月23日"} /></label>
                <br />
                <label>办案单位：<input defaultValue={"盐边县林业局"} /></label>
                <br />
                <label>办案人员：<input defaultValue={" 赵登国 肖峰"} /></label>
                <br />
                <label className={Module.label}>案情简介及处理结果执行情况：</label><textarea className={Module.text} defaultValue={" 2021年11月6日,盐边县林业局工作人员在日常监督检查中发现:2018年,盐边县富丰工贸有限责任公司在盐边县新九镇平谷村和平社堆放尾矿，"} />
                <br />
                <label className={Module.label1}>听证行政复议或行诉讼情况：</label><textarea className={Module.text1} defaultValue={" 当事人自愿放弃听证、行政复议或行政诉讼"} />
                <br />
                <label>归档时间：<input defaultValue={" 2012年12月12日"} /></label>
                <br />
                <label>保存期限：<input defaultValue={"永久"} /></label>
                <br />
                <label>&emsp;本卷共：<input defaultValue={""} /></label>
                <br />
                <label>&emsp;归档号：<input defaultValue={""} /></label>
            </div>
        </div>


    )

};

export default Cover;