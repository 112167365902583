import { Button, Form, Input, message } from "antd";
import React from "react";
import Module from '../style/repasswordusers.module.scss'
import { useLocation, useNavigate } from "react-router-dom";
import ApiAdminUser from "../internet/ApiAdminUser";


const ReasswordUsers: React.FC = () => {
  const [form] = Form.useForm<{ password: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const uid = (location.state as { uid: number }).uid
  const apiAdminUser = new ApiAdminUser(message, navigate)
  const updatePassword = () => {
    form.validateFields()
      .then(values => {
        apiAdminUser.updateUserPassword(uid, values.password)
      })
  }

  return (
    <div className={Module.all}>
      <Form className={Module.form} form={form}>
        <h1>修改密码</h1>
        <Form.Item
          className={Module.ite}
          label="&emsp;&emsp;密码"
          name="password"
          rules={[
            {
              required: true,
              message: '请输入你的密码!',
            }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          className={Module.ite}
          label="重复密码"
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请再次输入你的密码!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次输入的密码不一致!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className={Module.ite}>
          <Button type="primary" className={Module.btn} onClick={updatePassword}>确认修改</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default ReasswordUsers