import { RuleObject } from "antd/lib/form";

type cardAnalysisType = "birthDate" | "sex" | "age";
//  避免精度丢失 例如 210321198708251611 --> 210321198708251620, 故身份证号类型需为String类型
type cardType = string;

export default class IdCardTool {
  /**
   * @description 解析身份证信息
   * @param {String} idCard - 身份证号
   * @param {Number} analysisType - 解析类型（birthDate-出生日期 sex-性别 age-年龄）
   * @return {String}
   */
  getAnalysisIdCard(
    idCard: cardType,
    analysisType: cardAnalysisType = "birthDate"
  ): string | number {
    const analysisTypeArr: string[] = ["birthDate", "sex", "age"];
    if (!idCard) {
      throw new Error("传入身份证不能为空！");
    }
    if (!analysisTypeArr.includes(analysisType)) {
      throw new Error("请传入正确的解析类型！");
    }
    if (!this.validIdCard(idCard)) {
      throw new Error("传入身份证格式有误!");
    }
    const analysisObj = {
      birthDate: (idCard: cardType) => {
        // 获取出生日期
        return `${idCard.substring(6, 10)}年${idCard.substring(10, 12)}月${idCard.substring(
          12,
          14
        )}日`;
      },
      sex: (idCard: cardType) => {
        //获取性别
        return parseInt(idCard[16]) % 2 === 1 ? "男" : "女";
      },
      age: (idCard: cardType) => {
        //获取年龄(计算周岁，未过今年的生日则不加上一岁)
        const myDate = new Date(),
          month = myDate.getMonth() + 1,
          day = myDate.getDate();
        let age = myDate.getFullYear() - Number(idCard.substring(6, 10)) - 1;
        if (
          Number(idCard.substring(10, 12)) < month ||
          (Number(idCard.substring(10, 12)) === month && Number(idCard.substring(12, 14)) <= day)
        ) {
          age++;
        }
        return age;
      },
    };
    return analysisObj[analysisType](idCard);
  };

  validIdCard(idCard: cardType): boolean {
    // 例如 "11":"北京"
    const cityCodeArr: string[] = [
      "11",
      "12",
      "13",
      "14",
      "15",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "50",
      "51",
      "52",
      "53",
      "54",
      "61",
      "62",
      "63",
      "64",
      "65",
      "71",
      "81",
      "82",
      "91",
    ];
    //是否为空
    if (!idCard) {
      return false;
    }
    //校验长度，类型
    if (!this.isRightIdCardLength(idCard)) {
      return false;
    }
    //检查省份
    if (!this.checkProvince(idCard, cityCodeArr)) {
      return false;
    }
    //校验生日
    if (!this.checkBirthday(idCard)) {
      return false;
    }
    //检验位的检测
    return this.checkParity(idCard);

  };


  isRightIdCardLength(card: cardType): boolean {
    // 身份证位数校验，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    const reg = /^\d{17}(\d|X|x)$/;
    return reg.test(card);
  };

  checkProvince(card: cardType, cityCodeArr: string[]): boolean {
    const province = card.slice(0, 2);
    return cityCodeArr.includes(province);
  };

  checkBirthday(card: cardType): boolean {
    //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
    if (!card || card.length !== 18) {
      return false;
    }
    const reg = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X|x)$/;
    const arrData = card.match(reg);
    if (arrData) {
      const year = arrData[2];
      const month = arrData[3];
      const day = arrData[4];
      const birthday = new Date(year + "/" + month + "/" + day);
      return this.verifyBirthday(year, month, day, birthday);
    } else {
      return false;
    }
  };

  // 校验日期
  verifyBirthday(year: string, month: string, day: string, birthday: any): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    //年月日是否合理
    if (
      birthday.getFullYear() === parseInt(year) &&
      birthday.getMonth() + 1 === parseInt(month) &&
      birthday.getDate() === parseInt(day)
    ) {
      //判断年份的范围（0岁到100岁之间)
      const time = currentYear - Number(year);
      return time >= 0 && time <= 100;

    }
    return false;
  }

  // 检测校验位
  checkParity(card: cardType): boolean {
    // 第一代居民身份证(15位)已经于2013年1月1日正式退出
    // 故不做15位转18位换算
    if (card.length < 18) {
      return false;
    }
    const arrInt: number[] = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    const arrCh: string[] = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
    let cardTemp: number = 0;
    for (let i = 0; i < 17; i++) {
      cardTemp += Number(card.slice(i, i + 1)) * arrInt[i];
    }
    const checkBit = arrCh[cardTemp % 11];
    return checkBit === card.slice(17).toLocaleUpperCase();
  };
}
