import { Cascader, message, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Punish } from "../entity/Punish";
import Law from "../entity/Law";
import { LawLevels } from "../tool/LawLevels";
import { ApiCasePunish } from "../internet/ApiCasePunish";
import { ApiLaw } from "../internet/ApiLaw";
import { LawPunishType } from "../entity/LawPunishType";
import LawPunishArticleLaw from "../entity/LawPunishArticleLaw";
import { LawPunishArticle } from "../entity/LawPunishArticle";
import { LawConversion } from "../tool/lawConversion";


interface PunishTable {
  id: number;
  lawPunishType: string;
  lawPunish: string;
  punishLevel: string;
  punishArticle: string
  lawId: number[],
  punish: Punish;
}

const CasePunish: React.FC<{ caseId: number, caseTypeId: number }> = ({ caseId, caseTypeId }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [punishes, setPunishes] = useState<Punish[]>([])
  const [laws, setLaws] = useState<Law[]>([])
  const [lawPunishTypes, setLawPunishTypes] = useState<LawPunishType[]>([])
  const apiCasePunish = new ApiCasePunish(message, navigate)
  const apiLaw = new ApiLaw(message, navigate)
  const laws1 = LawConversion.law11(laws)

  const columns: ColumnsType<PunishTable> = [
    {
      title: "案件类型",
      dataIndex: 'lawPunishType',
      width: 200,
      ellipsis: true
    },
    {
      title: "违法行为",
      dataIndex: 'lawPunish',
      width: 200,
      ellipsis: true
    },
    {
      title: '裁量阶次',
      dataIndex: 'punishLevel',
      width: 60,
      align: "center",
      ellipsis: true
    },
    {
      title: '适用情形',
      dataIndex: 'punishArticle',
      width: 200,
      ellipsis: true
    },
    {
      title: '处罚依据',
      dataIndex: 'lawId',
      render(value, record, index) {
        return <Cascader
          style={{ width: "100%" }}
          defaultValue={record.lawId}
          options={laws1} />
      },
      ellipsis: true
    }
  ];
  function getLaws() {
    apiLaw.get({
      successHandler: laws => {
        if (laws) {
          setLaws(laws)
        }
      }
    })
  }
  function convert2Table(): PunishTable[] {
    let list: PunishTable[] = []
    if (punishes.length && lawPunishTypes.length) {
      const name = lawPunishTypes.filter(item => item.id === caseTypeId).map(item => item.typeName)[0]
      punishes.forEach(punish => {
        lawPunishTypes.forEach(lawPunishType => {
          lawPunishType.punishes?.forEach(lawPunish => {
            lawPunish.articles?.forEach(article => {
              if (article.id === punish.punishArticleId) {
                list.push(
                  {
                    id: punish.id,
                    lawPunishType: name,
                    lawPunish: lawPunish.lawPunishName,
                    punishLevel: LawPunishArticle.punishLevels[article.punishLevel] ?? "",
                    punishArticle: article.articleValue,
                    lawId: punish.lawId,
                    punish: punish
                  }
                )
              }
            })
          })
        })
      })
    }
    return list
  }

  function getPunishes() {
    apiCasePunish.getByCaseId(caseId, {
      successHandler: punishes => {
        if (punishes) {
          console.log(punishes);
          setPunishes(punishes)
        }
      }
    })

  }

  function getLawPunishes() {
    apiLaw.getPunishTypes({
      successHandler: lawPunishTypes => {
        if (lawPunishTypes) {
          console.log(lawPunishTypes);
          setLawPunishTypes(lawPunishTypes)

        }
      }
    })
  }

  useEffect(() => {
    getLawPunishes()
    getLaws()
    getPunishes()
  }, [location])
  const punishChange = (punishData: PunishTable) => {
    return {
      onClick: () => {
        navigate("/punishcontext", { state: { punish: punishData.punish, caseId: caseId, caseTypeId: caseTypeId } })
      }
    }

  }
  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={convert2Table()}
      bordered
      onRow={punishChange}
    />
  )
}
export default CasePunish