import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd"
import Module from '../style/samplingForensicsBook.module.scss'
import { DocumentProps } from "../entity/DocumentProps";
import React from "react";
import FieldEntity from "../entity/FieldEntity";
import { StringDatePicker } from "../component/StringDatePicker";

export function SamplingForensicsBook(props: DocumentProps): JSX.Element {

  const onFinish = (values: any) => {
  }
  return (
    <div className={Module.all}>

      <Form
        form={props.form}
        onFinish={onFinish}
        className={Module.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <h1 className={Module.h1}>抽样取证物品清单</h1>
        <Form.List name={"fields"}>
          {fields => {
            const fieldEntity = props.form.getFieldValue(["fields", 0])
            return <div className={Module.center}>
              <div className={Module.top}>
                <div className={Module.top1}>
                  <span>序号</span>
                </div>
                <div className={Module.top2}>
                  <span>物品名称</span>
                </div>
                <div className={Module.top3}>
                  <span>规格型号</span>
                </div>
                <div className={Module.top4}>
                  <span>数 量</span>
                </div>
                <div className={Module.top6}>
                  <span>生产日期（批号）</span>
                </div>
                <div className={Module.top7}>
                  <span>生产单位</span>
                </div>
                <div className={Module.top8}>
                  <span>物品特征</span>
                </div>
                <div className={Module.top9}>
                  <span>备注</span>
                </div>
              </div>
              <Form.List name={[0, "children"]}>
                {(fields, { add, remove }) => {
                  return <div className={Module.list}>
                    {fields.map(field => {
                      return <Form.List name={field.name}>
                        {fields => {
                          return <div className={Module.buttom}>
                            <div className={Module.buttom1}>
                              <Form.Item
                                name={[0, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom2}>
                              <Form.Item
                                name={[1, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom3}>
                              <Form.Item
                                name={[2, "fieldValue"]}
                              >
                                <Input
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom4}>
                              <Form.Item
                                name={[3, "fieldValue"]}
                              >
                                <Input
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom6}>
                              <Form.Item
                                name={[4, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom7}>
                              <Form.Item
                                name={[5, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom8}>
                              <Form.Item
                                name={[6, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom9}>
                              <Form.Item
                                name={[7, "fieldValue"]}
                              >
                                <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "children", field.name, 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                              </Form.Item>
                            </div>
                            <div className={Module.buttom10}>
                              <Button type="text" onClick={() => remove(field.name)}><CloseOutlined
                                style={{ fontSize: 20 }} /></Button>
                            </div>
                          </div>
                        }}
                      </Form.List>
                    })}

                    {props.genAddButton(fieldEntity, "添加处理清单", add)}
                  </div>
                }}
              </Form.List>
              <div className={Module.sig}>
                <p>主办人员签名：</p>
                <Form.Item
                  style={{ width: "20%", }}
                  name={[4, "fieldValue"]} className={Module.sig} >
                  <Select
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="签名"
                  >
                    {(props.users ?? []).map(user => <Select.Option
                      key={user.id.toString()}>{user.realName}</Select.Option>)}
                  </Select>
                </Form.Item>
                <p>签名日期：</p>
                <Form.Item
                  name={[2, "fieldValue"]} className={Module.sig}>
                  <StringDatePicker
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                </Form.Item>
              </div>
              <div className={Module.sig}>
                <p>主办人员执法证号：</p>
                <Form.Item
                  name={[1, "fieldValue"]}
                >
                  <Input
                    style={{ borderBottom: "1px solid black" }}
                    placeholder="主办人员执法证号"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                </Form.Item>
              </div>
              <div className={Module.sig}>
                <p>协办人员签名：</p>
                <Form.Item
                  style={{ width: "20%", }}
                  name={[5, "fieldValue"]} className={Module.sig} >
                  <Select
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="签名"
                  >
                    {(props.users ?? []).map(user => <Select.Option
                      key={user.id.toString()}>{user.realName}</Select.Option>)}
                  </Select>
                </Form.Item>
                <p>签名日期：</p>
                <Form.Item
                  name={[2, "fieldValue"]} className={Module.sig}>
                  <StringDatePicker
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                </Form.Item>
              </div>
              <div className={Module.sig}>
                <p>协办人员执法证号：</p>
                <Form.Item
                  name={[3, "fieldValue"]}
                >
                  <Input
                    style={{ borderBottom: "1px solid black" }}
                    placeholder="协办人员执法证号"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                </Form.Item>
              </div>
            </div>
          }}
        </Form.List>

      </Form >
    </div>

  )

}

