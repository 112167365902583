import InternetTool from "./Internetool"
import UnitEntity from "../entity/UnitEntity"
import UserEntity from "../entity/UserEntity"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
class ApiAdminUnit {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/unit", message, navigate)
  }
  addUnit(unit: UnitEntity, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("unit", new Blob([JSON.stringify(unit)], { type: "application/json" }))
    this.internetTool.ft<void>(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  getUnits(resultParam?: ResultParam<Array<UnitEntity>>) {
    this.internetTool.ft(
      "",
      {
        method: "GET"
      },
      resultParam
    )
  }
  getUnit(unitId: number, resultParam?: ResultParam<UnitEntity>) {
    this.internetTool.ft(
      `/${unitId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  updateUnit(unit: UnitEntity, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("unit", new Blob([JSON.stringify(unit)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  deleteUnit(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  getUnitUsers(unitId: number, resultParam?: ResultParam<Array<UserEntity>>) {
    this.internetTool.fe
      (
        `/users?unitId=${unitId}`,
        resultParam
      )
  }
}
export default ApiAdminUnit