import DocumentEntity from "../entity/DocumentEntity"
import InternetTool from "./Internetool"
import DocumentInfo from "../entity/documentinfo";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";

class ApiDocument {
  readonly internetTool: InternetTool

  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("document", message, navigate)
  }

  getInfos(caseId?: number, resultParam?: ResultParam<Array<DocumentInfo>>) {
    let path = ""
    if (caseId) {
      path = "/" + caseId
    }
    this.internetTool.fe(
      `/infos${path}`,
      resultParam
    )
  }
  getAttachmentInfos(documentId: number, resultParam?: ResultParam<Array<DocumentInfo>>) {
    this.internetTool.fe(
      `/${documentId}/infos`,
      resultParam
    )
  }

  select(caseId: number, resultParam?: ResultParam<Array<DocumentEntity>>) {
    this.internetTool.fe(
      `?caseId=${caseId}`,
      resultParam
    )
  }

  selectById(documentId: number, resultParam?: ResultParam<DocumentEntity>) {
    this.internetTool.ft(
      `/${documentId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  add(document: DocumentEntity, resultParam?: ResultParam<void>, masterId?: number,) {
    let formData = new FormData()
    if (masterId) {
      formData.append("masterId", masterId.toString())
    }
    formData.append("document", new Blob([JSON.stringify(document)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }

  update(document: DocumentEntity, resultParam?: ResultParam<void>, masterId?: number,) {
    let formData = new FormData()
    if (masterId) {
      formData.append("masterId", masterId.toString())
    }
    formData.append("document", new Blob([JSON.stringify(document)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  delete(documentId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${documentId}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  generate(documentId: number, shrink?: number, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    if (shrink) {
      formData.append("shrink", shrink?.toString())
    }
    this.internetTool.ft(
      `/${documentId}`,
      {
        method: "POST",
        body: formData
      },
      resultParam
    )
  }

  addApproval(documentId: number, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("documentId", documentId.toString())
    this.internetTool.ft(
      "/approval",
      {
        method: "POST",
        body: formData
      },
      resultParam
    )
  }

  updateApproval(documentId: number, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("documentId", documentId.toString())
    this.internetTool.ft(
      "/approval",
      {
        method: "PUT",
        body: formData
      },
      resultParam
    )
  }

  getPdf(documentId: number, resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.fe
      (
        `/${documentId}/pdf`,
        resultParam
      )
  }

  getDocx(documentId: number, resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.fe
      (
        `/${documentId}/docx`,
        resultParam
      )
  }

  cancelApproval(documentId: number, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("documentId", documentId.toString())
    this.internetTool.ft(
      "/approval",
      {
        method: "DELETE",
        body: formData
      },
      resultParam
    )
  }

  complete(documentId: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${documentId}/complete`,
      {
        method: "PUT",
      },
      resultParam
    )
  }

  uploadDocument(file: File, documentName: string, caseId: number, masterId?: number, typeId?: number, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("documentName", documentName)
    formData.append("caseId", caseId.toString())
    if (typeId) {
      formData.append("typeId", typeId.toString())
    }
    if (masterId) {
      formData.append("masterId", masterId.toString())
    }
    this.internetTool.ft(
      "/upload",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  reupload(file: File, documentId: number, resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("documentId", documentId.toString())
    this.internetTool.ft(
      "/reupload",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  updateDocumentPage(arr: number[][], resultParam?: ResultParam<void>) {
    const formData = new FormData()
    formData.append("list", new Blob([JSON.stringify(arr)], { type: "application/json" }))
    this.internetTool.ft("/page-number", {
      method: "PUT",
      body: formData
    }, resultParam)
  }
}

export default ApiDocument