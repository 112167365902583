import Region from "../entity/Region"
import InternetTool from "./Internetool"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
class ApiAdminRegion {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/region", message, navigate)
  }
  addRegion(region: Region, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("region", new Blob([JSON.stringify(region)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  getRegions(resultParam?: ResultParam<Array<Region>>) {
    this.internetTool.ft(
      "",
      {
        method: "GET"
      },
      resultParam
    )
  }
  updateRegion(region: Region, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("region", new Blob([JSON.stringify(region)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  deleteRegion(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
}
export default ApiAdminRegion