import { Button, Form, Input, message, Select, Upload } from 'antd';
import Module from '../style/wordmain.module.scss'
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Role from "../entity/Role";
import { ApiDocumentType } from "../internet/ApiDocumentType";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { ApiAdminDocumentType } from '../internet/ApiAdminDocumentType';
import { ApiAdminConfiguration } from '../internet/ApiAdminConfiguration';
import ApiDocument from '../internet/ApiDocument';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 10 },
};

const UploadDocument: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const caseId = (location.state as { caseId?: number })?.caseId
  const masterId = (location.state as { masterId?: number })?.masterId
  const complate = (location.state as { complate?: boolean })?.complate
  const recomplate = (location.state as { recomplate?: boolean })?.recomplate
  const documentId = (location.state as { documentId?: number })?.documentId
  const typeId = (location.state as { typeId?: number })?.typeId
  const [form] = Form.useForm<{ name: string, file: File }>()
  const apiDocument = new ApiDocument(message, navigate)
  function uploadDocument() {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "pdf")) {
          message.error("文件后缀名错误")
          return;
        }
        if (caseId) {
          apiDocument.uploadDocument(file, values.name, caseId, masterId, typeId, {
            exit: 3,
            successHandler: () => {
              message.success("上传成功")
            }
          })
        }
      })
  }
  function reupload() {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "pdf")) {
          message.error("文件后缀名错误")
          return;
        }
        if (documentId) {
          apiDocument.reupload(file, documentId, {
            exit: 2,
            successHandler: () => {
              message.success("上传成功")
            }
          })
        }
      })
  }
  function addDocument() {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "pdf")) {
          message.error("文件后缀名错误")
          return;
        }
        if (caseId) {
          apiDocument.uploadDocument(file, values.name, caseId, documentId, undefined, {
            exit: 2, successHandler: () => {
              message.success("上传成功")
            }
          })
        }
      })
  }
  function configFile(arg: UploadChangeParam): File | undefined {
    const file = arg.file.originFileObj;
    if (file) {
      form.setFieldsValue({ name: file.name.replace(/(\.pdf)/, "") })
      return file
    } else {
      form.setFieldsValue({ file: undefined })
      return undefined
    }
  }
  let btn;
  if (complate) {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <h1>文件上传</h1>
      <Form.Item
        label="文件名称"
        className={Module.ite}
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept={".pdf"}
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button type="primary" style={{ marginLeft: 170 }} onClick={uploadDocument}>确认上传</Button>
      </Form.Item>
    </Form>
  } else if (recomplate) {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <h1>文件上传</h1>
      <Form.Item
        label="文件名称"
        className={Module.ite}
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept={".pdf"}
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button type="primary" style={{ marginLeft: 170 }} onClick={reupload}>确认上传</Button>
      </Form.Item>
    </Form>
  }
  else {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <h1>文件上传</h1>
      <Form.Item
        label="文书模板名称"
        className={Module.ite}
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept={".pdf"}
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择附件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button type="primary" style={{ marginLeft: 170 }} onClick={addDocument}>确认上传</Button>
      </Form.Item>
    </Form>
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        {btn}
      </div>
    </div>
  );
};

export default UploadDocument;