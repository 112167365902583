import { MenuOutlined } from '@ant-design/icons';
import { Button, message, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useState } from 'react';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Module from '../style/jzong.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import ApiDocument from "../internet/ApiDocument";
import DocumentInfo from "../entity/documentinfo";


const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);


const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
  <tr {...props} />
));

const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
  <tbody {...props} />
));


const Jzong: React.FC = () => {
  function render(text: any, record: DocumentInfo, index: number) {
    let page = infos.map((item, index) => {
      return item.pageNumber
    })
      .filter(page => page)
      .map(page => page as number)
    if (index === 0) {
      record.startPageNumber = index + 1
      return (index + 1 + "-" + page[0])
    } else {
      let sum = 0
      for (let i = 0; i < index; i++) {
        sum += page[i]
      }
      record.startPageNumber = sum + 1
      return sum + 1 + "-" + (page[index] + sum)
    }
  }

  const columns: ColumnsType<DocumentInfo> = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      render: () => sortable,
    },
    {
      title: '文档名称',
      dataIndex: 'documentName',
      width: 200,
    },
    {
      title: '页号范围',
      dataIndex: 'pageNumber',
      render: render,
      align: "center",

    },
    {
      title: '操作',
      dataIndex: 'sort',
      width: 100,
      align: "center",
      render: (_, record) => {
        return (<Button type="link" onClick={() => {
          navigate("/pdf", { state: { documentId: record.id, status: record.status } })
        }
        }>查看详细</Button>)
      }
    },
  ];
  const [infos, setInfos] = useState<DocumentInfo[]>([])
  const location = useLocation()
  const navigate = useNavigate()
  const caseId = (location.state as { caseId?: number })?.caseId
  const complete = (location.state as { complete?: boolean })?.complete

  const apiDocument = new ApiDocument(message, navigate)

  function sort(a: DocumentInfo) {
    let aNumber;
    if (a.documentName.includes("封面")) {
      aNumber = -2000 + (a.startPageNumber ?? 0)
    } else if (a.documentName.includes("目录")) {
      aNumber = -1000 + (a.startPageNumber ?? 0)
    } else {
      aNumber = a.startPageNumber ?? 0
    }
    return aNumber
  }
  function getDocuments(caseId: number) {
    apiDocument.getInfos(caseId, {
      successHandler: documents => {
        if (documents) {
          if (documents.some(info => info.status !== "已完成")) {
            message.error("请先完成所有文书!")
            navigate(-1)
          } else {
            setInfos(documents.sort((a, b) => {
              return sort(a) - sort(b)
            }))
          }
        }
      }
    })
  }
  useEffect(() => {
    if (!caseId) {
      navigate(-1)
    } else {
      getDocuments(caseId)
    }
  }, [location])

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(infos.slice(), oldIndex, newIndex,).filter(
        (el: DocumentInfo) => !!el,
      );
      setInfos(newData);
    }
  }
  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = infos.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  function selectMenu() {
    if (infos.length) {
      infos.forEach((value: DocumentInfo, index: number, array: DocumentInfo[]) => {
        if (value.pageNumber) {
          const lastDocument = array[index - 1]
          value.startPageNumber = (lastDocument?.startPageNumber ?? 0) + (lastDocument?.pageNumber ?? 1)
        }
      })
      apiDocument.updateDocumentPage(
        infos.map(it => [it.id, it.startPageNumber ?? 0])
          .filter(it => it[1] !== 0), {
        successHandler: () => {
          navigate("/writ", { state: { infos: infos, limit: "目录", caseId: caseId } })
        }, exit: 0
      })
    }
  }
  let btn: JSX.Element = <></>;
  let sortable: JSX.Element;
  if (!complete) {
    if (caseId) {
      sortable = <DragHandle />
      btn = <Button type="primary" style={{ marginLeft: 3, marginTop: 3 }} onClick={selectMenu}>生成目录</Button>
    } else {
      btn = <></>
      sortable = <></>
    }
  }
  return (
    <div className={Module.all}>
      {btn}
      <Table
        className={Module.table
        }
        pagination={false}
        dataSource={infos}
        columns={columns}
        rowKey="id"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
};

export default Jzong;