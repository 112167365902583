export const ArrayTool = {
  subtractById: function (a: Array<any>, b: Array<any>, me: any | undefined = undefined): Array<any> {
    const c = [...b]
    if (me) {
      c.push(me)
    }
    return a.filter(item => !c.find(bi => bi.id === item.id))
  },
  subtract: function (a: Array<any>, b: Array<any>, me: any | undefined = undefined): Array<any> {
    const c = [...b]
    if (me) {
      c.push(me)
    }
    return a.filter(item => !c.includes(item))
  },
}