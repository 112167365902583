import { Col, Row } from 'antd';
import React from 'react';
import Module from '../style/cover.module.scss'
const LawCase: React.FC = () => {
    return (
        <div className={Module.all} >
            <div className={Module.margin}>
                <Row className={Module.row}>
                    <Col className={Module.col}><h1>盐边县林业局</h1></Col>
                </Row>
                <Row className={Module.row1}>
                    <Col className={Module.col1}><h1>XXXX处罚书</h1></Col>
                </Row>
                <Row className={Module.row2}>
                    <label ><h3>案件编号</h3></label><input disabled defaultValue={"边林罚立字〔2021〕30号"} />
                </Row>
                <Row className={Module.row3}>
                    <label ><h3>案件类别</h3></label><input defaultValue={"毁坏林木、林地案件"} />
                </Row>
                <Row className={Module.row4}>
                    <label ><h3>案  由</h3></label><input defaultValue={"盐边县富丰工贸有限责任公司擅自改变林地用途"} />
                </Row>
                <Row className={Module.row5}>
                    <label ><h3>当事人</h3></label><input defaultValue={"盐边县富丰工贸有限责任公司"} />
                </Row>
                <Row className={Module.row6}>
                    <label ><h3>立案时间:</h3></label>
                    <label ><h3>结案时间:</h3></label>
                </Row>

            </div>
        </div>


    )

};

export default LawCase;