import FieldTypeEntity from "./FieldTypeEntity";

class FieldEntity {
  id: number = 0
  /**
   * 所属文档
   */
  documentId: number = 0
  /**
   * 字段类型
   */
  typeId: number | undefined = undefined
  parentId: number | undefined = undefined
  index: number | undefined = undefined
  baseTypeId: number
  fieldCode: string
  fieldName: string
  /**
   * 字段值
   */
  fieldValue: string | undefined = undefined
  defaultStr: string = ""
  permissionRoleId?: number | undefined = undefined
  fillRoleId?: number | undefined = undefined
  children?: Array<Array<FieldEntity>> | undefined = undefined
  fieldType?: FieldTypeEntity | undefined


  constructor(
      id: number,
      documentId: number,
      typeId: number | undefined,
      parentId: number | undefined,
      index: number | undefined,
      baseTypeId: number,
      fieldCode: string,
      fieldName: string,
      fieldValue: string | undefined,
      defaultStr: string,
      permissionRoleId?: number | undefined,
      fillRoleId?: number | undefined,
      children?: Array<Array<FieldEntity>> | undefined,
      fieldType?: FieldTypeEntity | undefined) {
    this.id = id;
    this.documentId = documentId;
    this.typeId = typeId;
    this.parentId = parentId;
    this.index = index;
    this.baseTypeId = baseTypeId;
    this.fieldCode = fieldCode;
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
    this.defaultStr = defaultStr;
    this.permissionRoleId = permissionRoleId;
    this.fillRoleId = fillRoleId;
    this.children = children;
    this.fieldType = fieldType;
  }
}

export default FieldEntity