import { Button, message, Table } from "antd"
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Role from "../entity/Role";
import Module from '../style/wordroles.module.scss'
import { ApiAdminDocumentType } from "../internet/ApiAdminDocumentType";

const columns: ColumnsType<Role> = [
  {
    title: '权限名称',
    dataIndex: 'roleDescription',
  }

]

const WordRoles: React.FC = () => {
  const [data, setData] = useState<Role[]>([])
  const location = useLocation()
  const documentTypeId = (location.state as { documentTypeId?: number })?.documentTypeId
  const navigate = useNavigate()
  const apiAdminDocumentType = new ApiAdminDocumentType(message, navigate)
  const rowClick = (role: Role) => {
    return {
      onClick: () => {
        navigate("/wordrolesname", { state: { role: role, documentTypeId: documentTypeId } })
      }
    }
  }
  const addClick = () => [
    navigate("/wordrolesname", { state: { documentTypeId: documentTypeId } })
  ]

  function getRoles() {
    if (documentTypeId) {
      
      apiAdminDocumentType.getDocumentTypeRole(documentTypeId, {
        successHandler: roles => {
          if (roles) {
            setData(roles)
          }
        }
      })
    }
  }

  useEffect(() => {
    if (!documentTypeId) {
      navigate(-1)
    } else {
      getRoles()
    }
  }, [])
  return (
    <div className={Module.all}>
      <Button type="primary" onClick={addClick}>增加权限</Button>
      <Table
        pagination={false}
        size="small"
        expandable={{ childrenColumnName: "children1234" }}
        bordered
        className={Module.list}
        dataSource={data}
        columns={columns}
        onRow={rowClick}
      />
    </div>
  )
}

export default WordRoles