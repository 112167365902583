import { Button, Card, Form, Input, message, Select } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCase } from '../internet/ApiCase';
import SuspectPerson from '../entity/SuspectPerson';
import Module from '../style/criminal.module.scss'
import IdCardTool from '../tool/IdCardTool';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 19 },
};
const { Option } = Select
const Criminal: React.FC = () => {
  const [form] = Form.useForm<SuspectPerson>();
  const location = useLocation()
  const state = (location.state as { criminal?: SuspectPerson, caseId?: number, })
  const caseId = state?.caseId
  const navigate = useNavigate()
  const criminal = state?.criminal
  const apiCase = new ApiCase(message, navigate)
  const criminalChange = () => {
    if (criminal) {
      form.setFieldsValue(criminal)
    }
  }
  useEffect(() => {
    if (!caseId) {
      navigate(-1)
    }
    criminalChange()
  }, [location])

  function addCriminal(person: SuspectPerson, caseId: number) {
    person.caseId = caseId
    apiCase.addSuspectPerson(person)
  }

  function deleteCriminal(criminalId: number) {
    apiCase.deleteSuspectPerson(criminalId)
  }

  const updateCriminal = (person: SuspectPerson, criminalId: number, caseId: number) => {
    person.caseId = caseId
    person.id = criminalId
    apiCase.updateSuspectPerson(person)
  }

  let button: JSX.Element = <></>
  let onFinish = (_: SuspectPerson) => {
  }
  const criminalId = criminal?.id;
  if (caseId)
    if (criminalId) {
      onFinish = (person: SuspectPerson) => {
        updateCriminal(person, criminalId, caseId)
      }
      button = (
        <Form.Item className={Module.item}>
          <Button type="primary" onClick={() => deleteCriminal(criminalId)}>删除</Button>
          <Button type="primary" htmlType={"submit"}>修改</Button>
        </Form.Item>
      )
    } else {
      onFinish = (person: SuspectPerson) => {
        addCriminal(person, caseId)
      }
      button = (
        <Form.Item className={Module.item}>
          <Button type="primary" htmlType={"submit"}>添加</Button>
        </Form.Item>
      )
    }
  const idCard = new IdCardTool()
  return (
    <div className={Module.all}>
      <Card className={Module.card}>
        <Form
          {...layout}
          onFinish={onFinish}
          name='添加嫌疑人员'
          form={form}
          className={Module.form1}
        >
          <h1 style={{ fontSize: 20 }}>嫌疑人信息</h1>
          <Form.Item
            label="嫌疑人姓名"
            className={Module.item1}
            name="suspectName"
            rules={[
              {
                required: true,
                message: '该字段不能为空',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="gender"
            className={Module.item}
            label="性别"
            initialValue={true}

          >
            <Select className={Module.selset}>
              <Option key={1} value={true}>
                男
              </Option>
              <Option key={2} value={false}>
                女
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="age"
            className={Module.item}
            label="年龄"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="race"
            className={Module.item}
            label="民族"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="identificationCard"
            className={Module.item}
            label="身份证号码"
            rules={[
              {
                required: true,
                message: "身份证号码格式错误",
                validator: (rule, value) => {
                  if (idCard.validIdCard(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('身份证号码格式错误!'));
                }
              },

            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobilePhone"
            className={Module.item}
            label="手机号码"

          >
            <Input />

          </Form.Item>
          <Form.Item
            name="address"
            className={Module.item}
            label="现居住地"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="domicilePlace"
            className={Module.item}
            label="户籍地址"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="unitName"
            className={Module.item}
            label="单位地址"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="position"
            className={Module.item}
            label="职位"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="occupation"
            className={Module.item}
            label="职业"
          >
            <Input />
          </Form.Item>
          {button}
        </Form>
      </Card>
    </div>
  );
};

export default Criminal;