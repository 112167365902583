import Table, { ColumnsType } from 'antd/lib/table';
import React from 'react';

interface DataType {
    key: number;
    id: string;
    type: string;
    name: string;
    politics: string;
    num: string;
    feature: string;
    deal: string;
}
const columns: ColumnsType<DataType> = [
    {
        title: '物品名称',
        dataIndex: 'id',
        width: 150,
    },
    {
        title: '物品类别',
        dataIndex: 'name',
        width: 100,
    },
    {
        title: '规格型号',
        dataIndex: 'type',
        width: 100,
    },
    {
        title: '数量',
        dataIndex: 'num',
        width: 100,
    },
    {
        title: '单位',
        dataIndex: 'politics',
        width: 100,
    },
    {
        title: '特征',
        dataIndex: 'phone',
        width: 100,
    },
    {
        title: '处理',
        dataIndex: 'deal',
        width: 150,
    },

];

const data: DataType[] = [
    {
        key: 1,
        id: "镰刀",
        name: "刀具",
        type: "001",
        num: "13",
        politics: "长沙市XX市XX乡",
        feature: "形状奇特",
        deal: "回收"
    },
    {
        key: 2,
        id: "镰刀",
        name: "刀具",
        type: "001",
        num: "13",
        politics: "长沙市XX市XX乡",
        feature: "形状奇特",
        deal: "回收"
    },
    {
        key: 3,
        id: "镰刀",
        name: "刀具",
        type: "001",
        num: "13",
        politics: "长沙市XX市XX乡",
        feature: "形状奇特",
        deal: "回收"
    },
    {
        key: 4,
        id: "镰刀",
        name: "刀具",
        type: "001",
        num: "13",
        politics: "长沙市XX市XX乡",
        feature: "形状奇特",
        deal: "回收"
    },
    {
        key: 5,
        id: "镰刀",
        name: "刀具",
        type: "001",
        num: "13",
        politics: "长沙市XX市XX乡",
        feature: "形状奇特",
        deal: "回收"
    },
]

const Lawfgoods = () => {

    return (
        <Table
            dataSource={data}
            columns={columns}
            pagination={{ pageSize: 40 }}
            scroll={{ y: 240 }}
        />
    )
}
export default Lawfgoods