import { message, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ApiDocument from "../internet/ApiDocument"
import { ApiDocumentType } from "../internet/ApiDocumentType"
import DocumentInfo from "../entity/documentinfo"
import DocumentTypeEntity from "../entity/DocumentTypeEntity"
import Module from '../style/WritList.module.scss'
import DocumentEntity from "../entity/DocumentEntity"


const WritList = () => {
    const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([])
    const [documentInfos, setInfos] = useState<DocumentInfo[]>([])
    const navigate = useNavigate()
    const apiDocument = new ApiDocument(message, navigate)
    const apiDocumentType = new ApiDocumentType(message, navigate)
    const location = useLocation()
    const caseId = (location.state as { caseId?: number })?.caseId
    const infos = (location.state as { infos?: DocumentInfo[] })?.infos
    const document = (location.state as { document?: DocumentEntity })?.document
    function getInfos() {
        apiDocument.getInfos(caseId, {
            successHandler: infos => {
                if (infos) {
                    setInfos(infos)
                }
            }
        })
    }
    function getDocumentTypes() {
        apiDocumentType.getDocumentTypes({
            successHandler: types => {
                if (types) {
                    setDocumentTypes(types)
                }
            }
        })

    }


    useEffect(() => {
        getInfos()
        getDocumentTypes()
    }, [location])

    function compareStatus(status: string) {
        if (status === "已完成") {
            return 0
        } else if (status === "已审批") {
            return 1
        }
        else if (status === "审批中") {
            return 2
        }
        else if (status === "未审批") {
            return 3
        }
        else {
            return 4
        }
    }
    const filter = (record: DocumentTypeEntity) => {
        let flag
        const documentInfos1 = documentInfos.filter(info => (info.typeId === record.id || info.documentName === record.typeName))
        const status = documentInfos1.map(info => {
            return info.status
        }).sort((a, b) => {
            return compareStatus(b) - compareStatus(a)
        })

        if (!status.length) {
            flag = <span className={Module.span1} style={{ marginRight: 10, }} ><span className={Module.text1}></span></span>
        } else {
            if (status[0] === "已完成") {
                flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "#8eb855" }} >
                    <span className={Module.text}>{status.length}</span>
                </span>
            } else if (status[0] === "已审批") {
                flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "#506de0" }} >
                    <span className={Module.text}>{status.length}</span>
                </span>
            }
            else if (status[0] === "审批中") {
                flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "orange" }} >
                    <span className={Module.text}>{status.length}</span>
                </span>
            }
            else if (status[0] === "未审批") {
                flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "#c1c1c1" }} >
                    <span className={Module.text}>{status.length}</span>
                </span>
            }
            else {
                flag = <span className={Module.span} style={{ marginRight: 10, backgroundColor: "#d81322" }} >
                    <span className={Module.text}>{status.length}</span>
                </span>
            }
        }
        return (<span>{flag}{record.typeName}</span>)
    }
    const columns: ColumnsType<DocumentTypeEntity> = [
        {
            title: "",
            dataIndex: "typeName",
            render: (value: any, record: DocumentTypeEntity, index: number) => {
                return filter(record)
            }
        }
    ]
    const order: string[] = ["案件来源登记表", "立案登记表", "立案审批表", "立案决定书", "行政处罚决定法制审核意见书", "行政处罚告知书", "行政处罚决定审批表", "责令改正违法行为通知书", "行政处罚决定书", "行政处罚案件结案审批表", "案件调查终结报告", "权利义务告知书", "送达地址确认书", "送达回证", "现场勘验笔录", "行政处罚卷宗封面", "询问通知书"]
    function documentSort(a: DocumentTypeEntity, b: DocumentTypeEntity) {
        const indexA = order.indexOf(a.typeName);
        const indexB = order.indexOf(b.typeName);
        if (indexA === -1 && indexB === -1) {
            return a.typeName.localeCompare(b.typeName);
        } else if (indexA === -1) {
            return 1;
        } else if (indexB === -1) {
            return -1;
        } else {
            return indexA - indexB;
        }
    }

    const onClick = (documentType: DocumentTypeEntity) => {
        return {
            onClick: () => {
                navigate("/wenshu", {
                    state: {
                        documentTypeId: documentType.id,
                        caseId: caseId,
                        infos: infos,
                        menu: document
                    }
                })
            }
        }
    }

    return (
        <div className={Module.all}>
            <div>
                <Tag color="green">已生成</Tag>
                <Tag color="blue">已审批</Tag>
                <Tag color="red">未生成</Tag>
                <Tag color="warning">审批中</Tag>
                <Tag color="#c1c1c1">未审批</Tag>
            </div>
            <div className={Module.center}>
                <Table
                    className={Module.table}
                    size="small"
                    columns={columns}
                    pagination={false}
                    dataSource={documentTypes.sort(documentSort)}
                    rowKey="id"
                    onRow={onClick}
                />
            </div>
        </div>
    )
}

export default WritList