import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/reportCompaint.module.scss'
export default function ReportCompaint(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>举报投诉处理单</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.a1}>
                      <p>举报标题</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[0, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="举报标题"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                    <div className={Module.a3}>
                      <p>区域</p>
                    </div>
                    <div className={Module.a4}>
                      <Form.Item
                        name={[1, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input
                          placeholder="区域"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>举报方式</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[2, "fieldValue"]}
                        initialValue="☑"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 2, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>电话</p>
                      <Form.Item
                        name={[3, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 3, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>信函</p>
                      <Form.Item
                        name={[4, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 4, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>传真</p>
                      <Form.Item
                        name={[5, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>电子邮件</p>
                      <Form.Item
                        name={[6, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 6, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>来访</p>
                      <Form.Item
                        name={[7, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>信息网络</p>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}><p>举报行业</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[8, "fieldValue"]}
                        initialValue="☑"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 8, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>城市管理</p>
                      <Form.Item
                        name={[9, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 9, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>城乡规划</p>
                      <Form.Item
                        name={[10, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 10, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>国土资源</p>
                      <Form.Item
                        name={[11, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 11, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>交通运输</p>
                      <Form.Item
                        name={[12, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 12, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>水务</p>
                      <Form.Item
                        name={[13, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 13, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>文化体育旅游</p>
                      <Form.Item
                        name={[14, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 14, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>住房和建设</p>
                      <Form.Item
                        name={[15, "fieldValue"]}
                        initialValue="⬜"
                        className={Module.item}
                      >
                        <Input readOnly
                          onClick={() => props.changeCheckbox(["fields", 15, "fieldValue"])}
                        />
                      </Form.Item>
                      <p>其他</p>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.a1}>
                      <p>举报时间</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[16, "fieldValue"]}
                        className={Module.item}
                      >
                        <StringDatePicker
                          format={"YYYY年MM月DD日"}
                          placeholder="举报时间"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1}>
                      <p>举报人信息</p>
                    </div>
                    <div className={Module.a2}>
                      <div className={Module.e1}>
                        <div className={Module.f1}>
                          <p>姓名（名称）</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[17, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="姓名（名称）"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.f3}>
                          <p>性别</p>
                        </div>
                        <div className={Module.f4}>
                          <Form.Item
                            name={[18, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="性别"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e2}>
                        <div className={Module.f1}>
                          <p>联系电话</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[19, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="联系电话"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                        <div className={Module.f3}>
                          <p>电子邮件</p>
                        </div>
                        <div className={Module.f4}>
                          <Form.Item
                            name={[20, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="电子邮件"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e3}>
                        <div className={Module.f1}>
                          <p>通讯地址</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[21, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="通讯地址"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e4}>
                        <div className={Module.f1}>
                          <p>是否要求回复</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[22, "fieldValue"]}
                            initialValue="⬜"
                            className={Module.item}
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 22, "fieldValue"])}
                            />
                          </Form.Item>
                          <p>是</p>
                          <Form.Item
                            name={[23, "fieldValue"]}
                            initialValue="⬜"
                            className={Module.item}
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 23, "fieldValue"])}
                            />
                          </Form.Item>
                          <p>否</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row8}>
                    <div className={Module.a1}>
                      <p>被举报人信息</p>
                    </div>
                    <div className={Module.a2}>
                      <div className={Module.e3}>
                        <div className={Module.f1}>
                          <p>名称</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[24, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="名称"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e3}>
                        <div className={Module.f1}>
                          <p>地址</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[25, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="地址"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.e4}>
                        <div className={Module.f1}>
                          <p>联系方式</p>
                        </div>
                        <div className={Module.f2}>
                          <Form.Item
                            name={[26, "fieldValue"]}
                            className={Module.item}
                          >
                            <Input
                              placeholder="通讯地址"
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.a1}>
                      <p>举报内容</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[27, "fieldValue"]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 20 }}
                          placeholder="举报内容"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 27, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>举报受理部门意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[28, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            placeholder="举报受理部门意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 28, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[34, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 34, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="举报受理部门签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[29, "fieldValue"]} className={Module.sig}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 29, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.a1}>
                      <p>查处情况及结果</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[30, "fieldValue"]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 20 }}
                          placeholder="查处情况及结果"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 30, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.a1}>
                      <p>回复情况</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[31, "fieldValue"]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 20 }}
                          placeholder="回复情况"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 31, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>承办部门负责人审核意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[32, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="承办部门负责人审核意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 32, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[36, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 36, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="承办部门负责人签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className={Module.sig}
                          name={[29, "fieldValue"]}>
                          <StringDatePicker
                            className={Module.sig}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 29, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} placeholder="签名日期" />
                        </Form.Item>
                        <Form.Item
                          className={Module.sig}
                          name={[35, "fieldValue"]} >
                          <Select placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 36, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>

                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.a1}>
                      <p>附件及备注</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        name={[33, "fieldValue"]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 20 }}
                          placeholder="附件及备注"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 33, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}