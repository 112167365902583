import { Button, message, Table, Upload } from 'antd';
import Module from '../style/summaryTable.module.scss'
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { ColumnsType } from 'antd/lib/table';
import { ApiTool } from '../internet/ApiTool';
import { Summary } from '../entity/Summary';
import { ForestPatroLedger } from '../entity/ForestPatrolLedger';
import { ForestSummary } from '../entity/foresSummary';


// function handleDelete(name: string | null) {
//   const newFileData = data1.filter(fileName => fileName.name !== name)
//   setData1(newFileData)
// }
const columns: ColumnsType<Summary> = [
  {
    title: '地区',
    dataIndex: 'name',
  },
  {
    title: '巡林记录表',
    width: 100,
    align: "center",
    render: (value: any, record: Summary, index: number) => {
      if (record.name) {
        if (record.forestPatrolTable) {
          return <span style={{ color: "green" }} >已上传</span>
        }
        return <span style={{ color: "red" }} >未上传</span>
      }
      return <></>
    }
  },
  {
    title: '巡林问题台账',
    width: 120,
    align: "center",
    render: (value: any, record: Summary, index: number) => {
      if (record.name) {
        if (record.ledgerTable) {
          return <span style={{ color: "green" }} >已上传</span>
        }
        return <span style={{ color: "red" }} >未上传</span>
      }
      return <></>
    }
  },
  // {
  //   title: '操作',
  //   width: 80,
  //   align: "center",
  //   render: (value: any, record: Summary, index: number) => {
  //     return <Button danger onClick={(e) => {
  //       e.stopPropagation()
  //       handleDelete(record.name)
  //     }}>删除</Button>
  //   }
  // },

];

const SummaryTable: React.FC<{ setStoreData: (storeData: Summary[]) => void, storeData: Summary[] }> = ({ setStoreData, storeData }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [fileList, setFileList] = useState<(RcFile | undefined)[]>([])
  const [fileList1, setFileList1] = useState<UploadFile[]>([])
  const apiTool = new ApiTool(message, navigate)

  function mergeSummaries(data1: Summary[], data: Summary[]): Summary[] {
    const mergedSummaries: Summary[] = [];
    for (const value of data) {
      if (data.some(value1 => value1.name === value.name)) {
        const newData = [...data1, ...data];
        for (const summary of newData) {
          const existingSummary = mergedSummaries.find(s => s.name === summary.name);
          if (!existingSummary) {
            mergedSummaries.push(summary);
          } else {
            if (!existingSummary.ledgerTable) {
              existingSummary.ledgerTable = summary.ledgerTable;
            }
            if (!existingSummary.forestPatrolTable) {
              existingSummary.forestPatrolTable = summary.forestPatrolTable;
            }
          }
        }
      } else {
        mergedSummaries.push(value);
      }
    }
    const uniqueSummaries = Array.from(new Set(mergedSummaries));
    setStoreData(uniqueSummaries);
    return uniqueSummaries;
  }

  function addFile() {
    apiTool.getRecordTool(fileList, {
      exit: 0,
      successHandler: data => {
        if (data) {
          if (storeData.length) {
            mergeSummaries(storeData, data)
          }
          else {
            setStoreData(data)
          }
          message.success("上传成功")
          setFileList1([])
        }
      }
    })
  }
  function summarizeForestSummary(data: ForestSummary[]): ForestSummary {
    return data.reduce((acc, cur) => {
      return {
        classes: cur.classes,
        findTheProblemNumber: (acc.findTheProblemNumber ?? 0) + (cur.findTheProblemNumber ?? 0),
        forestAmount: (acc.forestAmount ?? 0) + (cur.forestAmount ?? 0),
        forestPatrolAmount: (acc.forestPatrolAmount ?? 0) + (cur.forestPatrolAmount ?? 0),
        forestPatrolFrequency: (acc.forestPatrolFrequency ?? 0) + (cur.forestPatrolFrequency ?? 0),
        id: cur.id,
        region: cur.region,
        remark: cur.remark,
        grade: cur.grade,
        forestPatrolTime: cur.forestPatrolTime
      }
    });
  }
  function addAll() {
    let ledger: ForestPatroLedger[] = [];
    storeData.map(i => ledger.push(...i.ledgerTable));
    let forestSummary: ForestSummary[] = [];
    storeData.map(i => forestSummary.push(...i.forestPatrolTable));
    let contury: ForestSummary[] = [];
    let subContury: ForestSummary[] = [];
    let village: ForestSummary[] = [];
    let subVillage: ForestSummary[] = [];
    let newForestSummary: ForestSummary[] = [];

    forestSummary.forEach(a => {
      switch (a.classes) {
        case "乡级林长":
          contury.push(a);
          break;
        case "乡级副林长":
          subContury.push(a);
          break;
        case "村级林长":
          village.push(a);
          break;
        case "村级副林长":
          subVillage.push(a);
          break;
        default:
          break;
      }
    });

    newForestSummary.push(
      summarizeForestSummary(contury),
      summarizeForestSummary(subContury),
      summarizeForestSummary(village),
      summarizeForestSummary(subVillage)
    );
    const newSummary = new Summary("", ledger, newForestSummary);
    setStoreData([]);
    console.log(newSummary);

    navigate("newsummarydata", { state: { allData: newSummary } });
  }

  function onChage(info: UploadChangeParam) {
    const file = info.fileList.map(i => i.originFileObj)
    setFileList(file)
    setFileList1(info.fileList)
    info.file.status = "success"
  }
  const rowClick = (data: Summary) => {
    return {
      onClick: () => {
        navigate("newsummarydata", { state: { data: data, show: true } })
      }
    }
  }
  const props1 = {
    multiple: true,
    accept: ".xls,.xlsx,.doc,.docx",
    name: "file",
    fileList: fileList1,
    onChange: onChage,
    beforeUpload: () => { return false }
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <div className={Module.uplode}>
          <p> 文件上传</p>
          <div className={Module.uploadFile}>
            <Upload
              {...props1}
            >
              <Button icon={<UploadOutlined />}>选择文件</Button>
            </Upload>
          </div>
          <Button type="primary" disabled={!fileList.length} className={Module.addFile} onClick={addFile}>确认上传</Button>
        </div>
        <div className={Module.table}>
          <Table
            size="small"
            columns={columns}
            dataSource={storeData}
            onRow={rowClick}
            pagination={false}
            rowKey="name"
          />
        </div>
        <Button type="primary" disabled={!storeData.length} className={Module.add} onClick={addAll}>确认信息添加</Button>
        <Button type="primary" className={Module.add} onClick={() => setStoreData([])}>清空列表</Button>
      </div>
    </div >
  );
};

export default SummaryTable;