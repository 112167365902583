import React, {useEffect} from "react";
import {User} from "../entity/User";
import UserEntity from "../entity/UserEntity";

const Logout: React.FC<{ setUser: (users: UserEntity | null) => void }> = (props) => {
  useEffect(() => {
    props.setUser(null)
  }, [])
  return <div/>
}
export default Logout;