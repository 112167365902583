import { Button, Col, DatePicker, Form, Input, Row, Select, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import Lawfgoods from '../lawlist/lawgoods';
import Lawfwork from '../lawlist/lawwork';
import Lawfyj from '../lawlist/lawyj';
import Lawfzj from '../lawlist/lawzj';
import Recordlive from '../lawlist/record';
import Module from '../style/dossier.module.scss'
const Dossier: React.FC = () => {

    const { Option } = Select;
    const { TextArea } = Input;
    const { TabPane } = Tabs;
    const navegate = useNavigate()

    const crimnalChange = () => {
        navegate("/criminal")
    }
    const workChange = () => {
        navegate("/work")
    }
    const booksChange = () => {
        navegate("/books")
    }
    const zjlistChange = () => {
        navegate("/zjlist")
    }
    const punishChange = () => {
        navegate("/punish")
    }
    const liveChange = () => {
        navegate("/live")
    }
    const jzList = () => {
        navegate("/jzlist")
    }
    const jzfm = () => {
        navegate("/jzong")
    }
    const jzmlChange = () => {
        navegate("/cover")
    }
    const [form] = Form.useForm()
    return (

        <div className={Module.all}>
            <div className={Module.cen}>
                <div className={Module.btnt}>
                    <Button onClick={jzList} className={Module.hbtn} type="link" >卷宗列表</Button>
                    <Button onClick={jzfm} className={Module.hbtn} type="link" >卷宗目录</Button>
                    <Button onClick={jzmlChange} className={Module.hbtn} type="link" >卷宗封面</Button>
                </div>

                <Form
                    name='表单'
                    form={form}
                    layout="vertical"
                    className={Module.form}
                >

                    <div className={Module.head}><span>案件信息</span> </div>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                key={1}
                                name={"案件编号"}
                                initialValue={"20021215"}
                                className={Module.item1} >
                                <label >案件编号: <Input defaultValue={"20021215"} className={Module.input} /></label>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                key={2}
                                name={"案件名称"}
                                initialValue={"盐边县林业局"}
                                className={Module.item1} >
                                <label >案件名称: <Input defaultValue={"盐边县林业局"} className={Module.input} /></label>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label className={Module.lab} >主办单位：</label>
                            <Form.Item
                                key={3}
                                name={"主办单位"}
                                className={Module.ite} >
                                <Select className={Module.select} >
                                    <Option value="公安局">公安局</Option>
                                    <Option value="林业局">林业局</Option>
                                </Select >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label className={Module.lab} >受案单位：</label>
                            <Form.Item
                                key={4}
                                name={"协办单位"}
                                className={Module.ite} >
                                <Select className={Module.select} >
                                    <Option value="公安局">公安局</Option>
                                    <Option value="林业局">林业局</Option>
                                </Select >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label className={Module.lab}> 办案日期：</label>
                            <Form.Item
                                key={5}
                                name={"办案日期"}
                                className={Module.ite}
                            >
                                <DatePicker className={Module.input} format="YYYY-MM-DD" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label className={Module.lab}>案件来源：</label>
                            <Form.Item
                                key={6}
                                name={"案件来源"}
                                className={Module.ite}
                            >
                                <Select className={Module.select} >
                                    <Option value="盗窃">盗窃</Option>
                                    <Option value="林木损坏">林木损坏</Option>
                                    <Option value="植被损坏">植被损坏</Option>
                                    <Option value="湿地损坏">湿地损坏</Option>
                                    <Option value="偷盗">偷盗</Option>
                                    <Option value="放火">放火</Option>
                                </Select >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label className={Module.lab}>案&emsp; &emsp; 由：</label>
                            <Form.Item
                                key={6}
                                name={"案件来由"}
                                className={Module.ite}
                            >
                                <Select className={Module.select} >
                                    <Option value="盗窃">盗窃</Option>
                                    <Option value="林木损坏">林木损坏</Option>
                                    <Option value="植被损坏">植被损坏</Option>
                                    <Option value="湿地损坏">湿地损坏</Option>
                                    <Option value="偷盗">偷盗</Option>
                                    <Option value="放火">放火</Option>
                                </Select >
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <label className={Module.lab}>违法条款：</label>
                            <Form.Item
                                key={6}
                                name={"案件来由"}
                                className={Module.ite}
                            >
                                <Select className={Module.select} >
                                    <Option value="盗窃">1</Option>
                                    <Option value="林木损坏">2</Option>
                                    <Option value="植被损坏">3</Option>
                                    <Option value="湿地损坏">4</Option>
                                    <Option value="偷盗">5</Option>
                                    <Option value="放火">6</Option>
                                </Select >
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className={Module.head}><span>案件地点</span></div>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={Module.item2}
                            >
                                <label >&emsp; &emsp; &emsp;市: <Select className={Module.select} ></Select ></label>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={Module.item2}
                            >
                                <label >&emsp;&emsp;区/县:<Select className={Module.select} ></Select ></label>
                            </Form.Item>
                        </Col >
                        <Col span={6}>
                            <Form.Item
                                className={Module.item2}
                            >
                                <label >&emsp;&emsp;乡镇: <Select className={Module.select} ></Select ></label>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={Module.item2}
                            >
                                <label >&emsp;街道/村: <Select className={Module.select} ></Select ></label>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={Module.item2}
                            >
                                <label >&emsp; 社区/组: <Select className={Module.select} ></Select ></label>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={Module.item2}
                            >
                                <label >案发地点: <Select className={Module.select} ></Select ></label>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        className={Module.item3}
                    >
                        <div className={Module.head}><span>主要案情</span></div>
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>

                <div draggable={true} className={Module.list}>
                    <div className={Module.listtable}>
                        <Tabs className={Module.TabPane} type="card" >
                            <TabPane tab="违法嫌疑人员" className={Module.TabPane} key="1">
                                <Button onClick={crimnalChange}>添加</Button>
                                <Button>编辑</Button>
                                <Button>删除</Button>
                            </TabPane>
                            <TabPane tab="违法嫌疑单位" key="2">
                                <Button onClick={workChange}>添加</Button>
                                <Button>编辑</Button>
                                <Button>删除</Button>
                                <Lawfwork />
                            </TabPane>
                            <TabPane tab="涉案物品" key="4">
                                <Button onClick={booksChange} >添加</Button>
                                <Button>编辑</Button>
                                <Button>删除</Button>
                                <Lawfgoods />
                            </TabPane>
                            <TabPane tab="证据清单" key="5">
                                <Button onClick={zjlistChange}>添加</Button>
                                <Button>编辑</Button>
                                <Button>删除</Button>
                                <Lawfzj />
                            </TabPane>
                            <TabPane tab="处罚意见" key="6">
                                <Button onClick={punishChange}>添加</Button>
                                <Button>编辑</Button>
                                <Button>删除</Button>
                                <Lawfyj />
                            </TabPane>
                            <TabPane tab="询问笔录" key="7">
                                <Button onClick={liveChange}>添加</Button>
                                <Button>编辑</Button>
                                <Button>删除</Button>
                                <Recordlive />
                            </TabPane>

                        </Tabs>

                    </div>
                </div >

            </div>
        </div >
    );
};

export default Dossier;