import { message, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentTypeEntity from '../entity/DocumentTypeEntity';
import Module from '../style/wordlist.module.scss'
import Role from "../entity/Role";
import { ApiDocumentType } from "../internet/ApiDocumentType";
import DocumentInfo from "../entity/documentinfo";
import DocumentEntity from "../entity/DocumentEntity";

const columns: ColumnsType<DocumentTypeEntity> = [
  {
    title: "",
    dataIndex: "typeName",
  }
]

const Writ: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [role, setRole] = useState<number | undefined>()
  const [roles, setRoles] = useState<Role[]>([])
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([])
  const caseId = (location.state as { caseId?: number })?.caseId
  const infos = (location.state as { infos?: DocumentInfo[] })?.infos
  const limit = (location.state as { limit?: string })?.limit
  const menu = (location.state as { menu?: DocumentEntity })?.menu
  const apiDocumentType = new ApiDocumentType(message, navigate)

  function getTypeRoles() {
    apiDocumentType.getTypeRoles({
      successHandler: roles => {
        if (roles) {
          const roles1 = Role.createList(roles)
          const role = new Role(0, "all_type", "全部文书类型", [], [])
          let roles2 = [role, ...roles1];
          if (limit) {
            roles2 = roles2.filter(role => role.roleDescription.includes(limit))
          }
          setRoles(roles2)
          if (roles2.length) {
            setRole(roles2[0].id)
          }
        }
      }
    })
  }

  function getDocumentTypes() {
    apiDocumentType.getDocumentTypes({
      successHandler: types => {
        if (types) {
          setDocumentTypes(types.map(type => DocumentTypeEntity.create(type)))
        }
      }
    })

  }

  useEffect(() => {
    if (!caseId) {
      navigate(-1)
    }
    getTypeRoles()
    getDocumentTypes()
  }, [location])

  const onClick = (documentType: DocumentTypeEntity) => {
    return {
      onClick: () => {
        navigate("/wenshu", {
          state: {
            documentTypeId: documentType.id,
            caseId: caseId,
            infos: infos,
            menu: menu
          }
        })
      }
    }
  }


  const element = roles.map(item => {
    return <Tabs.TabPane tab={item.roleDescription} key={item.id} />
  })
  const onChange = (key: string) => {
    setRole(parseInt(key))
  }
  let filter
  if (role === undefined) {
    filter = function (_: DocumentTypeEntity) {
      return false
    }
  } else if (role !== 0) {
    filter = function (type: DocumentTypeEntity) {
      return (type.roleList ?? []).includes(role)
    }
  } else {
    filter = function (_: DocumentTypeEntity) {
      return true
    }
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <Tabs onChange={onChange}>
          {element}
        </Tabs>
        <Table
          columns={columns}
          pagination={false}
          dataSource={documentTypes.filter(filter)}
          rowKey="id"
          onRow={onClick}
        />
      </div>
    </div>
  );
};
export default Writ