import { Button, Form, Input, message, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Module from '../style/unitlist.module.scss'
import UnitEntity from "../entity/UnitEntity";
import ApiAdminUnit from "../internet/ApiAdminUnit";
import UserEntity from "../entity/UserEntity";
import { ColumnsType } from 'antd/lib/table';


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


const Unitlist: React.FC = () => {
  const unitId = (useLocation().state as { entity?: UnitEntity })?.entity?.id
  const [users, setUsers] = useState<UserEntity[]>([])
  const [unit, setUnit] = useState<UnitEntity | undefined>()
  const location = useLocation()
  const [form] = Form.useForm<UnitEntity>()
  const navigate = useNavigate()
  const apiAdminUnit = new ApiAdminUnit(message, navigate)
  function updateUnit() {
    form.validateFields()
      .then(value => {
        if (unitId) {
          const newUnit = new UnitEntity(
            unitId, value.unitName, value.unitDescription,
            unit?.parentUnitId ?? null, unit?.children ?? []
          )
          apiAdminUnit.updateUnit(newUnit)
        }
      })
  }

  function deleteUnit() {
    if (unitId) {
      apiAdminUnit.deleteUnit(unitId)
    }
  }

  function modifySeal() {
    if (unitId) {
      navigate("/seallist", { state: { unitId: unitId } })
    }
  }

  function addUnitPeople() {
    navigate("/userlist", { state: { unitId: unitId } })
  }

  function getUnit() {
    if (unitId) {
      apiAdminUnit.getUnit(unitId, {
        successHandler: newUnit => {
          if (newUnit) {
            let unitEntity = UnitEntity.create(newUnit);
            setUnit(unitEntity)
            form.setFieldsValue(unitEntity)
          }
        }
      })
    }
  }

  function getUserByUnit() {
    if (unitId) {
      apiAdminUnit.getUnitUsers(unitId, {
        successHandler: users => {
          if (users) {
            const users1 = UserEntity.creatList(users)
            setUsers(users1)
          }
        }
      })

    }
  }

  useEffect(() => {
    getUnit()
    getUserByUnit()
  }, [location])
  const updateRoles = (e: React.MouseEvent<HTMLElement, MouseEvent>, uid: number) => {
    e.stopPropagation()
    navigate("/chomod", { state: { uid: uid } })
  }

  const updateSignature = (e: React.MouseEvent<HTMLElement, MouseEvent>, uid: number) => {
    e.stopPropagation()
    navigate("/singnar", { state: { uid: uid } })
  }
  const columns: ColumnsType<UserEntity> = [
    {
      title: "单位人员",
      dataIndex: 'realName',
    },
    {
      title: "操作",
      align: "center",
      width: 200,
      render: (record) => {
        return (<span>
          <Button type="link" onClick={e => updateRoles(e, record.id)}>修改权限</Button>
          <Button type="link" onClick={e => updateSignature(e, record.id)}>修改签名</Button></span>
        )

      }
    }


  ];
  const rowClick = (user: UserEntity) => {
    return {
      onClick: () => {
        navigate("/userinfo", { state: { user: user } })
      }
    }
  }
  return (
    <div className={Module.all}>
      <Form
        {...layout}
        className={Module.form}
        form={form}>
        <Form.Item
          name="unitName"
          label="单位名称"
          className={Module.item}
          rules={[{
            required: true,
            message: "清输入单位名称"
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="unitDescription"
          label="单位描述"
          className={Module.item}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={Module.ite}
        >
          <Button type="primary" onClick={updateUnit}>修改</Button>
        </Form.Item>
        <Form.Item
          className={Module.ite}
        >
          <Button type="primary" onClick={deleteUnit}>删除</Button>
        </Form.Item>
        <Form.Item
          className={Module.ite1}
        >
          <Button type="primary" onClick={modifySeal}>印章管理</Button>
        </Form.Item>
      </Form>
      <div className={Module.left}>
        <Button type="primary" onClick={addUnitPeople}>添加单位人员</Button>
        <Table
          rowKey={"id"}
          onRow={rowClick}
          columns={columns}
          size="small"
          dataSource={users}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Unitlist;