import { Button, Card, Form, Input, message, Tabs } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiUser from "../internet/ApiAdminUser";
import Module from '../style/users.module.scss'

const { TabPane } = Tabs;

const Repass: React.FC = () => {
    const [form] = Form.useForm<{ password: string }>()
    const navigate = useNavigate()
    const location = useLocation()
    const passReg = new RegExp(/^(\w){6,20}$/)
    const updatePassword = () => {

    }

    return (
        <div className={Module.all}>
            <Card className={Module.card}>
                <Tabs className={Module.tab} defaultActiveKey="1" tabPosition="left" size="middle">
                    <TabPane tab="修改密码" key="2">
                        <Form
                            form={form}
                            className={Module.form}>
                            <Form.Item
                                className={Module.ite}
                                label="&emsp;&emsp;密码"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入你的密码!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder='初始密码' className={Module.input} />
                            </Form.Item>
                            <Form.Item
                                className={Module.ite}
                                label="重复密码"
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: '请再次输入你的密码!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('两次输入的密码不一致!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input className={Module.input} />
                            </Form.Item>
                            <Button className={Module.btn} onClick={updatePassword}>确认修改</Button>
                        </Form>
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    )
}
export default Repass