import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import ResultBody from "../entity/ResultBody";
import InternetTool from "../internet/Internetool";
import { ResultParam } from "./ResultParam";

export class ResultHandler {
  message: MessageApi
  navigate: NavigateFunction

  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.message = message;
    this.navigate = navigate;
  }


  bodyHandlerDefault<T>(
    resultParam?: ResultParam<T>
  ): (body: ResultBody<T>) => void {
    return (body: ResultBody<any>) => {
      if (body.code === InternetTool.success) {
        if (resultParam?.successHandler) {
          resultParam.successHandler(body.body)
        } else {
          this.message.success("操作成功")
        }
        if (resultParam?.exit !== 0) {
          this.navigate(-(resultParam?.exit ?? 1))
        }
      } else if (body.code === "not_login") {
        this.message.error("账号或密码错误")
        this.navigate("/login")
      } else {
        if (resultParam?.otherHandler) {
          if (!resultParam.otherHandler(body.code, body.message)) {
            this.message.error(body.message ?? "操作失败")
          }
        } else {
          this.message.error(body.message ?? "操作失败")
        }
      }
    }
  }
}