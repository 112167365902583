class UserEntity {
  id: number;
  realName: string;
  username: string;
  mobilePhone: string;
  identificationCard: string;
  lawEnforcementCard: string
  signature: string | undefined = undefined

  constructor(id: number,
    realName: string,
    username: string,
    mobilePhone: string,
    identificationCard: string,
    lawEnforcementCard: string,
    signature: string | undefined = undefined) {
    this.id = id
    this.realName = realName
    this.username = username
    this.mobilePhone = mobilePhone
    this.identificationCard = identificationCard
    this.lawEnforcementCard = lawEnforcementCard
    this.signature = signature
  }

  static create(entity: any): UserEntity | null {
    const id = entity.id
    const realName = entity.realName
    const username = entity.username
    const mobilePhone = entity.mobilePhone
    const identificationCard = entity.identificationCard
    const lawEnforcementCard = entity.lawEnforcementCard
    const signature = entity.signature ?? undefined
    if (id && realName && username && mobilePhone && identificationCard && lawEnforcementCard) {
      return new UserEntity(id, realName, username, mobilePhone, identificationCard, lawEnforcementCard, signature)
    } else {
      return null
    }
  }

  static creatList(list: UserEntity[]): UserEntity[] {
    const users: UserEntity[] = []
    list.forEach(user => {
      const user1 = UserEntity.create(user)
      if (user1) {
        users.push(user1)
      }
    })
    return users
  }
}

export default UserEntity