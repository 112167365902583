import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, message, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Module from '../style/chomod.module.scss'
import Role from "../entity/Role";
import ApiRoles from "../internet/ApiRoles";
import { Roles } from "../tool/Roles";
import { ArrayTool } from "../tool/ArrayTool";

const { TabPane } = Tabs;


const Chomod: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const uid = (location.state as { uid?: number })?.uid
  const [typeRoles, setTypeRoles] = useState<Role[]>([]);
  const [allRoles, setAllRoles] = useState<Role[]>([])
  const [typeRole, setTypeRole] = useState<number | undefined>()
  const [roles, setRoles] = useState<Role[]>([])
  const [currentRoles, setCurrentRoles] = useState<Role[]>([])
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
  const [load, setLoad] = useState<{ s: boolean, r: boolean }>({ s: false, r: false })
  const apiRoles = new ApiRoles(message, navigate)
  function setRoleList() {
    setRoles(ArrayTool.subtractById(allRoles, currentRoles))
  }

  useEffect(setRoleList, [load])


  function selectRole(role1: Role) {
    const sr = [...currentRoles]
    sr.push(role1)
    setCurrentRoles(sr)
    setRoles(ArrayTool.subtractById(allRoles, sr))
  }

  function unselectRole(role1: Role) {
    const sr = [...currentRoles]
    const index = sr.indexOf(role1)
    sr.splice(index, 1)
    setCurrentRoles(sr)
    setRoles(ArrayTool.subtractById(allRoles, sr))

  }

  function updateUserRole() {
    if (uid) {
      const addList = ArrayTool.subtractById(currentRoles, selectedRoles).map(r => r.id)
      const deleteList = ArrayTool.subtractById(selectedRoles, currentRoles).map(r => r.id)
      apiRoles.updateUserRoles(uid, addList, deleteList)
    }
  }

  function getTypeRoles() {
    apiRoles.getTypeRoles(Roles.bind.user.id, {
      successHandler: typeRoles => {
        if (typeRoles) {
          const roles = Role.createList(typeRoles)
          const role = new Role(0, "", "所有角色", [], []);
          setTypeRoles([role, ...roles])
          setTypeRole(0)
        }
      }
    })
  }


  function onSelected(key: string) {
    setTypeRole(parseInt(key))
  }


  const currentColumns: ColumnsType<Role> = [
    {
      title: '已有权限',
      dataIndex: 'id',
      render: (text, record) => {
        return (<span>{record.roleDescription}<MinusSquareOutlined onClick={() => {
          unselectRole(record)
        }} style={{ float: "right" }} /></span>)
      }
    },
  ]

  const updateColumns: ColumnsType<Role> = [
    {
      title: '可添加权限',
      dataIndex: 'id',
      render: (text, record) => {
        return (<span>{record.roleDescription}<PlusSquareOutlined onClick={() => {
          selectRole(record)
        }} style={{ float: "right" }} /></span>)
      }
    },
  ]

  const getDistributableRoles = () => {
    apiRoles.getDistributableRoles({
      successHandler: distributableRoles => {
        if (distributableRoles) {
          const roles = Role.createList(distributableRoles)
          setAllRoles(roles)
          setRoles(ArrayTool.subtractById(roles, currentRoles))
          setLoad({ s: load.s, r: true })
        }
      }
    })
  }

  function getSelected(uid: number) {
    apiRoles.getUserRoles(uid, {
      successHandler: selectedRoles => {
        if (selectedRoles) {
          const roles = Role.createList(selectedRoles)
          setSelectedRoles(roles)
          setCurrentRoles(roles)
          setRoles(ArrayTool.subtractById(allRoles, roles))
          setLoad({ s: true, r: load.s })
        }
      }
    })
  }

  useEffect(() => {
    if (!uid) {
      navigate(-1)
    } else {
      getTypeRoles()
      getDistributableRoles()
      getSelected(uid)
    }
  }, [location])

  let filter
  if (typeRole === undefined) {
    filter = function (_: Role) {
      return false
    }
  } else if (typeRole !== 0) {
    filter = function (role: Role) {
      return (role.parents ?? []).includes(typeRole)
    }
  } else {
    const parents = typeRoles.map(role => role.id)
    filter = function (role: Role) {
      return (role.parents ?? []).some(id => parents.includes(id))
    }
  }
  const tabs = typeRoles.map(typeRole => {
    return (
      <TabPane className={Module.tabp} tab={typeRole.roleDescription} key={typeRole.id} />
    )
  })
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <Tabs className={Module.tab} tabPosition='left' onChange={onSelected}>
          {tabs}
        </Tabs>
        <Table
          rowKey={"id"}
          className={Module.table}
          columns={currentColumns}
          expandable={{ childrenColumnName: "children1234" }}
          dataSource={currentRoles.filter(filter)}
          pagination={false}
        />
        <Table
          rowKey={"id"}
          className={Module.table}
          columns={updateColumns}
          expandable={{ childrenColumnName: "children1234" }}
          pagination={false}
          dataSource={roles.filter(filter)}
        />
      </div>
      <Button className={Module.btn} onClick={updateUserRole}>确认修改</Button>
    </div>
  )
}
export default Chomod;
