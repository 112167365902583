import { Button, Form, Input, message, Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Module from '../style/reason.module.scss'
const { Option } = Select;

const Reason: React.FC = () => {
    const { TextArea } = Input;
    const [value, setValue] = useState('');
    const change = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value)
    };
    const navegate = useNavigate()
    const click = () => {
        message.info({
            content: "提交成功",
            duration: 1,
            onClose() {
                navegate(-1)
            },
        })
    }
    return (
        <div className={Module.cen}>
            <Form
                className={Module.form}
                name="complex-form" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item className={Module.item} label="申请人姓名" >
                    <Input style={{ width: 200 }} placeholder="请输入你的姓名" />
                </Form.Item>
                <Form.Item label="职 务" className={Module.select} >
                    <Select placeholder="你的职务" style={{ width: 200, }} >
                        <Option value="林长">林长</Option>
                        <Option value="州长">州长</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="申请理由" style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="year"
                        style={{ display: 'inline-block', width: 300 }}
                    >
                        <TextArea
                            value={value}
                            onChange={change}
                            placeholder="请输入你的理由"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item label=" " colon={false}>
                    <Button type="primary" htmlType="submit" style={{ width: 80, }} onClick={click}>
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Reason;