import { Form, Input, Select } from "antd"
import Module from '../style/samplingNotice.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function SamplingNotice(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>行政处罚抽样取证通知书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[1, "fieldValue"]}
                >
                  <Input
                    placeholder="当事人"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                  />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[8, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="抽样取证内容"
                  />
                </Form.Item>
                <div className={Module.time}>
                  <p style={{ fontWeight: "bolder" }}>执法人员签名：</p>
                  <Form.Item
                    className={Module.item}
                    name={[5, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主办人员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className={Module.item}
                    name={[6, "fieldValue"]}
                  >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="协办人员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.flex}>
                  <p className={Module.a1}>附件：</p>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="附件"
                    />
                  </Form.Item>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>行政执法主体名称：</p>
                    <Form.Item
                      style={{ width: "30%", }}
                      name={[3, "fieldValue"]}>
                      <Select placeholder="行政执法主体名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", }}
                      name={[4, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      className={Module.select1}
                      name={[7, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

