import { PunishItem } from "./PunishItem";

export class Punish {
  id: number;
  caseId: number;
  punishArticleLawId: number;
  punishArticleId: number;
  lawId: Array<number>;
  items?: PunishItem[];

  constructor(
    id: number,
    caseId: number,
    punishArticleLawId: number,
    punishArticleId: number,
    lawId: Array<number>,
    items?: PunishItem[]
  ) {
    this.id = id
    this.caseId = caseId
    this.punishArticleLawId = punishArticleLawId
    this.punishArticleId = punishArticleId
    this.lawId = lawId
    this.items = items
  }
}