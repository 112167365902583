import InternetTool from "./Internetool";
import Role from "../entity/Role";
import { RoleMultistage } from "../entity/RoleMultistage";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
class ApiRoles {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("role", message, navigate)
  }
  getSelectedRoles(roleId: number, resultParam?: ResultParam<Role[]>) {
    this.internetTool.fe(
      `/selected-roles?roleId=${roleId}`,
      resultParam
    )
  }
  getParentRoles(roleId: number, resultParam?: ResultParam<Role[]>) {
    this.internetTool.fe<Role[]>(
      `/parent-roles?roleId=${roleId}`,
      resultParam
    )
  }
  getDistributableRoles(resultParam?: ResultParam<Role[]>) {
    this.internetTool.fe(
      "/distributable-roles",
      resultParam
    )
  }
  getTypeRoles(roleId?: number, resultParam?: ResultParam<Role[]>) {
    let url = "/type-roles"
    if (roleId) {
      url = url + "?roleId=" + roleId
    }
    this.internetTool.fe(
      url,
      resultParam
    )
  }
  addRole(
    roleDescription: string,
    roleList: number[],
    resultParam?: ResultParam<Role[]>
  ) {
    const formData = new FormData()
    formData.append("roleDescription", roleDescription)
    formData.append("roleList", new Blob([JSON.stringify(roleList)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  updateRole(
    roleId: number,
    roleDescription: string,
    addList: number[],
    deleteList: number[],
    resultParam?: ResultParam<Role[]>
  ) {
    const formData = new FormData()
    formData.append("roleId", roleId.toString())
    formData.append("roleDescription", roleDescription)
    formData.append("addList", new Blob([JSON.stringify(addList)], { type: "application/json" }))
    formData.append("deleteList", new Blob([JSON.stringify(deleteList)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateUserRoles(
    uid: number,
    addList: number[],
    deleteList: number[],
    resultParam?: ResultParam<Role[]>
  ) {
    const formData = new FormData()
    formData.append("uid", uid.toString())
    formData.append("addList", new Blob([JSON.stringify(addList)], { type: "application/json" }))
    formData.append("deleteList", new Blob([JSON.stringify(deleteList)], { type: "application/json" }))
    this.internetTool.ft(
      "/user",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateUnitRoles(
    unitId: number,
    addList: number[],
    deleteList: number[],
    resultParam?: ResultParam<Role[]>
  ) {
    const formData = new FormData()
    formData.append("unitId", unitId.toString())
    formData.append("addList", new Blob([JSON.stringify(addList)], { type: "application/json" }))
    formData.append("deleteList", new Blob([JSON.stringify(deleteList)], { type: "application/json" }))
    this.internetTool.ft(
      "/unit",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  deleteRole(
    roleId: number,
    resultParam?: ResultParam<Role[]>
  ) {
    const formData = new FormData()
    formData.append("roleId", roleId.toString())
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )

  }
  getChildrenRoles(roleId: number, resultParam?: ResultParam<Role[]>) {
    let formData = new FormData()
    formData.append("roleId", roleId.toString())
    this.internetTool.ft(
      "/children-roles",
      {
        body: formData,
        method: "GET"
      },
      resultParam
    )
  }
  getUnitRole(unitId: number, resultParam?: ResultParam<Role[]>) {
    this.internetTool.fe(
      `/unit-role?unitId=${unitId}`,
      resultParam
    )
  }
  getUserRoles(uid: number, resultParam?: ResultParam<Role[]>) {
    this.internetTool.fe
      (
        `/user?uid=${uid}`,
        resultParam
      )
  }
  getFillRole(resultParam?: ResultParam<Array<RoleMultistage>>) {
    this.internetTool.fe(
      "/fill_role",
      resultParam
    )
  }
  getRoles(resultParam?: ResultParam<Array<number>>) {
    this.internetTool.fe(
      "",
      resultParam
    )

  }
  getTemplate(resultParam?: ResultParam<Array<Role>>) {
    this.internetTool.fe(
      "/template",
      resultParam
    )
  }
}
export default ApiRoles