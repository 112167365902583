import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/notPutPunishApproval.module.scss'
export default function NotPutPunishApproval(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>不予行政处罚决定审批表</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.clumn1}>
                      <p>当事人基本情况</p>
                    </div>
                    <div className={Module.clumn2}>
                      <div className={Module.a1}>
                        <div className={Module.b1}>
                          <Form.Item
                            name={[0, "fieldValue"]}
                            className={Module.item}
                            initialValue="⬜"
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 0, "fieldValue"])}
                            />
                          </Form.Item>
                          <p className={Module.h2}>公民</p>
                        </div>
                        <div className={Module.b2}>
                          <div className={Module.e1}>
                            <div className={Module.f1}>
                              <p>姓名</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[1, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="姓名"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>性别</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[2, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="性别"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f5}>
                              <p>年龄</p>
                            </div>
                            <div className={Module.f6}>
                              <Form.Item
                                name={[3, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="年龄"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={Module.e2}>
                            <div className={Module.f1}>
                              <p>住址</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[4, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  style={{ fontSize: 10 }}
                                  autoSize={{ minRows: 2, maxRows: 2.8 }}
                                  placeholder="住址"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>身份证号码</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[5, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 3.5 }}
                                  placeholder="身份证号码"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f5}>
                              <p>联系电话</p>
                            </div>
                            <div className={Module.f6}>
                              <Form.Item
                                name={[6, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  placeholder="联系电话"
                                  autoSize={{ minRows: 2, maxRows: 3 }}
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={Module.a2}>
                        <div className={Module.b1}>
                          <Form.Item
                            name={[7, "fieldValue"]}
                            className={Module.item}
                            initialValue="⬜"
                          >
                            <Input readOnly
                              onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])}
                            />
                          </Form.Item>
                          <p className={Module.h2}>法人或其他组织</p>
                        </div>
                        <div className={Module.b2}>
                          <div className={Module.e1}>
                            <div className={Module.f1}>
                              <p>名称</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[8, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="名称"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>法定代表人</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[9, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input
                                  placeholder="法定代表人"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className={Module.e2}>
                            <div className={Module.f1}>
                              <p>住址</p>
                            </div>
                            <div className={Module.f2}>
                              <Form.Item
                                name={[10, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  style={{ fontSize: 10 }}
                                  autoSize={{ minRows: 2, maxRows: 2.5 }}
                                  placeholder="住址"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                            <div className={Module.f3}>
                              <p>联系电话</p>
                            </div>
                            <div className={Module.f4}>
                              <Form.Item
                                name={[11, "fieldValue"]}
                                className={Module.item}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 2, maxRows: 2.5 }}
                                  placeholder="联系电话"
                                  disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>案件基本情况和建议作出不予行政处罚决定的理由、依据及内容</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[12, "fieldValue"]}
                        className={Module.item}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 10 }}
                          placeholder="案件基本情况和建议作出不予行政处罚决定的理由、依据及内容"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}><p>陈述申辩及听证情况</p></div>
                    <div className={Module.c2}>
                      <Form.Item
                        name={[13, "fieldValue"]}
                        className={Module.item1}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 10 }}
                          placeholder="陈述申辩及听证情况"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        />
                      </Form.Item>
                    </div>

                  </div>

                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>当事人陈述申辩或听证意见复核及采纳情况</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[14, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            placeholder="当事人陈述申辩或听证意见复核及采纳情况"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[19, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="主办人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[20, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="协办人员签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[15, "fieldValue"]} className={Module.sig}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>承办机构审查意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[16, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 10 }}
                            placeholder="承办机构审查意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[21, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="承办机构签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[15, "fieldValue"]} className={Module.sig}>
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>负责法制工作的机构审核意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[17, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="负责法制工作的机构审核意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[22, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className={Module.sig}
                          name={[15, "fieldValue"]} >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} className={Module.sig} placeholder="签名日期" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.d1}>
                      <p>单位负责人审批意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.e1}>
                        <Form.Item
                          name={[18, "fieldValue"]}
                          className={Module.item}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            placeholder="单位负责人审批意见"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          />
                        </Form.Item>
                      </div>
                      <div className={Module.e2}>
                        <Form.Item
                          name={[24, "fieldValue"]} className={Module.sig} >
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名"
                          >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className={Module.sig}
                          name={[15, "fieldValue"]}>
                          <StringDatePicker
                            placement="topLeft"
                            className={Module.sig}
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            format={"YYYY年MM月DD日"} placeholder="签名日期" />
                        </Form.Item>
                        <Form.Item
                          className={Module.sig}
                          name={[23, "fieldValue"]} >
                          <Select placeholder="印章"
                            placement="topLeft"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}