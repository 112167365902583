import DocumentTypeEntity from "./DocumentTypeEntity";
import FieldEntity from "./FieldEntity";

class DocumentEntity {
  id: number = 0
  /**
   * 所属案件
   */
  caseId: number = 0
  /**
   * 文档类型
   */
  typeId: number | undefined = undefined
  documentName: string
  documentCode: string | undefined = undefined
  docxPath: string | undefined = undefined
  pdfPath: string | undefined = undefined
  templatePath: string | undefined
  pageNumber: number | undefined = undefined
  startPageNumber: number | undefined = undefined
  fix: boolean = false
  complete: boolean = false
  upload: boolean = false
  masterId: number | undefined = undefined
  fields: Array<FieldEntity> | undefined = undefined
  documentTypeEntity: DocumentTypeEntity | undefined = undefined
  attachments: Array<DocumentEntity> | undefined = undefined


  constructor(
      id: number,
      caseId: number, 
      typeId: number | undefined, 
      documentName: string,
      documentCode: string | undefined, 
      docxPath?: string | undefined,
      pdfPath?: string | undefined,
      templatePath?: string | undefined,
      pageNumber?: number | undefined,
      startPageNumber?: number | undefined,
      fix: boolean = false, 
      complete: boolean = false,
      upload: boolean = false,
      masterId?: number | undefined,
      fields?: Array<FieldEntity> | undefined,
      documentTypeEntity?: DocumentTypeEntity | undefined,
      attachments?: Array<DocumentEntity> | undefined
  ) {
    this.id = id;
    this.caseId = caseId;
    this.typeId = typeId;
    this.documentName = documentName;
    this.documentCode = documentCode;
    this.docxPath = docxPath;
    this.pdfPath = pdfPath;
    this.templatePath = templatePath;
    this.pageNumber = pageNumber;
    this.startPageNumber = startPageNumber;
    this.fix = fix;
    this.complete = complete;
    this.upload = upload;
    this.masterId = masterId;
    this.fields = fields;
    this.documentTypeEntity = documentTypeEntity;
    this.attachments = attachments;
  }
}

export default DocumentEntity