import Law from "./Law";
import LawChapter from "./LawChapter";
import LawArticle from "./LawArticle";
import { LawParagraph } from "./LawParagraph";
import { LawSubparagraph } from "./LawSubparagraph";
import { LawItem } from "./LawItem";
export class LawPunishArticleLawEntity {
  law?: Law
  lawChapter?: LawChapter
  lawArticle?: LawArticle
  lawParagraph?: LawParagraph
  lawSubparagraph?: LawSubparagraph
  lawItem?: LawItem

  constructor(law?: Law, lawChapter?: LawChapter, lawArticle?: LawArticle, lawParagraph?: LawParagraph, lawSubparagraph?: LawSubparagraph, lawItem?: LawItem) {
    this.law = law;
    this.lawChapter = lawChapter;
    this.lawArticle = lawArticle;
    this.lawParagraph = lawParagraph;
    this.lawSubparagraph = lawSubparagraph;
    this.lawItem = lawItem;
  }
  static laws(law: Law[], index: number[]): LawPunishArticleLawEntity {
    let law1 = law.find(lawId => lawId.id === index[0])
    let chapter = law1?.chapters?.find(chapter => chapter.id === index[1])
    let article = chapter?.articles?.find(article => article.id === index[2])
    let paragraph = article?.paragraphs?.find(paragraph => paragraph.id === index[3])
    let subparagraph = paragraph?.subparagraphs?.find(subparagraph => subparagraph.id === index[4])
    let item = subparagraph?.items?.find(item => item.id === index[5])
    const punishActicleLaw = new LawPunishArticleLawEntity(law1, chapter, article, paragraph, subparagraph, item)
    return punishActicleLaw
  }
}