import { DataNode } from "../message/dataNode";

class Region {
  id: number | null;
  parentRegionId: number | null;
  regionName: string | null;
  children?: Array<Region>;

  constructor(
    id: number | null,
    parentRegionId: number | null,
    regionName: string | null,
    children?: Array<Region>
  ) {
    this.id = id
    this.parentRegionId = parentRegionId
    this.regionName = regionName
    this.children = children
  }

  toNode(): DataNode<Region> {
    return {
      entity: this,
      title: this.regionName,
      key: this.id?.toString() ?? "",
      children: (this.children ?? []).map(item => {
        return item.toNode()
      })
    }
  }

  static create(entity: any): Region {
    let children = entity.children
    if (Array.isArray(children)) {
      children = children.map(child => {
        return Region.create(child)
      })
    } else {
      children = []
    }
    return new Region(
      entity.id ?? null,
      entity.parentRegionId ?? null,
      entity.regionName ?? null,
      children
    )
  }
}

export default Region