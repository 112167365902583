
import { Card, List } from 'antd';
import React from 'react';
import Module from '../style/wslist.module.scss'
import AllList from './allList';


const a = <div style={{ color: "green" }}>已办理</div>
const b = <div style={{ color: "red" }}>待办理</div>
const datar = [
    {
        title: '立案文书',
        content: b
    },
    {
        title: '处罚文书',
        content: a
    },
    {
        title: '结案文书',
        content: a
    },

];
const Wslist: React.FC<{ setCaseId: (caseId: number | undefined) => void }> = (props) => {

    return (
        <div className={Module.center}>
            <List
                className={Module.list}
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 3,
                }}
                dataSource={datar}
                renderItem={item => (
                    <List.Item className={Module.item} >
                        <Card className={Module.card} title={item.title}>{item.content}</Card>
                    </List.Item>
                )}
            />

            <AllList setCaseId={props.setCaseId} />

        </div>
    )
};

export default Wslist;








