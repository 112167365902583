import { Form, Input, Card, Button, message } from "antd"
import { FcUndo } from "react-icons/fc"
import { useNavigate } from "react-router-dom"
import Module from '../style/userinfo.module.scss'
const AddAddress: React.FC = (props) => {
    const [form] = Form.useForm()
    const btnClick = () => {
        message.success("修改成功")
    }
    const navigate = useNavigate()

    function iconClick() {
        navigate(-1)
    }

    return (
        <div className={Module.all}>
            <Card className={Module.card}>
                < FcUndo size={30} onClick={iconClick} style={{ cursor: "pointer" }} />
                <Form
                    name='添加嫌疑人员'
                    form={form}
                    className={Module.form}
                >
                    <h1>添加地理信息</h1>
                    <Form.Item
                        name="positionName"
                        className={Module.item1}
                        label="地理名称">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="positionDescription"
                        className={Module.item1}
                        label="地理描述">
                        <Input />
                    </Form.Item>

                    <Form.Item  >
                        <Button type="primary" onClick={btnClick}>确认添加</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}
export default AddAddress