import Role from "../entity/Role";
import InternetTool from "./Internetool";
import DocumentTypeEntity from "../entity/DocumentTypeEntity";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiDocumentType {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("document-type", message, navigate)
  }
  getTypeRoles(resultParam?: ResultParam<Array<Role>>) {
    this.internetTool.fe
      (
        "/type-roles",
        resultParam
      )

  }
  getDocumentTypes(resultParam?: ResultParam<Array<DocumentTypeEntity>>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )
  }
  getSubDocumentType(documentTypeId: number, resultParam?: ResultParam<Array<DocumentTypeEntity>>) {
    this.internetTool.fe
      (
        `/${documentTypeId}/sub`,
        resultParam
      )
  }
  getDocumentType(documentTypeId: number, resultParam?: ResultParam<DocumentTypeEntity>) {
    this.internetTool.fe
      (
        `/${documentTypeId}`,
        resultParam)
  }
  getPermissionRoles(documentTypeId: number, resultParam?: ResultParam<Array<Role>>) {
    this.internetTool.fe
      (
        `/${documentTypeId}/permission_role`,
        resultParam
      )
  }
}