import { Form, Input, Select } from "antd"
import Module from '../style/significantPenaltiesReport.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function SignificantPenaltiesReport(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>重大行政处罚决定备案报告</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>决定机关：</p>
                  <Form.Item
                    className={Module.item}
                    name={[1, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="决定机关"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>决定批准人：</p>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="决定批准人姓名"
                    />
                  </Form.Item>
                  <p className={Module.a1}>决定批准人职务：</p>
                  <Form.Item
                    className={Module.item}
                    name={[3, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="职务"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>办案人员：</p>
                  <Form.Item
                    className={Module.item}
                    name={[4, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主办人员"
                    />
                  </Form.Item>
                  <p className={Module.a1}>执法证号 ：</p>
                  <Form.Item
                    className={Module.item}
                    name={[5, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="执法证号"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>办案人员：</p>
                  <Form.Item
                    className={Module.item}
                    name={[6, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="协办人员"
                    />
                  </Form.Item>
                  <p className={Module.a1}>执法证号 ：</p>
                  <Form.Item
                    className={Module.item}
                    name={[7, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="执法证号"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>是否举行听证：</p>
                  <p>是</p>
                  <Form.Item
                    name={[8, "fieldValue"]}
                    initialValue="⬜"
                    className={Module.item1}
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 8, "fieldValue"])}
                    />
                  </Form.Item>
                  <p>否</p>
                  <Form.Item
                    name={[9, "fieldValue"]}
                    initialValue="⬜"
                    className={Module.item1}
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 9, "fieldValue"])}
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>是否由行政机关负责人集体讨论决定：</p>
                  <p>是</p>
                  <Form.Item
                    name={[10, "fieldValue"]}
                    initialValue="⬜"
                    className={Module.item1}
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 10, "fieldValue"])}
                    />
                  </Form.Item>
                  <p>否</p>
                  <Form.Item
                    name={[11, "fieldValue"]}
                    initialValue="⬜"
                    className={Module.item1}
                  >
                    <Input readOnly
                      onClick={() => props.changeCheckbox(["fields", 11, "fieldValue"])}
                    />
                  </Form.Item>
                </div>
                <p className={Module.a1}>其他需要说明的情况：</p>
                <Form.Item
                  className={Module.item}
                  name={[12, "fieldValue"]}
                >
                  <Input.TextArea className={Module.textarea}
                    autoSize={{ minRows: 2, maxRows: 15 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="其他需要说明的情况" />
                </Form.Item>

                <div className={Module.time}>
                  <p className={Module.a1}>报备单位：</p>
                  <Form.Item
                    className={Module.item}
                    name={[13, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="报备单位"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>生成时间：</p>
                  <Form.Item
                    className={Module.item}
                    name={[14, "fieldValue"]}
                  >
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} placeholder="生成时间"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>联系人：</p>
                  <Form.Item
                    className={Module.item}
                    name={[4, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主办人员"
                    />
                  </Form.Item>
                  <p>,</p>
                  <Form.Item
                    className={Module.item}
                    name={[6, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="协办人员"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>电话：</p>
                  <Form.Item
                    className={Module.item}
                    name={[15, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="电话"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>附件：</p>
                  <Form.Item
                    className={Module.item}
                    name={[16, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="文书名称"
                    />
                  </Form.Item>
                  <p>,</p>
                  <Form.Item
                    className={Module.item}
                    name={[17, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="文号"
                    />
                  </Form.Item>
                </div>
                <div className={Module.time}>
                  <p className={Module.a1}>印章管理：</p>
                  <Form.Item
                    className={Module.select1}
                    name={[18, "fieldValue"]} >
                    <Select placeholder="印章"
                    placement="topLeft"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    >
                      {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

