import { useInterval } from 'ahooks';
import { Button, Comment, Form, Input, List, message, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiChat } from '../internet/ApiChat';
import ChatEntity from '../entity/Chat';
import Module from "../style/speak.module.scss"

const { TextArea } = Input;

export interface CommentItem {
    author: string;
    content: string;
    datetime: string;
}

interface EditorProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSubmit: () => void;
    submitting: boolean;
    value: string;
}


const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
    <div className={Module.sub}>
        <Form.Item>
            <TextArea className={Module.text} onChange={onChange} value={value} />
            <Button className={Module.btn} htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                提交评论
            </Button>
        </Form.Item>
    </div>
);


const Speak: React.FC<{ caseId1: number | undefined }> = ({ caseId1 }) => {
    const location = useLocation()
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState<string>('');
    const [chats, setChats] = useState<ChatEntity[]>([])
    const [caseId, setCaseId] = useState<number | undefined>(caseId1)
    const [caseChats, setCaseChat] = useState<ChatEntity[]>([])
    const navigate = useNavigate()
    const apiChat = new ApiChat(message, navigate)
    const comments = chats.map(item => {
        const comment = new ChatEntity(item.id, item.realName, item.chatContent, item.chatTime, item.caseId)
        return comment.toItem()
    }
    )
    const caseComments = caseChats.map(item => {
        const comment = new ChatEntity(item.id, item.realName, item.chatContent, item.chatTime, item.caseId)
        return comment.toItem()
    }
    )
    function getChats() {
        if (!chats.length) {
            getAllChats(undefined)
        } else {
            getNewChats(undefined)
        }
        if (!caseChats.length) {
            getAllChats(caseId)
        } else {
            getNewChats(caseId)
        }

    }
    function getAllChats(caseId: number | undefined) {
        apiChat.getAllChat(caseId, {
            exit: 0,
            successHandler: chats => {
                if (chats) {
                    if (caseId === undefined) {
                        setChats(
                            chats.map(chat => {
                                chat.chatTime = new Date(chat.chatTime)
                                return chat
                            })
                        )
                    } else {
                        setCaseChat(
                            chats.map(chat => {
                                chat.chatTime = new Date(chat.chatTime)
                                return chat
                            })
                        )
                    }
                }
            }
        })
    }

    function getNewChats(caseId: number | undefined) {
        const sortedChats = chats.sort((chat, chat1) => {
            return chat.chatTime.getMilliseconds() - chat1.chatTime.getMilliseconds()
        })
        const chatTime = sortedChats[0].chatTime
        apiChat.getNewChat(chatTime, caseId, {
            exit: 0,
            successHandler: newChats => {
                if (newChats) {
                    if (caseId === undefined) {
                        const chats1 = newChats.filter(chat => !chats.map(chat1 => chat1.id).includes(chat.id))
                            .map(chat => {
                                chat.chatTime = new Date(chat.chatTime)
                                return chat
                            })
                        setChats([...chats, ...chats1])
                    }
                    else {
                        const chats2 = newChats.filter(chat => !caseChats.map(chat1 => chat1.id).includes(chat.id))
                            .map(chat => {
                                chat.chatTime = new Date(chat.chatTime)
                                return chat
                            })
                        setCaseChat([...caseChats, ...chats2])
                    }
                }
            }
        })



    }

    useInterval(() => {
        getChats()
    }, 10000, { immediate: true })

    const CommentList = ({ comments }: { comments: CommentItem[] }) => (
        <List
            className={Module.list}
            dataSource={comments}
            header={`${comments.length} ${comments.length > 1 ? '条评论' : '评论'}`}
            itemLayout="horizontal"
            renderItem={chat1 => <Comment {...chat1} />}
        />
    );
    useEffect(() => {
        if (!caseId) {
            setCaseChat([])
        }
    }, [location])
    useEffect(() => {
        scroll()
    }, [chats])

    const handleSubmit = () => {
        apiChat.addChat(value, caseId, { exit: 0, })
        if (!value) return;
        setSubmitting(true);
        setSubmitting(false);
        setValue('');
    };
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };
    function scroll() {
        let scrolls = document.getElementById("center")
        if (scrolls !== undefined && scrolls !== null) {
            scrolls.scrollTop = scrolls.scrollHeight
        }
    }
    let element;
    if (caseId) {
        element = (<Tabs.TabPane className={Module.tabphone} tab="案件评论区" key="2">
            <div id="center" className={Module.centers} > {comments.length > 0 && <CommentList comments={caseComments} />}</div>
            <div className={Module.commit}>
                <Comment
                    className={Module.add}
                    content={
                        <Editor
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            submitting={submitting}
                            value={value}
                        />
                    }
                />
            </div>
        </Tabs.TabPane>)
    } else {
        element = (<></>)
    }
    return (
        <div className={Module.all}>
            <div className={Module.tops}> <h1>评论区</h1></div>
            <Tabs centered defaultActiveKey={"2"} className={Module.tabs}>
                <Tabs.TabPane tab="公共评论区" className={Module.tabphone} key="1">
                    <div id="center" className={Module.centers} > {comments.length > 0 && <CommentList comments={comments} />}
                    </div>
                    <div className={Module.commit}>
                        <Comment
                            className={Module.add}
                            content={
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={submitting}
                                    value={value}
                                />
                            }
                        />
                    </div>
                </Tabs.TabPane>
                {element}
            </Tabs>
        </div>
    )
};

export default Speak;