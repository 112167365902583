import { AutoComplete, Button, Form, Input, message, Select } from "antd"
import Module from '../style/askRecord.module.scss'
import React, { useEffect, useState } from "react";
import { MinusOutlined } from "@ant-design/icons";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { ApiAdminDictionaryType } from "../internet/ApiAdminDictionaryType";
import { useLocation, useNavigate } from "react-router-dom";
import DictionaryType from "../entity/DictionaryType";

const AskRecord: React.FC<DocumentProps> = (props) => {
  const navegate = useNavigate()
  const location = useLocation()
  const [types, setTypes] = useState<DictionaryType[]>([])
  const apiAdminDictionaryType = new ApiAdminDictionaryType(message, navegate)
  const getTypes = () => {
    apiAdminDictionaryType.getDictionaryTypes({
      successHandler: newTypes => {
        if (newTypes != null) {
          setTypes(newTypes)
        }
      }
    })
  }

  useEffect(() => {
    getTypes()
  }, [location])

  function optionAsk(dictionary: DictionaryType[]) {
    return dictionary.map(dictionarys => {
      if (dictionarys.typeName === "询问笔录问") {
        if (dictionarys.dictionaries) {
          return dictionarys.dictionaries.map(d => {
            return d.dictionaryValue
          })
        }
      }
    })
  }
  function optionAnswer(dictionary: DictionaryType[]) {
    return dictionary.map(dictionarys => {
      if (dictionarys.typeName === "询问笔录答") {
        if (dictionarys.dictionaries) {
          return dictionarys.dictionaries.map(d => {
            return d.dictionaryValue
          })
        }

      } else {
        return []
      }
    })
  }
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div >
              <h1 className={Module.h1}>询问笔录</h1>
              <div className={Module.center}>
                <div className={Module.text1}>
                  <div className={Module.textsig1}>
                    <p >询问时间：
                    </p>
                    <Form.Item
                      name={[0, "fieldValue"]} style={{ marginRight: 0 }} className={Module.select1} >
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        className={Module.time} showTime placeholder="开始时间" format="YYYY年MM月DD日hh时mm分" />
                    </Form.Item>
                    <Form.Item
                      name={[1, "fieldValue"]} className={Module.select1}>
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        className={Module.time} showTime placeholder="结束时间" format="DD日hh时mm分" />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p >询问地点：
                    </p>
                    <Form.Item
                      name={[2, "fieldValue"]} className={Module.select1} >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="询问地点" />
                    </Form.Item>
                  </div>
                  <div className={Module.people}>
                    <div className={Module.textsig}>
                      <p>询问人：</p>
                      <Form.Item
                        name={[3, "fieldValue"]} className={Module.select1} >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="执法人员姓名" />
                      </Form.Item>
                    </div>
                    <div className={Module.textsig}>
                      <p>执法证号：</p>
                      <Form.Item
                        name={[4, "fieldValue"]} className={Module.select1} >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="执法证号" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.people}>
                    <div className={Module.textsig}>
                      <p>询问人：</p>
                      <Form.Item
                        name={[5, "fieldValue"]} className={Module.select1} >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="执法人员姓名" />
                      </Form.Item>
                    </div>
                    <div className={Module.textsig}>
                      <p>执法证号：</p>
                      <Form.Item
                        name={[6, "fieldValue"]} className={Module.select1} >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="执法证号" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.textsig1}>
                    <p>记录人：</p>
                    <Form.Item
                      name={[7, "fieldValue"]} className={Module.select1} >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="记录人姓名" />
                    </Form.Item>
                  </div>
                  <p className={Module.p1}>一、被询问人基本情况</p>
                  <div>
                    <div className={Module.people2}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="被询问人姓名"
                          name={[8, "fieldValue"]} className={Module.select2} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="被询问人姓名" />
                        </Form.Item>
                        <Form.Item
                          label="性别"
                          name={[9, "fieldValue"]} className={Module.select2} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="被询问人性别" />
                        </Form.Item>
                        <Form.Item
                          label="身份证号码"
                          name={[10, "fieldValue"]} className={Module.select1} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="被询问人身份证号码" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people1}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="工作单位"
                          name={[11, "fieldValue"]} className={Module.select1} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="询问人工作单位" />
                        </Form.Item>
                        <Form.Item
                          label="职业"
                          name={[12, "fieldValue"]} className={Module.select1} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="被询问人职业" />
                        </Form.Item>
                        <Form.Item
                          label="联系电话"
                          name={[13, "fieldValue"]} className={Module.select1} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="被询问人联系电话" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.people1}>
                      <div className={Module.textsig}>
                        <Form.Item
                          label="住址"
                          name={[14, "fieldValue"]} className={Module.select1} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="住址" />
                        </Form.Item>
                        <Form.Item
                          label="邮编"
                          name={[15, "fieldValue"]} className={Module.select1} >
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="邮编" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={Module.check}>
                      <p className={Module.p1}>是否为：</p>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[16, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 16, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>中共党员</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[17, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 17, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>人大代表</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[18, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 18, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>政协委员</p>
                      </div>
                    </div>
                    <div className={Module.check}>
                      <p className={Module.p1}>与当事人关系：</p>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[19, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 19, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>当事人</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[20, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 20, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>法人代表</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[21, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 21, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>现场负责人</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[22, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly
                            onClick={() => props.changeCheckbox(["fields", 22, "fieldValue"])}
                          />
                        </Form.Item>
                        <p className={Module.h2}>其他</p>
                      </div>
                    </div>
                    <p className={Module.p1}>二、告知事项：</p>
                    <Form.List name={[23, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 23])
                        return (<div className={Module.list}>
                          {fields.map((field) => (
                            <Form.List name={[field.name]}>
                              {fields => (
                                <div className={Module.list1}>
                                  <Form.Item
                                    label="问"
                                    name={[0, "fieldValue"]}
                                    className={Module.item}
                                  >
                                    <AutoComplete
                                      dataSource={optionAsk(types)[0]}
                                      children={<Input />}
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    />
                                  </Form.Item>
                                  <div className={Module.list2}>
                                    <Form.Item
                                      label="答"
                                      name={[1, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAnswer(types)[1]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                      style={{ fontSize: 20 }} /></Button>
                                  </div>
                                </div>
                              )}
                            </Form.List>
                          ))}
                          {props.genAddButton(fieldEntity, "添加问答", add)}
                        </div>)
                      }}
                    </Form.List>
                    <p className={Module.p1}>三、询问内容：</p>
                    <Form.List name={[24, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 24])
                        return (<div className={Module.list}>
                          {fields.map((field) => (
                            <Form.List name={[field.name]}>
                              {fields => (
                                <div className={Module.list1}>
                                  <Form.Item
                                    label="问"
                                    name={[0, "fieldValue"]}
                                    className={Module.item}
                                  >
                                    <AutoComplete
                                      dataSource={optionAsk(types)[0]}
                                      children={<Input />}
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    />
                                  </Form.Item>
                                  <div className={Module.list2}>
                                    <Form.Item
                                      label="答"
                                      name={[1, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAnswer(types)[1]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                      style={{ fontSize: 20 }} /></Button>
                                  </div>
                                </div>
                              )}
                            </Form.List>
                          ))}
                          {props.genAddButton(fieldEntity, "添加问答", add)}
                        </div>)
                      }}
                    </Form.List>
                    <p className={Module.p1}>四、陈述（申辩）情况（可选）：</p>
                    <Form.List name={[25, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 25])
                        return (<div className={Module.list}>
                          <>
                            {fields.map((field) => (
                              <Form.List name={[field.name]}>
                                {fields => (
                                  <div className={Module.list1}>
                                    <Form.Item
                                      label="问"
                                      name={[0, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAsk(types)[0]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <div className={Module.list2}>
                                      <Form.Item
                                        label="答"
                                        name={[1, "fieldValue"]}
                                        className={Module.item}
                                      >
                                        <AutoComplete
                                          dataSource={optionAnswer(types)[1]}
                                          children={<Input />}
                                          disabled={props.getPermission((props.form.getFieldValue(["fields", 25, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        />
                                      </Form.Item>
                                      <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                        style={{ fontSize: 20 }} /></Button>
                                    </div>
                                  </div>
                                )}
                              </Form.List>
                            ))}
                            {props.genAddButton(fieldEntity, "添加问答", add)}
                          </>
                        </div>)
                      }}
                    </Form.List>
                    <p className={Module.p1}>无、送达地址确认（可选）：</p>
                    <Form.List name={[26, "children"]}>
                      {(fields, { add, remove }) => {
                        const fieldEntity = props.form.getFieldValue(["fields", 26])
                        return (<div className={Module.list}>
                          <>
                            {fields.map((field) => (
                              <Form.List name={[field.name]}>
                                {fields => (
                                  <div className={Module.list1}>
                                    <Form.Item
                                      label="问"
                                      name={[0, "fieldValue"]}
                                      className={Module.item}
                                    >
                                      <AutoComplete
                                        dataSource={optionAsk(types)[0]}
                                        children={<Input />}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      />
                                    </Form.Item>
                                    <div className={Module.list2}>
                                      <Form.Item
                                        label="答"
                                        name={[1, "fieldValue"]}
                                        className={Module.item}
                                      >
                                        <AutoComplete
                                          dataSource={optionAnswer(types)[1]}
                                          children={<Input />}
                                          disabled={props.getPermission((props.form.getFieldValue(["fields", 26, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        />
                                      </Form.Item>
                                      <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                        style={{ fontSize: 20 }} /></Button>
                                    </div>
                                  </div>
                                )}
                              </Form.List>
                            ))}
                            {props.genAddButton(fieldEntity, " 添加问答", add)}
                          </>
                        </div>)
                      }}
                    </Form.List>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>

  )

}


export default AskRecord