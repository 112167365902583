import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/putrecorddecisoin.module.scss'
const PutRecordDecisoin: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>立案决定书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.text}>
                  <Form.Item
                    className={Module.item1}
                    name={[1, "fieldValue"]}
                  >
                    <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="当事人" />
                  </Form.Item>
                  <Form.Item
                    className={Module.item}
                    name={[2, "fieldValue"]}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} className={Module.textarea} rows={3}
                      placeholder="经查" />
                  </Form.Item>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位名称：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      name={[3, "fieldValue"]}
                      className={Module.itemtime}>
                      <Select
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.id.toString()}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成日期：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      name={[4, "fieldValue"]}
                      className={Module.itemtime}
                    >
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        className={Module.date} format={"YYYY年MM月DD日"} placeholder="生成日期" />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      className={Module.itemtime}
                      style={{ marginLeft: "2%" }}
                      name={[5, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default PutRecordDecisoin