import InternetTool from "./Internetool";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import { Summary } from "../entity/Summary";
export class ApiTool {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("tool", message, navigate)
  }
  getRecordTool(file: Array<(File | undefined)>, resultParam?: ResultParam<Array<Summary>>) {
    let formData = new FormData()
    file.forEach(file => {
      if (file) {
        formData.append("file", file)
      }
    })
    this.internetTool.ft
      (
        "/file-data",
        {
          method: "POST",
          body: formData,
        },
        resultParam
      )
  }
  mergeAll(file: Array<(File | undefined)>, da: Summary, resultParam?: ResultParam<Uint8Array>) {
    let formData = new FormData()
    formData.append("da", new Blob([JSON.stringify(da)], { type: "application/json" }))
    file.forEach(file => {
      if (file) {
        formData.append("file", file)
      }
    })
    this.internetTool.ft
      (
        "/merge-all",
        {
          method: "POST",
          body: formData,
        },
        resultParam
      )
  }
  getSelect(resultParam?: ResultParam<Array<Summary>>) {
    this.internetTool.ft
      (
        "/select",
        {
          method: "GET",
        },
        resultParam
      )
  }
  merge(list: Array<Summary>, resultParam?: ResultParam<Summary>) {
    let formData = new FormData()
    formData.append("list", new Blob([JSON.stringify(list)], { type: "application/json" }))
    this.internetTool.ft
      (
        "/merge",
        {
          body: formData,
          method: "POST",
        },
        resultParam
      )
  }

  uplode(file: File, resultParam?: ResultParam<string>) {
    let formData = new FormData()
    formData.append("file", new Blob([JSON.stringify(file)], { type: "application/json" }))
    this.internetTool.ft
      (
        "upload",
        {
          method: "POST",
          body: formData,
        },
        resultParam
      )
  }


}