import { Button, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import Module from '../style/wsxg.module.scss'




const Wsxg = () => {
    return (
        <div className={Module.all}>
            <div className={Module.center}>
                <Form
                    className={Module.form}
                >
                    <Row>
                        <Form.Item
                            className={Module.item}
                            name="name"
                            label="案件名称"
                            initialValue={"林木盗窃"}
                        >
                            <Input className={Module.ipt} />
                        </Form.Item>
                        <Form.Item
                            className={Module.item}
                            name="id"
                            label="案件编号"
                            initialValue={"19898989"}
                        >
                            <Input className={Module.ipt} />
                        </Form.Item>
                        <Form.Item
                            className={Module.item}
                            name="people"
                            label="主办人员"
                            initialValue={"公安局"}
                        >
                            <Input className={Module.ipt} />
                        </Form.Item>
                        <Form.Item
                            className={Module.item}
                            name="npeople"
                            label="协办人员"
                            initialValue={"林业局"}
                        >
                            <Input className={Module.ipt} />
                        </Form.Item>
                    </Row>
                    <Form.Item
                        className={Module.item1}
                        name="reason"
                        label="申请审批理由"
                    >
                        <TextArea autoSize={{ minRows: 5, maxRows: 8 }} className={Module.ipt} />
                    </Form.Item>
                    <Form.Item
                        className={Module.btn}
                    >
                        <Button type="primary">确认申请</Button>
                    </Form.Item>

                </Form>
            </div>



        </div>
    )
}
export default Wsxg