import LawPunishItem from "./LawPunishItem";

export class PunishItem {
  id: number;
  punishId: number;
  lawPunishItemId: number;
  itemValue: string;

  constructor(
      id: number,
      punishId: number,
      lawPunishItemId: number,
      itemValue: string
  ) {
    this.id = id
    this.punishId = punishId
    this.lawPunishItemId = lawPunishItemId
    this.itemValue = itemValue
  }

  static convertLawPunishItem(item: LawPunishItem, punishId: number = 0): PunishItem {
    return new PunishItem(0, punishId, item.id, item.punishValue)
  }

  static convertLawPunishItems(items: Array<LawPunishItem>, punishId: number = 0): PunishItem[] {
    return items.map(item => PunishItem.convertLawPunishItem(item, punishId))
  }
}