import { User } from "../entity/User"
import InternetTool from "./Internetool"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import { ProjectInfoEntity } from "../entity/ProjectInfo";
class ApiOpen {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("open", message, navigate)
  }
  login(username: string, password: string, resultParam?: ResultParam<User>) {
    let formData = new FormData()
    formData.append("username", username)
    formData.append("password", password)
    this.internetTool.ftopen(
      "/login",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  get(resultParam?: ResultParam<ProjectInfoEntity>) {
    this.internetTool.fe
      (
        "/project/info",
        resultParam
      )
  }
}
export default ApiOpen