import InternetTool from "./Internetool"
import UserEntity from "../entity/UserEntity"
import ResultBody from "../entity/ResultBody";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
class ApiAdminUser {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/user", message, navigate)
  }
  getUsers(resultParam?: ResultParam<Array<UserEntity>>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )

  }
  getSignature(uid: number, resultParam?: ResultParam<ResultBody<Uint8Array>>) {
    this.internetTool.fe
      (
        `/signature/${uid}`,
        resultParam
      )
  }
  addUser(user: UserEntity, role: number, unit: number, password: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    formData.append("role", role.toString())
    formData.append("unit", unit.toString())
    formData.append("password", password.toString())
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  addUserSimple(user: UserEntity, password: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    formData.append("password", password.toString())
    this.internetTool.ft(
      `/user-simple`,
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  updateUser(user: UserEntity, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateUserPassword(uid: number, password: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("uid", uid.toString())
    formData.append("password", password)
    this.internetTool.ft(
      `/password`,
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  deleteUser(uid: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("uid", uid.toString())
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }
}
export default ApiAdminUser