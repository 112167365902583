import { Button, message, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Seal from "../entity/Seal"
import Module from '../style/seal.module.scss'
import { ApiAdminSeal } from "../internet/ApiAdminSeal";

const columns: ColumnsType<Seal> = [
  {
    title: "印章名称",
    dataIndex: "sealName",
  }
]
const SealList: React.FC = () => {
  const [seals, setSeals] = useState<Seal[]>([])
  const location = useLocation()
  const navigate = useNavigate()
  const unitId = (location.state as { unitId?: number })?.unitId
  const apiAdminSeal = new ApiAdminSeal(message, navigate)

  function getSeals(unitId: number) {
    apiAdminSeal.getByUnit(unitId, {
      successHandler: seals => {
        if (seals) {
          setSeals(seals)
        }
      }
    })

  }

  useEffect(() => {
    if (!unitId) {
      navigate(-1)
      return
    }
    getSeals(unitId)
  }, [location])
  const rowClick = (seal: Seal) => {
    return {
      onClick: () => {
        navigate("/modifyseal", { state: { seal: seal } })
      }
    }
  }

  function addSeal() {
    navigate("/modifyseal", { state: { unitId: unitId } })
  }

  return (
    <div className={Module.all}>
      <Table
        className={Module.table}
        rowKey={"id"}
        size="small"
        onRow={rowClick}
        columns={columns}
        dataSource={seals}
      />
      <Button type="primary" className={Module.btn} onClick={addSeal}>添加印章</Button>
    </div>

  )
}

export default SealList