import FieldTypeEntity from "./FieldTypeEntity";


class DocumentTypeEntity {
  id: number;
  typeName: string;
  typeDescription?: string;
  filePath: string;
  masterId: number| undefined;
  fieldTypes?: Array<FieldTypeEntity>;
  roleList?: Array<number>

  constructor(id: number, typeName: string, typeDescription: string | undefined = undefined,
    filePath: string,masterId: number | undefined = undefined, fieldTypes?: Array<FieldTypeEntity>, roleList?: Array<number>) {
    this.id = id
    this.typeName = typeName
    this.typeDescription = typeDescription
    this.filePath = filePath
    this.masterId = masterId
    this.fieldTypes = fieldTypes
    this.roleList = roleList
  }

  static create(type: DocumentTypeEntity): DocumentTypeEntity {
    return new DocumentTypeEntity(
      type.id,
      type.typeName,
      type.typeDescription,
      type.filePath,
      type.masterId,
      type.fieldTypes ?? [],
      type.roleList ?? []
    )
  }

  static config(type: DocumentTypeEntity, entity: { typeName: string, typeDescription: string, fields: FieldTypeEntity[] }) {
    return new DocumentTypeEntity(
      type.id,
      type.typeName,
      entity.typeDescription,
      type.filePath,
      type.masterId,
      entity.fields,
      type.roleList
    )
  }
}

export default DocumentTypeEntity