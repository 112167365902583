import { Button, message, Table, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ApiDocument from "../internet/ApiDocument"
import DocumentInfo from "../entity/documentinfo"
import Module from '../style/WritList.module.scss'
import DocumentEntity from "../entity/DocumentEntity"


const AccessoriesList = () => {
  const [documentInfos, setDocumentInfos] = useState<DocumentInfo[]>([])
  const navigate = useNavigate()
  const apiDocument = new ApiDocument(message, navigate)
  const location = useLocation()
  const caseId = (location.state as { caseId?: number })?.caseId
  const infos = (location.state as { infos?: DocumentInfo[] })?.infos
  const document = (location.state as { document?: DocumentEntity })?.document
  const documentId = (location.state as { documentId?: number })?.documentId
  const masterId = (location.state as { masterId?: number })?.masterId
  const status = (location.state as { status?: string })?.status
  function getInfos() {
    if (documentId) {
      apiDocument.getAttachmentInfos(documentId, {
        successHandler: infos => {
          if (infos) {
            console.log(documentId);
            setDocumentInfos(infos)
          }
        }
      })
    }
  }

  useEffect(() => {
    getInfos()
  }, [location])

  const columns: ColumnsType<DocumentInfo> = [
    {
      title: "附件名称",
      dataIndex: "documentName",
    },
    {
      title: "案件状态",
      dataIndex: "status",
      width: 100,
      render: (value: any, record: DocumentInfo, index: number) => {
        if (record.status === "已完成") {
          return (<Button type="link" style={{ color: "rgba(0,128,0,0.3)" }}>{record.status}</Button>)
        } else if (record.status === "未完成") {
          return (<Button type="link" style={{ color: "red" }}>{record.status}</Button>)
        }
      }
    },
    {
      title: "操作",
      dataIndex: "caozuo",
      width: 100,
      render: (value: any, record: DocumentInfo, index: number) => {
        return <Button type="link" onClick={
          (e) => {
            e.stopPropagation()
            const newInfos = documentInfos.filter(item => item.id !== record.id)
            setDocumentInfos(newInfos)
          }
        }>删除</Button>
      }
    },
  ]

  const onClick = (documentType: DocumentInfo) => {
    return {
      onClick: () => {
        navigate("/wenshu", {
          state: {
            masterId: documentType.id,
            caseId: caseId,
            documentId: documentType.id,
            infos: infos,
            menu: document,
            status: documentType.status,
            accessorieComplate: true
          }
        })
      }
    }
  }
  function addAccessorie() {
    navigate("/addaccessories", { state: { masterId: masterId, documentId: documentId, caseId: caseId } })
  }
  let btn;
  if (status === "已完成") {
    btn = <></>
  } else {
    btn = <Button type="primary" style={{ float: "right" }} onClick={addAccessorie}>添加附件</Button>
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        {btn}
        <Table
          className={Module.table}
          size="small"
          columns={columns}
          dataSource={documentInfos}
          pagination={false}
          rowKey="id"
          onRow={onClick}
        />
      </div>
    </div>
  )
}

export default AccessoriesList