import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/putreacordregist.module.scss'
const PutReacordRegist: React.FC<DocumentProps> = (props) => {

  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>立案登记表</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[16, "fieldValue"]}
                  >
                    <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.a1}><p>案由</p></div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[0, "fieldValue"]}
                      >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案由" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.a1}><p>案件来源</p></div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[1, "fieldValue"]}
                      >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案件来源" />
                      </Form.Item>
                    </div>
                    <div className={Module.a3}><p>受案时间</p></div>
                    <div className={Module.a4}>
                      <Form.Item
                        className={Module.time}
                        name={[2, "fieldValue"]}
                      >
                        <StringDatePicker disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="受案时间" format="YYYY年MM月DD日" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}>
                      <p>违法当事人</p>
                    </div>
                    <div className={Module.c2}>
                      <div className={Module.d1}>
                        <div className={Module.e1}>
                          <p>姓名</p>
                        </div>
                        <div className={Module.e2}>
                          <Form.Item
                            className={Module.item}
                            name={[3, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="姓名一" />
                          </Form.Item>
                        </div>
                        <div className={Module.e1}>
                          <p>姓别</p>
                        </div>
                        <div className={Module.e3}>
                          <Form.Item
                            className={Module.item}
                            name={[4, "fieldValue"]}
                          >
                            <Input placeholder="姓别" disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                          </Form.Item>
                        </div>
                        <div className={Module.e1}>
                          <p>年龄</p>
                        </div>
                        <div className={Module.e4}>
                          <Form.Item
                            className={Module.item}
                            name={[5, "fieldValue"]}
                          >
                            <Input placeholder="年龄" disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                          </Form.Item>
                        </div>
                        {/* <div className={Module.e5}>
                          <p>电话</p>
                        </div>
                        <div className={Module.e6}>
                          <Form.Item
                            className={Module.item}
                            name={[6, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="电话" />
                          </Form.Item>
                        </div> */}
                      </div>
                      <div className={Module.d2}>
                        <div className={Module.e1}><p>工作单位</p></div>
                        <div className={Module.e2}>
                          <Form.Item
                            className={Module.item}
                            name={[7, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="工作单位" />
                          </Form.Item>
                        </div>
                        <div className={Module.e3}><p>住址</p></div>
                        <div className={Module.e4}>
                          <Form.Item
                            className={Module.item}
                            name={[8, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="住址" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d3}>
                        <div className={Module.e1}><p>单位名称</p></div>
                        <div className={Module.e2}>
                          <Form.Item
                            className={Module.item}
                            name={[9, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="单位名称" />
                          </Form.Item>
                        </div>
                        <div className={Module.e3}><p>地址</p></div>
                        <div className={Module.e4}>
                          <Form.Item
                            className={Module.item}
                            name={[10, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="地址" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.d4}>
                        <div className={Module.e1}><p>法定代表人</p></div>
                        <div className={Module.e2}>
                          <Form.Item
                            className={Module.item}
                            name={[11, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="法定代表人" />
                          </Form.Item>
                        </div>
                        <div className={Module.e3}><p>职务</p></div>
                        <div className={Module.e4}>
                          <Form.Item
                            className={Module.item}
                            name={[12, "fieldValue"]}
                          >
                            <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="职务" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}><p>简要案情</p></div>
                    <div className={Module.d2}>
                      <Form.Item
                        name={[13, "fieldValue"]}
                      >
                        <Input.TextArea disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          autoSize={{ minRows: 2, maxRows: 5 }} placeholder="简要案情" className={Module.text} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}><p>备注</p></div>
                    <div className={Module.d2}>
                      <Form.Item
                        className={Module.text}
                        name={[14, "fieldValue"]}
                      >
                        <Input.TextArea
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          autoSize={{ minRows: 2, maxRows: 5 }} placeholder="备注"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginLeft: "2%" }}
                        className={Module.select}
                        name={[15, "fieldValue"]} >
                        <Select placeholder="印章"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}

                        >
                          {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default PutReacordRegist