export class ModalControl<T> {
  entity: T | null = null
  visible: boolean = false

  constructor(
      entity: T | null = null,
      visible: boolean = false
  ) {
    this.entity = entity
    this.visible = visible
  }

  static show<T>(entity: T | null = null) {
    return new ModalControl<T>(entity, true)
  }

  static hide<T>() {
    return new ModalControl<T>()
  }

}