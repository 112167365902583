import { Button, message } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentInfo from "../entity/documentinfo";
import Module from '../style/alllaw.module.scss'
import ApiDocument from "../internet/ApiDocument";
interface Type {
  documentId?: number;
  caseId?: number;
  masterId?: number;
  status?: string;
  documentName?: string;
}
const columns: ColumnsType<DocumentInfo> = [
  {
    title: "案件编号",
    dataIndex: "caseCode",
    width: 50,
  },
  {
    title: "文档名称",
    dataIndex: "documentName",
    width: 180,
  },
  {
    title: "案件名称",
    dataIndex: "caseName",
    width: 150,
    ellipsis: true
  },
  {
    title: "案件状态",
    dataIndex: "status",
    width: 100,
    render: (value: any, record: DocumentInfo, index: number) => {
      if (record.status === "已完成") {
        return (<Button type="link" style={{ color: "rgba(0,128,0,0.3)" }}>{record.status}</Button>)
      } else if (record.status === "未完成") {
        return (<Button type="link" style={{ color: "red" }}>{record.status}</Button>)
      } else if (record.status === "待审批") {
        return (<Button type="link" style={{ color: "SaddleBrown" }}>{record.status}</Button>)
      } else if (record.status === "已审批") {
        return (<Button type="link" style={{ color: "rgba(0,0,255,1s)" }}>{record.status}</Button>)
      } else if (record.status === "审批中") {
        return (<Button type="link" style={{ color: "rgba(47,79,79,0.6)" }}>{record.status}</Button>)
      }
    }
  },

];
const AllLaw: React.FC<{ caseId: number, setTypes: (types: Type[]) => void, types: Type[] }> = ({ caseId, setTypes, types }) => {
  const [infos, setInfos] = useState<DocumentInfo[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const apiDocument = new ApiDocument(message, navigate)
  const rowClick = (datas: DocumentInfo) => {
    const documentId = datas.id
    const masterId = datas.typeId
    const status = datas.status
    const documentName = datas.documentName
    const type = { documentId, caseId, masterId, status, documentName } as Type;
    return {
      onClick: () => {
        if (type) {
          if (type.caseId !== types[types.length - 1]?.caseId) {
            setTypes([type]);
            navigate("/tabwrit", { state: { type: [type] } })
          } else {
            const updatedTypes = [type, ...types];
            const distinctTypes = updatedTypes.filter((type, index, self) =>
              index === self.findIndex(t => t.documentId === type.documentId)
            );
            setTypes(distinctTypes);
            navigate("/tabwrit", { state: { type: distinctTypes } })
          }
        } else {
          return null
        }
      }
    }
  }

  function getInfos() {
    apiDocument.getInfos(caseId, {
      successHandler: infos => {
        if (infos) {
          setInfos(infos)
        }
      }
    })
  }

  useEffect(() => {
    getInfos()
  }, [location])
  return (
    <div className={Module.all}>
      <Table
        rowKey={"id"}
        onRow={rowClick}
        size="small"
        className={Module.table}
        columns={columns}
        pagination={{ showLessItems: true }}
        dataSource={infos}
      />

    </div>
  )
}
export default AllLaw