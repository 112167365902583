import InternetTool from "./Internetool"
import Law from "../entity/Law"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiAdminLaw {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/law", message, navigate)
  }
  addLaw(law: Law, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("law", new Blob([JSON.stringify(law)], { type: "application/json" }))
    this.internetTool.ft<void>(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  getLaws(resultParam?: ResultParam<Array<Law>>) {
    this.internetTool.ft(
      "",
      {
        method: "GET"
      },
      resultParam
    )
  }
  updateLaw(law: Law, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("law", new Blob([JSON.stringify(law)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  deleteLaw(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
}
