class UnitEntity {
  id: number;
  unitName: string;
  unitDescription: string | null;
  parentUnitId: number | null;
  children?: Array<UnitEntity>

  constructor(id: number,
    unitName: string,
    unitDescription: string | null,
    parentUnitId: number | null,
    children?: Array<UnitEntity>) {
    this.id = id
    this.unitName = unitName
    this.unitDescription = unitDescription
    this.parentUnitId = parentUnitId
    this.children = children
  }

  static create(unit: UnitEntity): UnitEntity {
    return new UnitEntity(
      unit.id ?? 0,
      unit.unitName ?? "",
      unit.unitDescription ?? null,
      unit.parentUnitId ?? null,
      unit.children?.map(child => {
        return UnitEntity.create(child)
      }) ?? []
    )
  }
}

export default UnitEntity