import InternetTool from "./Internetool"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";

class ApiAdminSignature {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/user/signature", message, navigate)
  }

  updateSignature(
    uid: number, signature: File | Blob,
    resultParam?: ResultParam<Uint8Array>
  ) {
    let formData = new FormData()
    formData.append("uid", uid.toString())
    formData.append("signature", signature)
    this.internetTool.ft("", { body: formData, method: "PUT" }, resultParam)
  }

  async deleteSignature(uid: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("uid", uid.toString())
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }
}

export default ApiAdminSignature