import { Button, message, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentTypeEntity from '../entity/DocumentTypeEntity';
import Module from '../style/wordlist.module.scss'
import Role from "../entity/Role";
import { ApiDocumentType } from "../internet/ApiDocumentType";
import { ApiAdminConfiguration } from '../internet/ApiAdminConfiguration';

const columns: ColumnsType<DocumentTypeEntity> = [
  {
    title: "",
    dataIndex: "typeName",
  }
]

const WordList: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [role, setRole] = useState<number | undefined>()
  const [roles, setRoles] = useState<Role[]>([])
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([])
  const apiDocumentType = new ApiDocumentType(message, navigate)
  const apiAdminConfiguration = new ApiAdminConfiguration(message, navigate)

  function getTypeRoles() {
    apiDocumentType.getTypeRoles({
      successHandler: roles => {
        if (roles) {
          const roles1 = Role.createList(roles)
          const role = new Role(0, "all_type", "全部文书类型", [], [])
          setRoles([role, ...roles1])
          setRole(0)
        }
      }
    })
  }

  function getDocumentTypes() {
    apiDocumentType.getDocumentTypes({
      successHandler: types => {
        if (types) {
          setDocumentTypes(types.map(type => DocumentTypeEntity.create(type)))
        }
      }
    })

  }

  useEffect(() => {
    getTypeRoles()
    getDocumentTypes()
  }, [location])

  const onClick = (documentType: DocumentTypeEntity) => {
    return {
      onClick: () => {
        navigate("/wordsee", { state: { documentTypeId: documentType.id, roles: roles } })
      }
    }
  }

  const addWord = () => {
    navigate("/wordmain")
  }
  const wordType = () => {
    navigate("/wordtype")
  }
  const outPut = () => {
    apiAdminConfiguration.output({
      exit: 0,
      successHandler: data => {
        if (data) {
          const url = "data:zip;base64," + data.toString()
          const link = document.createElement('a')
          link.href = url
          link.download = "output.zip"
          link.click()
        }
      }
    })
  }
  const inPut = () => {
    navigate("/wordmain", { state: { complate: true } })
  }
  const element = roles.map(item => {
    return <Tabs.TabPane tab={item.roleDescription} key={item.id} />
  })
  const onChange = (key: string) => {
    setRole(parseInt(key))
  }
  let filter
  if (role === undefined) {
    filter = function (_: DocumentTypeEntity) {
      return false
    }
  } else if (role !== 0) {
    filter = function (type: DocumentTypeEntity) {
      return (type.roleList ?? []).includes(role)
    }
  } else {
    filter = function (_: DocumentTypeEntity) {
      return true
    }
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        <Button onClick={inPut} type='primary' style={{ marginRight: 10 }} >导入模板</Button>
        <Button onClick={outPut} type='primary' >导出模板</Button>
        <Button onClick={wordType} type='primary' style={{ float: "right" }}>管理文书模板类型</Button>
        <Tabs onChange={onChange}>
          {element}
        </Tabs>
        <Table
          className={Module.tab}
          columns={columns}
          pagination={false}
          dataSource={documentTypes.filter(filter)}
          rowKey="id"
          onRow={onClick}
        />
        <Button onClick={addWord} type='primary'>增加</Button>
      </div>
    </div>
  );
};
export default WordList