class ResultBody<T> {
  code: string
  body?: T
  message?: string

  constructor(code: string, body?: T, message?: string) {
    this.code = code
    this.body = body
    this.message = message
  }

}

export default ResultBody