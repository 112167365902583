import InternetTool from "./Internetool";
import Law from "../entity/Law";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import { LawPunishType } from "../entity/LawPunishType";

export class ApiLaw {
  readonly internetTool: InternetTool

  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("law", message, navigate)
  }

  get(resultParam?: ResultParam<Array<Law>>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )
  }

  getPunishTypes(resultParam?: ResultParam<Array<LawPunishType>>) {
    this.internetTool.fe
      (
        "/punish",
        resultParam
      )
  }
}