import TemplateItem from "./TemplateItem";

export class Template {
  id: number;
  templateName: string;
  templateValue: string;
  roleId: number = 0;
  items?: Array<TemplateItem>
  constructor(id: number, templateName: string, templateValue: string, roleId: number, items?: Array<TemplateItem>) {
    this.id = id
    this.templateName = templateName
    this.roleId = roleId
    this.items = items
    this.templateValue = templateValue
  }
}