import CaseEntity from "../entity/CaseEntity";
import { Roles } from "./Roles";
import UnitEntity from "../entity/UnitEntity";
import UserEntity from "../entity/UserEntity";
import { LawPunishType } from "../entity/LawPunishType";
import { Punish } from "../entity/Punish";
import LawPunish from "../entity/LawPunish";
import { LawPunishArticle } from "../entity/LawPunishArticle";
import LawPunishArticleLaw from "../entity/LawPunishArticleLaw";
import { LawLevels } from "./LawLevels";
import Evidence from "../entity/Evidence";
import { Template } from "../entity/Template";
import { RoleMultistage } from "../entity/RoleMultistage";
import { PunishItem } from "../entity/PunishItem";
import moment from "moment";
import { LawPunishTypeLaw } from "../entity/LawPunishTypeLaw";
import IdCardTool from "./IdCardTool";
import Role from "../entity/Role";
import Law from "../entity/Law";
import { LawPunishArticleLawEntity } from "../entity/LawPunishArticleLawEntity";

export class StrTemplate {
  private format: string = "YYYY年MM月DD日"
  units: UnitEntity[]
  users: UserEntity[]
  punishTypes: LawPunishType[]
  punishMap: Map<number, {
    type: LawPunishType,
    punish: LawPunish,
    article: LawPunishArticle,
    articleLaw: LawPunishArticleLaw,
  }>
  punishTypeLawMap: Map<number, {
    type: LawPunishType,
    typeLaw: LawPunishTypeLaw,
  }>
  templates: Template[]
  fillRoles: RoleMultistage[]
  templateRoles: Role[]
  department?: UnitEntity
  law: Law[]
  documentCode: string


  icTool = new IdCardTool()

  constructor(
    documentCode: string,
    units: UnitEntity[],
    users: UserEntity[],
    lawPunishTypes: LawPunishType[],
    templates: Template[],
    fillRoles: RoleMultistage[],
    templateRoles: Role[],
    law: Law[],

    department?: UnitEntity,
  ) {
    this.units = units;
    this.users = users;
    this.punishTypes = lawPunishTypes
    this.punishMap = new Map()
    this.punishTypeLawMap = new Map()
    this.templates = templates
    this.fillRoles = fillRoles
    this.department = department
    this.law = law
    this.templateRoles = templateRoles
    this.documentCode = documentCode
    for (const punishType of lawPunishTypes) {
      for (const typeLaw of punishType.laws ?? []) {
        if (!this.punishTypeLawMap.get(typeLaw.id)) {
          this.punishTypeLawMap.set(typeLaw.id, {
            type: punishType,
            typeLaw: typeLaw
          })
        }
      }
      for (const punish of punishType.punishes ?? []) {
        for (const article of punish.articles ?? []) {
          for (const punishArticleLaw of article.punishArticleLaws ?? []) {
            if (!this.punishMap.get(punishArticleLaw.id)) {
              this.punishMap.set(punishArticleLaw.id, {
                type: punishType,
                punish: punish,
                article: article,
                articleLaw: punishArticleLaw
              })
            }
          }
        }
      }
    }
  }

  getDepartmentStr(roleId: number): string | undefined {
    const departmentRoles = Roles.bind.fieldType.department
    switch (roleId) {
      case departmentRoles.departmentCode.id:
        if (this.department?.unitDescription) {
          return this.department.unitDescription
        }
    }
    return undefined
  }
  genBaseCaseString(roleId: number, caseEntity: CaseEntity): string[] {
    const evidences = caseEntity.evidenceList
    const person = caseEntity.suspectPerson
    const unit = caseEntity.suspectUnit
    const punishes = caseEntity.punishes
    const units = this.units
    const users = this.users

    const fieldTypeRoles = Roles.bind.fieldType
    const caseRoles = fieldTypeRoles.caseBase
    const evidenceRoles = fieldTypeRoles.evidence
    const personRoles = fieldTypeRoles.suspectPerson
    const unitRoles = fieldTypeRoles.suspectUnit
    const punishRoles = fieldTypeRoles.punish
    const constantRoles = fieldTypeRoles.constant
    const documentRoles = fieldTypeRoles.document
    switch (roleId) {
      case caseRoles.caseCode.id:
        return this.getStrArray(caseEntity.caseCode)
      case caseRoles.caseName.id:
        return this.getStrArray(caseEntity.caseName)
      case caseRoles.caseCause.id:
        return this.getStrArray(caseEntity.caseCause)
      case caseRoles.caseType.id:
        return this.getStrArray(this.getPunishTypeName(caseEntity.caseType))
      case caseRoles.hostUnit.id:
        return this.getStrArray(units.find(it => it.id === caseEntity.hostUnitId)?.unitName)
      case caseRoles.caseSource.id:
        return this.getStrArray(caseEntity.caseSource)
      case caseRoles.handle.id:
        return this.getStrArray(users.find(it => it.id === caseEntity.handleUid)?.realName)
      case caseRoles.handleSub.id:
        return this.getStrArray(users.find(it => it.id === caseEntity.handleSubUid)?.realName)
      case caseRoles.mainCase.id:
        return this.getStrArray(caseEntity.mainCase)
      case caseRoles.city.id:
        return this.getStrArray(caseEntity.caseCity)
      case caseRoles.district.id:
        return this.getStrArray(caseEntity.caseDistrict)
      case caseRoles.townVillage.id:
        return this.getStrArray(caseEntity.caseTownVillage)
      case caseRoles.streetVillage.id:
        return this.getStrArray(caseEntity.caseStreetVillage)
      case caseRoles.communityGroup.id:
        return this.getStrArray(caseEntity.caseCommunityGroup)
      case caseRoles.casePlace.id:
        return this.getStrArray(caseEntity.casePlace)
      case caseRoles.buildTime.id:
        return this.getStrArrayByTime(caseEntity.caseTime)
      case caseRoles.registerTime.id:
        return this.getStrArrayByTime(caseEntity.caseRegisterTimer)
      case caseRoles.handleUid.id:
        return this.getUserArray(caseEntity.handleUid).map(it => it.id.toString())
      case caseRoles.handleSubUid.id:
        return this.getUserArray(caseEntity.handleSubUid).map(it => it.id.toString())
      case caseRoles.handleCode.id:
        return this.getUserArray(caseEntity.handleUid).map(it => it.lawEnforcementCard)
      case caseRoles.handleSubCode.id:
        return this.getUserArray(caseEntity.handleSubUid).map(it => it.lawEnforcementCard)
      case caseRoles.law.id:
        if (caseEntity.factLawId) {
          const laws = LawPunishArticleLawEntity.laws(this.law, caseEntity.factLawId)
          return this.getStrArray(LawLevels.getLawPrefixByArticleLaw({ entity: laws }))
        }
        return []
      case caseRoles.lawFull.id:
        if (caseEntity.factLawId) {
          const laws = LawPunishArticleLawEntity.laws(this.law, caseEntity.factLawId)
          return this.getStrArray(LawLevels.getLawStringByArticleLaw({ entity: laws }))
        }
        return []


      case personRoles.suspectName.id:
        return this.getStrArray(person?.suspectName)
      case personRoles.gender.id:
        if (person?.identificationCard) {
          try {
            return [this.icTool.getAnalysisIdCard(person.identificationCard, "sex").toString()]
          } catch (e) {
          }
        }
        return []
      case personRoles.age.id:
        if (person?.identificationCard) {
          try {
            return [this.icTool.getAnalysisIdCard(person.identificationCard, "age").toString()]
          } catch (e) {
          }
        }
        return []
      case personRoles.race.id:
        return this.getStrArray(person?.race)
      case personRoles.identificationCard.id:
        return this.getStrArray(person?.identificationCard)
      case personRoles.domicilePlace.id:
        return this.getStrArray(person?.domicilePlace)
      case personRoles.mobilePhone.id:
        return this.getStrArray(person?.mobilePhone)
      case personRoles.address.id:
        return this.getStrArray(person?.address)
      case personRoles.unitName.id:
        return this.getStrArray(person?.unitName)
      case personRoles.position.id:
        return this.getStrArray(person?.position)
      case personRoles.occupation.id:
        return this.getStrArray(person?.occupation)
      case personRoles.birthday.id:
        if (person?.identificationCard) {
          try {
            return [this.icTool.getAnalysisIdCard(person.identificationCard).toString()]
          } catch (e) {
          }
        }
        return []


      case unitRoles.unitCode.id:
        return this.getStrArray(unit?.unitCode)
      case unitRoles.unitName.id:
        return this.getStrArray(unit?.unitName)
      case unitRoles.unitType.id:
        return this.getStrArray(unit?.unitType)
      case unitRoles.unitAddress.id:
        return this.getStrArray(unit?.unitAddress)
      case unitRoles.unitPhone.id:
        return this.getStrArray(unit?.unitPhone)
      case unitRoles.representativeName.id:
        return this.getStrArray(unit?.representativeName)
      case unitRoles.representativeIdentificationCard.id:
        return this.getStrArray(unit?.representativeIdentificationCard)
      case unitRoles.representativePhone.id:
        return this.getStrArray(unit?.representativePhone)
      case unitRoles.representativeAddress.id:
        return this.getStrArray(unit?.representativeAddress)
      case unitRoles.principalName.id:
        return this.getStrArray(unit?.principalName)
      case unitRoles.principalIdentificationCard.id:
        return this.getStrArray(unit?.principalIdentificationCard)
      case unitRoles.principalPhone.id:
        return this.getStrArray(unit?.principalPhone)
      case unitRoles.principalAddress.id:
        return this.getStrArray(unit?.principalAddress)


      case evidenceRoles.evidenceName.id:
        return this.mapArray(evidences, (evidence) => evidence.evidenceName)
      case evidenceRoles.evidenceType.id:
        return this.mapArray(evidences, (evidence) => evidence.evidenceType)
      case evidenceRoles.evidenceDescription.id:
        return this.mapArray(evidences, (evidence) => evidence.evidenceDescription)
      case evidenceRoles.pageSize.id:
        return this.mapArray(evidences, (evidence) => evidence.pageSize?.toString())
      case evidenceRoles.pageNumber.id:
        return this.mapArray(evidences, (evidence) => evidence.pageStartNumber?.toString())
      case evidenceRoles.evidenceCategoryAction.id:
        const type1 = "实施行为的事实，过程，结果"
        return [(evidences ?? []).filter(it => it.evidenceType === type1)
          .map(it => it.evidenceName)
          .join("、")]



      case punishRoles.caseType.id:
        return this.mapArray(this.mapPunishStr(punishes), it => it.type.typeName)
      case punishRoles.criminalType.id:
        return this.mapArray(this.mapPunishStr(punishes), it => it.punish.lawPunishName)
      case punishRoles.punishLevel.id:
        return this.mapArray(this.mapPunishStr(punishes), it => LawPunishArticle.punishLevels[it.article.punishLevel])
      case punishRoles.punishAction.id:
        return this.mapArray(this.mapPunishStr(punishes), it => {
          console.log(it.article.articleValue);
          return it.article.articleValue
        })
      case punishRoles.punishLaw.id:
        return this.mapArray(punishes, it => {
          const laws = LawPunishArticleLawEntity.laws(this.law, it.lawId)
          return LawLevels.getLawPrefixByArticleLaw({ entity: laws })
        })
      case punishRoles.punishContent.id:
        return this.mapArray(this.mapPunishStr(punishes), it => it.article?.items?.map(item => item.punishValue)?.join())
      case punishRoles.punishLawFull.id:
        return this.mapArray(punishes, it => {
          const laws = LawPunishArticleLawEntity.laws(this.law, it.lawId)
          return LawLevels.getLawStringByArticleLaw({ entity: laws })
        }
        )

      case constantRoles.dateNow.id:
        return [moment().format(this.format)]
      case constantRoles.yearNow.id:
        return [moment().format("YYYY")]

      case documentRoles.documentDate.id:
        return [moment().format(this.format)]
      case documentRoles.documentCode.id:
        if ((!this.department?.unitDescription) && (!caseEntity.caseCode)) {
          return []
        }
        return [(this.department?.unitDescription ?? "")
          + this.documentCode
          + `〔${moment().format("YYYY")}〕`
          + (caseEntity.caseCode ?? "") + "号"]

      case documentRoles.documentRegisterDate.id:
        return this.getStrArrayByTime(caseEntity.caseRegisterTimer)
    }
    return []
  }

  genEvidenceString(roleId: number, evidence: Evidence): string | undefined {
    const fieldTypeRoles = Roles.bind.fieldType
    const evidenceRoles = fieldTypeRoles.evidence

    switch (roleId) {
      case evidenceRoles.evidenceName.id:
        return evidence.evidenceName
      case evidenceRoles.evidenceType.id:
        return evidence.evidenceType
      case evidenceRoles.evidenceDescription.id:
        return evidence.evidenceDescription
      case evidenceRoles.pageSize.id:
        return evidence.pageSize?.toString()
      case evidenceRoles.pageNumber.id:
        return evidence.pageStartNumber?.toString()
    }
  }

  genEvidencesString(roleId: number, evidences: Evidence[]): string | undefined {
    const fieldTypeRoles = Roles.bind.fieldType
    const evidenceRoles = fieldTypeRoles.evidence
    switch (roleId) {
      case evidenceRoles.evidenceCategory.id:
        const type = evidences[0].evidenceType
        const names = evidences.map(it => it.evidenceName)
          .join("、")
        return `${names}等，证明${type}`
      case evidenceRoles.evidenceCategoryAction.id:
        const type1 = "实施行为的事实，过程，结果"
        return evidences.filter(it => it.evidenceType === type1)
          .map(it => it.evidenceName)
          .join("、")
    }
    return undefined
  }

  getPunishTypeName(typeId: number | undefined): string | undefined {
    if (typeId) {
      return this.punishTypes.find(it => it.id === typeId)?.typeName
    } else {
      return undefined
    }
  }

  genPunishString(roleId: number, punish: Punish): string | undefined {
    const fieldTypeRoles = Roles.bind.fieldType
    const punishRoles = fieldTypeRoles.punish

    let articleLaw = this.punishMap.get(punish.punishArticleLawId)?.articleLaw;
    switch (roleId) {
      case punishRoles.caseType.id:
        return this.punishMap.get(punish.punishArticleLawId)?.type?.typeName
      case punishRoles.criminalType.id:
        return this.punishMap.get(punish.punishArticleLawId)?.punish?.lawPunishName
      case punishRoles.punishLevel.id:
        let punishLevel = this.punishMap.get(punish.punishArticleLawId)?.article?.punishLevel;
        if (punishLevel) {
          return LawPunishArticle.punishLevels[punishLevel]
        } else {
          return undefined
        }
      case punishRoles.punishAction.id:
        return this.punishMap.get(punish.punishArticleLawId)?.article?.articleValue
      case punishRoles.punishLaw.id:
        if (articleLaw) {
          const laws = LawPunishArticleLawEntity.laws(this.law, articleLaw?.lawId)
          return LawLevels.getLawPrefixByArticleLaw({ entity: laws })
        } else {
          return undefined
        }
      case punishRoles.punishLawFull.id:
        if (articleLaw) {
          const laws = LawPunishArticleLawEntity.laws(this.law, articleLaw?.lawId)
          return LawLevels.getLawStringByArticleLaw({ entity: laws })
        } else {
          return undefined
        }
    }
  }

  getPunishItem(roleId: number, punishItem: PunishItem): string {
    if (roleId === Roles.bind.fieldType.punish.punishContent.id) {
      return punishItem.itemValue
    }
    return ""
  }

  getTemplateStr(template: Template, caseEntity: CaseEntity): string {
    const map = new Map<string, string>()
    template.items?.forEach(it => {
      map.set(it.itemCode, this.getStr(it.itemRoleId, caseEntity)[0] ?? "")
    })
    let str = template.templateValue
    map.forEach((value, key) => {
      str = str.replace("${" + key + "}", value)
    })
    return str
  }

  getTemplateStrByEvidence(template: Template, evidence: Evidence): string {
    const map = new Map<string, string>()
    template.items?.forEach(it => {
      map.set(it.itemCode, this.getStrByEvidence(it.itemRoleId, evidence)[0] ?? "")
    })
    let str = template.templateValue
    map.forEach((value, key) => {
      str = str.replace("${" + key + "}", value)
    })
    return str
  }

  getTemplateStrByPunish(template: Template, punish: Punish): string {
    const map = new Map<string, string>()
    template.items?.forEach(it => {
      map.set(it.itemCode, this.getStrByPunish(it.itemRoleId, punish)[0] ?? "")
    })
    let str = template.templateValue
    map.forEach((value, key) => {
      str = str.replace("${" + key + "}", value)
    })
    return str
  }

  getTemplateStrByPunishItem(template: Template, punishItem: PunishItem): string {
    const map = new Map<string, string>()
    template.items?.forEach(it => {
      map.set(it.itemCode, this.getStrByPunishItem(it.itemRoleId, punishItem)[0] ?? "")
    })
    let str = template.templateValue
    map.forEach((value, key) => {
      str = str.replace("${" + key + "}", value)
    })
    return str
  }

  getTemplateStrByRole(role: number, caseEntity: CaseEntity): string {
    const template = this.templates.find(it => it.roleId === role)
    if (template) {
      const parentRole = this.templateRoles.find(it => it.children?.includes(template.roleId))
      switch (parentRole?.id) {
        case Roles.bind.template.suspectPerson.id:
          if (!caseEntity.suspectPerson) {
            return ""
          }
          break
        case Roles.bind.template.suspectUnit.id:
          if (!caseEntity.suspectUnit) {
            return ""
          }
          break
        case Roles.bind.template.punishItem.id:
          return this.mapArray(caseEntity.punishes
            ?.flatMap(it => it.items) ?? [], it => it)
            ?.map(it => this.getTemplateStrByPunishItem(template, it))
            ?.join("\n") ?? ""
        case Roles.bind.template.evidence.id:
          return caseEntity.evidenceList?.map(it => this.getTemplateStrByEvidence(template, it))
            ?.join("\n") ?? ""
        case Roles.bind.template.punish.id:
          return caseEntity.punishes?.map(it => this.getTemplateStrByPunish(template, it))
            ?.join("\n") ?? ""

      }
      return this.getTemplateStr(template, caseEntity)
    } else {
      return ""
    }
  }

  getTemplateStrByEvidenceAndRole(role: number, evidence: Evidence): string {
    const template = this.templates.find(it => it.roleId === role)
    if (template) {
      return this.getTemplateStrByEvidence(template, evidence)
    } else {
      return ""
    }
  }

  getTemplateStrByPunishAndRole(role: number, punish: Punish): string {
    const template = this.templates.find(it => it.roleId === role)
    if (template) {
      const parentRole = this.templateRoles.find(it => it.children?.includes(template.roleId))
      switch (parentRole?.id) {
        case Roles.bind.template.punishItem.id:
          return punish.items?.map(it => this.getTemplateStrByPunishItem(template, it))
            ?.join("\n") ?? ""

      }
      return this.getTemplateStrByPunish(template, punish)
    } else {
      return ""
    }
  }

  getTemplateStrByPunishItemAndRole(role: number, punishItem: PunishItem): string {
    const template = this.templates.find(it => it.roleId === role)
    if (template) {
      return this.getTemplateStrByPunishItem(template, punishItem)
    } else {
      return ""
    }
  }

  getSerialNumber(role: number, i?: number): string {
    const index = i ?? 0
    switch (role) {
      case Roles.bind.fieldType.serialNumber.arabicNumber.id:
        return (index + 1).toString()
      case Roles.bind.fieldType.serialNumber.chineseNumber.id:
        return LawLevels.toChinese(index + 1)
    }
    return ""
  }

  getStr(role: number, caseEntity?: CaseEntity, i?: number): string[] {
    if (!caseEntity) {
      return []
    }
    const parent = this.fillRoles
      .find(it => it.children?.some(roleEntity => roleEntity.id === role))
    let fieldType = Roles.bind.fieldType;
    switch (parent?.id) {
      case fieldType.caseBase.id:
      case fieldType.evidence.id:
      case fieldType.suspectPerson.id:
      case fieldType.suspectUnit.id:
      case fieldType.punish.id:
      case fieldType.constant.id:
      case fieldType.document.id:
        return this.genBaseCaseString(role, caseEntity)
      case fieldType.template.id:
        return [this.getTemplateStrByRole(role, caseEntity)]
      case fieldType.serialNumber.id:
        return [this.getSerialNumber(role, i)]
      case fieldType.department.id:
        return this.getStrArray(this.getDepartmentStr(role))
    }
    return []
  }

  getStrByEvidence(role: number, evidence?: Evidence, i?: number): string[] {
    if (!evidence) {
      return []
    }
    const parent = this.fillRoles
      .find(it => it.children?.some(roleEntity => roleEntity.id === role))
    let fieldType = Roles.bind.fieldType;
    switch (parent?.id) {
      case fieldType.evidence.id:
        return [this.genEvidenceString(role, evidence) ?? ""]
      case fieldType.template.id:
        return [this.getTemplateStrByEvidenceAndRole(role, evidence)]
      case fieldType.serialNumber.id:
        return [this.getSerialNumber(role, i)]
    }
    return []
  }

  getStrByEvidences(role: number, evidences?: Evidence[], i?: number): string[] {
    if (!evidences?.length) {
      return []
    }
    const parent = this.fillRoles
      .find(it => it.children?.some(roleEntity => roleEntity.id === role))
    let fieldType = Roles.bind.fieldType;
    switch (parent?.id) {
      case fieldType.evidence.id:
        return [this.genEvidencesString(role, evidences ?? []) ?? ""]
      case fieldType.serialNumber.id:
        return [this.getSerialNumber(role, i)]
    }
    return []
  }

  getStrByPunish(role: number, punish?: Punish, i?: number): string[] {
    if (!punish) {
      return []
    }
    const parent = this.fillRoles
      .find(it => it.children?.some(roleEntity => roleEntity.id === role))
    let fieldType = Roles.bind.fieldType;
    switch (parent?.id) {
      case fieldType.punish.id:
        return [this.genPunishString(role, punish) ?? ""]
      case fieldType.template.id:
        return [this.getTemplateStrByPunishAndRole(role, punish)]
      case fieldType.serialNumber.id:
        return [this.getSerialNumber(role, i)]
    }
    return []
  }

  getStrByPunishItem(role: number, punishItem?: PunishItem, i?: number): string[] {
    if (!punishItem) {
      return []
    }
    const parent = this.fillRoles
      .find(it => it.children?.some(roleEntity => roleEntity.id === role))
    let fieldType = Roles.bind.fieldType;
    switch (parent?.id) {
      case fieldType.punish.id:
        return [this.getPunishItem(role, punishItem) ?? ""]
      case fieldType.template.id:
        return [this.getTemplateStrByPunishItemAndRole(role, punishItem)]
      case fieldType.serialNumber.id:
        return [this.getSerialNumber(role, i)]
    }
    return []
  }

  private getStrArray(str?: string): string[] {
    const arr: string[] = []
    if (str) {
      arr.push(str)
    }
    return arr
  }

  private getUserArray(uid?: number): UserEntity[] {
    const user = this.users.find(it => it.id === uid)
    if (user) {
      return [user]
    } else {
      return []
    }
  }

  private getStrArrayByTime(time?: moment.Moment): string[] {
    const arr: string[] = []
    if (time) {
      arr.push(moment(time).format(this.format))
    }
    return arr
  }

  private mapArray<T, R>(ts: T[] | undefined, map: (t: T) => R | undefined): R[] {
    const arr: R[] = []
    for (const t of (ts ?? [])) {
      const str = map(t)
      if (str) {
        arr.push(str)
      }
    }
    return arr
  }

  private mapPunishStr(punishes?: Punish[]): {
    type: LawPunishType,
    punish: LawPunish,
    article: LawPunishArticle,
    articleLaw: LawPunishArticleLaw,
  }[] {
    const articleLaws = punishes?.map(it => it.punishArticleLawId)
      .filter((it, i, arr) => i === arr.indexOf(it))
    return this.mapArray(articleLaws, it => this.punishMap.get(it))
  }
}