import { Moment } from "moment";
import CaseFieldType from "./CaseFieldType";
import Evidence from "./Evidence";
import SuspectPerson from "./SuspectPerson";
import SuspectUnit from "./SuspectUnit";
import { Punish } from "./Punish";

class CaseEntity {
  id: number;
  caseCode: string | undefined = undefined;
  caseName: string | undefined = undefined;
  caseCause: string | undefined = undefined;
  caseType: number | undefined = undefined;
  caseTime: Moment | undefined = undefined;
  caseRegisterTimer: Moment | undefined = undefined;
  handleUid: number | undefined = undefined;
  handleSubUid: number | undefined = undefined;
  suspectLawId: number | undefined = undefined;
  factLawId: Array<number> | undefined = undefined;
  caseFact: string | undefined = undefined;
  mainCase: string | undefined = undefined;
  hostUnitId: number | undefined = undefined;
  acceptingUnitId: number | undefined = undefined;
  caseSource: string | undefined = undefined;
  caseCity: string | undefined = undefined;
  caseDistrict: string | undefined = undefined;
  caseTownVillage: string | undefined = undefined;
  caseStreetVillage: string | undefined = undefined;
  caseCommunityGroup: string | undefined = undefined;
  casePlace: string | undefined = undefined;
  evidenceList?: Array<Evidence>;
  suspectPerson?: SuspectPerson | undefined = undefined;
  suspectUnit?: SuspectUnit | undefined = undefined;
  caseFieldTypes?: Array<CaseFieldType> = []
  punishes?: Array<Punish>

  constructor(
    id: number,
    caseCode: string | undefined,
    caseName: string | undefined,
    caseCause: string | undefined = undefined,
    caseType: number | undefined = undefined,
    caseTime: Moment | undefined,
    caseRegisterTimer: Moment | undefined,
    handleUid: number | undefined,
    handleSubUid: number | undefined,
    suspectLawId: number | undefined,
    factLawId: Array<number> | undefined,
    caseFact: string | undefined,
    mainCase: string | undefined = undefined,
    hostUnitId: number | undefined = undefined,
    acceptingUnitId: number | undefined = undefined,
    caseSource: string | undefined = undefined,
    caseCity: string | undefined = undefined,
    caseDistrict: string | undefined = undefined,
    caseTownVillage: string | undefined = undefined,
    caseStreetVillage: string | undefined = undefined,
    caseCommunityGroup: string | undefined = undefined,
    casePlace: string | undefined = undefined,
    suspectPerson?: SuspectPerson | undefined,
    suspectUnit?: SuspectUnit | undefined,
    caseFieldTypes?: Array<CaseFieldType> | undefined,
    evidenceList?: Array<Evidence> | undefined,
    punishes?: Array<Punish>
  ) {
    this.id = id
    this.caseCode = caseCode
    this.caseCause = caseCause
    this.caseName = caseName
    this.caseTime = caseTime
    this.caseRegisterTimer = caseRegisterTimer
    this.handleSubUid = handleSubUid
    this.handleUid = handleUid
    this.suspectLawId = suspectLawId
    this.factLawId = factLawId
    this.caseFact = caseFact
    this.mainCase = mainCase
    this.hostUnitId = hostUnitId
    this.acceptingUnitId = acceptingUnitId
    this.caseSource = caseSource
    this.caseCity = caseCity
    this.caseDistrict = caseDistrict
    this.caseTownVillage = caseTownVillage
    this.caseStreetVillage = caseStreetVillage
    this.caseCommunityGroup = caseCommunityGroup
    this.casePlace = casePlace
    this.evidenceList = evidenceList
    this.suspectPerson = suspectPerson
    this.suspectUnit = suspectUnit
    this.caseFieldTypes = caseFieldTypes
    this.punishes = punishes
    this.caseType = caseType
  }
}

export default CaseEntity