import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/fileCover.module.scss'
const FileCover: React.FC<DocumentProps> = (props) => {

  return (
    <div className={Module.all}>
      <Form
        form={props.form}
        className={Module.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>行政处罚卷宗封面</h1>
              <div className={Module.center}>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <Form.Item
                      name={[0, "fieldValue"]}
                      className={Module.item}
                    >
                      <Input
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="单位名称" />
                    </Form.Item>
                  </div>
                  <div className={Module.row2}>
                    <p>行 政 处 罚 案 卷</p>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.a1}><p>案卷编号</p></div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[17, "fieldValue"]}
                      >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案号前缀" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.a1}>
                      <p>案件类别</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[1, "fieldValue"]}
                      >
                        <Input
                          placeholder="案件类别" disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.a1}>
                      <p>案由</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[2, "fieldValue"]}
                      >
                        <Input placeholder="案由"
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.a1}>
                      <p>当事人</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[3, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="当事人" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.a1}>
                      <Form.Item
                        label="立案时间"
                        className={Module.item}
                        name={[4, "fieldValue"]}
                      >
                        <StringDatePicker
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)} format="YYYY年MM月DD日" placeholder="立案时间" />
                      </Form.Item>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        label="结案时间"
                        className={Module.item}
                        name={[5, "fieldValue"]}
                      >
                        <StringDatePicker
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)} format="YYYY年MM月DD日" placeholder="结案时间" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row8}>
                    <div className={Module.a1}>
                      <Form.Item
                        label="办案单位"
                        className={Module.item}
                        style={{ width: "82%", marginBottom: 0 }}
                        name={[6, "fieldValue"]}>
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        >
                          {(props.units ?? []).map(unit => <Select.Option key={unit.id.toString()}>{unit.unitName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        label="主办人员"
                        name={[7, "fieldValue"]}
                        className={Module.item2}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="主办人员" />
                      </Form.Item>
                      <Form.Item
                        label="协办人员"
                        name={[8, "fieldValue"]}
                        className={Module.item2}
                      >
                        <Input
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="协办人员" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row9}>
                    <div className={Module.a1}>
                      <p>执法内容</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[9, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="执法内容" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row10}>
                    <div className={Module.a1}>
                      <p>案情简介及处理结果、执行情况</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[10, "fieldValue"]}
                      >
                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 30 }} disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="案情简介及处理结果、执行情况" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row11}>
                    <div className={Module.a1}>
                      <p>听证、行政复议或行政诉讼情况</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        className={Module.item}
                        name={[11, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="听证、行政复议或行政诉讼情况" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row12}>
                    <div className={Module.a1}>
                      <Form.Item
                        label="归档时间"
                        className={Module.item}
                        name={[12, "fieldValue"]}
                      >
                        <StringDatePicker
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)} format="YYYY年MM月DD日" placeholder="归档时间" />
                      </Form.Item>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        label="保存期限"
                        className={Module.item}
                        name={[13, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="保存期限" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row13}>
                    <div className={Module.a1}>
                      <p>本卷共：</p>
                      <Form.Item
                        className={Module.item}
                        name={[14, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="件数" />
                      </Form.Item>
                      <Form.Item
                        className={Module.item}
                        name={[15, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="页数" />
                      </Form.Item>
                      <p>页</p>
                    </div>
                    <div className={Module.a2}>
                      <Form.Item
                        label="归档号"
                        className={Module.item}
                        name={[16, "fieldValue"]}
                      >
                        <Input disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)} placeholder="归档号" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default FileCover