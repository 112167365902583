import React, { useEffect, useState } from "react";
import AllList from "../lawlist/allList";
import Module from '../style/show.module.scss'
import ReactEcharts from 'echarts-for-react';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { ApiCase } from "../internet/ApiCase";

const Show: React.FC<{ setCaseId: (caseId: number | undefined) => void }> = (props) => {
    const [finishiedCase, setfinishiedCase] = useState<number>()
    const [unfinishiedCase, setUnfinishiedCase] = useState<number>()
    const location = useLocation()
    const setCaseId1 = props.setCaseId
    const navigate = useNavigate()
    const apiCase = new ApiCase(message, navigate)
    const FinishiedCase = () => {
        apiCase.getSimpleAccomplish({
            successHandler: finishied => {
                if (finishied) {
                    setfinishiedCase(finishied.length)
                }
            }
        })


    }
    const unFinishiedCase = () => {
        apiCase.getSimple({
            successHandler: unfinishied => {
                if (unfinishied) {
                    setUnfinishiedCase(unfinishied.length)
                }
            }
        })
    }
    useEffect(() => {
        FinishiedCase()
        unFinishiedCase()
    }, [location])
    const toption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#889'
                }
            }
        },
        legend: {
            data: ["已生成", '未生成']
        },
        xAxis: [
            {
                type: 'category',
                data: ['今年', '本月'],
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '案件数量',
                min: 0,
                max: 20,
                interval: 4,
                axisLabel: {
                    formatter: '{value} 个'
                }
            },
        ],
        series: [

            {
                name: '未生成',
                type: 'bar',
                data: [unfinishiedCase, unfinishiedCase],
            },
            {
                name: '已生成',
                type: 'bar',
                data: [finishiedCase, finishiedCase],
            }
        ]
    }
    function allChart() {
        const w = window.open('_black') //这里是打开新窗口
        let url = "http://api.pzhsykj.com:9811/#/chart/share/311"
        if (w) {
            w.location.href = url //这样就可以跳转了
        }
    }
    return (
        <div className={Module.all}>
            <div className={Module.table}>
                <h2>案件列表</h2>
                <AllList setCaseId={setCaseId1} />
            </div>
            <div className={Module.button}>
                <Button onClick={allChart} className={Module.allCahrt}>查看更多</Button>
            </div>
            <div className={Module.pic} style={{ zoom: 1.1 }}>
                <ReactEcharts
                    className={Module.pict}
                    option={toption}
                />
            </div>
        </div>
    )
}
export default Show