import { Button, Form, Input, message, Select } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Role from "../entity/Role"
import { Template } from "../entity/Template"
import { TemplateParent } from "../entity/Templateparent"
import { ApiAdminTemplate } from "../internet/ApiAdminTemplate"
import ApiRoles from "../internet/ApiRoles"
import Module from '../style/addtemplatemodule.module.scss'



const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
};
const { Option } = Select;
const AddTemplateModule: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm<TemplateParent>()
  const [roles, setRoles] = useState<Role[]>([])
  const apiRoles = new ApiRoles(message, navigate)
  const apiAdminTemplate = new ApiAdminTemplate(message, navigate)
  const location = useLocation()
  const getRoles = () => {
    apiRoles.getTemplate({
      successHandler: roles => {
        if (roles) {
          setRoles(roles)
        }
      }
    })
  }
  const add = () => {
    form.validateFields()
      .then(templateparent => {
        const template1 = new Template(0, templateparent.templateName,
          "", 0, undefined)
        apiAdminTemplate.add(template1, templateparent.roleParent)
      })
  }

  useEffect(() => {
    getRoles()
  }, [location])
  const rolesoptions = roles.map(role => {
    return <Option key={role.id} value={role.id}>{role.roleDescription}</Option>
  })
  return <div className={Module.all}>
    <Form
      form={form}
      {...layout}
      className={Module.form}
    >
      <h1>添加字符串模板</h1>
      <Form.Item
        className={Module.item}
        name={"templateName"}
        label="字符串模板名称"
        rules={[
          {
            required: true,
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={Module.item}
        name="roleParent"
        label="字符串模板权限"
        rules={[
          {
            required: true,
          }
        ]}
      >
        <Select >
          {rolesoptions}
        </Select>
      </Form.Item>
      <Button type="primary" onClick={add} className={Module.btn}>添加</Button>
    </Form>
  </div>

}
export default AddTemplateModule