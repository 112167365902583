import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Module from '../style/case.module.scss'
import { BsFillPencilFill } from "react-icons/bs";
import CaseSimpleEntity from "../entity/CaseSimpleEntity";
import { ApiCase } from "../internet/ApiCase";

const columns: ColumnsType<CaseSimpleEntity> = [
  {
    title: "案件编号",
    dataIndex: 'caseCode',
  },
  {
    title: '案由',
    dataIndex: 'caseCause',
  },
  {
    title: '立案时间',
    dataIndex: 'caseRegisterTimer',
    render: (value: any, record: CaseSimpleEntity) => {
      if (record.caseRegisterTimer) {
        const d = record.caseRegisterTimer
        return moment(d, 'YYYY-MM-DD ').format('YYYY-MM-DD ')
      }
      return undefined
    }
  },
  {
    title: '主办人员',
    dataIndex: 'handleName',
  },
  {
    title: '协办人员',
    dataIndex: 'handleSubName',
  }

];
const Case: React.FC<{ setCaseId: (caseId: number | undefined) => void }> = (props) => {
  const [data, setData] = useState<CaseSimpleEntity[]>([])
  const location = useLocation()//监听地址是否有变化
  const navigate = useNavigate()
  const apiCase = new ApiCase(message, navigate)
  const rowClick = (caseId: CaseSimpleEntity) => {
    return {
      onClick: () => {
        navigate("/law", { state: { caseId: caseId.id, number: true } })
        props.setCaseId(caseId.id)

      }
    }
  }

  useEffect(() => {
    apiCase.getSimpleUnfinished({
      successHandler: data => {
        if (data) {
          setData(data)
        }
      }
    })
  }, [location])
  const addCase = () => {
    navigate("/law")
  }
  return (
    <div className={Module.all}>
      <Button onClick={addCase} className={Module.addCase}>添加案件<BsFillPencilFill
        style={{ marginLeft: 6 }} /></Button>
      <Table
        className={Module.table}
        columns={columns}
        rowKey="id"
        onRow={rowClick}
        dataSource={data}
        pagination={false}
        bordered
      />
    </div>
  )
}
export default Case