import { Button, Form, Input, Select } from "antd"
import Module from '../style/expertiseReport.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";
import { MinusOutlined } from "@ant-design/icons";


export default function ExpertiseReport(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>鉴定委托书</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[0, "fieldValue"]}
                  >
                    <Input
                      style={{ width: 250 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <Form.Item
                  className={Module.item1}
                  name={[1, "fieldValue"]}
                >
                  <Input
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="鉴定单位" />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[10, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="鉴定理由"
                  />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[11, "fieldValue"]}
                >
                  <Input.TextArea
                    className={Module.textarea}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="问题"
                  />
                </Form.Item>
                <p className={Module.a1}>请于以下时间前将鉴定情况及结论形成书面材料送交我单位</p>
                <Form.Item
                  className={Module.item}
                  name={[2, "fieldValue"]}
                >
                  <StringDatePicker
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    format={"YYYY年MM月DD日"} placeholder="截止时间"
                  />
                </Form.Item>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>单位名称：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[3, "fieldValue"]}>
                      <Select placeholder="单位名称"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.units ?? []).map(unit => <Select.Option key={unit.unitName}>{unit.unitName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[4, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.time2}>
                  <p className={Module.a1}>本委托书已收到</p>
                  <Form.Item
                    name={[5, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])} />
                  </Form.Item>
                  <p>鉴定</p>
                  <Form.Item
                    name={[6, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 6, "fieldValue"])} />
                  </Form.Item>
                  <p>检测</p>
                  <Form.Item
                    name={[7, "fieldValue"]}
                    className={Module.item}
                    initialValue="⬜"
                  >
                    <Input readOnly onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])} />
                  </Form.Item>
                  <p className={Module.a2}>评估</p>
                  <p className={Module.a1}>事项及要求已确认</p>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[4, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[8, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {/* <div className={Module.time}>
                  <p style={{ fontWeight: "bolder" }}>被聘鉴定人签名：</p>
                  <Form.Item
                    style={{ width: "30%", marginLeft: "2%" }}
                    name={[9, "fieldValue"]} className={Module.sig} >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="签名"
                    >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div> */}
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

