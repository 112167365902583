import { Button, Form, Input, message } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { ApiAdminDictionary } from "../internet/ApiAdminDictionary";
import Dictionary from "../entity/Dictionary";
import DictionaryType from "../entity/DictionaryType";
import Module from "../style/dataSource.module.scss"


const Datasoure: React.FC<{
    type: DictionaryType,
    setType: Dispatch<SetStateAction<DictionaryType | null>>,
    update: () => void
}> = ({ type, setType, update }) => {
    const navigate = useNavigate()
    const apiAdminDictionary = new ApiAdminDictionary(message, navigate)
    function updateDictionary(dictionary: Dictionary) {
        apiAdminDictionary.updateDictionary(dictionary)
    }
    function deleteDictionary(id: number | null) {
        if (id != null) {
            apiAdminDictionary.deleteDictionary(id)
        }
    }
    function changeDictionaryValue(
        event: React.ChangeEvent<HTMLInputElement>, dictionary: Dictionary
    ) {
        const v = event.target.value
        if (v !== null && v !== undefined && v !== "") {
            dictionary.dictionaryValue = v
            setType(new DictionaryType(
                type.id,
                type.baseTypeId,
                type.typeName,
                type.typeDescription,
                type.regex,
                type.dictionaries
            ))
        }
    }
    const columns: ColumnsType<Dictionary> = [
        {
            title: "字典值",
            dataIndex: "dictionaryValue",
            render: (text, record) => {
                if (record.dictionaryValue !== null) {
                    return <div className={Module.for}>
                        <Form >
                            <Form.Item style={{ margin: 0 }} rules={[{
                                required: true,
                                message: "请输入字典值"
                            }]}>
                                <Input value={record.dictionaryValue} onChange={(event) => {
                                    changeDictionaryValue(event, record)
                                }} />
                            </Form.Item>
                        </Form>
                    </div>
                }
            },

        },
        {
            title: '操作',
            dataIndex: 'delete',
            render: (_, dictionary) => (
                <span>
                    <Button className={Module.btn} onClick={() => {
                        updateDictionary(dictionary)
                    }} type="link" >编辑</Button>
                    <Button className={Module.btn} type="link" onClick={() => {
                        deleteDictionary(dictionary.id)
                    }} >删除</Button>
                </span>
            ),
            width: 160,
            align: "center"

        },

    ]

    return (
        <Table
            rowKey={"id"}
            bordered
            className={Module.table}
            size="small"
            columns={columns}
            dataSource={type.dictionaries}
        />
    )
}
export default Datasoure
