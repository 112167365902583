import React, { useEffect, useState } from "react";
import Module from '../style/allchart.module.scss'
import ReactEcharts from 'echarts-for-react';
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { ApiCase } from "../internet/ApiCase";
import CaseSimpleEntity from "../entity/CaseSimpleEntity";

const AllChart: React.FC = () => {
  const [finishiedCase, setfinishiedCase] = useState<number>()
  const [caseNumber, setCaseNumber] = useState<string[]>([])
  const [unfinishiedCase, setUnfinishiedCase] = useState<number>()
  const location = useLocation()
  const navigate = useNavigate()
  const apiCase = new ApiCase(message, navigate)
  const FinishiedCase = () => {
    apiCase.getSimpleAccomplish({
      successHandler: finishied => {
        if (finishied) {
          setfinishiedCase(finishied.length)
        }
      }
    })
  }
  const getType = () => {
    apiCase.getCaseType({
      successHandler: data => {
        if (data) {
          setCaseNumber(data)
        }
      }
    })
  }
  const unFinishiedCase = () => {
    apiCase.getSimple({
      successHandler: unfinishied => {
        if (unfinishied) {
          setUnfinishiedCase(unfinishied.length)
        }
      }
    })
  }
  useEffect(() => {
    FinishiedCase()
    unFinishiedCase()
    getType()
  }, [location])
  const toption = {
    title: {
      text: '案件数量统计图',
      left: 'center',
      textStyle: {
        color: '#64b5f6'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#889'
        }
      }
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        color: '#64b5f6'
      }
    },
    xAxis: [
      {
        type: 'category',
        data: ['今年', '本月'],
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '案件数量',
        min: 0,
        max: 20,
        interval: 4,
        axisLabel: {
          formatter: '{value} 个'
        }
      },
    ],
    grid: {
      left: '10%',
      right: '10%',
      top: '20%',
      bottom: '20%',
    },
    series: [
      {
        name: '未生成',
        type: 'bar',
        data: [unfinishiedCase, unfinishiedCase],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0.5,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#00FFFF' // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#0066FF' // 100% 处的颜色
              }
            ]
          }
        }
      },
      {
        name: '已生成',
        type: 'bar',
        data: [finishiedCase, finishiedCase],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#00FFFF' // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#10d269' // 100% 处的颜色
              }
            ]
          }
        }
      }
    ]
  }
  interface CountByCaseType {
    [key: string]: number;
  }

  const countByCaseType: CountByCaseType = caseNumber.reduce((result: CountByCaseType, caseType) => {
    if (caseType !== null) {
      if (!result[caseType]) {
        result[caseType] = 0;
      }
      result[caseType]++;
    }
    return result;
  }, {});


  // 将数据转换成饼状图需要的格式
  const pieData = Object.keys(countByCaseType).map((caseType) => ({
    value: countByCaseType[caseType],
    name: caseType,
  }));

  const caseTypeChart = {
    title: {
      text: '案件类型统计图',
      left: 'center',
      textStyle: {
        color: '#64b5f6'
      }
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        color: '#64b5f6'
      },

    },
    series: [
      {
        name: '案件类型数量',
        type: 'pie',
        radius: ['25%', "50%"],
        data: pieData,
        label: {
          show: true,
          textStyle: {
            color: "auto" // 设置文字颜色为自动（跟随模块颜色变化）
          },
          formatter: (params: { name: any; percent: any; }) => {
            return `{a|${params.name}}\n{b|${params.percent}%}`;
          },
          rich: {
            a: {
              color: 'auto'
            },
            b: {
              color: 'auto'
            }
          }
        },
        labelLine: {
          show: true,
          length: 10,
          length2: 25
        },
      }
    ],
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)'
      }
    },
    color: ['#54b654', '#29B6F6', '#ffe665', '#1E88E5', '#33a1dc', '#55b553', '#80D8FF', '#00ACC1', '#00838F', '#B2EBF2']
  };

  return (
    <div className={Module.all}>
      <div className={Module.pic1} style={{ zoom: 1.1 }}>
        <ReactEcharts
          className={Module.pict}
          option={toption}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={Module.pic} style={{ zoom: 1.1 }}>
        <ReactEcharts
          className={Module.pict}
          option={caseTypeChart}
          style={{ width: "100%", height: "100%" }}
        />
      </div>

    </div>
  )
}
export default AllChart