import React, { createContext, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './page/login';
import 'antd/dist/antd.min.css'
import Home from './page/home';
import Nowrit from './home/nowrit';
import Dossier from './home/dossier ';
import Law1 from './home/law1';
import Reason from './home/reason';
import LawCase from './home/lawcase ';
import Cover from './home/cover';
import Jzong from './home/jzong';
import Writ from './lawlist/writ';
import Wenshu from './lawlist/wenshu';
import Work from './lawlist/work';
import Criminal from './lawlist/criminal';
import Pdf from './lawlist/pdf';
import Zjlist from './lawlist/zjlist';
import Live from './lawlist/live';
import See from './lawlist/see';
import Dsp from './lawlist/dsp';
import Dws from './lawlist/dws';
import Wslist from './lawlist/wslist';
import Wsall from './lawlist/wsall';
import Wsxg from './lawlist/wsxg';
import Jzlist from './lawlist/jzlist';
import Jzmain from './lawlist/jzmain';
import Unit from './message/unit';
import Unitlist from './message/unitList';
import Show from './page/show';
import LawItem from './message/lawitem';
import Case from './home/case';
import Direact from './message/direact';
import Users from './home/user';
import { User } from './entity/User';
import UserList from './lawlist/userlist';
import Address from './message/adress';
import Repass from './home/repass';
import Chomod from './home/chomod';
import WordList from './message/wordlist';
import WordMain from './message/wordmain';
import WordSee from './message/wordsee';
import UserInfo from './home/userinfo';
import Singanar from './home/singna';
import AddLaw from './message/addlaw';
import AddWord from './message/addword';
import AddAddress from './message/addaddress';
import AddDir from './message/adddir';
import AdddirType from './message/addtype';
import Addpermissions from './message/addpermissions';
import Logout from "./page/logout";
import ModifySeal from './message/modifyseal';
import ReasswordUsers from './home/repasswordusers';
import UserPermissions from './home/userpermissions';
import DistributionPeople from './home/distributionPeople';
import WordRoles from './message/wordRoles';
import WordRolesName from './message/wordrolesname';
import WordType from './message/wordtype';
import WordTypeMain from './message/wordtypemain';
import WritList from './home/writList';
import PunishContext from './lawlist/punishcontext';
import SealList from './message/seallist';
import TemplateModule from './home/templatemodule';
import AddTemplateModule from './home/addTemplateModule';
import AddTemplateItem from './home/addtemplateitem';
import Accessories from './message/accessories';
import AccessriesList from './home/accessorieslist';
import AddAccessories from './home/addAccessories';
import UploadDocument from './home/uploadDocument';
import SummaryTable from './gadget/summaryTable';
import ToolsPic from './gadget/toolspic';
import NewSummaryData from './gadget/newSummaryData';
import { Summary } from './entity/Summary';
import AllSummaryData from './gadget/allSummaryData';
import AllChart from './page/allChart';
import { TabWrit } from './lawlist/tabwrit';
import { message } from 'antd';
import Instructions from './page/instructions';
import CaseTable from './gadget/caseTable';
import UserEntity from './entity/UserEntity';
export interface MyContextProps {
  data1: Summary[];
  setData1: React.Dispatch<React.SetStateAction<Summary[]>>;
}
export const MyContext = createContext<MyContextProps>(
  {
    data1: [],
    setData1: () => { }
  }
)
interface Type {
  documentId?: number
  caseId?: number
  masterId?: number
  status?: string
  documentName?: string
}
const App: React.FC = () => {
  let [user, setUser] = useState<UserEntity | null>(null)
  const [caseId, setCaseId] = useState<number | undefined>()
  const [badge, setBadge] = useState<number | undefined>()
  const [storeData, setStoreData] = useState<Summary[]>([])
  const [types, setTypes] = useState<Type[]>([])
  function RequireUser({ children }: { children: JSX.Element }) {
    let location = useLocation();
    if (!user) {
      return <Navigate to={'/login'} state={{ from: location }} replace />
    }
    return children
  }

  return (
    <div>
      <Routes>
        <Route path='/login' element={<Login setUser={setUser} />} />
        <Route path="/" element={
          <RequireUser>
            <Home User={user} caseId={caseId} badge={badge} />
          </RequireUser>
        }>
          <Route index element={<Show setCaseId={setCaseId} />} />
          <Route path='logout' element={<Logout setUser={setUser} />} />
          <Route path='show' element={<Show setCaseId={setCaseId} />} />
          <Route path='allchart' element={<AllChart />} />

          <Route path='law' element={<Law1 setTypes={setTypes} types={types} />} />
          <Route path='case' element={<Case setCaseId={setCaseId} />} />
          <Route path='nowrit' element={<Nowrit setCaseId={setCaseId} setBadge={setBadge} />} />
          <Route path='distributionpeople' element={<DistributionPeople />} />

          <Route path='dossier' element={<Dossier />} />
          <Route path='jzlist' element={<Jzlist setCaseId={setCaseId} />} />
          <Route path='jzong' element={<Jzong />} />
          <Route path='jzmain' element={<Jzmain />} />

          <Route path='reason' element={<Reason />} />
          <Route path='lawcase' element={<LawCase />} />
          <Route path='cover' element={<Cover />} />

          <Route path='writ' element={<Writ />} />
          <Route path='writlist' element={<WritList />} />
          <Route path='wenshu' element={<Wenshu setTypes={setTypes} types={types} />} />
          <Route path='criminal' element={<Criminal />} />
          <Route path='work' element={<Work />} />
          <Route path='pdf' element={<Pdf />} />
          <Route path='zjlist' element={<Zjlist />} />
          <Route path='live' element={<Live />} />
          <Route path='see' element={<See />} />
          <Route path='dsp' element={<Dsp />} />
          <Route path='dws' element={<Dws />} />
          <Route path='tabwrit' element={<TabWrit setTypes={setTypes} types={types} />} />

          <Route path='direact' element={<Direact />} />
          <Route path='wslist' element={<Wslist setCaseId={setCaseId} />} />
          <Route path='wsall' element={<Wsall />} />
          <Route path='wsxg' element={<Wsxg />} />
          <Route path='userlist' element={<UserList />} />
          <Route path='address' element={<Address />} />
          <Route path='wordlist' element={<WordList />} />
          <Route path='wordmain' element={<WordMain />} />
          <Route path='wordsee' element={<WordSee />} />
          <Route path='addword' element={<AddWord />} />
          <Route path='wordroles' element={<WordRoles />} />
          <Route path='wordrolesname' element={<WordRolesName />} />
          <Route path='wordtypemain' element={<WordTypeMain />} />
          <Route path='wordtype' element={<WordType />} />
          <Route path='adddirtype' element={<AdddirType />} />
          <Route path='addpermissions' element={<Addpermissions />} />
          <Route path="direact" element={<Direact />} />
          <Route path="punishcontext" element={<PunishContext />} />
          <Route path="addtemplatemodule" element={<AddTemplateModule />} />
          <Route path="addtemplateitem" element={<AddTemplateItem />} />

          <Route path='users' element={<Users />} />
          <Route path='userinfo' element={<UserInfo />} />
          <Route path='repass' element={<Repass />} />
          <Route path='chomod' element={<Chomod />} />
          <Route path='singnar' element={<Singanar />} />
          <Route path='modifyseal' element={<ModifySeal />} />
          <Route path='repasswordusers' element={<ReasswordUsers />} />
          <Route path='Userpermissions' element={<UserPermissions />} />

          <Route path='uploaddocument' element={<UploadDocument />} />
          <Route path='addaccessories' element={<AddAccessories />} />
          <Route path='accessrieslist' element={<AccessriesList />} />
          <Route path='accessories' element={<Accessories />} />
          <Route path='unit' element={<Unit />} />
          <Route path='template/module' element={<TemplateModule />} />
          <Route path='unitlist' element={<Unitlist />} />
          <Route path='seallist' element={<SealList />} />
          <Route path='lawitem' element={<LawItem />} />
          <Route path='addlaw' element={<AddLaw />} />
          <Route path='adddir' element={<AddDir />} />
          <Route path='addaddress' element={<AddAddress />} />
          <Route path='toolspic' element={<ToolsPic />} />
          <Route path='allsummarydata' element={<AllSummaryData />} />
          <Route path='instructions' element={<Instructions />} />

          <Route path='casetable' element={<CaseTable />} />
          <Route path='summarytable' element={< AllSummaryData />} >
            <Route index element={<SummaryTable setStoreData={setStoreData} storeData={storeData} />} />
            <Route path='newsummarydata' element={<NewSummaryData />} />
          </Route>
        </Route>

      </Routes>
    </div>
  );
}

export default App;
