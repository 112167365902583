import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select, SelectProps, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Role from '../entity/Role';
import Module from '../style/userpermissions.module.scss'
import ApiRoles from "../internet/ApiRoles";
import { TableProps } from "antd/lib/table/Table";
import { ArrayTool } from "../tool/ArrayTool";


const { Option } = Select;


const UserPermissions: React.FC = () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const role: Role | undefined = (location.state as { role?: Role })?.role
  const [typeRoles, setTypeRoles] = useState<Role[]>([]);
  const [allRoles, setAllRoles] = useState<Role[]>([])
  const [typeRole, setTypeRole] = useState<number | undefined>()
  const [roles, setRoles] = useState<Role[]>([])
  const [currentRoles, setCurrentRoles] = useState<Role[]>([])
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
  const [load, setLoad] = useState<{ s: boolean, r: boolean }>({ s: false, r: false })
  const apiRoles = new ApiRoles(message, navigate)
  function setRoleList() {
    setRoles(ArrayTool.subtractById(allRoles, currentRoles, role))
  }

  useEffect(setRoleList, [load])


  function selectRole(role1: Role) {
    const sr = [...currentRoles]
    sr.push(role1)
    setCurrentRoles(sr)
    setRoles(ArrayTool.subtractById(allRoles, sr, role))
  }

  function unselectRole(role1: Role) {
    const sr = [...currentRoles]
    const index = sr.indexOf(role1)
    sr.splice(index, 1)
    setCurrentRoles(sr)
    setRoles(ArrayTool.subtractById(allRoles, sr, role))

  }

  const selectedColumns: ColumnsType<Role> = [
    {
      title: '已有权限',
      dataIndex: 'id',
      render: (text, record) => {
        return (<span>{record.roleDescription}<MinusSquareOutlined onClick={() => {
          unselectRole(record)
        }} style={{ float: "right" }} /></span>)
      }
    },
  ];
  const columns: ColumnsType<Role> = [
    {
      title: '可修改权限',
      dataIndex: 'id',
      render: (text, record) => {
        return (<span>{record.roleDescription}<PlusSquareOutlined onClick={() => {
          selectRole(record)
        }} style={{ float: "right" }} /></span>)
      }
    },
  ];

  const initForm = () => {
    if (role) {
      form.setFieldsValue(role)
    }
  }

  const options = typeRoles?.map(typeRole => <Option
    key={typeRole.id}>{typeRole.roleDescription}
  </Option>);

  const getTypeRoles = () => {
    apiRoles.getTypeRoles(undefined, {
      successHandler: typeRoles => {
        if (typeRoles) {
          const roles = Role.createList(typeRoles)
          setTypeRoles(roles)
          if (role) {
            const parentId = roles.find(role1 => (role.parents ?? []).includes(role1.id))
            if (parentId) {
              setTypeRole(parentId.id)
              const values = form.getFieldsValue()
              values.roleType = parentId.id.toString()
              form.setFieldsValue(values)
            }
          }
        }
      }
    })
  }

  function getSelected(roleId: number) {
    apiRoles.getSelectedRoles(roleId, {
      successHandler: selectedRoles => {
        if (selectedRoles) {
          const roles = Role.createList(selectedRoles)
          setSelectedRoles(roles)
          setCurrentRoles(roles)
          setRoles(ArrayTool.subtractById(allRoles, roles, role))
          setLoad({ s: true, r: load.s })
        }
      }
    })
  }

  const getDistributableRoles = () => {
    apiRoles.getDistributableRoles({
      successHandler: distributableRoles => {
        if (distributableRoles) {
          const roles = Role.createList(distributableRoles)
          setAllRoles(roles)
          setRoles(ArrayTool.subtractById(roles, currentRoles, role))
          setLoad({ s: load.s, r: true })
        }
      }
    })

  }

  function onSelected(s: string) {
    setTypeRole(parseInt(s))
    setCurrentRoles(selectedRoles)
    setRoles(ArrayTool.subtractById(allRoles, currentRoles, role))
  }

  function addRole() {
    if (!typeRole) {
      message.error("请选择权限类型")
    } else {
      form.validateFields()
        .then(values => {
          let roleList = currentRoles.map(role => role.id);
          roleList.push(typeRole)
          apiRoles.addRole(values.roleDescription, roleList, {
            otherHandler: (code) => {
              if (code === "role_description_exist") {
                message.warn("你的案件已生成文书无法删除")
                return true
              } else {
                return false
              }
            }
          })
        })
    }
  }

  function updateRole() {
    if (role) {
      form.validateFields()
        .then(values => {
          const addList = ArrayTool.subtractById(currentRoles, selectedRoles, role).map(r => r.id)
          const deleteList = ArrayTool.subtractById(selectedRoles, currentRoles, role).map(r => r.id)
          apiRoles.updateRole(role.id, values.roleDescription, addList, deleteList, {
            otherHandler: code => {
              if (code === "sql_exist") {
                message.error("权限名称已经存在")
                return true
              } else {
                return false
              }
            }
          })

        })
    }
  }

  function deleteRole() {
    if (role) {
      apiRoles.deleteRole(role.id)
    }
  }

  useEffect(() => {
    initForm()
    getDistributableRoles()
    getTypeRoles()
    if (role) {
      getSelected(role.id)
    }
  }, [location]
  )
  let buttons: JSX.Element[]
  if (role) {
    buttons = [
      <Button type="primary" className={Module.btn} onClick={updateRole}>修改</Button>,
      <Button type="primary" className={Module.btn} onClick={deleteRole}>删除</Button>
    ]
  } else {
    buttons = [<Button type="primary" className={Module.btn} onClick={addRole}>增加</Button>]
  }

  let selectProps: SelectProps
  if (role) {
    selectProps = {
      disabled: true
    }
  } else {
    selectProps = {}
  }

  let tableProps: TableProps<Role>
  if (typeRole) {
    tableProps = {
      dataSource: roles.filter(role => (role.parents ?? []).includes(typeRole))
    }
  } else {
    tableProps = {}
  }
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 15 },
  };
  return (
    <div className={Module.all}>
      <div className={Module.form}>
        <Form
          {...layout}
          form={form}
        >
          <Form.Item
            name={"roleDescription"}
            label="权限名称"
            rules={[
              {
                required: true,
                message: '请输入权限名称!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="权限类型"
            name={"roleType"}
          >
            <Select onSelect={onSelected} {...selectProps}>
              {options}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className={Module.center}>
        <div className={Module.tab}>
          <Table
            columns={selectedColumns}
            expandable={{ childrenColumnName: "children1234" }}
            bordered
            className={Module.table}
            pagination={false}
            dataSource={currentRoles}
          />

          <Table
            columns={columns}
            expandable={{ childrenColumnName: "children1234" }}
            bordered
            className={Module.table}
            pagination={false}
            {...tableProps}
          />
        </div>
        {buttons}
      </div>
    </div>
  )
}
export default UserPermissions;
