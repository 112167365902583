import { Button, Form, Input, message, Select } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Role from "../entity/Role"
import { Template } from "../entity/Template"
import { TemplateParent } from "../entity/Templateparent"
import { ApiAdminTemplate } from "../internet/ApiAdminTemplate"
import ApiRoles from "../internet/ApiRoles"
import { ApiTemplate } from "../internet/ApiTemplate"
import Module from '../style/addtemplatemodule.module.scss'



const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
};
const { Option } = Select;
const AddTemplateItem: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm<TemplateParent>()
  const [roles, setRoles] = useState<Role[]>([])
  const apiRoles = new ApiRoles(message, navigate)
  const apiTemplate = new ApiTemplate(message, navigate)
  const location = useLocation()
  const add = () => {
  }
  return <div className={Module.all}>
    <Form
      form={form}
      {...layout}
      className={Module.form}
    >
      <h1>添加字符串</h1>
      <Form.Item
        className={Module.item}
        name={"templateId"}
        label="字符串模板名称"
        rules={[
          {
            required: true,
          }
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  </div>

}
export default AddTemplateItem