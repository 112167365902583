import InternetTool from "./Internetool";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiAdminConfiguration {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/configuration", message, navigate)
  }
  output(resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.ft
      (
        ``,
        {
          method: "Get"
        },
        resultParam
      )
  }
  input(file: File, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("file", file)
    this.internetTool.ft
      (
        "",
        {
          body: formData,
          method: "POST"
        },
        resultParam
      )
  }
}