import { Form, Input, Select } from "antd"
import { StringDatePicker } from "../component/StringDatePicker"
import { DocumentProps } from "../entity/DocumentProps"
import FieldEntity from "../entity/FieldEntity"
import Module from '../style/casesourceregister.module.scss'
const CaseSourceRegister: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.margin}>
              <h1 className={Module.h1}>案件来源登记表</h1>
              <div className={Module.center}>
                <div className={Module.casenumber}>
                  <Form.Item
                    className={Module.item}
                    name={[24, "fieldValue"]}
                  >
                    <Input
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 24, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案号" />
                  </Form.Item>
                </div>
                <div className={Module.table}>
                  <div className={Module.row1}>
                    <div className={Module.clumn1}>
                      <p>案源提供人</p>
                    </div>
                    <div className={Module.clumn2}>
                      <div className={Module.clumn21}>
                        <div className={Module.a1}><p>姓名或名称</p></div>
                        <div className={Module.a2}>
                          <Form.Item
                            name={[0, "fieldValue"]}
                          >
                            <Input
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="姓名或名称" />
                          </Form.Item>
                        </div>
                        <div className={Module.a3}><p>联系电话</p></div>
                        <div className={Module.a4}>
                          <Form.Item
                            name={[1, "fieldValue"]}
                          >
                            <Input
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="联系电话" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className={Module.clumn22}>
                        <div className={Module.b1}>
                          <p>地址</p>
                        </div>
                        <div className={Module.b2}>
                          <Form.Item
                            name={[2, "fieldValue"]}
                          >
                            <Input
                              disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                              placeholder="地址" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row2}>
                    <div className={Module.c1}><p>案件来源类型</p></div>
                    <div className={Module.c2}>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[3, "fieldValue"]}
                          className={Module.item1}
                          initialValue="☑"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 3, "fieldValue"])} />
                        </Form.Item>
                        <p>投诉、举报</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[4, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 4, "fieldValue"])} />
                        </Form.Item>
                        <p>上级机关、领导交办</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[5, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 5, "fieldValue"])} />
                        </Form.Item>
                        <p>其他机关移送</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[6, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 6, "fieldValue"])} />
                        </Form.Item>
                        <p>检查、巡查发现</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[7, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 7, "fieldValue"])} />
                        </Form.Item>
                        <p>双随机</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[8, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 8, "fieldValue"])} />
                        </Form.Item>
                        <p>其他</p>
                      </div>
                    </div>
                    <div className={Module.c3}><p>来源形式</p></div>
                    <div className={Module.c4}>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[9, "fieldValue"]}
                          className={Module.item1}
                          initialValue="☑"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 9, "fieldValue"])} />
                        </Form.Item>
                        <p>信息</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[10, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 10, "fieldValue"])} />
                        </Form.Item>
                        <p>电话</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[11, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 11, "fieldValue"])} />
                        </Form.Item>
                        <p>网络</p>
                      </div>
                      <div className={Module.ite}>
                        <Form.Item
                          name={[12, "fieldValue"]}
                          className={Module.item1}
                          initialValue="⬜"
                        >
                          <Input readOnly onClick={() => props.changeCheckbox(["fields", 12, "fieldValue"])} />
                        </Form.Item>
                        <p>其他</p>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row3}>
                    <div className={Module.c1}>
                      <p>接件时间</p>
                    </div>
                    <div className={Module.c2}>  <Form.Item
                      name={[13, "fieldValue"]}
                      className={Module.item}
                    >
                      <StringDatePicker
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        format={"YYYY年MM月DD日"} placeholder="借件时间" />
                    </Form.Item></div>
                    <div className={Module.c3}><p>接件人签名</p></div>
                    <div className={Module.c4}>
                      <Form.Item
                        name={[20, "fieldValue"]} className={Module.select1} >
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 20, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="主办人员签名">
                          {(props.users ?? []).map(user => <Select.Option
                            key={user.id.toString()}>{user.realName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[19, "fieldValue"]} className={Module.select1}>
                        <Select
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 19, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          placeholder="协办人员签名" >
                          {(props.users ?? []).map(user => <Select.Option
                            key={user.id.toString()}>{user.realName}</Select.Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row4}>
                    <div className={Module.d1}>
                      <p>案源登记内容</p>
                    </div>
                    <div className={Module.d2}>
                      <Form.Item
                        name={[14, "fieldValue"]} className={Module.select1}
                      >
                        <Input.TextArea
                          disabled={props.getPermission((props.form.getFieldValue(["fields", 14, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          autoSize={{ minRows: 2, maxRows: 20 }} placeholder="案源登记内容" className={Module.text} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={Module.row5}>
                    <div className={Module.d1}>
                      <p>案源交办意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[15, "fieldValue"]} className={Module.select1}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 15, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="案源交办意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[21, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[25, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 21, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[16, "fieldValue"]}
                        >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.time1} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row6}>
                    <div className={Module.d1}>
                      <p>分管领导意见</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[17, "fieldValue"]} className={Module.select1}
                        >
                          <Input.TextArea
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 17, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            autoSize={{ minRows: 2, maxRows: 4 }} placeholder="分管领导意见" className={Module.text} />
                        </Form.Item>
                      </div>
                      <div className={Module.time}>
                        <Form.Item
                          name={[22, "fieldValue"]} className={Module.select1}>
                          <Select
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 22, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="分管领导签名" >
                            {(props.users ?? []).map(user => <Select.Option
                              key={user.id.toString()}>{user.realName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[16, "fieldValue"]}
                        >
                          <StringDatePicker
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 16, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            className={Module.time1} format="YYYY年MM月DD日" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={Module.row7}>
                    <div className={Module.d1}>
                      <p>备注</p>
                    </div>
                    <div className={Module.d2}>
                      <div className={Module.input}>
                        <Form.Item
                          name={[18, "fieldValue"]} className={Module.select1}>
                          <Input
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 18, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                            placeholder="备注" />
                        </Form.Item>
                      </div>
                      <div className={Module.time1}>
                        <Form.Item
                          style={{ marginLeft: "2%" }}
                          className={Module.select}
                          name={[23, "fieldValue"]} >
                          <Select
                            placement="topLeft"
                            placeholder="印章"
                            disabled={props.getPermission((props.form.getFieldValue(["fields", 23, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                          >
                            {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form >
    </div>
  )

}
export default CaseSourceRegister