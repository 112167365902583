import Dictionary from "../entity/Dictionary"
import InternetTool from "./Internetool"
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
export class ApiAdminDictionary {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin/dictionary", message, navigate)
  }
  getDictionaryByType(typeId: number, resultParam?: ResultParam<Array<Dictionary>>) {
    const params = new URLSearchParams()
    params.append("typeId", typeId.toString())
    this.internetTool.ft(
      params,
      {
        method: "GET"
      },
      resultParam
    )

  }

  getDictionary(id: number, resultParam?: ResultParam<Dictionary>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  updateDictionary(dictionary: Dictionary, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("dictionary", new Blob([JSON.stringify(dictionary)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  deleteDictionary(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      }
      ,
      resultParam
    )
  }
  addDictionary(dictionary: Dictionary, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("dictionary", new Blob([JSON.stringify(dictionary)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )

  }
}