import LawPunishArticleLaw from "./LawPunishArticleLaw";
import LawPunishItem from "./LawPunishItem";

export class LawPunishArticle {
  id: number;
  punishId: number;
  punishLevel: number;
  articleValue: string;
  roleId: number;
  punishArticleLaws?: Array<LawPunishArticleLaw> = [];
  items?: Array<LawPunishItem> = []

  constructor(
    id: number,
    punishId: number,
    punishLevel: number,
    articleValue: string,
    roleId: number,
    punishArticleLaws?: Array<LawPunishArticleLaw>,
    items?: Array<LawPunishItem>
  ) {
    this.id = id
    this.items = items
    this.punishArticleLaws = punishArticleLaws
    this.punishId = punishId
    this.punishLevel = punishLevel
    this.articleValue = articleValue
    this.roleId = roleId
  }

  static punishLevels = ["轻微", "较轻", "一般", "较重"]
}