import { Form, Input, Select } from "antd"
import Module from '../style/crimalCaseTransferbookProof.module.scss'
import React from "react";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";


export default function CrimalCaseTransferbookProof(props: DocumentProps): JSX.Element {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div className={Module.table}>
              <h1 className={Module.h1}>涉嫌犯罪案件移送书送达回证</h1>
              <div className={Module.center}>
                <Form.Item
                  className={Module.item1}
                  name={[0, "fieldValue"]}
                >
                  <Input
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="当事人" />
                </Form.Item>
                <Form.Item
                  className={Module.item}
                  name={[5, "fieldValue"]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 15 }}
                    className={Module.textarea}
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="移送内容 "
                  />
                </Form.Item>
                <div className={Module.people}>
                </div>
                <div className={Module.people}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>生成时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[1, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="生成时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>印章管理：
                    </p>
                    <Form.Item
                      style={{ marginLeft: "2%" }}
                      className={Module.select1}
                      name={[3, "fieldValue"]} >
                      <Select placeholder="印章"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      >
                        {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className={Module.people1}>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>移送人：</p>
                    <Form.Item
                      style={{ width: "35%", marginLeft: 0 }}
                      name={[4, "fieldValue"]}>
                      <Select
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="移送人签名"
                      >
                        {(props.users ?? []).map(user => <Select.Option
                          key={user.id.toString()}>{user.realName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      style={{ width: "35%", marginLeft: 0 }}
                      name={[6, "fieldValue"]}>
                      <Select
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                        placeholder="移送人签名"
                      >
                        {(props.users ?? []).map(user => <Select.Option
                          key={user.id.toString()}>{user.realName}</Select.Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={Module.textsig}>
                    <p style={{ fontWeight: "bolder" }}>移送人签名时间：</p>
                    <Form.Item
                      style={{ width: "50%", marginLeft: "2%" }}
                      name={[2, "fieldValue"]}
                    >
                      <StringDatePicker format="YYYY年MM月DD日" placeholder="移送人签名时间"
                        disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}

