import { Button, Form, Input, message, Select, Upload } from 'antd';
import Module from '../style/wordmain.module.scss'
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Role from "../entity/Role";
import { ApiDocumentType } from "../internet/ApiDocumentType";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { ApiAdminDocumentType } from '../internet/ApiAdminDocumentType';
import { ApiAdminConfiguration } from '../internet/ApiAdminConfiguration';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 10 },
};

const WordMain: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const documentId = (location.state as { documentId?: number })?.documentId
  const masterId = (location.state as { masterId?: number })?.masterId
  const complate = (location.state as { complate?: boolean })?.complate
  const [form] = Form.useForm<{ name: string, type: number, file: File }>()
  const [roles, setRoles] = useState<Role[]>([])
  const apiAdminDocumentType = new ApiAdminDocumentType(message, navigate)
  const apiDocumentType = new ApiDocumentType(message, navigate)
  const apiAdminConfiguration = new ApiAdminConfiguration(message, navigate)
  function addDocumentType() {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "docx" && suffix !== "fodt")) {
          message.error("文件后缀名错误")
          return;
        }
        apiAdminDocumentType.addDocumentType(values.name, file, suffix, values.type, undefined, {
          successHandler: documentTypeId => {
            if (documentTypeId) {
              navigate("/wordsee", { replace: true, state: { documentTypeId: documentTypeId } })
            } else {
              message.error("添加失败, 请检查文件格式")
            }
          },
          otherHandler: (code) => {
            switch (code) {
              case "name_exist":
                message.error("文件名称已存在")
                return true
              default:
                message.error("添加失败, 请检查文件格式")
                return true
            }
          },
        })
      })
  }
  function accessories() {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "docx" && suffix !== "fodt")) {
          message.error("文件后缀名错误")
          return;
        }
        apiAdminDocumentType.addDocumentType(values.name, file, suffix, values.type, masterId, {
          successHandler: documentTypeId => {
            if (documentTypeId) {
              navigate("/wordsee", { replace: true, state: { documentTypeId: documentTypeId, masterId: masterId } })
            } else {
              message.error("添加失败, 请检查文件格式")
            }
          },
          otherHandler: (code) => {
            switch (code) {
              case "name_exist":
                message.error("文件名称已存在")
                return true
              default:
                message.error("添加失败, 请检查文件格式")
                return true
            }
          },
        })
      })
  }
  function updateFile(documentId: number) {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "docx" && suffix !== "fodt")) {
          message.error("文件后缀名错误")
          return;
        }
        apiAdminDocumentType.updateDocumentTypeFile(file, documentId, suffix, {
          successHandler: () => {
            message.success("修改成功")
            navigate("/wordsee")
          }
        })

      })
  }

  function configFile(arg: UploadChangeParam): File | undefined {
    const file = arg.file.originFileObj;
    if (file) {
      form.setFieldsValue({ name: file.name.replace(/(\.docx)|(\.fodt)/, "") })
      return file
    } else {
      form.setFieldsValue({ file: undefined })
      return undefined
    }
  }

  function getTypeRoles() {
    apiDocumentType.getTypeRoles({
      successHandler: roles => {
        if (roles) {
          const roles1 = Role.createList(roles)
          if (roles1.length) {
            const values = form.getFieldsValue()
            values.type = roles1[0].id
            form.setFieldsValue(values)
          }
          setRoles(roles1)
        }
      }
    })
  }

  function click() {
    if (documentId) {
      return updateFile(documentId)
    } else {
      return addDocumentType()
    }
  }
  useEffect(() => {
    getTypeRoles()
  }, [location])

  const input = () => {
    form.validateFields()
      .then(values => {
        const file = values.file
        const suffix = file.name.split(".").pop()
        if (!suffix || (suffix !== "zip")) {
          message.error("文件后缀名错误")
          return;
        }
        apiAdminConfiguration.input(file, {
          successHandler: () => {
            message.success("添加成功")
          }
        })
      })
  }
  const options: JSX.Element[] = roles.map(role => (
    <Select.Option key={role.id.toString()}
      value={role.id}>{role.roleDescription}</Select.Option>))
  let btn;
  if (documentId) {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <Form.Item
        label="文书模板名称"
        className={Module.ite}
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept=".docx,.fodt"
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择模板文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button className={Module.addFile} onClick={click}>确认上传</Button>
      </Form.Item>
    </Form>
  } else if (complate) {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <h1>文件上传</h1>

      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept=".zip"
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择模板文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button className={Module.addFile} onClick={input}>确认上传</Button>
      </Form.Item>
    </Form>
  } else if (masterId) {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <h1>附件上传</h1>
      <Form.Item
        label="附件文书模板名称"
        className={Module.ite}
        name="name"

      >
        <Input />
      </Form.Item>
      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept=".docx,.fodt"
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择模板文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button className={Module.addFile} onClick={accessories}>确认上传</Button>
      </Form.Item>
    </Form>
  } else {
    btn = <Form
      {...layout}
      className={Module.form}
      name="register"
      form={form}
      scrollToFirstError
    >
      <h1>文件上传</h1>
      <Form.Item
        label="文书模板名称"
        className={Module.ite}
        name="name"
        rules={[
          {
            required: true,
            message: '请输入文档名称!',
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="文件上传"
        className={Module.ite1}
        name="file"
        getValueFromEvent={configFile}
        required={true}
      >
        <Upload
          accept=".docx,.fodt"
          name="file"
          maxCount={1}
          showUploadList={false}
          customRequest={() => {
          }}
        >
          <Button icon={<UploadOutlined />}>选择模板文件</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        className={Module.ite}
        label="文书模板类型"
        name="type"
        required
      >
        <Select className={Module.select}>
          {options}
        </Select>
      </Form.Item>
      <Form.Item
        className={Module.ite}
      >
        <Button className={Module.addFile} onClick={addDocumentType}>确认上传</Button>
      </Form.Item>
    </Form>
  }
  return (
    <div className={Module.all}>
      <div className={Module.center}>
        {btn}
      </div>
    </div>
  );
};

export default WordMain;