import { Button, message, Table } from "antd"
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Role from "../entity/Role";
import { ApiDocumentType } from "../internet/ApiDocumentType";

const columns: ColumnsType<Role> = [
  {
    title: "文书模板类型名称",
    dataIndex: "roleDescription"
  },

]
const WordType: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([])
  const location = useLocation()
  const navigate = useNavigate()

  const apiDocumentType = new ApiDocumentType(message, navigate)
  useEffect(() => {
    apiDocumentType.getTypeRoles({
      successHandler: roles => {
        if (roles) {
          setRoles(roles)
        }
      }
    })
  }, [location])

  const rowClick = (role: Role) => {
    return {
      onClick: () => {
        navigate("/wordtypemain", { state: { role: role } })
      }
    }
  }

  function addTypeRole() {
    navigate("/wordtypemain")
  }

  return (
    <div>
      <Button style={{ float: "right" }} type="primary" onClick={addTypeRole}>添加</Button>
      <Table
        expandable={{ childrenColumnName: "children1234" }}
        columns={columns}
        bordered
        size="small"
        dataSource={roles}
        pagination={false}
        onRow={rowClick}
      />
    </div>
  )
}
export default WordType