import { Button, Card, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiCase } from '../internet/ApiCase';
import SuspectUnit from '../entity/SuspectUnit';
import Module from '../style/work.module.scss'
import IdCardTool from '../tool/IdCardTool';


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const Work: React.FC = () => {
  const [form] = Form.useForm<SuspectUnit>();
  const location = useLocation()
  const state = (location.state as { criminalUnitData?: SuspectUnit, caseId?: number })
  const caseId = state?.caseId
  const criminalUnitData = state?.criminalUnitData
  const navigate = useNavigate()
  const apiCase = new ApiCase(message, navigate)
  const configForm = (criminalUnitData: SuspectUnit) => {
    form.setFieldsValue(criminalUnitData)
  }

  useEffect(() => {
    if (!caseId) {
      navigate(-1)
    }
    if (criminalUnitData)
      configForm(criminalUnitData)
  }, [location])

  function addCriminalUnit(caseId: number) {
    form.validateFields()
      .then(suspectUnit => {
        suspectUnit.caseId = caseId
        apiCase.addSuspectUnit(suspectUnit)
      })
  }

  function deleteCriminalUnit(criminalUnitId: number) {
    if (criminalUnitId) {
      apiCase.deleteSuspectUnit(criminalUnitId)
    }

  }

  const updateCriminalUnit = (criminalUnitId: number, caseId: number) => {
    form.validateFields()
      .then(criminal => {
        criminal.caseId = caseId
        criminal.id = criminalUnitId
        apiCase.updateSuspectUnit(criminal)
      })
  }

  let button: JSX.Element = <></>
  const criminalUnitId = criminalUnitData?.id
  if (caseId)
    if (criminalUnitId) {
      button = (
        <Form.Item className={Module.item}>
          <Button type="primary" onClick={() => deleteCriminalUnit(criminalUnitId)}>删除</Button>
          <Button type="primary" onClick={() => updateCriminalUnit(criminalUnitId, caseId)}>确认修改</Button>
        </Form.Item>
      )
    } else {
      button = (
        <Form.Item className={Module.item}>
          <Button type="primary" onClick={() => addCriminalUnit(caseId)}>添加</Button>
        </Form.Item>
      )
    }
  const idCard = new IdCardTool()
  return (
    <div className={Module.all}>
      <Card className={Module.card}>
        <Form
          {...layout}
          name='嫌疑单位'
          form={form}
          className={Module.form}
        >
          <h1 style={{ fontSize: 20 }}>嫌疑单位信息</h1>
          <Form.Item
            name="unitCode"
            className={Module.item1}
            label="单位编码"

          >

            <Input />
          </Form.Item>
          <Form.Item
            name="unitName"
            className={Module.item}
            label="单位名称"
            rules={[
              {
                required: true,
                message: '该字段不能为空',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="unitType"
            className={Module.item}
            label="单位类型"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="representativeName"
            className={Module.item}
            label="法定代表人姓名"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="representativeIdentificationCard"
            className={Module.item}
            label="身份证号码"
            rules={[
              {
                required: true,
                message: "身份证号码格式错误",
                validator: (rule, value) => {
                  if (idCard.validIdCard(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('身份证号码格式错误!'));
                }
              },

            ]}

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="unitAddress"
            className={Module.item}
            label="单位地址"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="unitPhone"
            className={Module.item}
            label="单位电话"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="representativePhone"
            className={Module.item}
            label="法定代表人电话"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="representativeAddress"
            className={Module.item}
            label="法定代表人地址"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="principalName"
            className={Module.item}
            label="负责人姓名"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="principalIdentificationCard"
            className={Module.item}
            label="负责人身份证号码"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="principalPhone"
            className={Module.item}
            label="负责人手机号码"

          >
            <Input />
          </Form.Item>
          <Form.Item
            name="principalAddress"
            className={Module.item}
            label="负责人居住地址"

          >
            <Input />
          </Form.Item>
          {button}
        </Form>
      </Card>
    </div>
  );
};

export default Work;