import CaseEntity from "../entity/CaseEntity";
import InternetTool from "./Internetool";
import UnitEntity from "../entity/UnitEntity";
import { MessageApi } from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import UserEntity from "../entity/UserEntity";
export class ApiDepartment {
  readonly internetTool: InternetTool
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("department", message, navigate)
  }
  getUnits(resultParam?: ResultParam<Array<UnitEntity>>) {
    this.internetTool.fe
      (
        "",
        resultParam
      )
  }
  getUsers(resultParam?: ResultParam<Array<UserEntity>>) {
    this.internetTool.fe
      (
        "/users",
        resultParam
      )
  }
  selectCaseByUnitId(resultParam?: ResultParam<Array<CaseEntity>>) {
    this.internetTool.fe
      (
        "/getCase",
        resultParam
      )
  }
  selectAccomplishCaseByUnitId(resultParam?: ResultParam<Array<CaseEntity>>) {
    this.internetTool.fe
      (
        "/accomplishCase",
        resultParam
      )

  }
}