import { FolderAddOutlined } from "@ant-design/icons";
import { Button, Form, Tree } from "antd";
import React, { Key, useState } from "react";
import Module from '../style/lawitem.module.scss'
import { DataNode } from "./dataNode";


const LawItem: React.FC = () => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])


  function expandAll(data: DataNode<any>[]): string[] {
    return data.flatMap(node => {
      let childKeys: string[] = [];
      if (node.children) {
        childKeys = expandAll(node.children);
      }
      childKeys.push(node.key)
      return childKeys;
    })
  }

  function onExpand(expandedKeys: Key[]) {
    setExpandedKeys(expandedKeys.map(key => {
      return key.toString()
    }))
  }




  return (
    <div className={Module.all}>
      <div className={Module.cen}>

        <div className={Module.list}>
          <h1>法律条文</h1>
          <Form className={Module.form}>
            <Button type="text" onClick={() => {
            }}><FolderAddOutlined
                className={Module.icon} />增加</Button>
          </Form>
          <Tree
            className={Module.tree}
            expandedKeys={expandedKeys}
            onExpand={item => onExpand(item)}

          />

        </div>
      </div>
    </div>
  )
}
export default LawItem
