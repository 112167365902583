import { Button, Form, Input, Select } from "antd"
import Module from '../style/casereport.module.scss'
import { MinusOutlined } from "@ant-design/icons";
import { StringDatePicker } from "../component/StringDatePicker";
import { DocumentProps } from "../entity/DocumentProps";
import FieldEntity from "../entity/FieldEntity";

const { TextArea } = Input;
const CaseClosureReport: React.FC<DocumentProps> = (props) => {
  return (
    <div className={Module.all}>
      <Form
        className={Module.form}
        form={props.form}
        disabled={props.dis}
        onFieldsChange={props.onFieldsChange}
      >
        <Form.List name="fields">
          {fields => {
            return <div>
              <h1 className={Module.h1}>案件调查终结报告</h1>
              <div className={Module.center}>
                <div className={Module.text2}>
                  <Form.Item name={[12, "fieldValue"]} style={{ marginBottom: 0 }} className={Module.item1}>
                    <TextArea
                      style={{ border: "1px solid black" }}
                      autoSize={{ minRows: 2, maxRows: 20 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 12, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="调查理由" />
                  </Form.Item>
                </div>
                <div className={Module.text2}>
                  <p style={{ fontWeight: "bolder" }}>
                    当事人基本情况：
                  </p>
                  <Form.Item
                    name={[1, "fieldValue"]}
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 20 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      style={{ border: "1px solid black" }}
                    />
                  </Form.Item>
                  <p style={{ fontWeight: "bolder" }}>
                    案件来源、调查经过及采取行政强制措施的情况：</p>
                  <Form.Item
                    name={[2, "fieldValue"]}
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 20 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 2, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="案件来源、调查经过及采取行政强制措施的情况" style={{ border: "1px solid black" }} />
                  </Form.Item>
                </div>
              </div>
              <div className={Module.text1}>
                <p>
                  <p style={{ fontWeight: "bolder" }}>
                    调查认定的事实：
                  </p>
                  <Form.Item
                    name={[3, "fieldValue"]}
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 20 }}
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 3, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="调查认定的事实" style={{ border: "1px solid black" }} rows={2} />
                  </Form.Item>
                  <span style={{ fontWeight: "bolder" }}>上述事实，主要有以下证据证明：</span>
                </p>
                <Form.List name={[4, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 4])
                    return (<div className={Module.list}>
                      <>
                        {fields.map((field, index) => (
                          <Form.List name={[field.name]}>
                            {fields => (
                              <div className={Module.list1}>
                                <div className={Module.context1}>
                                  <Form.Item
                                    name={[1, "fieldValue"]}
                                    className={Module.page}
                                    initialValue={index + 1} // 从索引计算序号
                                  >
                                    <Input
                                      type={"number"}
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                      placeholder="序号"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label="证据内容"
                                    name={[0, "fieldValue"]}
                                    className={Module.item}
                                  >
                                    <Input
                                      disabled={props.getPermission((props.form.getFieldValue(["fields", 4, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                    />
                                  </Form.Item>
                                  <Button type="text" onClick={() => remove(field.name)}><MinusOutlined
                                    style={{ fontSize: 20 }} /></Button>
                                </div>
                              </div>
                            )}
                          </Form.List>
                        ))}
                        {props.genAddButton(fieldEntity, "添加证据", add)}
                      </>
                    </div>)
                  }}
                </Form.List>
              </div>
              <div>
                <p style={{ fontWeight: "bolder" }}>
                  案件性质：
                </p>
                <Form.Item
                  name={[13, "fieldValue"]}
                >
                  <TextArea
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 13, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    placeholder="案件性质" style={{ border: "1px solid black" }} autoSize={{ minRows: 2, maxRows: 20 }} />
                </Form.Item>
              </div>
              <div>
                <p style={{ fontWeight: "bolder" }}>
                  自由裁量理由等其他需要说明的事项：
                </p>
                <Form.Item
                  name={[5, "fieldValue"]}
                >
                  <TextArea
                    placeholder=" 自由裁量理由等其他需要说明的事项"
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 5, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    style={{ border: "1px solid black" }} autoSize={{ minRows: 2, maxRows: 20 }} />
                </Form.Item>
              </div>
              <div className={Module.text2}>
                <p style={{ fontWeight: "bolder" }}>
                  处理意见及依据：
                </p>
                <Form.Item
                  name={[0, "fieldValue"]} className={Module.item1}>
                  <TextArea
                    disabled={props.getPermission((props.form.getFieldValue(["fields", 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    style={{ border: "1px solid black" }} placeholder="处理意见及依据"
                    autoSize={{ minRows: 2, maxRows: 20 }} />
                </Form.Item>
                <Form.List name={[6, "children"]}>
                  {(fields, { add, remove }) => {
                    const fieldEntity = props.form.getFieldValue(["fields", 6]);
                    return (
                      <div className={Module.list}>
                        {fields.map((field, index) => {
                          return (
                            <div className={Module.list1}>
                              <Form.List name={field.name}>
                                {fields => (
                                  <div className={Module.context1}>
                                    <Form.Item
                                      name={[0, "fieldValue"]}
                                      className={Module.page}
                                      initialValue={index + 1} // 从索引计算序号
                                    >
                                      <Input
                                        type={"number"}
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "children", field.name, 0, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        placeholder="序号"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      label="："
                                      className={Module.item}
                                      name={[1, "fieldValue"]}
                                    >
                                      <Input
                                        disabled={props.getPermission((props.form.getFieldValue(["fields", 6, "children", field.name, 1, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                                        placeholder="处罚内容"
                                      />
                                    </Form.Item>
                                    <Button type="text" onClick={() => remove(field.name)}>
                                      <MinusOutlined style={{ fontSize: 20 }} />
                                    </Button>
                                  </div>
                                )}
                              </Form.List>
                            </div>
                          );
                        })}
                        {props.genAddButton(fieldEntity, "添加处罚", add)}
                      </div>
                    );
                  }}
                </Form.List>

              </div>
              <div className={Module.text1}>
                <div className={Module.textsig}>
                  <p style={{ fontWeight: "bolder" }}>办案人员：
                  </p>
                  <Form.Item
                    name={[8, "fieldValue"]} className={Module.select1} >
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 8, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="主办人员签名">
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[9, "fieldValue"]} className={Module.select1}>
                    <Select
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 9, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      placeholder="协办人员签名" >
                      {(props.users ?? []).map(user => <Select.Option
                        key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.textsig}>
                  <p style={{ fontWeight: "bolder" }}>印章管理：
                  </p>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    className={Module.select}
                    name={[10, "fieldValue"]} >
                    <Select placeholder="印章"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 10, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    >
                      {(props.seals ?? []).map(seal => <Select.Option key={seal.id.toString()}>{seal.sealName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.textsig}>
                  <p style={{ fontWeight: "bolder" }}>生成日期：
                  </p>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    name={[7, "fieldValue"]} className={Module.itemtime}>
                    <StringDatePicker
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"} className={Module.time} placeholder="生成日期" />
                  </Form.Item>
                </div>
                <div className={Module.textsig}>
                  <p style={{ fontWeight: "bolder" }}>办案机构负责人：</p>
                  <Form.Item
                    style={{ width: "50%", marginLeft: "2%" }}
                    name={[11, "fieldValue"]}>
                    <Select
                      placement="topLeft"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 11, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                    >
                      {(props.users ?? []).map(user => <Select.Option key={user.id.toString()}>{user.realName}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </div>
                <div className={Module.textsig}>
                  <p style={{ fontWeight: "bolder" }}>生成日期：
                  </p>
                  <Form.Item
                    style={{ marginLeft: "2%" }}
                    name={[7, "fieldValue"]} className={Module.itemtime}>
                    <StringDatePicker
                      placement="topLeft"
                      disabled={props.getPermission((props.form.getFieldValue(["fields", 7, "fieldValue"]) as FieldEntity)?.permissionRoleId)}
                      format={"YYYY年MM月DD日"}
                      className={Module.time}
                      placeholder="生成日期"
                    />

                  </Form.Item>
                </div>
              </div>
            </div>
          }}
        </Form.List>
      </Form>
    </div>
  )

}


export default CaseClosureReport