import {MinusSquareOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {Button, message, Table, Tabs} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Module from '../style/addpermissions.module.scss'
import Role from "../entity/Role";
import {ArrayTool} from "../tool/ArrayTool";
import ApiRoles from "../internet/ApiRoles";
import {Roles} from "../tool/Roles";

const {TabPane} = Tabs;

const Addpermissions: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const unitId = (location.state as { unitId?: number })?.unitId
  const [typeRoles, setTypeRoles] = useState<Role[]>([]);
  const [allRoles, setAllRoles] = useState<Role[]>([])
  const [typeRole, setTypeRole] = useState<number | undefined>()
  const [roles, setRoles] = useState<Role[]>([])
  const [currentRoles, setCurrentRoles] = useState<Role[]>([])
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
  const [load, setLoad] = useState<{ s: boolean, r: boolean }>({s: false, r: false})
  const apiRoles = new ApiRoles(message, navigate)

  function setRoleList() {
    setRoles(ArrayTool.subtractById(allRoles, currentRoles))
  }

  useEffect(setRoleList, [load])


  function selectRole(role1: Role) {
    const sr = [...currentRoles]
    sr.push(role1)
    setCurrentRoles(sr)
    setRoles(ArrayTool.subtractById(allRoles, sr))
  }

  function unselectRole(role1: Role) {
    const sr = [...currentRoles]
    const index = sr.indexOf(role1)
    sr.splice(index, 1)
    setCurrentRoles(sr)
    setRoles(ArrayTool.subtractById(allRoles, sr))

  }

  function updateUnitRoles() {
    if (unitId) {
      const addList = ArrayTool.subtractById(currentRoles, selectedRoles).map(r => r.id)
      const deleteList = ArrayTool.subtractById(selectedRoles, currentRoles).map(r => r.id)
      apiRoles.updateUnitRoles(unitId, addList, deleteList)
    }
  }

  function getTypeRoles() {
    apiRoles.getTypeRoles(Roles.bind.unit.id, {
      successHandler: typeRoles => {
        if (typeRoles) {
          const roles = Role.createList(typeRoles)
          const role = new Role(0, "", "所有角色", [], []);
          setTypeRoles([role, ...roles])
          setTypeRole(0)
        }
      }
    })
  }


  function onSelected(key: string) {
    setTypeRole(parseInt(key))
  }


  const currentColumns: ColumnsType<Role> = [
    {
      title: '已有权限',
      dataIndex: 'id',
      render: (text, record) => {
        return (<span>{record.roleDescription}<MinusSquareOutlined onClick={() => {
          unselectRole(record)
        }} style={{float: "right"}}/></span>)
      }
    },
  ]

  const columns: ColumnsType<Role> = [
    {
      title: '可添加权限',
      dataIndex: 'id',
      render: (text, record) => {
        return (<span>{record.roleDescription}<PlusSquareOutlined onClick={() => {
          selectRole(record)
        }} style={{float: "right"}}/></span>)
      }
    },
  ]

  const getDistributableRoles = () => {
    apiRoles.getDistributableRoles({
      successHandler: distributableRoles => {
        if (distributableRoles) {
          const roles = Role.createList(distributableRoles)
          setAllRoles(roles)
          setRoles(ArrayTool.subtractById(roles, currentRoles))
          setLoad({s: load.s, r: true})
        }
      }
    })
  }

  function getSelected(unitId: number) {
    apiRoles.getUnitRole(unitId, {
      successHandler: selectedRoles => {
        if (selectedRoles) {
          const roles = Role.createList(selectedRoles)
          setSelectedRoles(roles)
          setCurrentRoles(roles)
          setRoles(ArrayTool.subtractById(allRoles, roles))
          setLoad({s: true, r: load.s})
        }
      }
    })
  }

  useEffect(() => {
    if (!unitId) {
      navigate(-1)
    } else {
      getTypeRoles()
      getDistributableRoles()
      getSelected(unitId)
    }
  }, [location])

  let filter
  if (typeRole === undefined) {
    filter = function (_: Role) {
      return false
    }
  } else if (typeRole !== 0) {
    filter = function (role: Role) {
      return (role.parents ?? []).includes(typeRole)
    }
  } else {
    const parents = typeRoles.map(role => role.id)
    filter = function (role: Role) {
      return (role.parents ?? []).some(id => parents.includes(id))
    }
  }
  const tabs = typeRoles.map(typeRole => {
    return (
        <TabPane className={Module.tabp} tab={typeRole.roleDescription} key={typeRole.id}/>
    )
  })
  return (
      <div className={Module.all}>
        <div className={Module.center}>
          <Tabs className={Module.tab} onChange={onSelected} tabPosition='left'>
            {tabs}
          </Tabs>
          <Table
              className={Module.table}
              columns={currentColumns}
              expandable={{childrenColumnName: "children1234"}}
              dataSource={currentRoles.filter(filter)}
              pagination={false}
          />
          <Table
              className={Module.table}
              columns={columns}
              expandable={{childrenColumnName: "children1234"}}
              pagination={false}
              dataSource={roles.filter(filter)}
          />
        </div>
        <Button className={Module.btn} onClick={updateUnitRoles}>确认修改</Button>
      </div>
  )
}
export default Addpermissions;
