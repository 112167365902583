import Module from '../style/allSummaryData.module.scss'
import React from "react";
import { Outlet } from "react-router-dom";




const AllSummaryData: React.FC = () => {

  return (
    <div className={Module.all}>
      <Outlet />
    </div>
  );
};

export default AllSummaryData;